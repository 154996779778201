/* General imports */
import React from 'react';
import styled from 'styled-components';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { theme } from '../../app/config';

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 400px;
    min-height: 400px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.form`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    box-sizing: border-box;
    flex-direction: column;
`

const Inputbox = styled.div`
    width: 90%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 20px;
    margin: 10px 0;
    box-sizing: border-box;
    height: 48px;
    border-bottom: 1px solid black;
    ${(props) => props.error ? 'border-color: red;' : ''};
    label {
        position: absolute;
        top: ${(props) => props.effect ? '-10px' : '18px' };
        left: 0;
        color: #ccc;
        transition: .5s;
        pointer-events: none;
        font-size: ${(props) => props.effect ? '16px' : '20px'};
        height: 16px;
    }

    input {
        border: none;
        outline: none;
        background: none;
        color: black;
        font-size: 18px;
        width: calc(100% - 35px);
        padding-bottom: 12px;
        margin-top: 16px;
        box-sizing: border-box;
        height: 32px;
    }

    input:focus + label {
        top: -10px;
        color: black;
        font-size: 16px;
    }

    select:focus + label {
        top: -10px;
        color: black;
        font-size: 16px;
    }
`

const Select = styled.select`
    background: transparent;
    border: none;
    font-size: 18px;
    height: 30px;
    width: 100%;
    padding-bottom: 12px;
    margin-top: 16px;
    height: 32px;
`

const Option = styled.option`
    font-size: 18px;
`

const StyledButton = styled.button`
    width: calc(${(props) => props.columWidth ? props.columWidth : '100%'} * 0.75);
    height: 44px;
    background-color: ${theme.default.primaryButtonBg};
    color: ${theme.default.primaryButtonText};
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin-top: 25px;
    margin-bottom: 25px;
`

/* ----- Principal function ----- */
function ModalAddSupplier({ title, textButton, openModal, closeModal, handleAction, supList }) {

    const [data, setData ] = React.useState({
        idSupplier: '',
        name: '',
        price: 0.00
    })

    const [ suppliers, setSuppliers ] = React.useState(null);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if(name === 'idSupplier'){
            const sup = suppliers.find(element => element.idSupplier === parseInt(value));
            setData({
                ...data,
                [name]: value,
                name: sup.name
            })
        } else {
            setData({
                ...data,
                [name]: value
            })
        }
    }

    const submit = (e) => {
        e.preventDefault();
        handleAction(data);
    }

    React.useEffect(() => {
        let config = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!suppliers){
            axios.get('/api/suppliers' ,config)
            .then(suppliersData => {
                if(supList.length === 0){
                    setSuppliers(suppliersData.data.rows)    
                } else {
                    let sups = [];
                    suppliersData.data.rows.forEach(element => {
                        if(!supList.find(sup => parseInt(sup.idSupplier) === parseInt(element.idSupplier))){
                            sups.push(element)
                        }
                    });
                    
                    setSuppliers(sups);
                }
            })
            .catch(err => {
                alert('Hubo un error al cargar los proveedores')
            })
        }
        
    }, [suppliers, setSuppliers, supList])

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ModalBody onSubmit={submit}>
                        <Inputbox  type='select' effect={ data.type !== '' ? true : false} >
                            <Select id='idSupplier' value={data.idSupplier} required={true} name='idSupplier' onChange={handleChange}>
                                <Option value="" hidden></Option>
                                {
                                    suppliers && suppliers.map((supplier, index) => 
                                        <Option key={`optionSup${index}`} value={supplier.idSupplier}>{supplier.name}</Option>        
                                    )
                                }
                            </Select>
                            <label>Proveedor*</label>
                        </Inputbox>
                        <Inputbox  type='text' effect={ true} >
                            <input id='price' required={true} name='price' type='number' value={data.price}  onChange={handleChange} />
                            <label>Precio*</label>
                        </Inputbox>
                        <StyledButton > {textButton} </StyledButton>
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalAddSupplier;