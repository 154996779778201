import React from 'react'
import styled from 'styled-components'
import ImageContent from './ImageContent'
import TwoColumns1Content from './TwoColumns1Content'
import TwoColumns2Content from './TwoColumns2Content'
import VideoContent from './VideoContent'

const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`

const Nothing = styled.div`
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #001554 0%, #2196F382 100%);
`




const Slide = (props) => {

    const getSlide = () => {
        switch (props.slideType) {
            case 'videocontent':
                    return <VideoContent data={props.data} />
                break;
            case 'twocolumns1':
                    return <TwoColumns1Content data={props.data} />
                    
                break;
            case 'twocolumns2':
                    return <TwoColumns2Content data={props.data} />
                    
                break;
            case 'imagecontent':
                    return <ImageContent data={props.data} />
                break;
            default:
                return <Nothing />
                break;
        }
    }

    return (
        <Root>
            {
                props.data && getSlide()
            }
        </Root>
    );
}

export default Slide;