/* General imports */
import React from 'react'
import styled, {keyframes} from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../../app/config'
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner';
import Slide from './Slide';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
/* import NewsLetter from './NewsLetter'; */

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

const Show = keyframes`
    0% {
        z-index: 1;
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        z-index: 1;
        transform: scale(1);
        opacity: 1;
    }
`
const Hide = keyframes`
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
`

/* Styled components */
const Root = styled.section`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: ${(props) => props.height ? props.height : "100vh"};
    min-height: ${(props) => props.minHeight ? props.minHeight : "100vh"};
    align-items: center;
    justify-content: center;
    background: ${(props) => props.background ? props.background : "transparent"};
    font-family: 'IBM Plex Sans';
`

const Container = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    border-bottom: 1px solid #00000029;
`

const Slider = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: ${(props) => props.slides ? `calc(${props.slides} * 100vw)` : '100vw'};
    height: 100%;
    transform: ${(props) => `translateX(calc(${props.activeIndex} * -100vw))`} ;
    transition: all 300ms ease-in-out;
    scroll-behavior: smooth;
`

const DotWrapper = styled.div`
    position: absolute;
    display: flex;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 99;
`

const Dot = styled.div`
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background: ${(props) => props.active ? '#FFF' : 'rgba(255,255,255,0.5)'};
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
`

const BackButton = styled.button`
    border: none;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    z-index: 999;
    color: #c9c9c9;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
`

const ForwardButton = styled.button`
    border: none;
    background: transparent;
    position: absolute;
    top: 50%;
    left: calc(100vw - 70px);
    transform: translateY(-50%);
    z-index: 999;
    color: #c9c9c9;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
`

/* ----- Principal function ----- */
function Carousel({ idsection }) {

    const [ activeIndex, setActiveIndex ] = React.useState(0);
    const [ carousels, setCarousels ] = React.useState(null);
    const [ sectionData, setSectionData ] = React.useState(null);
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    const moveSliderRight = () => {
        if(activeIndex < carousels.length -1){
            setActiveIndex(activeIndex + 1)
        } else {
            setActiveIndex(0)
        }
    }

    const moveSliderLeft = () => {
        if(activeIndex > 0){
            setActiveIndex(activeIndex - 1)
        } else {
            setActiveIndex(carousels.length -1)
        }
    }

    const back = (e) => {
        e.preventDefault();
        moveSliderLeft();
    }

    const forward = (e) => {
        e.preventDefault();
        moveSliderRight();
    }

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        if (touchStart - touchEnd > 150) {
            // do your stuff here for left swipe
            moveSliderRight();
        }

        if (touchStart - touchEnd < -150) {
            // do your stuff here for right swipe
            moveSliderLeft();
        }
    }

    React.useEffect(() => {
        if(!carousels){
            let config = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/carousels/' + idsection,config)
            .then(carouselsData => {
                setCarousels(carouselsData.data.carousels);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [activeIndex, carousels, idsection])

    React.useEffect(() => {
        if(!sectionData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(response => {
                setSectionData(response.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [sectionData, idsection])

    

    return(
        <React.Fragment>
            <Desktop>
                <Root minHeight="calc(100vh - 65px)" height="calc(100vh - 65px)">
                    <Container  onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                        {
                            !carousels ? <Spinner spinnerType='ThreeDots' />
                            :
                            sectionData && 
                            <Slider background={sectionData.background} activeIndex={activeIndex} slides={carousels.length}>
                                {
                                    carousels.map((slide, index) => 
                                        <Slide key={`Slide_${index}`} slideType={slide.slideType} data={slide} />
                                    )
                                }
                            </Slider>
                        }
                        <BackButton onClick={back}><ArrowBack /></BackButton>
                        <ForwardButton onClick={forward}><ArrowForward /></ForwardButton>
                        {/* <NewsLetter /> */}
                    </Container>
                </Root>
            </Desktop>
            <Tablet>
                <Root minHeight="calc(100vh - 65px)" height="calc(100vh - 65px)">
                    <Container  onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                        {
                            !carousels ? <Spinner spinnerType='ThreeDots' />
                            :
                            sectionData && 
                            <Slider background={sectionData.background} activeIndex={activeIndex} slides={carousels.length}>
                                {
                                    carousels.map((slide, index) => 
                                        <Slide key={`Slide_${index}`} slideType={slide.slideType} data={slide} />
                                    )
                                }
                            </Slider>
                        }
                        <BackButton onClick={back}><ArrowBack /></BackButton>
                        <ForwardButton onClick={forward}><ArrowForward /></ForwardButton>
                        {/* <NewsLetter /> */}
                    </Container>
                </Root>
            </Tablet>
            <Mobile>
                <Root minHeight="calc(100vh - 50px)" height="calc(100vh - 50px)">
                    <Container  onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                        {
                            !carousels ? <Spinner spinnerType='ThreeDots' />
                            :
                            sectionData && 
                            <Slider background={sectionData.background} activeIndex={activeIndex} slides={carousels.length}>
                                {
                                    carousels.map((slide, index) => 
                                        <Slide key={`Slide_${index}`} slideType={slide.slideType} data={slide} />
                                    )
                                }
                            </Slider>
                        }
                        {
                            carousels &&
                            <DotWrapper>
                                {
                                    carousels.map((dot, index) => 
                                        <Dot key={`dot_${index}`} active={index === activeIndex} />
                                    )
                                }
                            </DotWrapper>
                        }
                    </Container>
                </Root>
            </Mobile>
        </React.Fragment>
    );
}

export default Carousel;