/* General imports */
import React from 'react'
import styled from 'styled-components'

/* Styled components */
const InputPrice = styled.input`
    text-align: center;
    border: none;
`

const InputChange = ({inputvalue, index, changePrice}) => {

    const [value, setValue] = React.useState(null);

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const onKeyUpCode = (e) => {
        const keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13') {
            // eslint-disable-next-line no-restricted-globals
            if(confirm(`¿Está seguro/a que desea cambiar el precio a $${e.target.value}?`)){
                /* setValue(e.target.value); */
                changePrice(e.target.dataset.index, e.target.value);
            } else {
                setValue(inputvalue);  
            }
        }
        if(keycode == '27') {
            setValue(inputvalue);
        }
    }

    const onBlur = (e) => {
        e.preventDefault();
        /* setValue(props.value); */
    }

    React.useEffect(() => {
        if(value !== inputvalue){
            setValue(inputvalue);
        }
    }, [ inputvalue ])

    return (
        value !== null && <InputPrice data-index={index} onBlur={onBlur} onChange={onChange} onKeyUp={onKeyUpCode} type="number" value={value} />
    );
}

export default InputChange;