/* General imports */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { invoicesActions } from './InvoicesActions'
import { alertActions } from '../alert/AlertActions'

/* Components imports */
import Button from '@material-ui/core/Button';
import Logo from '../images/DIETETICA_SANTA_LUCIA_LOGO.jpg';

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const Container = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 50px);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
`

const Voucher = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    @media all {
        .page-break {
            display: none;
        }
    }

    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
    }

    @media print {
        .page-break {
            /* margin-top: 1rem; */
            display: block;
            page-break-before: auto;
        }
    }

    @page {
        size: auto;
        margin: 2.5mm;
    }
`

const VoucherContainer = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 1102px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
`

const InvoiceHeader = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 200px;
`

const TransmitterData = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    border: 1px solid black;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 20px;
    span {
        font-size: 14px;
    }
    span:first-child {
        font-weight: bold;
        font-size: 25px;
        align-self: center;
        margin-bottom: 10px;
    }
`

const InvoiceGrlData = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    border: 1px solid black;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 20px 20px 20px 50px;
    span {
        font-size: 14px;
    }
    span:first-child {
        font-weight: bold;
        font-size: 25px;
        margin-bottom: 10px;
    }
    span:nth-child(3) {
        margin-bottom: 10px;
    }
`

const InvoiceTypeIndicator = styled.div`
    position: absolute;
    display: flex;
    box-sizing: border-box;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    font-size: 20px;
    font-weight: 10;
    border: 1px solid black;
    background: white;
    padding: 5px;
    span {
        font-size: 14px;
        font-weight: bold;
    }
    span:first-child {
        font-size: 45px;
        align-self: center;
    }
`

const InvoiceDates = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border: 1px solid black;
`

const ReceiverData = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    border: 1px solid black;
`

const ReceiverDataBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
`

const InvoiceBody = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const InvoiceBodyFields = styled.ul`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    padding: 0;
    margin: 0;
`

const HeaderField = styled.li`
    position: relative;
    display: flex;
    box-sizing: border-box;
    background: grey;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    width: ${(props) => props.width ? props.width : '100px' };
    font-size: 0.75rem;
`

const InvoiceBodyDataContent = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    min-height: 450px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid black;
`

const InvoiceBodyRowData = styled.ul`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    padding: 0;
    margin: 0;
`

const DataField = styled.li`
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.width ? props.width : '100px' };
    ${(props) => props.wordWrap ? 'word-wrap: anywhere;' : ''}
`

const InvoiceFooter = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    align-items: space-evenly;
    justify-content: flex-end;
    border: 1px solid black;
    border-radius: 0 0 10px 10px;
    div:first-child {
        position: absolute;
        left: 0;
        padding-left: 25px;
        align-items: flex-start;
    }
`

const InvoiceFooterBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: ${(props) => props.width ? props.width : '20%' };
    height: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: ${(props) => props.alignH ? props.alignH : 'flex-end' };
    justify-content: space-evenly;
`

const Footer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
`

const VoucherLogo = styled.img`
    height: 80px;
    width: auto;
`

const VoucherHeader = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 40px;
    border-top: solid 1px black;
    border-left: solid 1px black;
    border-right: solid 1px black;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
`

const VoucherFooter = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
`


/* ----- Principal function ----- */
function TicketConfirm({ rowData, voucherPrintRef, invoicesFields, getFields, onBack, handleFinish }) {

    const getDateInvoice = (d) => {
        const result = d.split('-')
        return result[2]+'/'+result[1]+'/'+result[0]
    }

    const getAliquotAmount = (t, sub) => {
        switch (parseInt(t)) {
            case 3:
                return sub;
            case 4:
                return (Math.round((sub / 1.105)*100) / 100).toFixed(2);
            case 5:
                return (Math.round((sub / 1.21)*100) / 100).toFixed(2);        
            default:
                break;
        }
    }

    const getAliquotType = (t) => {
        switch (parseInt(t)) {
            case 3:
                return '0.00';
            case 4:
                return '10.50';
            case 5:
                return '21.00';        
            default:
                return '0.00'
        }
    }

    React.useEffect(() => {
        if(!invoicesFields){
            getFields()
        }
    }, [ invoicesFields, getFields ])
    
    return(
        <Root>
            <Container>
            {
                invoicesFields ?
                <Voucher ref={voucherPrintRef}>
                {
                    ["ORIGINAL", "DUPLICADO", "TRIPLICADO"].map((title, index) =>
                    <VoucherContainer key={`voucher_${title}`}>
                        <VoucherHeader>{title}</VoucherHeader>
                        <InvoiceHeader>
                            <TransmitterData>
                                <VoucherLogo src={Logo} alt="logo" />
                                <span>santalucia.jintand.com</span>
                                <span><strong>Razón Social:</strong> {rowData.transmitterBusinessName} </span>
                                <span><strong>Domicilio Comercial:</strong> {rowData.transmitterCommercialAddress}</span>
                                <span><strong>Condición frente al IVA: {invoicesFields.generalData.find(item => (item.name === 'transmitterIvaCondition')).options.find(item => (parseInt(item.value) === parseInt(rowData.transmitterIvaCondition))).name} </strong></span>
                            </TransmitterData>
                            <InvoiceGrlData>
                                <span>{invoicesFields.generalData.find(item => (item.name === 'invoiceTypeCode')).options.find(item => (parseInt(item.value) === parseInt(rowData.invoiceTypeCode))).key === "X" ? 'TICKET DE COMPRA' : 'FACTURA'}</span>
                                <span><strong>Punto de Venta: {rowData.pointSale} &nbsp;&nbsp;&nbsp;&nbsp; Comp. Nro: {rowData.voucherNumber}</strong></span>
                                <span><strong>Fecha de Emisión: {getDateInvoice(rowData.invoiceDate)}</strong></span>
                                <span><strong>CUIT:</strong> 30-71586015-1</span>
                                <span><strong>Ingresos Brutos:</strong> {rowData.transmitterGrossIncome}</span>
                                <span><strong>Fecha de Inicio de Actividades:</strong> {getDateInvoice(rowData.transmitterActivityStartDate)}</span>
                            </InvoiceGrlData>
                            <InvoiceTypeIndicator>
                                <span> {invoicesFields.generalData.find(item => (item.name === 'invoiceTypeCode')).options.find(item => (parseInt(item.value) === parseInt(rowData.invoiceTypeCode))).key} </span>
                                <span>COD. {invoicesFields.generalData.find(item => (item.name === 'invoiceTypeCode')).options.find(item => (parseInt(item.value) === parseInt(rowData.invoiceTypeCode))).name}</span>
                            </InvoiceTypeIndicator>
                        </InvoiceHeader>
                        <InvoiceDates>
                            <span><strong>Período Facturado Desde:</strong> {getDateInvoice(rowData.invoicedPeriodFrom)}</span>
                            <span><strong>Hasta:</strong> {getDateInvoice(rowData.invoicedPeriodTo)}</span>
                            <span><strong>Fecha de Vto. para el pago:</strong> {getDateInvoice(rowData.paymentDueDate)}</span>
                        </InvoiceDates>
                        <ReceiverData>
                            <ReceiverDataBox>
                                <span><strong>CUIT:</strong> {rowData.receiverCuit}</span>
                                <span><strong>Condición frente al IVA:</strong> {invoicesFields.receiverData.find(item => (item.name === 'receiverIvaCondition')).options.find(item => (parseInt(item.value) === parseInt(rowData.receiverIvaCondition))).name}</span>
                                <span><strong>Condición de venta:</strong> {invoicesFields.receiverData.find(item => (item.name === 'saleCondition')).options.find(item => (parseInt(item.value) === parseInt(rowData.saleCondition))).name}</span>
                            </ReceiverDataBox>
                            <ReceiverDataBox>
                                <span><strong>Razón Social:</strong> {rowData.receiverBusinessName}</span>
                                <span><strong>Domicilio:</strong> {rowData.receiverCommercialAddress}</span>
                            </ReceiverDataBox>
                        </ReceiverData>
                        <InvoiceBody>
                            <InvoiceBodyFields>
                                <HeaderField width='10%'>Código</HeaderField>
                                <HeaderField width='30%'>Producto/Servicio</HeaderField>
                                <HeaderField width='10%'>Cantidad</HeaderField>
                                <HeaderField width='10%'>U. Medida</HeaderField>
                                <HeaderField width='10%'>Precio Unit.</HeaderField>
                                <HeaderField width='10%'>Subtotal</HeaderField>
                                <HeaderField width='10%'>Alicuota IVA</HeaderField>
                                <HeaderField width='10%'>Subtotal c/IVA</HeaderField>
                            </InvoiceBodyFields>
                            <InvoiceBodyDataContent>
                                {
                                    (rowData.invoiceDetails.length > 0) ? 
                                        rowData.invoiceDetails.map((element, index) => 
                                            <InvoiceBodyRowData key={`InvoiceDetail${index}`}>
                                                <DataField width='10%'> {element.code} </DataField>
                                                <DataField width='30%' wordWrap={true}> {element.concept} </DataField>
                                                <DataField width='10%'> {element.quantity} </DataField>
                                                <DataField width='10%'> {invoicesFields.invoiceDetailData.find(item => (item.name === 'unitMeasurement')).options.find(item => (parseInt(item.value) === parseInt(element.unitMeasurement))).name} </DataField>
                                                <DataField width='10%'> {element.unitPrice} </DataField>
                                                <DataField width='10%'> {getAliquotAmount(rowData.afipAliquotType !== '' ? rowData.afipAliquotType : 3, element.subtotal)} </DataField>
                                                <DataField width='10%'> {getAliquotType(rowData.afipAliquotType)} </DataField>
                                                <DataField width='10%'> {element.subtotal} </DataField>
                                            </InvoiceBodyRowData>
                                        )
                                    :
                                        <span> NO HAY INFORMACIÓN PARA MOSTRAR</span>
                                }
                                
                            </InvoiceBodyDataContent>
                        </InvoiceBody>
                        <InvoiceFooter>
                            <InvoiceFooterBox width='30%'>
                                {/* <span><strong>CAE Nº:</strong></span>
                                <span>{rowData.caeNumber}</span>
                                <span><strong>Fecha de Vto. de CAE:</strong></span>
                                <span>{getDateInvoice(rowData.caeExpirationDate)}</span> */}
                            </InvoiceFooterBox>
                            <InvoiceFooterBox>
                                <span><strong>Imp. Neto Grav.: $</strong></span>
                                <span><strong>IVA 21%: $</strong></span>
                                <span><strong>IVA 10.5%: $</strong></span>
                                <span><strong>IVA 0%: $</strong></span>
                                <span><strong>Imp. Otros Trib.: $</strong></span>
                                <span><strong>Importe Total: $</strong></span>
                            </InvoiceFooterBox>
                            <InvoiceFooterBox width='12.5%'>
                                <span><strong> {rowData.subtotal} </strong></span>
                                <span><strong> {parseInt(rowData.afipAliquotType) === 5 ? rowData.afipAliquotAmount : 0.00} </strong></span>
                                <span><strong> {parseInt(rowData.afipAliquotType) === 4 ? rowData.afipAliquotAmount : 0.00} </strong></span>
                                <span><strong> {parseInt(rowData.afipAliquotType) === 3 ? rowData.afipAliquotAmount : 0.00} </strong></span>
                                <span><strong> {rowData.amountOtherTaxes} </strong></span>
                                <span><strong> {rowData.total} </strong></span>
                            </InvoiceFooterBox>
                        </InvoiceFooter>
                        <VoucherFooter>{`Página ${index + 1}/3`}</VoucherFooter>
                    </VoucherContainer>
                    )
                }
                </Voucher>
            :
                <h2>CARGANDO...</h2>
            }
            </Container>
            <Footer>
                {
                    onBack && <Button onClick={onBack}>ATRÁS</Button>
                }
                <Button onClick={handleFinish}>FINALIZAR</Button>
            </Footer>
        </Root>
    )
}

function mapState(state) {
    const { invoicesFields } = state.invoice;
    return { invoicesFields};
}

const actionCreators = {
    getFields: invoicesActions.getFields,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(TicketConfirm);