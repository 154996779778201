import { pointSaleConstants } from './PointSaleConstants';

let items = JSON.parse(localStorage.getItem('items'));
let saledata = JSON.parse(localStorage.getItem('saledata'));
const initialState = items ? 
  { 
      error: false,
      isFetching: false,
      items: items,
      saledata: saledata ? 
      {
        ...saledata,
        saleDate: new Date().toISOString().slice(0, 10),
      }
      : 
      {
        customer: 0,
        saleDate: new Date().toISOString().slice(0, 10),
        storage: 2,
        paymentMethod: 10,
        observation: ''
      }
  } : 
  { 
    error: false,
    isFetching: true,
    items: [],
    saledata: saledata ? 
    {
      ...saledata,
      saleDate: new Date().toISOString().slice(0, 10),
    }
    : 
    {
      customer: 0,
      saleDate: new Date().toISOString().slice(0, 10),
      storage: 2,
      paymentMethod: 10,
      observation: ''
    }
  };

export function pointsale(state = initialState, action) {
  switch (action.type) {
    case pointSaleConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case pointSaleConstants.ADD:
      return { 
            ...state,
            isFetching: false,
            items: action.items
        };
    case pointSaleConstants.ADDSALEDATA:
      return { 
            ...state,
            isFetching: false,
            saledata: action.data
        };
    case pointSaleConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false
        };
    case pointSaleConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}