import React from "react";
import styled from 'styled-components';
import translate from "../../providers/i18n/translate";
import Row from "./Row";
import HeaderCell from "./HeaderCell";
import OrderButton from "./OrderButton";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";


const Root = styled.thead`
    position: sticky;
    top: 0;
    width: 100%;
    vertical-align: middle;
    z-index: 2;
`

const StyledCheckbox = styled(Checkbox)`
    &.Mui-checked{
        color: #122230!important;
    }
`


const Head = ({ actions, columnsTable, columnOrder, onOrder }) => {

    const handleOrder = (name, order) => {
        onOrder(name, order);
    }

    const handleSelect = (e) => {
        const checked = e.target.checked;
        actions.select.handleChangeAll(checked);
    };

    const handleIndeterminate = () => {
        const selected = actions?.select?.selectedItems;
        const dataset = actions?.select?.dataset;
        if (selected.size === 0) {
            return false
        }
        if (selected.size < dataset.length) {
            return true
        };
        return dataset.some((i) => !selected.has(i.idProduct))
    };

    return (
        <Root>
            <Row>
                {
                    actions?.select ?
                        <HeaderCell width="auto" align="left">
                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        checked={!actions.select.dataset?.some((i) => !actions.select.selectedItems?.has(i.idProduct))}
                                        indeterminate={handleIndeterminate()}
                                        onChange={handleSelect}
                                        style={{position: 'relative' ,zIndex: 9999}}
                                    />
                                }
                            />
                        </HeaderCell>
                        :
                        null
                }
                {
                    columnsTable && columnsTable.map((ct, index) =>
                        <HeaderCell key={`headercell_${index}`} width={`${ct.width}`} hidden={ct.hidden} hiddenMobile={(ct.mobile !== null && ct.mobile !== undefined) ? !ct.mobile : false} align={ct.align} color={ct.color}>
                            <span>{ct.title}</span>
                            {
                                ct.order && <OrderButton active={columnOrder.field === ct.field} name={ct.field} action={handleOrder} />
                            }
                        </HeaderCell>
                    )
                }
            </Row>
        </Root>
    );
}

export default Head;
