import React from 'react'
import styled from 'styled-components'
import { Bounce } from 'react-reveal'
import ReactPlayer from 'react-player/lazy'


const ContentSlide = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
box-sizing: border-box;
padding: 0 120px;
z-index: 99;
@media (max-width: 600px) {
    padding: 0 50px;
    align-items: center;
}
`

const FilterVideo = styled.div`
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(90deg, rgba(99,99,99,9.5) 0%, #2196F382 100%);
    opacity: 0.75;
`

const ContentVideoSlide = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: hidden;
`

const TitleVideo = styled.h2`
width: 384px;
font-size: 2.9rem;
color: ${(props) => props.color ? props.color : '#FFF'};
font-weight: 500;
margin: 20px 0;
text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
@media (max-width: 600px) {
    width: 100%;
    font-size: 2rem;
    text-align: center;
}
`

const TextVideo = styled.span`
width: 384px;
font-size: 1.8rem;
color: #FFF;
font-weight: 300;
text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
@media (max-width: 600px) {
    width: 100%;
    font-size: 1.25rem;
    text-align: center;
}
`

const Video = styled.video`
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background: linear-gradient(0deg, #001554 0%, #2196F382 100%) ;
    }
`

const ButtonVideo = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 48px;
    font-size: 20px;
    background: #ff3030;
    border-radius: 24px;
    color: #FFF;
    margin: ${(props) => props.margin ? props.margin : '30px 0'};
    cursor: pointer;
    text-decoration: none;
`

const VideoContent = (props) => {
    return (
        <ContentVideoSlide>
            <ReactPlayer url={props.data.videolink} 
                autoPlay 
                loop={true} 
                muted={true} 
                volume={0}
                width='100%'
                height='100%' 
                config={{
                    youtube: {
                        playerVars: { showinfo: 0, autoplay: 1, loop: 1 }
                    }
                }}
            />
            {/* <Video src='/Corte_VideoMAX_Slider1.mp4' autoPlay loop muted alt={props.key} /> */}
            {/* <FilterVideo /> */}
            <Bounce right cascade duration={3000} delay={100}>
                <ContentSlide>
                    {
                        props.data.title && <TitleVideo>{props.data.title}</TitleVideo> 
                    }
                    {
                        props.data.text && <TextVideo>{props.data.text}</TextVideo>
                    }
                    {
                        props.data.link && <ButtonVideo href={props.data.link}>{props.data.textButton}</ButtonVideo>
                    }
                </ContentSlide>
            </Bounce>
        </ContentVideoSlide>
    );
}

export default VideoContent;