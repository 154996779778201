/* General imports */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';
import axios from 'axios';
import { sidebarCartActions } from '../../SidebarCart/SidebarCartActions';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import ProductDescription from './ProductDescription'
import FondoProduct from '../../images/FondoProduct.png'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: flex-start;
    height: auto;
`

const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 80%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 150px 0 0 0;
    @media (max-width: 450px) {
        margin: 100px 0 0 0;
        width: 95%;
    }
`

const ProductName = styled.h1`
    position: relative;
    display: flex;
    width: 80%;
    font-size: 2rem;
    color: #FFF;
    @media (max-width: 450px) {
        font-size: 1.25rem;
    }
`

const BackProduct = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 300px;
    background-image: url(${FondoProduct});
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 50px 0;
    justify-content: center;
    @media (max-width: 450px) {
        padding: 20px 0;
    }
`

/* ----- Principal function ----- */
function Product({idsection, match, cartitems, addToCart, switchSidebarCart}) {

    const { idproduct } = match.params;

    const [productData, setProductData] = React.useState(null)  

    React.useEffect(() => {
        if(!productData){
            axios.get('/api/products/' + idproduct)
            .then(response => {
                setProductData(response.data.product)
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [])

    return(
        !productData ? <Spinner spinnerType='ThreeDots' /> :
        <Root>
            <BackProduct>
                <ProductName>{productData.name}</ProductName>
            </BackProduct>
            <Desktop>
                <ContentBox>
                    <ProductDescription productData={productData} cartitems={cartitems} addToCart={addToCart} switchSidebarCart={switchSidebarCart} />
                </ContentBox>
            </Desktop>
            <Tablet>
                <ContentBox>
                <ProductDescription productData={productData} cartitems={cartitems} addToCart={addToCart} switchSidebarCart={switchSidebarCart} />
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    <ProductDescription productData={productData} cartitems={cartitems} addToCart={addToCart} switchSidebarCart={switchSidebarCart} />
                </ContentBox>
            </Mobile>
        </Root>
    );
}

function mapState(state) {
    const { error, isFetching, cartitems} = state.sidebarcart;
    return { error, isFetching, cartitems};
}

const actionCreators = {
    addToCart: sidebarCartActions.addToCart,
    switchSidebarCart: sidebarCartActions.switchSidebarCart
}

export default connect(mapState, actionCreators)(Product);