/* General imports */
import React, {useRef} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import { invoicesActions } from './InvoicesActions'
import { alertActions } from '../alert/AlertActions'
import { useReactToPrint } from 'react-to-print';
import { authHeader } from '../app/auth-header'
import axios from 'axios'
import {config} from '../app/config';

/* Components imports */
import Table from '../tables/InvoicesTable'
import ModalCreateUpdateInvoice from './ModalCreateUpdateInvoice'
import ModalViewInvoice from './ModalViewInvoice'
import ModalExport from './ModalExport'
/* import ModalCreateUpdateInvoiceMobile from './ModalCreateUpdateInvoiceMobile' */
import InvoiceIcon from '../icons/invoice.png'
import ModalViewInvoiceA from './ModalViewInvoiceA'
import ModalViewInvoiceB from './ModalViewInvoiceB'
import ModalViewInvoiceTicket from './ModalViewInvoiceTicket'
import ModalViewReceiptA from './ModalViewReceiptA'
import ModalViewReceiptX from './ModalViewReceiptX'
import ModalViewReceiptB from './ModalViewReceiptB'
import ModalViewEstimate from './ModalViewEstimate'
import ModalViewCreditNoteA from './ModalViewCreditNoteA'
import ModalViewCreditNoteB from './ModalViewCreditNoteB'


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
    justify-content: space-between;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 100px);
    padding: 0 35px;
    box-sizing: border-box;
    top: -64px;
`

const NewButton = styled.button`
    position: fixed;
    display: flex;
    right: 0;
    bottom: 70px;
    border-radius: 25px 0 0 25px;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    box-sizing: border-box;
    outline: none;
    color: white;
    background: #192d3e;
    border: 0;
`

const AfipOnline = styled.h4`
    position: relative;
    display: flex;
    color: ${(props) => props.status ? 'green' : 'red'};
    margin: 0;
    font-size: 1.5rem;
`

/* ----- Principal function ----- */
function Invoices({ user, invoices, getInvoices, createInvoice, updateInvoice, deleteInvoice, isFetching }) {

    const getVoucherDesc = (num) => {
        switch (parseInt(num)) {
            case 6:
                return "FACTURA C"
                break;
            case 19:
                return "TICKET DE COMPRA"
                break;
            case 29:
                return "FACTURA A"
                break;
            case 30:
                return "FACTURA B"
                break;
            case 31:
                return "NOTA DE CRÉDITO A"
                break;
            case 32:
                return "ORDEN DE COMPRA"
                break;
            case 33:
                return "RECIBO A"
                break;
            case 34:
                return "REMITO"
                break;
            case 35:
                return "PRESUPUESTO"
                break;
            case 36:
                return "RECIBO B"
                break;
            case 37:
                return "RECIBO X"
                break;
            case 39:
                return "NOTA DE CRÉDITO B"
                break;
            default:
                break;
        }
    }

    const columsTable = [
        { title: 'ID', field: 'idInvoice' },
        { title: 'Fecha', field: 'invoiceDate', type: 'date' },
        { title: 'CUIT', field: 'receiverCuit' },
        { title: 'Tipo', field: 'invoiceTypeCode', render: rowData => <span>{getVoucherDesc(rowData.invoiceTypeCode)}</span>, width: 50 },
        { 
            title: 'Razón Social', 
            field: 'receiverBusinessName', 
            width: 300,
            hidden: useMediaQuery({ maxWidth: devices.mobileMaxWidth }) ? true : false
        },
        { title: 'Nro. Comprobante', field: 'voucherNumber', render: rowData => <span>{rowData.voucherNumber ? rowData.voucherNumber.toString().padStart(8,0) : '---'}</span>, width: 50 },
        { title: 'Importe', field: 'total', type: 'currency' },
        /* { title: 'Importe Neto', field: 'nettotal', type: 'currency', hidden: useMediaQuery({ maxWidth: devices.mobileMaxWidth }) ? true : false } */
    ]

    const tableRef = React.createRef()

    const [open, setOpen] = React.useState(false);

    const [ view, setView ] = React.useState(false)

    const [ modalExport, setModalExport ] = React.useState(false)

    const [ viewData, setViewData ] = React.useState(null);

    const [ edit, setEdit ] = React.useState(false)

    const [ editData, setEditData ] = React.useState(null);

    const [ creditNote, setCreditNote ] = React.useState(false)

    const [ creditNoteData, setCreditNoteData ] = React.useState(null);

    const [ afipStatus, setAfipStatus ] = React.useState({
        status: false,
        msg: 'Offline'
    });

    const refreshData = () => getInvoices() && tableRef.current.onQueryChange()

    const handleView = (rowData) => {
        setViewData(rowData)
        setView(true);
    };

    const handleCloseView = () => {
        setView(false)
        refreshData()
    };

    const handleNew = () => {
        setOpen(true);
    };

    const handleConfirmNew = () => {
        try {
            handlePrint();
            handleClose();
            if(edit){
                handleCloseEdit();
            }
            if(creditNote){
                handleCloseCreditNote();
            }
        } catch (error) {
            alert("Hubo un error")
        }
        
        
    }
    
    const handleClose = () => {
        setOpen(false)
        refreshData()
    };

    const handleEdit = (rowData) => {
        if(parseInt(rowData.invoiceTypeCode) === 19 || parseInt(rowData.invoiceTypeCode) === 29 || parseInt(rowData.invoiceTypeCode) === 30){
            setEditData(rowData)
            setEdit(true)
        } else {
            alert("No se puede generar pago de este comprobante.")
        }
    }

    const handleCreditNote = (rowData) => {
        if(parseInt(rowData.invoiceTypeCode) === 19 || parseInt(rowData.invoiceTypeCode) === 29 || parseInt(rowData.invoiceTypeCode) === 30){
            setCreditNoteData(rowData)
            setCreditNote(true)
        } else {
            alert("No se puede generar pago de este comprobante.")
        }
        
    }

    const handleCloseCreditNote = () => {
        setCreditNote(false)
        setCreditNoteData(null)
        refreshData()
    }

    const handleConfirmEdit = async (data) => {
        await updateInvoice(data)
        handleCloseEdit()
    }

    const handleCloseEdit = () => {
        setEdit(false)
        setEditData(null)
        refreshData()
    }

    const handleDelete = async (rowData) => {
        if(rowData.voucherNumber){
            alert("Este comprobante no puede ser eliminado.")
        } else {
            // eslint-disable-next-line no-restricted-globals
            if(confirm('¿Desea eliminar esta factura?')){
                await deleteInvoice(rowData.idInvoice)
                refreshData()   
            }
        }
    }

    const exportCsv = () => {
        setModalExport(true);
    }

    const cloaseExportCsv = () => {
        setModalExport(false);
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    React.useEffect(() => {
        if(!invoices){
            getInvoices()
        }
    }, [getInvoices, invoices])

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(config.enabledAfip && !afipStatus){
            axios.get(`/api/afip/status`,configHeader)
            .then(statusData => {
                const status = statusData.data;
                if(status.AppServer === 'OK'){
                    setAfipStatus({
                        status: true,
                        msg: 'Online'
                    });
                } else {
                    setAfipStatus({
                        status: false,
                        msg: 'Offline'
                    });
                }
                
            })
            .catch(err => {
                console.log(err);
                setAfipStatus({
                    status: false,
                    msg: 'Offline'
                });
            })
        }
    }, [])

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <Header>
                        <Title>
                            <img src={InvoiceIcon} alt='Icon' />
                            <h6>Comprobantes</h6>
                        </Title>
                        {
                            afipStatus && <AfipOnline status={afipStatus.status}>{`AFIP ${afipStatus.msg}`}</AfipOnline>
                        }
                    </Header>
                    <Content>
                        {
                            isFetching ? <h1>CARGANDO...</h1> : 
                            <Table tableRef={tableRef} exportCsv={exportCsv} createButton={true} viewButton={true} editButton={true} deleteButton={true} refreshButton={true} autoExport={false}  title="LISTADO DE COMPROBANTES" columnsTable={columsTable} data={invoices} getData={getInvoices} handleView={handleView} handleNew={handleNew} handleEdit={handleEdit} handleCreditNote={handleCreditNote} handleDelete={handleDelete} />   
                        }
                        {
                            open && <ModalCreateUpdateInvoice afipStatus={afipStatus.status} voucherPrintRef={componentRef} title='NUEVO COMPROBANTE' textButton='CREAR' handleAction={handleConfirmNew} openModal={open} closeModal={handleClose} />
                        }
                        {
                            editData && <ModalCreateUpdateInvoice voucherType={[33,36,37]} afipStatus={afipStatus.status} voucherPrintRef={componentRef} title='PAGO COMPROBANTE' textButton='APLICAR' handleAction={handleConfirmNew} rowData={editData} openModal={edit} closeModal={handleCloseEdit} />
                        }
                        {
                            creditNoteData && <ModalCreateUpdateInvoice voucherType={[31,39]} afipStatus={afipStatus.status} voucherPrintRef={componentRef} title='NOTA DE CRÉDITO' textButton='APLICAR' handleAction={handleConfirmNew} rowData={creditNoteData} openModal={creditNote} closeModal={handleCloseCreditNote} />
                        }
                        {
                            view && viewData && parseInt(viewData.invoiceTypeCode) === 19 && <ModalViewInvoiceTicket title='VER COMPROBANTE' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                        }
                        {
                            view && viewData && parseInt(viewData.invoiceTypeCode) === 29 && <ModalViewInvoiceA title='VER COMPROBANTE' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                        }
                        {
                            view && viewData && parseInt(viewData.invoiceTypeCode) === 33 && <ModalViewReceiptA title='VER COMPROBANTE' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                        }
                        {
                            view && viewData && parseInt(viewData.invoiceTypeCode) === 36 && <ModalViewReceiptB title='VER COMPROBANTE' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                        }
                        {
                            view && viewData && parseInt(viewData.invoiceTypeCode) === 30 && <ModalViewInvoiceB title='VER COMPROBANTE' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                        }
                        {
                            view && viewData && parseInt(viewData.invoiceTypeCode) === 37 && <ModalViewReceiptX title='VER COMPROBANTE' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                        }
                        {
                            view && viewData && parseInt(viewData.invoiceTypeCode) === 35 && <ModalViewEstimate title='COTIZACIÓN' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                        }
                        {
                            view && viewData && parseInt(viewData.invoiceTypeCode) === 31 && <ModalViewCreditNoteA title='VER COMPROBANTE' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                        }
                        {
                            view && viewData && parseInt(viewData.invoiceTypeCode) === 39 && <ModalViewCreditNoteB title='VER COMPROBANTE' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                        }
                        {
                            modalExport && <ModalExport title='EXPORTAR' textButton='CONFIRMAR' openModal={modalExport} closeModal={cloaseExportCsv} />
                        }
                    </Content>
                </Root>
            </Desktop>
            <Tablet> NO DISPONIBLE</Tablet>
            <Mobile>
                NO DISPONIBLE
                {/* <Root width='100%' padding='0'>
                    {
                        isFetching ? <h1>CARGANDO...</h1> : 
                        <Table rowClick={handleEdit} tableRef={tableRef} autoExport={false}  title="" columnsTable={columsTable} data={invoices} getData={getInvoices} handleView={handleView} handleNew={handleNew} handleDelete={handleDelete} />   
                    }
                    {
                        open && <ModalCreateUpdateInvoiceMobile title='NUEVA FACTURA' textButton='CREAR' handleAction={handleConfirmNew} openModal={open} closeModal={handleClose} />
                    }
                    {
                        editData && <ModalCreateUpdateInvoiceMobile title='EDITAR FACTURA' textButton='EDITAR' handleAction={handleConfirmEdit} rowData={editData} openModal={edit} closeModal={handleCloseEdit} />
                    }
                    {
                        view && <ModalViewInvoice title='VER FACTURA' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                    }
                    {
                        modalExport && <ModalExport title='EXPORTAR' textButton='CONFIRMAR' openModal={modalExport} closeModal={cloaseExportCsv} />
                    }
                    <NewButton onClick={handleNew}>+</NewButton>

                </Root> */}
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, invoices, invoicesFields } = state.invoice;
    const { user } = state.login;
    return { user, error, isFetching, invoices, invoicesFields};
}

const actionCreators = {
    getInvoices: invoicesActions.getInvoices,
    getFields: invoicesActions.getFields,
    createInvoice: invoicesActions.createInvoice,
    updateInvoice: invoicesActions.updateInvoice,
    deleteInvoice: invoicesActions.deleteInvoice,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Invoices);