/* General imports */
import React from 'react'
import styled from 'styled-components'

import InputChange from './InputChange';

const Table = styled.table`
    width: 100%;
    border-spacing: 0;
    thead {
        background: aliceblue;
        
    }
    th, td {
        border: 1px solid grey;
        height: 30px;
        text-align: center;
    }
    tr {
        height: 30px;
    }
    tfoot {
        background: aliceblue;
    }
`

/* ----- Principal function ----- */
function ItemsTable({items, changeAmount, deleteItem, storage}) {

    const onDelete = (e) => {
        deleteItem(e.target.dataset.index);
    }

    return(
        <Table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Código</th>
                    <th>Descripción</th>
                    <th>Cantidad</th>
                    <th>Stock Actual</th>
                    <th>Stock Descontado</th>
                </tr>
            </thead>
            <tbody>
                {
                   items && items.map((item, index) => 
                    <tr key={`item_${index}`}>
                        <td>{index}</td>
                        <td>{item.code ? item.code : item.barcode}</td>
                        <td>{item.name}</td>
                        <td><InputChange changeAmount={changeAmount} index={index} inputvalue={item.amount} /></td>
                        <td>{item.stock.find(s => s.idStorage == storage).quantity}</td>
                        <td>{item.stock.find(s => s.idStorage == storage).quantity - item.amount}</td>
                        <td data-index={index} style={{cursor: "pointer", color: "red"}} onClick={onDelete}>X</td>
                    </tr>
                    )
                }
            </tbody>
            <tfoot>
            </tfoot>
        </Table>
    );
}
export default ItemsTable;