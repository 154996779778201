import { printTagsConstants } from "./printTagsConstants";

export const printTagsActions = {
  setTags,
  addTag,
  addAllTags,
  removeTag,
  removeAllTags,
}

function setTags(items) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedItems = state.printTags.selectedItems;
    let newTags = items.filter((i) => selectedItems.has(i.idProduct));
    dispatch({ type: printTagsConstants.SET, items: newTags });
  };
}

function addTag(item) {
  return (dispatch, getState) => {
    const state = getState();
    const tags = state.printTags.tags;
    const newSelectedItems = state.printTags.selectedItems;
    newSelectedItems.add(item.idProduct);
    const newTags = [...tags, item]
    dispatch({ type: printTagsConstants.ADD, newSelectedItems, newTags });
  };
}

function addAllTags(items) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedItems = state.printTags.selectedItems;
    const newSelectedItems = new Set(items.map((i) => i.idProduct));
    const union = new Set([...selectedItems, ...newSelectedItems]);
    const newTags = items;
    dispatch({ type: printTagsConstants.ADD_ALL, newSelectedItems: union, newTags });
  };
}

function removeTag(item) {
  return (dispatch, getState) => {
    const state = getState();
    const tags = state.printTags.tags;
    const newSelectedItems = state.printTags.selectedItems;
    newSelectedItems.delete(item.idProduct);
    const newTags = tags.filter((t) => newSelectedItems.has(t.idProduct));
    dispatch({ type: printTagsConstants.REMOVE, newSelectedItems, newTags });
  };
}

function removeAllTags(items) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedItems = state.printTags.selectedItems;
    const newSelectedItems = new Set(items.map((i) => i.idProduct));
    const difference = new Set([...selectedItems].filter(i => !newSelectedItems.has(i)));
    const newTags = [];
    dispatch({ type: printTagsConstants.REMOVE, newSelectedItems: difference, newTags });
  };
}
