/* General imports */
import React from 'react'
import styled from 'styled-components'

const ContainerStep = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const Option = styled.button`
    box-sizing: border-box;
    padding: 30px 40px;
    outline: none;
    cursor: pointer;
    transform: ${(props) => props.active ? 'scale(1.25)' : 'scale(1)'};
    ${(props) => props.active ? 'text-shadow: 1px 1px 10px rgba(255,255,255,0.8);' : ''};
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    transition: all 150ms ease-in-out;
    &:hover {
        box-shadow: 0 0 15px rgba(0,0,0,0.5);
    }
`

const SubOption = styled(Option)`
    background-color: #0059a5!important;
    height: 80px;
    padding: 20px;
    margin: 0 10px;
`

const OptionsContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 50%;
    transition: all 150ms ease-in-out;
`

const SubOptionsContainer = styled(OptionsContainer)`
    display: ${(props) => props.active ? 'flex' : 'none'};
    opacity: ${(props) => props.active ? 1 : 0};
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 50px;
    box-sizing: border-box;
`

const OptionButton = ({active, handleAction, value, label}) => {

    const [ activeButton, setActiveButton ] = React.useState(false);

    React.useEffect(() => {
        setActiveButton(active)
    }, [active])

    return (
        <Option value={value} active={activeButton} onClick={handleAction}>{label}</Option>
    )
}

const InvoiceActionsStep = (props) => {

    const [ action, setAction ] = React.useState(0);

    const handleAction = (e) => {
        e.preventDefault();
        props.handleAction(e.target.value);
    }

    const handleButton = (e) => {
        e.preventDefault();
        setAction(e.target.value);
    }

    return (
        <ContainerStep>
            <OptionsContainer>
                <OptionButton value={1} active={parseInt(action) === 1} handleAction={handleButton} label="INGRESAR" />
                <OptionButton value={2} active={parseInt(action) === 2} handleAction={handleButton} label="GENERAR" />
            </OptionsContainer>
            <SubOptionsContainer active={parseInt(action) === 1}>
                <SubOption disabled={true} value={1} onClick={handleAction}>FACTURA</SubOption>
            </SubOptionsContainer>
            <SubOptionsContainer active={parseInt(action) === 2}>
                <SubOption disabled={!props.afipStatus || (props.voucherType && !(props.voucherType.includes(29)))} value={29} onClick={handleAction}>FACTURA A (001)</SubOption>
                <SubOption disabled={!props.afipStatus || (props.voucherType && !(props.voucherType.includes(30)))} value={30} onClick={handleAction}>FACTURA B (006)</SubOption>
                {/* <SubOption disabled={true} value={6} onClick={handleAction}>FACTURA C (011)</SubOption> */}
                <SubOption disabled={props.rowData} value={19} onClick={handleAction}>TICKET X (001)</SubOption>
                <SubOption disabled={!props.afipStatus || (props.voucherType && !(props.voucherType.includes(33)))} value={33} onClick={handleAction}>RECIBO A</SubOption>
                <SubOption disabled={!props.afipStatus || (props.voucherType && !(props.voucherType.includes(36)))} value={36} onClick={handleAction}>RECIBO B</SubOption>
                <SubOption disabled={(props.voucherType && !(props.voucherType.includes(36)))} value={37} onClick={handleAction}>RECIBO X</SubOption>
                <SubOption disabled={!props.afipStatus || (props.voucherType && !(props.voucherType.includes(31)))} value={31} onClick={handleAction}>NOTA DE CRÉDITO A</SubOption>
                <SubOption disabled={!props.afipStatus || (props.voucherType && !(props.voucherType.includes(39)))} value={39} onClick={handleAction}>NOTA DE CRÉDITO B</SubOption>
                {/* <SubOption value={32} onClick={handleAction}>ORDEN DE COMPRA</SubOption>
                <SubOption value={35} onClick={handleAction}>PRESUPUESTO</SubOption>
                
                <SubOption value={34} onClick={handleAction}>REMITO</SubOption> */}
            </SubOptionsContainer>
        </ContainerStep>
    );
}

export default InvoiceActionsStep;