/* General imports */
import React from 'react';
import styled from 'styled-components';

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { theme } from '../app/config';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { authHeader } from '../app/auth-header';
import axios from 'axios';
import { AFIPDocumentTypes } from '../app/config';

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 600px;
    min-height: 450px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.form`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    box-sizing: border-box;
    flex-direction: column;
`

const BoxContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`

const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;
`

const StyledButton = styled.button`
    width: calc(${(props) => props.columWidth ? props.columWidth : '100%'} * 0.75);
    height: 44px;
    background-color: ${theme.default.primaryButtonBg};
    color: ${theme.default.primaryButtonText};
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin-top: 25px;
    margin-bottom: 25px;
    outline: none;
`

const StyledFormControl = styled(FormControl)`
  width: 250px;
`

/* ----- Principal function ----- */
function ModalInvoiceData({ title, textButton, openModal, closeModal, rowData, user, handleAction }) {

    const [data, setData ] = React.useState(rowData && rowData.invoiceData ? rowData.invoiceData : {
        afipDocTipo: null,
        cuit: null,
        businessname: '',
        commercialAddress: '',
        ivaCondition: null,
        grossIncome: null,
        activityStartDate: null
    })

    const [ ivaConditions, setIvaConditions ] = React.useState(null);

    const onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }

    const submit = (e) => {
        e.preventDefault();
        handleAction(data);
        closeModal();
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!ivaConditions){
            axios.get(`/api/valuelist/group/ivaConditions`,configHeader)
            .then(ivaConditionsData => {
                const ivaConditionsList = [];
                ivaConditionsData.data.valueslist.forEach(option => {
                    ivaConditionsList.push({
                        name: option.value,
                        key: option.key,
                        value: option.idValueList
                    })
                });
                setIvaConditions(ivaConditionsList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ ivaConditions ])

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ModalBody onSubmit={submit}>
                        <BoxContainer>
                            <Box>
                                <StyledFormControl required>
                                    <InputLabel id="afipDocTipo-label"> Tipo doc. AFIP </InputLabel>
                                    <Select
                                        native
                                        labelId="afipDocTipo-label"
                                        onChange={onChange}
                                        required
                                        inputProps={{
                                            id: "afipDocTipo",
                                            name: "afipDocTipo",
                                            value: data.afipDocTipo
                                        }}
                                    >
                                        <option value="" />
                                        {
                                            AFIPDocumentTypes.map((dt, index) => 
                                                <option key={`afipDocTipo_value_option_${index}`} value={dt.Id}> {dt.Desc} </option>
                                            )
                                        }
                                    </Select>
                                </StyledFormControl>
                                <TextField fullWidth margin="normal" id="cuit" name="cuit" label="CUIT" type='number' value={data.cuit} onChange={onChange} required />
                                <TextField fullWidth margin="normal" id="businessname" name="businessname" label="Razón Social" type='text' value={data.businessname} onChange={onChange} required />
                                <TextField fullWidth margin="normal" id="commercialAddress" name="commercialAddress" label="Domicilio comercial" type='text' value={data.commercialAddress} onChange={onChange} required />
                            </Box>
                            <Box>
                                <StyledFormControl required>
                                    <InputLabel id="ivaCondition-label"> Condición de IVA </InputLabel>
                                    <Select
                                        native
                                        labelId="ivaCondition-label"
                                        onChange={onChange}
                                        required
                                        inputProps={{
                                            id: "ivaCondition",
                                            name: "ivaCondition",
                                            value: data.ivaCondition
                                        }}
                                    >
                                        <option value="" />
                                        {
                                            ivaConditions && ivaConditions.map((ic, index) =>
                                                <option key={`iva_option_${index}`} value={ic.value}> {`${ic.key} (${ic.name})`} </option>
                                            )
                                        }
                                    </Select>
                                </StyledFormControl>
                                <TextField fullWidth margin="normal" id="grossIncome" name="grossIncome" label="Ingresos brutos" type='number' value={data.grossIncome} onChange={onChange} />
                                <TextField fullWidth margin="normal" id="activityStartDate" name="activityStartDate" label="Inicio de actividades" type='date' value={data.activityStartDate} onChange={onChange} />
                            </Box>
                        </BoxContainer>
                        <StyledButton type="submit" > {textButton} </StyledButton>
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalInvoiceData;