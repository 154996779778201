/* General imports */
import React from 'react'
import styled from 'styled-components'
import Barcode from 'react-barcode';

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
`

const Content = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    @media print{
      display: table;
    }
`

const ContentProduct = styled.div`
    display: flex;
    float: center;
    box-sizing: border-box;
    position: relative;
    height: 20mm;
    width: 38mm;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* padding: 20px 10px 10px 10px; */
      page-break-after: always;
      page-break-inside: avoid;
    background-color: ${props => props.brak ? 'red' : ''};
    svg{
        width: 38mm!important;
        height: 20mm!important;
    }
   `
const Barcodes = ({ ref, tags }) => {

    return (
        <Root ref={ref}>
            <Content>
                {
                    tags?.map((product, index) =>
                        <ContentProduct key={`product_${index}`} index={index}>
                            <Barcode value={`${product.barcode ?? product.code}`}/>
                        </ContentProduct>)
                }
            </Content>
        </Root>
    );
}

export default Barcodes;

