import { nameAccess } from '../app/nameAccessConstants'
/* Local Icons */
import DashboardIcon from '../icons/dashboard.svg'
import ProfessionalsIcon from '../icons/professionals.svg'
import PatientsIcon from '../icons/patient.svg'
import InvoiceIcon from '../icons/invoice.png'
import AccountsIcon from '../icons/accounts.svg'
import GeneralIcon from '../icons/blackconfig.svg'
import PagesIcon from '../icons/blackpages.svg'
import StockIcon from '../icons/stock.png'
import ProductsIcon from '../icons/products.png'
import CategoriesIcon from '../icons/category.png'
import PurchaseIcon from '../icons/purchase.svg';
import ReportsIcon from '../icons/reports.svg'
import TaskListAddIcon from '../icons//task-list-add.svg';



export const menuItems = [
    {
        title: 'APLICACIÓN',
        name_access: nameAccess.APPLICATION,
        menus: [
            {
                id: 'shortcutscreen',
                name: 'Dashboard',
                name_access: nameAccess.DASHBOARD,
                icon: DashboardIcon,
                link: '/admin',
                exact: true
            },
            { 
                id: 'pointsale',
                name: 'Venta',
                name_access: nameAccess.SALE,
                icon: InvoiceIcon,
                link: '/admin/pointsale'
            },
            { 
                id: 'pointpurchase',
                name: 'Compra',
                name_access: nameAccess.PURCHASE,
                icon: PurchaseIcon,
                link: '/admin/pointpurchase'
            }
            /* { 
                id: 'invoices',
                name: 'Facturas',
                name_access: nameAccess.INVOICES,
                icon: InvoiceIcon,
                link: '/admin/invoices'
            },
            { 
                id: 'professionals',
                name: 'Profesionales',
                name_access: nameAccess.PROFESSIONALS,
                icon: ProfessionalsIcon,
                link: '/admin/professionals'
            },
            { 
                id: 'patients',
                name: 'Pacientes',
                name_access: nameAccess.PATIENTS,
                icon: PatientsIcon,
                link: '/admin/patients'
            },
            { 
                id: 'accounts',
                name: 'Cuentas',
                name_access: nameAccess.ACCOUNTS,
                icon: AccountsIcon,
                link: '/admin/accounts'
            } */
        ]
    },
    {
        title: 'CATÁLOGO',
        name_access: nameAccess.STOCKSYSTEM,
        menus: [
            {
                id: 'categorypage',
                name: 'CATEGORÍAS',
                name_access: nameAccess.CATEGORY,
                icon: CategoriesIcon,
                link: '/admin/stocksystem/category',
                exact: false
            },
            {
                id: 'trademarks',
                name: 'MARCAS',
                name_access: nameAccess.TRADEMARKS,
                icon: CategoriesIcon,
                link: '/admin/stocksystem/trademarks',
                exact: false
            },
            {
                id: 'productpage',
                name: 'PRODUCTOS',
                name_access: nameAccess.PRODUCT,
                icon: ProductsIcon,
                link: '/admin/stocksystem/product',
                exact: false
            },
            {
                id: 'productxsupplierpage',
                name: 'PROD x PROV',
                name_access: nameAccess.PRODUCT,
                icon: TaskListAddIcon,
                link: '/admin/stocksystem/productxsupplier',
                exact: false
            },
            {
                id: 'stockpage',
                name: 'STOCK',
                name_access: nameAccess.STOCK,
                icon: StockIcon,
                link: '/admin/stocksystem/stock',
                exact: false
            }
        ]
    },
    {
        title: 'ADMINISTRACIÓN',
        name_access: nameAccess.MANAGEMENT,
        menus: [
            { 
                id: 'invoices',
                name: 'Comprobantes',
                name_access: nameAccess.INVOICES,
                icon: InvoiceIcon,
                link: '/admin/invoices'
            },
            { 
                id: 'customers',
                name: 'Clientes',
                name_access: nameAccess.CUSTOMER,
                icon: ProfessionalsIcon,
                link: '/admin/customers'
            },
            { 
                id: 'suppliers',
                name: 'Proveedores',
                name_access: nameAccess.SUPPLIER,
                icon: ProfessionalsIcon,
                link: '/admin/suppliers'
            },
            { 
                id: 'accounts',
                name: 'Cuentas',
                name_access: nameAccess.ACCOUNTS,
                icon: AccountsIcon,
                link: '/admin/accounts'
            }
        ]
    },
    {
        title: 'REPORTES',
        name_access: nameAccess.REPORTS,
        menus: [
            {
                id: 'salesreport',
                name: 'VENTAS',
                name_access: nameAccess.SALESREPORT,
                icon: ReportsIcon,
                link: '/admin/reports/sales',
                exact: false
            },
            {
                id: 'purchasesreport',
                name: 'PURCHASES',
                name_access: nameAccess.PURCHASESREPORT,
                icon: ReportsIcon,
                link: '/admin/reports/purchases',
                exact: false
            }
        ]
    },
    {
        title: 'EDITOR WEB',
        name_access: nameAccess.ADMINISTRATION,
        menus: [
            {
                id: 'generalconfigpage',
                name: 'CONFIGURACIÓN',
                name_access: nameAccess.CONFIG,
                icon: GeneralIcon,
                link: '/admin/webeditor',
                exact: true
            },
            { 
                id: 'configpages',
                name: 'PÁGINAS',
                name_access: nameAccess.WEBEDITOR,
                icon: PagesIcon,
                link: '/admin/webeditor/pages'
            }
        ]
    }
]

export const internalPages = [
    {
        name: 'Page Editor',
        pageType: 'pageeditor',
        path: '/admin/webeditor/page/:idPage',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR
    },
    {
        name: 'Basic Settings',
        pageType: 'basicsettings',
        path: '/admin/webeditor/sectiontype/basicsettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Carousel Settings',
        pageType: 'carouselsettings',
        path: '/admin/webeditor/sectiontype/carouselsettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Buttons Access Settings',
        pageType: 'buttonsaccesssettings',
        path: '/admin/webeditor/sectiontype/buttonsaccesssettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Two Columns Settings',
        pageType: 'twocolumnssettings',
        path: '/admin/webeditor/sectiontype/twocolumnssettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Three Services Settings',
        pageType: 'threeservicessettings',
        path: '/admin/webeditor/sectiontype/threeservicessettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Three Data Icon Settings',
        pageType: 'threedataiconsettings',
        path: '/admin/webeditor/sectiontype/threedataiconsettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Three Vertical Data Icon Settings',
        pageType: 'threeverticaldataiconsettings',
        path: '/admin/webeditor/sectiontype/threeverticaldataiconsettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Suppliers Section Settings',
        pageType: 'supplierssettings',
        path: '/admin/webeditor/sectiontype/supplierssettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Novelty Section Settings',
        pageType: 'noveltysettings',
        path: '/admin/webeditor/sectiontype/noveltysettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Objetives Settings',
        pageType: 'objetivessettings',
        path: '/admin/webeditor/sectiontype/objetivessettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Product List Settings',
        pageType: 'productlistsettings',
        path: '/admin/webeditor/sectiontype/productlistsettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Contact Us Settings',
        pageType: 'contactussettings',
        path: '/admin/webeditor/sectiontype/contactussettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Map Section Settings',
        pageType: 'mapsectionsettings',
        path: '/admin/webeditor/sectiontype/mapsectionsettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Footer Section Settings',
        pageType: 'footersectionsettings',
        path: '/admin/webeditor/sectiontype/footersectionsettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Product Section Settings',
        pageType: 'producttypesectionsettings',
        path: '/admin/webeditor/sectiontype/producttypesectionsettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Categories List Settings',
        pageType: 'categorieslistsettings',
        path: '/admin/webeditor/sectiontype/categorieslistsettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    },
    {
        name: 'Category Products Settings',
        pageType: 'categoryproductssettings',
        path: '/admin/webeditor/sectiontype/categoryproductssettings/:idPage/:idSection',
        privateRoute: true,
        name_access: nameAccess.WEBEDITOR,
    }
]