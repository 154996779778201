/* General imports */
import React from 'react'
import styled from 'styled-components'
import { authHeader } from '../app/auth-header';
import axios from 'axios';
import { connect } from 'react-redux'
import { alertActions } from '../alert/AlertActions'

/* Components imports */
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import Select from '@material-ui/core/Select';

const Root = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
`

const Content = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: 1.5fr 0.5fr;
    gap: 10px;
    width: 100%;
    height: calc(100% - 50px);
    box-sizing: border-box;
    padding: 20px;
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
`

const Box = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
`

const Footer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
`

const StyledFormControl = styled(FormControl)`
  width: 150px;
`

const AliquotContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
`

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #4a4a4a;
    opacity: 0.5;
`

const ReceiptBDetail = ({rowData, onBack, handleFinish}) => {

    const [ dataForm, setDataForm ] = React.useState(rowData ? 
        {
            afipAliquotType: '',
            afipAliquotAmount: 0.00,
            invoiceDetails: [
                {
                    code: null,
                    concept: `Recibo de pago por factura ${rowData.voucherNumber.toString().padStart(8,0)}`,
                    quantity: 1,
                    unitMeasurement: 11,
                    unitPrice: rowData.total,
                    bonusPercentage: 0,
                    bonusAmount: 0.00,
                    subtotal: rowData.total
                }
            ],
            subtotal: rowData.total,
            amountOtherTaxes: 0.00,
            total: rowData.total,
            desctotal: '',
            nameReceiver: rowData.receiverBusinessName
        }
        : 
        {
            afipAliquotType: '',
            afipAliquotAmount: 0.00,
            invoiceDetails: [
                {
                    code: null,
                    concept: '',
                    quantity: 1,
                    unitMeasurement: 11,
                    unitPrice: 0.00,
                    bonusPercentage: 0,
                    bonusAmount: 0.00,
                    subtotal: 0.00
                }
            ],
            subtotal: 0.00,
            amountOtherTaxes: 0.00,
            total: 0.00,
            desctotal: '',
            nameReceiver: ''
        })

    const [ aliquotTypes, setAliquotTypes ] = React.useState(null);

    const onChange = (event) => {
        const {name, value} = event.target;
        if(name === 'amount'){
            let invoicesDet = dataForm.invoiceDetails;
            invoicesDet[0] = {
                ...dataForm.invoiceDetails[0],
                unitPrice: value,
                subtotal: value
            }
            setDataForm({
                ...dataForm,
                invoiceDetails: invoicesDet
            })
            updateInvoiceTotals();
        } else if(name === 'concept') {
            let invoicesDet = dataForm.invoiceDetails;
            invoicesDet[0] = {
                ...dataForm.invoiceDetails[0],
                concept: value
            }
            setDataForm({
                ...dataForm,
                invoiceDetails: invoicesDet
            })
        } else {
            setDataForm({
                ...dataForm,
                [name]: value
            })
        }
    }

    const updateInvoiceTotals = (afipAliquotType, afipAliquotAmount) => {
        let subtotal = 0.00;
        dataForm.invoiceDetails.forEach(element => {
            subtotal = subtotal + (element.quantity * element.unitPrice)
        });
        if(afipAliquotType && afipAliquotAmount){
            setDataForm({
                ...dataForm,
                afipAliquotType: afipAliquotType,
                afipAliquotAmount: afipAliquotAmount,
                subtotal: subtotal - afipAliquotAmount,
                total: dataForm.amountOtherTaxes + subtotal,
                nettotal: (dataForm.amountOtherTaxes + subtotal)            
            })
        } else {
            if(dataForm.afipAliquotType !== ''){
                const div = (parseFloat(aliquotTypes.filter(ali => parseInt(ali.value) === parseInt(dataForm.afipAliquotType))[0].name) / 100) + 1; 
                const temp = subtotal / div;
                const aliquotAmount = subtotal - temp;
                setDataForm({
                    ...dataForm,
                    afipAliquotAmount: aliquotAmount.toFixed(2),
                    subtotal: subtotal - aliquotAmount.toFixed(2),
                    total: dataForm.amountOtherTaxes + subtotal,
                    nettotal: (dataForm.amountOtherTaxes + subtotal)            
                })
            } else {
                setDataForm({
                    ...dataForm,
                    subtotal: subtotal - dataForm.afipAliquotAmount,
                    total: dataForm.amountOtherTaxes + subtotal,
                    nettotal: (dataForm.amountOtherTaxes + subtotal)            
                })
            }
        }   
    }

    const onChangeAliquot = (e) => {
        const {value} = e.target;
        const div = (parseFloat(aliquotTypes.filter(ali => parseInt(ali.value) === parseInt(value))[0].name) / 100) + 1; 
        const temp = dataForm.subtotal / div;
        const afipAliquotAmount = dataForm.subtotal - temp;
        updateInvoiceTotals(value, afipAliquotAmount.toFixed(2));
    }

    const onFinishStep = () => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm('¿Desea ya generar la factura?')){
            if(dataForm.invoiceDetails[0].concept !== '' && dataForm.total > 0){
                handleFinish({
                    ...dataForm,
                    metaVoucher: {
                        desctotal: dataForm.desctotal,
                        nameReceiver: dataForm.nameReceiver
                    }
                });
            } else {
                alert("El precio debe ser mayor a 0.")
            }
        }
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!aliquotTypes){
            axios.get(`/api/afip/aliquottypes`,configHeader)
            .then(aliquotTypesData => {
                const aliquotTypesList = [];
                aliquotTypesData.data.forEach(option => {
                    aliquotTypesList.push({
                        name: option.Desc,
                        value: option.Id
                    })
                });
                setAliquotTypes(aliquotTypesList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ aliquotTypes ])

    return (
        <Root>
            <Content>
                <Box>
                    <TextField
                        id="concept"
                        name="concept"
                        label="Descripción del servicio"
                        fullWidth
                        multiline
                        rows={5}
                        value={dataForm.invoiceDetails[0].concept}
                        onChange={onChange}
                        variant="outlined"
                        required
                        margin="normal"
                    />
                    <TextField 
                        fullWidth
                        id="nameReceiver" 
                        name="nameReceiver"
                        label="Recibí de" 
                        variant="outlined" 
                        value={dataForm.nameReceiver}
                        onChange={onChange}
                        margin="normal"
                    />
                    <TextField 
                        fullWidth
                        id="desctotal" 
                        name="desctotal"
                        label="Precio textual" 
                        variant="outlined" 
                        value={dataForm.desctotal}
                        onChange={onChange}
                        margin="normal"
                    />
                </Box>
                <Box>
                    <FormControl fullWidth variant="outlined" margin="normal" required>
                        <InputLabel htmlFor="amount-amount">Precio</InputLabel>
                        <OutlinedInput
                            required
                            id="amount-amount"
                            name="amount"
                            value={dataForm.invoiceDetails[0].subtotal}
                            onChange={onChange}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            labelWidth={60}
                        />
                    </FormControl>
                    <Divider />
                    <FormControl variant="outlined" margin="normal" readOnly fullWidth>
                        <InputLabel htmlFor="subtotal-amount">Subtotal</InputLabel>
                        <OutlinedInput
                            id="subtotal-amount"
                            name="subtotal"
                            readOnly
                            value={dataForm.subtotal}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            labelWidth={60}
                        />
                    </FormControl>
                    <AliquotContainer>
                        <StyledFormControl variant="outlined" margin="normal">
                            <InputLabel id="aliquottype-label"> Alicuota </InputLabel>
                            <Select
                                native
                                labelId="aliquottype-label"
                                onChange={onChangeAliquot}
                                inputProps={{
                                id:"afipAliquotType",
                                name:"afipAliquotType",
                                value:dataForm.afipAliquotType
                                }}
                                labelWidth={60}
                            >
                                <option value="" />
                                {
                                    aliquotTypes && aliquotTypes.map((ali, index) =>
                                        <option key={`aliquotType_option_${index}`} value={ali.value}> {ali.name} </option>
                                    )
                                }
                            </Select>
                        </StyledFormControl>
                        <FormControl variant="outlined" margin="normal" readOnly>
                            <InputLabel htmlFor="afipAliquotAmount-amount">Monto Alicuota</InputLabel>
                            <OutlinedInput
                                id="afipAliquotAmount-amount"
                                name="afipAliquotAmount"
                                readOnly
                                value={dataForm.afipAliquotAmount}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                labelWidth={110}
                            />
                        </FormControl>
                    </AliquotContainer>
                    <FormControl variant="outlined" margin="normal" readOnly fullWidth>
                        <InputLabel htmlFor="total-amount">Total</InputLabel>
                        <OutlinedInput
                            id="total-amount"
                            name="total"
                            readOnly
                            value={dataForm.total}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            labelWidth={60}
                        />
                    </FormControl>
                </Box>
            </Content>
            <Footer>
                {
                    onBack && <Button onClick={onBack}>ATRÁS</Button>
                }
                <Button onClick={onFinishStep}>GENERAR COMPROBANTE</Button>
            </Footer>
        </Root>
    );
}

function mapState(state) {
    return {};
}

const actionCreators = {
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ReceiptBDetail);