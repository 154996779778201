/* General imports */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { invoicesActions } from './InvoicesActions'
import { alertActions } from '../alert/AlertActions'
import QRCode from "react-qr-code";

/* Components imports */
import Button from '@material-ui/core/Button';
import Logo from '../images/DIETETICA_SANTA_LUCIA_LOGO.jpg';
import Logo_Afip from '../images/logo_afip_byn.png';

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const Container = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 50px);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
`

const Voucher = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    @media all {
        .page-break {
            display: none;
        }
    }

    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
    }

    @media print {
        .page-break {
            /* margin-top: 1rem; */
            display: block;
            page-break-before: auto;
        }
    }

    @page {
        size: auto;
        margin: 2.5mm;
    }
`

const VoucherContainer = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 1102px;
    min-height: 1102px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
`

const InvoiceHeader = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 120px;
`

const TransmitterData = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 0 20px;
    span {
        font-size: 12px;
    }
    span:first-child {
        font-weight: bold;
        font-size: 25px;
        align-self: center;
        margin-bottom: 10px;
    }
`

const InvoiceGrlData = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 0 20px;
    span {
        font-size: 14px;
    }
`

const InvoiceBody = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    span {
        margin: 2px 0;
        font-size: 1.05rem;
    }
`

const InvoiceFooter = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: ${(props) => props.height ? props.height : '170px'};
    align-items: ${(props) => props.alignItems ? props.alignItems : 'space-evenly'};
    justify-content: space-between;
    border: 1px solid black;
    padding:  ${(props) => props.padding ? props.padding : 0};
    border-radius: ${(props) => props.borderRadius ? props.borderRadius : 'none'};
    border: ${(props) => props.border ? props.border : 'none'};
    /* div:first-child {
        position: absolute;
        left: 0;
        padding-left: 25px;
        align-items: flex-start;
    } */
`

const InvoiceFooterBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: ${(props) => props.width ? props.width : '20%' };
    height: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: ${(props) => props.alignH ? props.alignH : 'flex-end' };
    justify-content: space-evenly;
`
const Footer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
`

const VoucherLogo = styled.img`
    height: 65px;
    width: auto;
`

const LogoAfip = styled.img`
    width: 100px;
    height: 50px;
`

const Msg1Afip = styled.h3`
    margin: 0;
    font-size: 0.75rem;
`

const VoucherHeader = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: underline;
`

const VoucherPart = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 45%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const ReceiverPayment = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 70px;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
`

const Dashedline = styled.div`
    width: 100%;
    height: 0px;
    border-top: 1px dashed #4a4a4a;
    margin: 10px 0;
`

const DescriptionContainer = styled.div`
    display: grid;
    position: relative;
    width: 100%;
    height: auto;
    grid-template-columns: 1.5fr 0.5fr;
    margin: 5px 0;
`

const DescriptionConcept = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #4a4a4a;
    box-sizing: border-box;
    padding: 5px 10px;
`

const DescriptionAmount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #4a4a4a;
    box-sizing: border-box;
    padding: 5px 10px;
`

/* ----- Principal function ----- */
function ReceiptAConfirm({ rowData, voucherPrintRef, invoicesFields, getFields, onBack, handleFinish }) {

    const getDateInvoice = (d) => {
        const result = d.split('-')
        return result[2]+'/'+result[1]+'/'+result[0]
    }

    const getDateQr = (d) => {
        const result = d.split('-')
        return result[0]+'-'+result[1]+'-'+result[2]
    }

    const getAliquotAmount = (t, sub) => {
        switch (parseInt(t)) {
            case 3:
                return sub;
                break;
            case 4:
                return (Math.round((sub / 1.105)*100) / 100).toFixed(2);
                break;
            case 5:
                return (Math.round((sub / 1.21)*100) / 100).toFixed(2);
                break;
        
            default:
                break;
        }
    }

    const getAliquotType = (t) => {
        switch (parseInt(t)) {
            case 3:
                return '0.00';
                break;
            case 4:
                return '10.50';
                break;
            case 5:
                return '21.00';
                break;
        
            default:
                return '0.00'
                break;
        }
    }

    let QrData = {
        ver: 1,
        fecha: getDateQr(rowData.invoiceDate),
        cuit: parseInt(rowData.transmitterCuit),
        ptoVta: parseInt(rowData.pointSale),
        tipoCmp: 4,
        nroCmp: parseInt(rowData.voucherNumber),
        importe: rowData.total,
        moneda: 'PES',
        ctz: 1,
        tipoDocRec: rowData.afipDocTipo,
        nroDocRec: parseInt(rowData.receiverCuit),
        tipoCodAut: 'E',
        codAut: parseInt(rowData.caeNumber)
    }

    React.useEffect(() => {
        if(!invoicesFields){
            getFields()
        }
    }, [ invoicesFields, getFields ])
    
    return(
        <Root>
            <Container>
            {
                invoicesFields ?
                <Voucher ref={voucherPrintRef}>
                    <VoucherContainer>
                        <VoucherPart>
                            <VoucherHeader>RECIBO</VoucherHeader>
                            <InvoiceHeader>
                                <TransmitterData>
                                    <VoucherLogo src={Logo} alt="logo" />
                                    <span>santalucia.jintand.com</span>
                                    <span><strong>Razón Social:</strong> {rowData.transmitterBusinessName} </span>
                                </TransmitterData>
                                <InvoiceGrlData>
                                    <span><strong>Punto de Venta: </strong> {rowData.pointSale.toString().padStart(5,0)} &nbsp;&nbsp;&nbsp;&nbsp;<strong> Comp. Nro: </strong>{rowData.voucherNumber.toString().padStart(8,0)}</span>
                                    <span><strong>Lugar y fecha: </strong> TANDIL, {getDateInvoice(rowData.invoiceDate)}</span>
                                </InvoiceGrlData>
                            </InvoiceHeader>
                            <InvoiceBody>
                                <span>{`Recibí de ${rowData.nameReceiver}`}</span>
                                <span>en concepto de:</span>
                                <DescriptionContainer>
                                    <DescriptionConcept>{rowData.invoiceDetails[0].concept}</DescriptionConcept>
                                    <DescriptionAmount>{rowData.total}</DescriptionAmount>
                                </DescriptionContainer>
                                <span>{`Recibí la cantidad de pesos ${rowData.desctotal}`}</span>
                                <span>{`TOTAL: $${rowData.total}`}</span>
                                <ReceiverPayment>
                                    <Dashedline />
                                    <span>Recibido por</span>
                                </ReceiverPayment>
                            </InvoiceBody>
                            <InvoiceFooter padding='0 20px' height='100px' alignItems='center'>
                                <QRCode size={70} value={`https://www.afip.gob.ar/fe/qr/?p=${btoa(JSON.stringify(QrData))}`} />
                                <InvoiceFooterBox width='25%' alignH='flex-start'>
                                    <LogoAfip src={Logo_Afip} alt='logo_afip' />
                                    <Msg1Afip>COMPROBANTE AUTORIZADO</Msg1Afip>
                                </InvoiceFooterBox>
                                <span><strong>ORIGINAL</strong></span>
                                <InvoiceFooterBox width='50%' alignH='flex-end'>
                                    <span><strong>CAE N°: </strong>{rowData.caeNumber}</span>
                                    <span><strong>Fecha de Vto. de CAE: </strong>{getDateInvoice(rowData.caeExpirationDate)}</span>
                                </InvoiceFooterBox>
                            </InvoiceFooter>
                        </VoucherPart>
                        <Dashedline />
                        <VoucherPart>
                            <VoucherHeader>RECIBO</VoucherHeader>
                            <InvoiceHeader>
                                <TransmitterData>
                                    <VoucherLogo src={Logo} alt="logo" />
                                    <span>santalucia.jintand.com</span>
                                    <span><strong>Razón Social:</strong> {rowData.transmitterBusinessName} </span>
                                </TransmitterData>
                                <InvoiceGrlData>
                                    <span><strong>Punto de Venta: </strong> {rowData.pointSale.toString().padStart(5,0)} &nbsp;&nbsp;&nbsp;&nbsp;<strong> Comp. Nro: </strong>{rowData.voucherNumber.toString().padStart(8,0)}</span>
                                    <span><strong>Lugar y fecha: </strong> TANDIL, {getDateInvoice(rowData.invoiceDate)}</span>
                                </InvoiceGrlData>
                            </InvoiceHeader>
                            <InvoiceBody>
                                <span>{`Recibí de ${rowData.nameReceiver}`}</span>
                                <span>en concepto de:</span>
                                <DescriptionContainer>
                                    <DescriptionConcept>{rowData.invoiceDetails[0].concept}</DescriptionConcept>
                                    <DescriptionAmount>{rowData.total}</DescriptionAmount>
                                </DescriptionContainer>
                                <span>{`Recibí la cantidad de pesos ${rowData.desctotal}`}</span>
                                <span>{`TOTAL: $${rowData.total}`}</span>
                                <ReceiverPayment>
                                    <Dashedline />
                                    <span>Recibido por</span>
                                </ReceiverPayment>
                            </InvoiceBody>
                            <InvoiceFooter padding='0 20px' height='100px' alignItems='center'>
                                <QRCode size={70} value={`https://www.afip.gob.ar/fe/qr/?p=${btoa(JSON.stringify(QrData))}`} />
                                <InvoiceFooterBox width='25%' alignH='flex-start'>
                                    <LogoAfip src={Logo_Afip} alt='logo_afip' />
                                    <Msg1Afip>COMPROBANTE AUTORIZADO</Msg1Afip>
                                </InvoiceFooterBox>
                                <span><strong>DUPLICADO</strong></span>
                                <InvoiceFooterBox width='50%' alignH='flex-end'>
                                    <span><strong>CAE N°: </strong>{rowData.caeNumber}</span>
                                    <span><strong>Fecha de Vto. de CAE: </strong>{getDateInvoice(rowData.caeExpirationDate)}</span>
                                </InvoiceFooterBox>
                            </InvoiceFooter>
                        </VoucherPart>
                    </VoucherContainer>
                </Voucher>
            :
                <h2>CARGANDO...</h2>
            }
            </Container>
            <Footer>
                {
                    onBack && <Button onClick={onBack}>ATRÁS</Button>
                }
                <Button onClick={handleFinish}>FINALIZAR</Button>
            </Footer>
        </Root>
    )
}

function mapState(state) {
    const { invoicesFields } = state.invoice;
    return { invoicesFields};
}

const actionCreators = {
    getFields: invoicesActions.getFields,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ReceiptAConfirm);