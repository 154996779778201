/* General imports */
import React from 'react'
import styled from 'styled-components'
import { authHeader } from '../app/auth-header';
import axios from 'axios';
import { connect } from 'react-redux'
import { invoicesActions } from './InvoicesActions'
import { alertActions } from '../alert/AlertActions'

/* Components imports */
import Button from '@material-ui/core/Button';
import ModalAddInvoiceDetail from './ModalAddInvoiceDetail'
import RemoveIcon from '../icons/remove.svg'
import EditIcon from '../icons/edit.svg'
import ModalAddFromSale from './ModalAddFromSale'
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const Root = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
`

const Content = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 50px);
    box-sizing: border-box;
    padding: 10px;
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
`

const Footer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
`

const StyledFormControl = styled(FormControl)`
  width: 150px;
`

const StepInvoiceDetails = styled.div`
    position: relative;
    display: flex;
    width: 80%;
    height: 350px;
    box-sizing: border-box;
    box-shadow: 1px 1px 5px black;
    border-radius: 10px;
    padding: 10px;
    background: aliceblue;
`

const StepInvoiceDetailsLeft = styled.div`
    position: relative;
    display: flex;
    width: 70%;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: aliceblue;
`

const DetailsList = styled.ul`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 5px;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    margin: 0;
    overflow-y: auto;
`

const ItemDetailsList = styled.li`
    list-style: none;
    position: relative;
    display: flex;
    width: 100%;
    height: 70px;
    box-shadow: 1px 1px 5px #888, 1px 1px 5px #888;
    padding: 0 70px 0 10px;
    border-radius: 5px;
    margin-top: 5px;
    box-sizing: border-box;
    background: white;
`

const ItemConcept = styled.div`
    position: relative;
    display: flex;
    width: 65%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px;
`

const ItemLabel = styled.div`
    position: relative;
    display: flex;
    width: 15%;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    box-sizing: border-box;
    color: grey;
`

const ItemValues = styled.div`
    position: relative;
    display: flex;
    width: 20%;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    box-sizing: border-box;
`

const ItemButtons = styled.div`
    position: absolute;
    right: 0;
    display: flex;
    width: 50px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    border-radius: 0 5px 5px 0; 
`

const ItemButton = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 50%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    ${(props) => props.borderRadius ? `border-radius: ${props.borderRadius};` : '' }
    img {
        width: 20px;
        height: 20px;
        transition: all .5s ease;
    }
    img:hover {
        width: 25px;
        height: 25px;
    }
`

const StepInvoiceDetailsRight = styled.div`
    position: relative;
    display: flex;
    width: 30%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    border-left: 1px solid grey;
`

const StepInvoiceDetailsRightLabels = styled.div`
    position: relative;
    display: flex;
    width: 70%;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
`

const StepInvoiceDetailsRightValues = styled.div`
    position: relative;
    display: flex;
    width: 30%;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
`

const AddButton = styled.button`
    position: relative;
    display: flex;
    width: 100px;
    height: 35px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin: 0 20px;
`

const ZoneButtons = styled.div`
    display: flex;
    position: relative;
`

const CreditNoteBDetail = ({rowData, onBack, handleFinish, invoicesFields}) => {

    const [ dataForm, setDataForm ] = React.useState(rowData ? rowData : {
        afipAliquotType: '',
        afipAliquotAmount: 0.00,
        invoiceDetails: [],
        subtotal: 0.00,
        amountOtherTaxes: 0.00,
        total: 0.00,
        deletedInvoiceDetails:[],
    })

    const [ aliquotTypes, setAliquotTypes ] = React.useState(null);

    const [ add, setAdd ] = React.useState(false)
    const [ addFromSale, setAddFromSale ] = React.useState(false)

    const [ edit, setEdit ] = React.useState(false)

    const [ indexEdit, setIndexEdit ] = React.useState(null);

    const updateInvoiceTotals = (afipAliquotType, afipAliquotAmount) => {
        let subtotal = 0.00;
        dataForm.invoiceDetails.forEach(element => {
            subtotal = subtotal + (element.quantity * element.unitPrice)
        });
        if(afipAliquotType && afipAliquotAmount){
            setDataForm({
                ...dataForm,
                afipAliquotType: afipAliquotType,
                afipAliquotAmount: afipAliquotAmount,
                subtotal: subtotal - afipAliquotAmount,
                total: dataForm.amountOtherTaxes + subtotal,
                nettotal: (dataForm.amountOtherTaxes + subtotal)            
            })
        } else {
            if(dataForm.afipAliquotType !== ''){
                const div = (parseFloat(aliquotTypes.filter(ali => parseInt(ali.value) === parseInt(dataForm.afipAliquotType))[0].name) / 100) + 1; 
                const temp = subtotal / div;
                const aliquotAmount = subtotal - temp;
                setDataForm({
                    ...dataForm,
                    afipAliquotAmount: aliquotAmount.toFixed(2),
                    subtotal: subtotal - aliquotAmount.toFixed(2),
                    total: dataForm.amountOtherTaxes + subtotal,
                    nettotal: (dataForm.amountOtherTaxes + subtotal)            
                })
            } else {
                setDataForm({
                    ...dataForm,
                    subtotal: subtotal - dataForm.afipAliquotAmount,
                    total: dataForm.amountOtherTaxes + subtotal,
                    nettotal: (dataForm.amountOtherTaxes + subtotal)            
                })
            }
        }
        
    }

    const addInvoiceDetail = () => {
        setAdd(true)
    }

    const addInvoiceDetailFromSale = () => {
        setAddFromSale(true)
    }

    const editInvoiceDetail = (e) => {
        const { index } = e.target.dataset
        setIndexEdit(index)
        setEdit(true)
    }

    const removeInvoiceDetail = (e) => {
        const { index } = e.target.dataset
        let invoicesDet = dataForm.invoiceDetails
        let deletedDet = dataForm.deletedInvoiceDetails
        const idDeleted = dataForm.invoiceDetails[index].idInvoiceDetail
        deletedDet.push(idDeleted)
        invoicesDet.splice(index, 1)
        setDataForm({
            ...dataForm,
            invoiceDetails: invoicesDet,
            deletedInvoiceDetails: deletedDet
        })
        updateInvoiceTotals()
    }

    const handleConfirmEdit = (data) => {
        let invoicesDet = dataForm.invoiceDetails
        const bonusPercentage = data.bonusPercentage || 0
        const bonusAmount = (data.quantity * data.unitPrice) * bonusPercentage / 100
        invoicesDet[indexEdit] = {
            idInvoice: data.idInvoice || null,
            idInvoiceDetail: data.idInvoiceDetail || null,
            code: data.code || null,
            concept: data.concept,
            quantity: data.quantity,
            unitMeasurement: data.unitMeasurement,
            unitPrice: parseInt(data.unitPrice),
            bonusPercentage: bonusPercentage,
            bonusAmount: bonusAmount,
            subtotal: (data.quantity * data.unitPrice) - bonusAmount,
            deleted: data.deleted || 0
        }
        setDataForm({
            ...dataForm,
            invoiceDetails: invoicesDet
        })
        updateInvoiceTotals()
        handleCloseEdit()
    }

    const handleCloseEdit = () => {
        setEdit(false)
        setIndexEdit(null)
    }

    const handleCloseAdd = () => {
        setAdd(false)
    }

    const handleCloseAddFromSale = () => {
        setAddFromSale(false)
    }

    const handleConfirmAdd = (data) => {
        let invoicesDet = dataForm.invoiceDetails
        const bonusPercentage = data.bonusPercentage || 0
        const bonusAmount = (data.quantity * data.unitPrice) * bonusPercentage / 100
        invoicesDet.push({
            code: data.code || null,
            concept: data.concept,
            quantity: data.quantity,
            unitMeasurement: data.unitMeasurement,
            unitPrice: data.unitPrice,
            bonusPercentage: bonusPercentage,
            bonusAmount: bonusAmount,
            subtotal: (data.quantity * data.unitPrice) - bonusAmount
        })
        setDataForm({
            ...dataForm,
            invoiceDetails: invoicesDet
        })
        updateInvoiceTotals()
        handleCloseAdd()
    }

    const handleConfirmAddFromSale = (idSale) => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        axios.get(`/api/saledetails/sale/${idSale}`,configHeader)
        .then(result => {
            let invoicesDet = dataForm.invoiceDetails
            result.data.rows.forEach(element => {
                invoicesDet.push({
                    code: element.details.code,
                    concept: element.details.name,
                    quantity: element.quantity,
                    unitMeasurement: element.details.idQuantityUnit,
                    unitPrice: element.price,
                    bonusPercentage: 0,
                    bonusAmount: 0,
                    subtotal: element.totalAmount
                })
            });
            setDataForm({
                ...dataForm,
                invoiceDetails: invoicesDet
            })
            updateInvoiceTotals()
            handleCloseAddFromSale()
        })
        .catch(err => {
            console.log(err)
        })
    }

    const onChangeAliquot = (e) => {
        const {value} = e.target;
        const div = (parseFloat(aliquotTypes.filter(ali => parseInt(ali.value) === parseInt(value))[0].name) / 100) + 1; 
        const temp = dataForm.subtotal / div;
        const afipAliquotAmount = dataForm.subtotal - temp;
        updateInvoiceTotals(value, afipAliquotAmount.toFixed(2));
    }

    const onFinishStep = () => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm('¿Desea ya generar la factura?')){
            if(dataForm.invoiceDetails.length > 0){
                handleFinish(dataForm);
            } else {
                alert("Debe ingresar al menos 1 item de concepto.")
            }
        }
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!aliquotTypes){
            axios.get(`/api/afip/aliquottypes`,configHeader)
            .then(aliquotTypesData => {
                const aliquotTypesList = [];
                aliquotTypesData.data.forEach(option => {
                    aliquotTypesList.push({
                        name: option.Desc,
                        value: option.Id
                    })
                });
                setAliquotTypes(aliquotTypesList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ aliquotTypes ])

    return (
        <Root>
            <Content>
                <StepInvoiceDetails>
                    <StepInvoiceDetailsLeft>
                        <ZoneButtons>
                            <AddButton onClick={addInvoiceDetail}>Agregar</AddButton>
                            <AddButton onClick={addInvoiceDetailFromSale}>Agregar de venta</AddButton>
                        </ZoneButtons>
                        <DetailsList>
                            {
                                dataForm.invoiceDetails.map((item, index) => 
                                    <ItemDetailsList key={`ItemDetail${index}`}>
                                        <ItemConcept>
                                            {item.concept}
                                        </ItemConcept>
                                        <ItemLabel>
                                            <span>Importe</span>
                                        </ItemLabel>
                                        <ItemValues>
                                            <span>${item.quantity * item.unitPrice}</span>
                                        </ItemValues>
                                        <ItemButtons>
                                            <ItemButton borderRadius='0 5px 0 0'>
                                                <img src={RemoveIcon} alt='Remove item' data-index={index} onClick={removeInvoiceDetail} />
                                            </ItemButton>
                                            <ItemButton borderRadius='0 0 5px 0'>
                                                <img src={EditIcon} alt='Edit item' data-index={index} onClick={editInvoiceDetail} />
                                            </ItemButton>
                                        </ItemButtons>
                                    </ItemDetailsList>    
                                )
                            }
                        </DetailsList>
                    </StepInvoiceDetailsLeft>
                    <StepInvoiceDetailsRight>
                        <StepInvoiceDetailsRightLabels>
                            <span><strong>SUBTOTAL</strong></span>
                            <StyledFormControl>
                                <InputLabel id="aliquottype-label"> Alicuota </InputLabel>
                                <Select
                                    native
                                    labelId="aliquottype-label"
                                    
                                    onChange={onChangeAliquot}
                                    inputProps={{
                                    id:"afipAliquotType",
                                    name:"afipAliquotType",
                                    value:dataForm.afipAliquotType
                                    }}
                                >
                                    <option value="" />
                                    {
                                        aliquotTypes && aliquotTypes.map((ali, index) =>
                                            <option key={`aliquotType_option_${index}`} value={ali.value}> {ali.name} </option>
                                        )
                                    }
                                </Select>
                            </StyledFormControl>
                            <span><strong>IMP. OTROS ATRIB.</strong></span>
                            <span><strong>IMP. TOTAL</strong></span>
                        </StepInvoiceDetailsRightLabels>
                        <StepInvoiceDetailsRightValues>
                            <span>${dataForm.subtotal}</span>
                            <span style={{margin: "15px 0"}}>${dataForm.afipAliquotAmount}</span>
                            <span>${dataForm.amountOtherTaxes}</span>
                            <span>${dataForm.total}</span>
                        </StepInvoiceDetailsRightValues>
                    </StepInvoiceDetailsRight>
                    <ModalAddInvoiceDetail title='DETALLE DE COMPROBANTE' textButton='CARGAR' handleAction={handleConfirmAdd} rowData={dataForm.invoiceDetails} fields={invoicesFields.invoiceDetailData} openModal={add} closeModal={handleCloseAdd} />
                    <ModalAddFromSale title='DETALLE DE COMPROBANTE' textButton='CARGAR' handleAction={handleConfirmAddFromSale} openModal={addFromSale} closeModal={handleCloseAddFromSale} />
                    <ModalAddInvoiceDetail title='DETALLE DE COMPROBANTE' textButton='ACTUALIZAR' handleAction={handleConfirmEdit} rowData={dataForm.invoiceDetails[indexEdit]} fields={invoicesFields.invoiceDetailData} openModal={edit} closeModal={handleCloseEdit} />
                </StepInvoiceDetails>          
            </Content>
            <Footer>
                {
                    onBack && <Button onClick={onBack}>ATRÁS</Button>
                }
                <Button onClick={onFinishStep}>GENERAR COMPROBANTE</Button>
            </Footer>
        </Root>
    );
}

function mapState(state) {
    const { invoicesFields } = state.invoice;
    return { invoicesFields};
}

const actionCreators = {
    getFields: invoicesActions.getFields,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(CreditNoteBDetail);