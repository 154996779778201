/* General imports */
import React from 'react';
import styled from 'styled-components';

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { theme } from '../../app/config';

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 450px;
    min-height: 500px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.form`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    box-sizing: border-box;
    flex-direction: column;
`

const Inputbox = styled.div`
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 20px;
    margin: 10px 0;
    box-sizing: border-box;
    height: 48px;
    border-bottom: 1px solid black;
    ${(props) => props.error ? 'border-color: red;' : ''};
    label {
        position: absolute;
        top: ${(props) => props.effect ? '-10px' : '18px' };
        left: 0;
        color: #ccc;
        transition: .5s;
        pointer-events: none;
        font-size: ${(props) => props.effect ? '16px' : '20px'};
        height: 16px;
    }

    input {
        border: none;
        outline: none;
        background: none;
        color: black;
        font-size: 18px;
        width: calc(100% - 35px);
        padding-bottom: 12px;
        margin-top: 16px;
        box-sizing: border-box;
        height: 32px;
    }

    input:focus + label {
        top: -10px;
        color: black;
        font-size: 16px;
    }
`

const StyledButton = styled.button`
    width: calc(${(props) => props.columWidth ? props.columWidth : '100%'} * 0.75);
    height: 44px;
    background-color: ${theme.default.primaryButtonBg};
    color: ${theme.default.primaryButtonText};
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin-top: 25px;
    margin-bottom: 25px;
    outline: none;
`

/* ----- Principal function ----- */
function ModalEditStock({ title, textButton, openModal, closeModal, rowData, user, handleUpdate }) {

    const [data, setData ] = React.useState({
        quantity: rowData.quantity || 0,
        minStockQuantity: rowData.minStockQuantity || 0,
        exhibitorStockQuantity: rowData.exhibitorStockQuantity || 0
    })

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }

    const submit = async (e) => {
        e.preventDefault();
        await handleUpdate(rowData.idProduct, rowData.idStorage, {...data, updatedUser: user.idUser});
        closeModal();
    }

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ModalBody onSubmit={submit}>
                        <Inputbox effect={ true} >
                            <input id='quantity' required={true} name='quantity' type='number' value={data.quantity}  onChange={handleChange} />
                            <label>Cantidad</label>
                        </Inputbox>
                        <Inputbox effect={ true} >
                            <input id='minStockQuantity' required={true} name='minStockQuantity' type='number' value={data.minStockQuantity}  onChange={handleChange} />
                            <label>Stock mínimo de cantidad</label>
                        </Inputbox>
                        <Inputbox effect={ true} >
                            <input id='exhibitorStockQuantity' required={true} name='exhibitorStockQuantity' type='number' value={data.exhibitorStockQuantity}  onChange={handleChange} />
                            <label>Stock exhibidor de cantidad</label>
                        </Inputbox>
                        <StyledButton > {textButton} </StyledButton>
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalEditStock;