/* General imports */
import React from 'react';
import styled from 'styled-components';
import TextField from '../form/TextField';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import { pointPurchaseConstants } from './PointPurchaseConstants';

/* Styled components */
const SaleParams = styled.div`
    display: flex;
    position: relative;
    width: 95%;
    margin: 10px 0;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
`

const Param = styled.div`
    display: flex;
    position: relative;
    width: 30%;
    margin: 0;
    box-sizing: border-box;
    padding: 5px;
`

const MetaPurchase = (props) => {

    const [storages, setStorages] = React.useState(null);

    const [ suppliers, setSuppliers ] = React.useState(null);
    const [ paymentMethods, setPaymentMethods ] = React.useState(null);

    const handleChange = (name, value) => {
        props.handleChange(name, value)
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!storages){
            axios.get(`/api/storages`,configHeader)
            .then(storagesData => {
                const storagesList = [];
                storagesData.data.storages.forEach(element => {
                    storagesList.push({
                        name: element.name,
                        value: element.idStorage
                    })
                });
                setStorages(storagesList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ storages ])

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!suppliers){
            axios.get(`/api/suppliers`,configHeader)
            .then(suppliersData => {
                const suppliersList = [];
                suppliersData.data.rows.forEach(element => {
                    suppliersList.push({
                        name: element.name,
                        value: element.idSupplier
                    })
                });
                setSuppliers(suppliersList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ suppliers ])


    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!paymentMethods){
            axios.get(`/api/valuelist/group/${pointPurchaseConstants.PURCHASECONDITIONS_GROUP}`,configHeader)
            .then(purchaseConditionsData => {
                const purchaseConditionsList = [];
                purchaseConditionsData.data.valueslist.forEach(option => {
                    purchaseConditionsList.push({
                        name: option.value,
                        key: option.key,
                        value: option.idValueList
                    })
                });
                setPaymentMethods(purchaseConditionsList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ paymentMethods ])
    
    return (
        <SaleParams>
            <Param>
                <TextField id="supplier" value={props.metaData.supplier} required={true} name="supplier" placeholder="Proveedor" options={suppliers} type="select" fontSize="14px" onChange={handleChange} />    
            </Param>
            <Param>
                <TextField id="storage" value={props.metaData.storage} required={true} name="storage" placeholder="Punto de venta" options={storages} type="select" fontSize="14px" onChange={handleChange} />    
            </Param>
            <Param>
                <TextField id="purchaseNumber" value={props.metaData.purchaseNumber} required={false} name="purchaseNumber" placeholder="Numero Factura" type="text" fontSize="14px" onChange={handleChange} />    
            </Param>
            <Param>
                <TextField id="purchaseDate" value={props.metaData.purchaseDate} required={true} name="purchaseDate" placeholder="Fecha" type="date" fontSize="14px" onChange={handleChange} />    
            </Param>
            <Param>
                <TextField id="paymentMethod" value={props.metaData.paymentMethod} required={true} name="paymentMethod" placeholder="Forma de pago" options={paymentMethods} type="select" fontSize="14px" onChange={handleChange} />    
            </Param>
            <Param></Param>
        </SaleParams>
    );
}

export default MetaPurchase;