/* General imports */
import React from 'react'
import styled from 'styled-components'
import { config } from '../../app/config'

/* Components imports */
import NoImage from '../../images/no-image.png'
import WhatsappIcon from '../../images/whatsapp-logo.png'

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 280px;
    height: 95%;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 20px;
    &:hover {
        box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
    }
`

const ImageLink = styled.a`
    width: 100%;
    height: 280px;
    margin-bottom: 10px;
`

const ProducImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
`

const Name = styled.a`
    margin-left: 10px;
    text-decoration: none;
    font-size: 1rem;
    color: #000;
    font-weight: 500;
`

const Data = styled.div`
    display: flex;
    margin-left: 10px;
    align-items: center;
    justify-content: flex-start;
    font-weight: 300;
    font-size: 1rem;
    color: #001554;
`

const Price = styled.span`
    display: flex;
    position: relative;
    width: 100%;
    font-size: 14px;
    margin: 5px 0 0 10px;
    color: #A30D0D;
    text-shadow: 1px 1px 2px rgba(0,0,0,.5);
    p {
        margin: 0 0 0 10px;
        color: #4E4E4E;
        font-size: 14px;
    }
`

const ButtonMessage = styled.a`
    display: flex;
    position: relative;
    width: 80%;
    height: 30px;
    border-radius: 5px;
    color: white;
    font-size: 10px;
    text-shadow: 1px 1px 2px rgba(0,0,0,.5);
    background: #B00505;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    transition: 0.5s;
    &:hover {
        background: #A30D0D;
    }
`

const PopoverContact = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 50%;
    background: white;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    width: 150px;
    height: 80px;
    transform: translate(-50%,-150%);
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    box-shadow: 0 5px 25px rgba(0,0,0,.5);
    z-index: 20;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    h4 {
        display: block;
        position: relative;
        color: #B00505;
        text-shadow: 1px 1px 2px rgba(0,0,0,.5);
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        margin: 5px 0 5px 0;
    }
    p {
        display: block;
        position: relative;
        font-size: 10px;
        color: #4E4E4E;
        text-align: center;
        margin: 5px 0 10px 0;
    }
    &::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        background: white;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
    }
`

const Contact = styled.div`
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    box-shadow: 2px 2px 10px rgba(0,0,0,.5);
    img {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    &:hover ${PopoverContact} {
        visibility: visible;
        opacity: 1;
        transform: translate(-50%,-110%);
    }
`


/* ----- Principal function ----- */
function ProductCard({productInfo}) {

    return(
        productInfo &&
        <Root>
            <ImageLink href={`${config.url}/product/${productInfo.idProduct}`}>
                <ProducImage src={productInfo.img && productInfo.img.images.length > 0 ? productInfo.img.images[0] : NoImage} alt={productInfo.name} />
            </ImageLink>
            <Name href={`${config.url}/product/${productInfo.idProduct}`}><strong>{productInfo.name}</strong></Name>
            <Data><span>{productInfo.trademark ? productInfo.trademark.name : 'Marca'}</span></Data>
            <Price>{`${productInfo.idCurrency_Unit.key}${productInfo.salePrice} ${productInfo.idCurrency_Unit.value}`}</Price>
            <Contact>
                <img src={WhatsappIcon}  alt='ContactIcon' />
                <PopoverContact>
                    <p>Consultar por este producto</p>
                    <ButtonMessage href={`https://api.whatsapp.com/send?phone=${config.whatsapp}&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto ${config.url}/product/${productInfo.idProduct}`} target="_blank" >ENVIAR MENSAJE</ButtonMessage>
                </PopoverContact>
            </Contact>
        </Root>
    );
}

export default ProductCard;