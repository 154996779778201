/* General imports */
import React from 'react'
import styled from 'styled-components'
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import AreaChart from './AreaChart'
import { getMonthName } from '../utils/getMonthName';
import DolarBox from './DolarBox';
import { AuthNameAccess } from '../utils/AuthNameAccess';
import { nameAccess } from '../app/nameAccessConstants';
import Table from '../tables/AsyncTable';
import Spinner from '../spinners/LoaderSpinner';
import ShortcutBox from './ShortcutBox';
import PurchaseIcon from '../icons/purchase.svg';
import StockIcon from '../icons/stock.png'
import ReStock from '../icons/restock.webp'

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 20px;
`

const GridContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${(props) => props.templateColums ? props.templateColums : '1fr'} ;
  grid-template-rows: ${(props) => props.templateRows ? props.templateRows : 'auto'};
  width: 100%;
  height: auto;
  gap: 2rem;
  box-sizing: border-box;
`

const Box = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: ${(props) => props.height ? props.height : '100%'};
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) => props.boxShadow ? props.boxShadow : 'unset'};
  grid-row: ${(props) => props.gridRow ? props.gridRow : 'unset' };
  grid-column: ${(props) => props.gridColumn ? props.gridColumn : 'unset' };
  padding: ${(props) => props.padding ? props.padding : 'none'};
  margin: ${(props) => props.margin ? props.margin : 0};
`



function GridLayout(props) {

  const [dolar, setDolar] = React.useState(null);
  const [dolarBlue, setDolarBlue] = React.useState(null);
  const [dolarLiqui, setDolarLiqui] = React.useState(null);
  const [sales, setSales] = React.useState(null);
  const [toReStock, setToReStock] = React.useState(null);

  const columsTableStock = [
    { title: 'Producto', field: 'productData.name', width: "40%", editable: 'never' },
    { title: 'Marca', field: 'productData.trademark.name', width: "40%", editable: 'never'},
    { title: 'Min', field: 'minStockQuantity', width: "10%", editable: 'never'},
    { title: 'Stock', field: 'quantity', width: "10%", editable: 'never'},
    { title: 'Exhibidor', field: 'exhibitorStockQuantity', width: "10%", editable: 'never'},
    { title: 'Reponer', field: 'exhibitorStockQuantity', width: "10%", editable: 'never', render: (data) => <div>{data.exhibitorStockQuantity - data.quantity}</div>}
  ]

  const tableRefStockReport1 = React.useRef();

  React.useEffect(() => {
    if(!dolar){
      axios.get('https://api-contenidos.lanacion.com.ar/json/V3/economia/cotizacion/DBNA')
      .then(response => {
          setDolar(response.data)
      })
      .catch(err => {
      })
    }
  }, [ dolar])

  React.useEffect(() => {
    if(!dolarBlue){
      axios.get('https://api-contenidos.lanacion.com.ar/json/V3/economia/cotizacionblue/DBLUE')
      .then(response => {
        setDolarBlue(response.data)
      })
      .catch(err => {
      })
    }
  }, [dolarBlue])

  React.useEffect(() => {
    if(!dolarLiqui){
      axios.get('https://api-contenidos.lanacion.com.ar/json/V3/economia/cotizacionblue/DCCL')
      .then(response => {
        setDolarLiqui(response.data)
      })
      .catch(err => {
      })
    }
  }, [ dolarLiqui ])

  React.useEffect(() => {
    let configHeader = {
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    if(!sales){
      axios.get(`/api/statistics/sales`,configHeader)
      .then(salesData => {
        let statisticsSales = [];
        salesData.data.forEach(element => {
          statisticsSales.push({
            name: getMonthName(element.month),
            total: element.total
          })
        });
        setSales(statisticsSales);
      })
      .catch(err => {
          console.log(err);
      })
  }
}, [sales ])

React.useEffect(() => {
  let configHeader = {
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }
  if(!toReStock){
    axios.get(`/api/reports/stocklist/2`,configHeader)
    .then(result => {
      setToReStock(parseInt(result.data.total));
    })
    .catch(err => {
        console.log(err);
    })
}
}, [toReStock ])

    return (
      <Root>
        {
          AuthNameAccess(props.user, nameAccess.SALECHART) &&
          <Box height="350px" margin="0 0 2rem 0" boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
            {sales?
            <AreaChart data={sales} />
            : Spinner
            }
          </Box>
        }
        {AuthNameAccess(props.user, nameAccess.SALECHART) &&
        <GridContainer templateColums="1fr 1fr 1fr " templateRows="200px auto">
          <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
            <DolarBox title="Dólar Banco Nación" dolar={dolar} />
          </Box>
          <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
            <DolarBox title="Dólar Blue Banco Nación" dolar={dolarBlue} />
          </Box>
          <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
            <DolarBox title="Dólar Liqui Banco Nación" dolar={dolarLiqui} />
          </Box>
        </GridContainer>
        }

        {AuthNameAccess(props.user, nameAccess.SHORTCUTREPOSITOR) &&
        <GridContainer templateColums="1fr 1fr 1fr" templateRows="50px auto">
          <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
            <ShortcutBox title="Compras" icon={PurchaseIcon} link="admin/pointpurchase" />
          </Box>
          <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
            <ShortcutBox title="Stock" icon={StockIcon} link="admin/stocksystem/stock" />
          </Box>
          <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
            <ShortcutBox title={`Productos a reponer: ${toReStock}`} icon={ReStock} link="admin" />
          </Box>
        </GridContainer>
        }
        <GridContainer templateColums="1fr" templateRows="400px 400px">
            <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
              <Table 
                urlapi="/api/reports/stocklist/2" 
                tableRef={tableRefStockReport1} 
                autoExport={true}
                hiddenView={true}
                hiddenNew={true} 
                hiddenEdit={true} 
                hiddenDelete={true}
                hiddenSearch={true}
                hiddenPaging={true}
                title="Reposición Local" 
                columnsTable={columsTableStock} 
                tableLayout="fixed"
              />
            </Box>
            <Box boxShadow="1px 1px 5px rgba(0,0,0,0.25)">
              <Table 
                  urlapi="/api/reports/stocklist/1" 
                  tableRef={tableRefStockReport1} 
                  autoExport={true}
                  hiddenView={true}
                  hiddenNew={true} 
                  hiddenEdit={true} 
                  hiddenDelete={true}
                  hiddenSearch={true}
                  hiddenPaging={true}
                  title=" Reposición Depósito" 
                  columnsTable={columsTableStock} 
                  tableLayout="fixed"
                />
            </Box>
        </GridContainer>
      </Root>
    )
}
export default GridLayout;