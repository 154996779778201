/* General imports */
import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { alertActions } from '../../alert/AlertActions';
import axios from 'axios';

/* Components imports */
import Table from '../../tables/AsyncTable';
import ReportsIcon from '../../icons/reports.svg';
import ModalPurchase from './ModalPurchase';
import { showDate } from '../../utils/getDate';

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 100px);
    padding: 0 35px;
    box-sizing: border-box;
    top: -64px;
`

/* ----- Principal function ----- */
function Purchases({ user, alertMessage, history }) {

    const columsTable = [
        { title: 'ID', field: 'idPurchase', width: 50, editable: 'never' },
        { title: 'Fecha', field: 'purchaseDate', width: 75, type: 'date', editable: 'never', render: (data) => showDate(data.purchaseDate)},
        { title: 'Hora', field: 'createdDate', width: 75, type: 'time', editable: 'never'},
        { title: 'Numero Factura', field: 'purchaseNumber', width: 250, hidden: false, editable: 'never' },
        { title: 'ID prov', field: 'idSupplier', hidden: true, editable: 'never' },
        { title: 'Nombre prov', field: 'supplier.name', width: 100, editable: 'never' },
        { title: 'ID vendedor', field: 'created_User.idUser', hidden: true, editable: 'never' },
        { title: 'Usuario vendedor', field: 'created_User.username', hidden: true, width: 100, editable: 'never' },
        { title: 'Depósito', field: 'storage.name', editable: 'never' },
        { title: 'Cond. pago', field: 'purchase_condition.value', hidden: true, editable: 'never' },
        { title: 'Observación', field: 'observation', hidden: true},
        { title: 'Subtotal', field: 'subTotal', width: 100, type: 'currency', editable: 'never' },
        { title: 'Descuento', field: 'discount', hidden: true, width: 100, type: 'numeric', editable: 'never' },
        { title: 'Total', field: 'totalAmount', width: 100, type: 'currency', editable: 'never' }
    ]

    const [open, setOpen] = React.useState(false);

    const [ purchase, setPurchase ] = React.useState(null);

    const tableRef = React.useRef();

    const handleView = (rowData) => {
        setPurchase(rowData.idPurchase);
        setOpen(true);
    }

    const handleDelete = (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar la compra "+rowData.idPurchase+"?")) {
            axios
            .delete( '/api/purchases/' + rowData.idPurchase)
            .then(response => {
                tableRef.current.onQueryChange();
            })
            .catch(error => {
                alert("Hubo un error...")
            })
        }
    }

    const handleClose = () => {
        tableRef.current.onQueryChange()
        setOpen(false);
    }

    return(
        <Root>
            <Header>
                <Title>
                    <img src={ReportsIcon} alt='Icon' />
                    <h6>Compras</h6>
                </Title>
            </Header>
            <Content>
                <Route path={'/admin/reports/purchases'} exact={true} >
                    <Table 
                    idName="idPurchase"
                    urlapi="/api/purchases" 
                    cellEditable={true}
                    urlapi_celledit={`/api/purchases/field`}
                    tableRef={tableRef} 
                    autoExport={true}  
                    handleView={handleView} 
                    hiddenNew={true} 
                    hiddenEdit={true} 
                    handleDelete={handleDelete}  
                    title="COMPRAS REALIZADAS" 
                    columnsTable={columsTable} />   
                    {
                        open && purchase && <ModalPurchase purchase={purchase} openModal={open} closeModal={handleClose} />
                    }
                </Route>
            </Content>
        </Root>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user};
}

const actionCreators = {
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Purchases);