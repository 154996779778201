export const updateSuppliersConstants = {
    FETCHING_FORMFIELDS: 'UPDATE_SUPPLIERS_FETCHING_FORMFIELDS',
    SUCCESS_FORMFIELDS: 'UPDATE_SUPPLIERS_SUCCESS_FORMFIELDS',
    SUCCESS_UPDATEFORMFIELDS: 'UPDATE_SUPPLIERS_SUCCESS_UPDATEFORMFIELDS',
    ERROR_FORMFIELDS: 'UPDATE_SUPPLIERS_ERROR_FORMFIELDS',
    SUCCESS: 'UPDATE_SUPPLIERS_SUCCESS',
    ERROR: 'UPDATE_SUPPLIERS_ERROR',
    FETCHING: 'UPDATE_SUPPLIERS_FETCHING',
    CURRENCY_GROUP: 'unitsCurrency',
    SUCCESS_CURRENCYUNITS: 'CURRENCY_UNITS_SUCCESS',
    SUCCESS_QUANTITYUNITS: 'QUANTITY_UNITS_SUCCESS',
    QUANTITY_GROUP: 'unitsMeasurement',
    SUPPLIER_IDROLE: 4,
    SET: 'UPDATE_SUPPLIERS_SET',
    SETDATA: 'UPDATE_SUPPLIERS_SETDATA'
};
