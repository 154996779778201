/* General imports */
import React from 'react'
import styled, {keyframes} from 'styled-components';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import BackgroundNovelty from '../../images/Background_Novelty_Example.jpeg'
import BackgroundN from '../../images/BackNovelty.webp'
import Zoom from 'react-reveal/Zoom';
import NoImage from '../../images/no-image.png';

/* Styled Components */
const moveSlideshow = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

const Root = styled.div`
    width: 100%;
    height: 55vh;
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-rows: 55vh;
    position:relative;
    align-items: center;
    justify-content: center;
    font-family: 'IBM Plex Sans';
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        height: auto;
        grid-template-rows: auto;
        min-height: 70vh;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 50px;
    /* background: ${`url(${BackgroundN})`};
    background-position: left;
    background-repeat: no-repeat; */
    width: 100%;
    height: 100%;
    justify-content: center;
    @media (max-width: 600px) {
        width: 95%;
        padding: 0 10px;
        min-height: 35vh;
    }
`

const Pretitle = styled.span`
    color: #871237;
    font-size: 1.2rem;
`

const Title = styled.h2`
    color: #f52c3e;
    font-size: 3.5rem;
    margin: 10px 0;
    @media (max-width: 600px) {
        font-size: 2rem;
    }
`

const Text = styled.span`
    color: #871237;
    font-size: 1.4rem;
    @media (max-width: 600px) {
        font-size: 1.2rem;
    }
`

const Buttons = styled.div`
    display: flex;
    width: 100%;
    @media (max-width: 600px) {
        align-items: center;
        justify-content: center;
    }
`

const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 48px;
    font-size: 20px;
    background: ${(props) => props.background ? props.background : '#f52c3e'};
    border-radius: 24px;
    color: ${(props) => props.color ? props.color : '#FFF'};
    margin: 50px 20px 20px 0;
    cursor: pointer;
    text-decoration: none;
    ${(props) => props.border ? `border: ${props.border};` : ''}
    @media (max-width: 600px) {
        width: 150px;
        height: 40px;
        font-size: 16px;
        margin: 20px 10px;
    }
`

const NoveltyImage = styled.img`
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`

const BackNovelty = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    opacity: 0.2;
    z-index: -1;
`


function Novelty({idsection}) {

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        if(!data){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idsection])

    return (
        data && 
        <Root>
            <NoveltyImage src={data.img ? data.img : NoImage} alt="Novedad" />
            <Content>
                <BackNovelty src={BackgroundN} alt="Background Novedad" />
                <Zoom left cascade>
                    <Pretitle>{`NOVEDAD EXCLUSIVA `}</Pretitle>
                    <Title>{data.title}</Title>
                    <Text>{data.text}</Text>
                    <Buttons>
                        {
                            data.link1 &&
                            <Button href={data.link1}>{data.button1}</Button>
                        }
                        {
                            data.link2 &&
                            <Button href={data.link2} color="#871237" background="transparent" border="1px solid #001554">{data.button2}</Button>
                        }
                    </Buttons>
                </Zoom>
            </Content>
        </Root>
    )
}

export default Novelty;