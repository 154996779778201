import React from 'react'
import styled from 'styled-components'


const ContentImageSlide = styled.div`
    width: 100%;
    height: 100%;
`

const ContentSlide = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 120px;
    z-index: 99;
    @media (max-width: 600px) {
        padding: 0 50px;
        align-items: center;
    }
`

const Filter = styled.div`
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0,0,0,0.1);
    opacity: 0.75;
`

const Title = styled.h2`
width: 384px;
font-size: 2.9rem;
color: #FFF;
font-weight: 500;
margin: 20px 0;
text-shadow: 1px 1px 2px rgba(255,255,255,0.5);
@media (max-width: 600px) {
    width: 100%;
    font-size: 2rem;
    text-align: center;
    color: #001554;
}
`

const Text = styled.span`
width: 384px;
font-size: 1.8rem;
color: #FFF;
font-weight: 300;
text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
@media (max-width: 600px) {
    width: 95%;
    font-size: 1.3rem;
    text-align: center;
}
`

const Image = styled.img`
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background: linear-gradient(0deg, #001554 0%, #2196F382 100%) ;
    }
`

const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    height: 48px;
    font-size: 20px;
    background: #ff3030;
    border-radius: 24px;
    color: #FFF;
    margin: 30px 168px 30px 0;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 600px) {
        margin: 30px 0;
    }
`


const ImageContent = (props) => {
    return (
        <ContentImageSlide>
            <Image src={props.data.img} alt="image_slide" />
            <Filter />
            <ContentSlide>
                {
                    props.data.title && <Title>{props.data.title}</Title> 
                }
                {
                    props.data.text && <Text>{props.data.text}</Text>
                }
                {
                    props.data.link && <Button href={props.data.link}>{props.data.textButton}</Button>
                }
            </ContentSlide>
        </ContentImageSlide>
    );
}





export default ImageContent;