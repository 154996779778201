import React from 'react'
import styled from 'styled-components'
import ArgMap from '../../images/mapArgSinFondo.png'


const Image = styled.img`
position: relative;
object-position: center;
width: 100%;
height: 100%;
@media (max-width: 600px) {
    display: none;
}
`


const ContentImageSlide = styled.div`
display: grid;
grid-template-columns: 0.4fr 0.6fr;
width: 100%;
height: 100%;
@media (max-width: 600px) {
    grid-template-columns: 1fr;
}
`

const ContentImageDecoration = styled.img`
position: absolute;
top: 55%;
transform: translateY(-50%);
left: 70px;
width: 250px;
height: auto;
@media (max-width: 600px) {
    width: 45%;
    left: 10px;
}
`







const ContentBoxSlide = styled.div`
position: relative;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
justify-content: center;
box-sizing: border-box;
padding: 0 70px;
z-index: 99;
background: linear-gradient(180deg,rgba(135,18,55,1) 0%,rgba(245,44,62,1) 100%);
@media (max-width: 600px) {
    padding: 0 20px;
    align-items: flex-end;
}
`



const TitleImage = styled.h2`
    width: 384px;
    font-size: 2.9rem;
    color: #FFF;
    font-weight: 400;
    margin: 20px 0;
    @media (max-width: 600px) {
        width: 50%;
        font-size: 1.5rem;
        text-align: left;
        margin: 50px 0 10px 0;
    }
`

const TextImage = styled.span`
    width: 384px;
    font-size: 1.8rem;
    color: #FFF;
    font-weight: 300;
    @media (max-width: 600px) {
        width: 50%;
        font-size: 1rem;
        text-align: left;
    }
`

const ButtonImage = styled.a`
display: flex;
align-items: center;
justify-content: center;
width: 216px;
height: 48px;
font-size: 20px;
background: #FFF;
border-radius: 24px;
color: rgba(245,44,62,1);
margin: 30px 168px 30px 0;
cursor: pointer;
text-decoration: none;
@media (max-width: 600px) {
    margin: 30px 0;
    width: 50%;
    height: 40px;
    font-size: 16px;
}
`

const TwoColumns1 = (props) => {
    return (
        <ContentImageSlide>
            <ContentBoxSlide>
                {
                    props.data.title && <TitleImage>{props.data.title}</TitleImage> 
                }
                {
                    props.data.text && <TextImage>{props.data.text}</TextImage>
                }
                {
                    props.data.link && <ButtonImage href={props.data.link}>{props.data.textButton}</ButtonImage>
                }
            </ContentBoxSlide>
            <Image src={props.data.img} alt={props.key} />
        </ContentImageSlide>
    );
}

export default TwoColumns1;