import React, {forwardRef} from "react";
import styled from 'styled-components';
import translate from "../../providers/i18n/translate";
import Head from "./Head";
import Body from "./Body";
import GridBody from "./GridBody";
import AccordionBody from "./AccordionBody";
import Foot from "./Foot";
import Pagination from "./Pagination";
import { ThemeContext } from "../../providers/theme";
import SearchInput from "./SearchInput";
import {Filters} from "./";
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ListIcon from '@material-ui/icons/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExportButton from "./ExportButton";
import { useIntl } from "react-intl";
import { CSVLink } from "react-csv";
import NewButton from "./NewButton";
import PrintTagsButton from "./printTagsButton";
import PrintBarcodesButton from "./printBarcodesButton";

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    @media (min-width: 400px) {

    }
`

const Header = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    @media (min-width: 400px) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 50px;
    }
`

const ContentTable = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
`

const StyledTable = styled.table`
    position: relative;
    width: 100%;
    max-height: 100%;
    border-collapse: collapse;
    overflow-x: auto;
`

const TableType = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: 100%;
    margin: 0 10px;
`

const View = styled.button`
    border: none;
    color: ${(props) => props.active ? props.color : "rgba(0,0,0,0.5)"};
    background: transparent;
    cursor: pointer;
    user-select: none;;
`

const Divider = styled.div`
    width: 1px;
    height: 1rem;
    background-color: ${(props) => props.bg ? props.bg : "#000"};
    margin: 0 10px;
`

const Footer = styled.div`
    position: relative;
    width: 100%;
    height: 100px;
    @media (min-width: 400px) {
        height: 50px;
    }
`

const Title = styled.h2`
    position: relative;
    width: 100%;
    font-size: 1rem;
    height: 100%;
    text-transform: uppercase;
    @media (min-width: 400px) {
        position: absolute;
        left: 0;
        font-size: 1.15rem;
    }
`

const ContentTools = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
`

const Loading = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const Table = React.forwardRef(({title, columns, directData, disableTotalTitle, disablePagination, viewTable, disablePlain, changeView, gridCard, accordion, actionsTable, options, data, dataExport, children}, ref) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const exportRef = React.useRef();

    const [ dataset, setDataset ] = React.useState(null);

    const [ total, setTotal ] = React.useState(null);

    const [ selected, setSelected ] = React.useState(null)

    const [ originalDataset, setOriginalDataset ] = React.useState(null);

    const [ csvData , setCsvData] = React.useState([]);

    const [ _options, setOptions ] = React.useState(options ?
        {
            pageSize: options.pageSize || options.pageSizeOptions ? options.pageSizeOptions[0] : 15,
            pageSizeOptions: options.pageSizeOptions || [10, 15, 20, 50, 100],
            order: columns ?
            {
                field: columns[0].field,
                order: "ASC"
            }
            :
            null,
            exportButton: options.exportButton || false,
            exportName: options.exportName || "export",
            exportAllData: options.exportAllData || false,
            pagination: options.pagination || true,
            newButton: options.newButton || false,
            printTags: options.printTags || false,
            printBarcodes: options.printBarcodes || false,
            onNew: options.onNew,
            onPrintTags: options.onPrintTags || null,
            onPrintBarcodes: options.onPrintBarcodes || null
        }
        :
        {
            order: columns ?
            {
                field: columns[0].field,
                order: "ASC"
            }
            :
            null,
            pageSize: 15,
            pageSizeOptions: [10, 15, 20, 50, 100],
            exportButton: false,
            exportAllData: false,
            pagination: true,
            newButton: false,
            printTags: false,
            onNew: null,
            onPrintTags: null
        }
    )

    const [ currentPage, setCurrentPage ] = React.useState(1);

    const onChangeView = (e) => {
        e.preventDefault();
        const { view } = e.currentTarget.dataset;
        if(changeView){
            changeView(view);
            if(view === "list" && !disablePlain){
                const flatArray = dataset?.map(no =>
                    flattObj(no, '')
                );
                setDataset(flatArray);
            } else {
                setDataset(originalDataset);
            }
        }
    }

    //Funcion para calcular la interseccion entre los elementos seleccionados de forma global y los elementos seleccionados en el dataset actual
    const handleSelected = () => {
        const globalSelection = actionsTable?.select?.selectedItems;
        const dataset = actionsTable?.select?.dataset;
        const currentSelection = new Set(dataset?.filter(d => globalSelection.has(d.idProduct)));
        setSelected(currentSelection.size);
    }

    const handleLimit = (newLimit) => {
        setOptions({
            ..._options,
            pageSize: newLimit
        })
        setCurrentPage(1);
        refresh(1, newLimit);
    }

    const handlePage = (page) => {
        setCurrentPage(page);
    }

    const onNext = () => {
        const maxPage = total ? Math.ceil(total / _options.pageSize) : 0;
        if(maxPage > currentPage){
            setCurrentPage(parseInt(currentPage) + 1);
            refresh(parseInt(currentPage) + 1);
        }

    }

    const onPrevios = () => {
        const maxPage = total ? Math.ceil(total / _options.pageSize) : 0;
        if(maxPage > currentPage - 1 && currentPage > 1){
            setCurrentPage(currentPage - 1);
            refresh(parseInt(currentPage) - 1);
        }
    }

    const refresh = (newPage, newLimit) => {
        if(directData){
            if(viewTable && viewTable === 'list' && !disablePlain){
                const flatArray = directData.map(no =>
                    flattObj(no, '')
                );
                setDataset(flatArray);
            } else {
                setDataset(directData);
            }
            setOriginalDataset(directData);
            setTotal(directData.length);
        } else {
            data({
                page: newPage ? newPage : currentPage,
                pageSize: newLimit ? newLimit : _options.pageSize,
                order: _options.order
            })
            .then(result => {
                if(viewTable && viewTable === 'list' && !disablePlain){
                    const flatArray = result.rows.map(no =>
                        flattObj(no, '')
                    );
                    setDataset(flatArray);
                } else {
                    setDataset(result.rows);
                }
                setOriginalDataset(result.rows);
                setTotal(result.total ? result.total : null);
            })
            .catch(err => {
                console.log(err);
            })
        }

    }

    const handleOrder = (field, order) => {
        setOptions({
            ..._options,
            order: {
                field: field,
                order: order
            }
        })
        setCurrentPage(1);
        data({
            page: 1,
            pageSize: _options.pageSize,
            search: _options.search,
            order: {
                field: field,
                order: order
            }
        })
        .then(result => {
            if(viewTable && viewTable === 'list' && !disablePlain){
                const flatArray = result.rows.map(no =>
                    flattObj(no, '')
                );
                setDataset(flatArray);
            } else {
                setDataset(result.rows);
            }
            setOriginalDataset(result.rows);
            setTotal(result.total ? result.total : null);
        })
        .catch(err => {
            console.log(err);
        })
    }

    const searchChildren = React.Children.map(children, (child) => {
        if(child.type === SearchInput){
            return React.cloneElement(child);
        }
        return null;
    })

    const filterChildren = React.Children.map(children, (child) => {
        if(child.type === Filters){
            return React.cloneElement(child);
        }
        return null;
    })

    function flattObj(obj, param) {
        let newObj = {};
        for (let key in obj) {
          if (typeof obj[key] === 'object') {
            newObj = { ...newObj,
              ...flattObj(obj[key], key + '.')
            }
          } else {
            newObj[param + key] = obj[key]
          }
        }
        return newObj;
    }

    const chargeCsv = async (dataRows) => {
        setCsvData((prev) => [...prev, ...dataRows]);
    }

    const onExport = async () => {
        if(dataExport){
            const data = await dataExport();
            await chargeCsv(data);
            exportRef.current.link.click();
        }
    }

    React.useEffect(() => {
        if(!dataset){
            if(data){
                data({
                    page: currentPage,
                    pageSize: _options.pageSize,
                    order: _options.order
                })
                .then(result => {
                    if(viewTable && viewTable === 'list' && !disablePlain){
                        const flatArray = result.rows.map(no =>
                            flattObj(no, '')
                        );
                        setDataset(flatArray);
                    } else {
                        setDataset(result.rows);
                    }
                    setOriginalDataset(result.rows);
                    setTotal(result.total ? result.total : null);
                })
                .catch(err => {
                    console.log(err);
                })
            } else {
                if(directData){
                    if(viewTable && viewTable === 'list' && !disablePlain){
                        const flatArray = directData.map(no =>
                            flattObj(no, '')
                        );
                        setDataset(flatArray);
                    } else {
                        setDataset(directData);
                    }
                    setOriginalDataset(directData);
                    setTotal(directData.length);
                } else {
                    setDataset([]);
                }

            }

        }
    }, [dataset, viewTable]);

    React.useEffect(() => {
        handleSelected(); 
    }, [actionsTable])

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    React.useImperativeHandle(ref, () => ({
        clean() {
            setCurrentPage(1);
            refresh(1);
        }
    }));

    const onClickPrintTags = () => {
        _options.onPrintTags(actionsTable.select.selectedItems);
    };

    const onClickPrintBarcodes = () => {
        _options.onPrintBarcodes(actionsTable.select.selectedItems);
    };

    return (
        dataset && originalDataset ?
        <Root>
            {
                (title || changeView || searchChildren || filterChildren || _options.exportButton || _options.newButton || _options.printTags) &&
                <Header>
                    {
                        title && dataset &&
                        <Title>{disableTotalTitle ? title : `${title} (${dataset?.length})`}</Title>
                    }
                    <ContentTools>
                        {
                            changeView &&
                            <TableType>
                                <View data-view={"grid"} onClick={onChangeView} active={viewTable === "grid"} color={theme.palette.principal}><ViewComfyIcon /></View>
                                <Divider />
                                <View data-view={"list"} onClick={onChangeView} active={viewTable === "list"} color={theme.palette.principal}><ListIcon /></View>
                            </TableType>
                        }
                        {
                            searchChildren
                        }
                        {
                            _options.exportButton &&
                            <ExportButton onClick={onExport} />
                        }
                        {
                            _options.newButton &&
                            <NewButton onClick={_options.onNew} />
                        }
                        {
                            filterChildren
                        }
                        {
                            _options.printTags &&
                            <PrintTagsButton onClick={onClickPrintTags} />
                        }
                        {
                            _options.printBarcodes &&
                            <PrintBarcodesButton onClick={onClickPrintBarcodes} />
                        }

                    </ContentTools>
                </Header>
            }
            <ContentTable>
                <StyledTable>
                    {
                        viewTable === "list" && <Head actions={actionsTable} columnsTable={columns} columnOrder={_options.order} onOrder={handleOrder}/>
                    }
                    {
                        viewTable === "list" ?
                        <Body rowsTable={dataset} rawData={originalDataset} columnsTable={columns} actions={actionsTable} />
                        :
                        viewTable === "accordion" ?
                        <AccordionBody rowsTable={dataset} accordion={accordion} actions={actionsTable} />
                        :
                        <GridBody rowsTable={dataset} gridCard={gridCard} actions={actionsTable} />
                    }
                </StyledTable>
            </ContentTable>
            {
                !disablePagination &&
                <Footer>
                    <Pagination total={total} selected={selected} show={_options.pagination} pageOptions={_options.pageSizeOptions} limit={_options.pageSize} refresh={refresh} changeLimit={handleLimit} currentPage={currentPage} changePage={handlePage} pages={total ? Math.ceil(total / _options.pageSize) : 0} previos={onPrevios} next={onNext} />
                </Footer>
            }
            <CSVLink data={csvData} isSafari separator={";"} filename={`${_options.exportName}_${intl.formatDate(new Date(), {year: "numeric",month: "numeric" ,day: "numeric" })}.csv`} ref={exportRef} style={{display: 'none'}} ></CSVLink>

        </Root>
        :
        <Loading color="inherit" />
    );
});


export default Table;
