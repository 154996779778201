/* General imports */
import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import { alertActions } from '../alert/AlertActions'
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

/* Components imports */
import Table from '../tables/AsyncTable'
import ProfessionalsIcon from '../icons/professionalsWhite.png'
import NewCustomer from './NewCustomer'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 100px);
    padding: 0 35px;
    box-sizing: border-box;
    top: -64px;
`

/* ----- Principal function ----- */
function Customers({ user, alertMessage, history }) {

    const columsTable = [
        { title: 'ID', field: 'idUser', width: 50 },
        { 
            title: 'Nombres', 
            field: 'firstname', 
            width: 300
        },
        { 
            title: 'Apellidos', 
            field: 'lastname', 
            width: 300
        },
        { 
            title: 'Tipo documento', 
            field: 'documentType',
            hidden: true
        },
        { 
            title: 'Nro. documento', 
            field: 'documentNumber'
        },
        { 
            title: 'Fecha de nacimiento', 
            field: 'birthday',
            hidden: true
        },
        { 
            title: 'Teléfono', 
            field: 'tel'
        },
        { 
            title: 'Correo electrónico', 
            field: 'email'
        },
        { 
            title: 'Usuario', 
            field: 'username'
        },
        { 
            title: 'Datos del rol', 
            field: 'roles[0].userRole.roleData',
            hidden: true
        },
        { 
            title: 'Datos de facturación', 
            field: 'roles[0].userRole.invoiceData',
            hidden: true
        }
    ]

    const tableRef = React.createRef();

    const [ editData, setEditData ] = React.useState(null);

    const handleNew = () => {
        history.push('/admin/customers/new')
    };

    const handleEdit = (rowData) => {
        setEditData(rowData);
        history.push('/admin/customers/update');
    }

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const handleDelete = (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar el cliente "+rowData.idUser+"?")) {
            axios.delete(`/api/customers/${rowData.idUser}`,configHeader)
            .then(result => {
                alertMessage("Cliente eliminado");
            })
            .catch(err => {
                alertMessage("Hubo un error al eliminar el cliente.")
            })
        }
    }

    const RouteComponent = ({ component: Component, rowData, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                return <Component rowData={rowData} {...props} />
            }}
        />
    )

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <Header>
                        <Title>
                            <img src={ProfessionalsIcon} alt='Icon' />
                            <h6>Clientes</h6>
                        </Title>
                    </Header>
                    <Content>
                        <Route path={'/admin/customers'} exact={true} >
                        { 
                            <Table urlapi="/api/customers" tableRef={tableRef} autoExport={true}  hiddenView={true} handleNew={handleNew} handleEdit={handleEdit} handleDelete={handleDelete}  title="CLIENTES" columnsTable={columsTable} />   
                        }
                        </Route>
                        <Route path={'/admin/customers/new'} component={NewCustomer} />
                        <RouteComponent path={'/admin/customers/update'} rowData={editData} component={NewCustomer} />
                    </Content>
                </Root>
            </Desktop>
            <Tablet>CUSTOMERS TABLET</Tablet>
            <Mobile>CUSTOMERS MOBILE</Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user};
}

const actionCreators = {
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Customers);