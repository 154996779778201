/* General imports */
import React, {useRef} from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { invoicesActions } from './InvoicesActions'
import { alertActions } from '../alert/AlertActions'
import { useReactToPrint } from 'react-to-print';
import { getDate, showDate } from '../utils/getDate'

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Logo from '../images/DIETETICA_SANTA_LUCIA_LOGO.jpg'
import { TextField } from '@material-ui/core'
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import { config } from '../app/config';


/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 900px;
    min-height: 600px;
    align-items: center;
    justify-content: center;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 20px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
    border-radius: 10px 10px 0 0;
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    box-sizing: border-box;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
`

const Voucher = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 90%;
    height: auto;
    margin: 20px auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid black;
    @media all {
        .page-break {
            display: none;
        }
    }

    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
    }

    @media print {
        .page-break {
            /* margin-top: 1rem; */
            display: block;
            page-break-before: auto;
        }
    }

    @page {
        size: auto;
        margin: 2.5mm;
    }
`

const VoucherContainer = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 1050px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
`

const InvoiceHeader = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 150px;
`

const TransmitterData = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    span {
        font-size: 14px;
    }
    span:first-child {
        font-weight: bold;
        font-size: 25px;
        align-self: center;
        margin-bottom: 10px;
    }
`

const InvoiceGrlData = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 20px 20px 20px 50px;
    span {
        font-size: 14px;
    }
`

const ReceiverData = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    min-height: 70px;
    align-items: flex-start;
    justify-content: center;
`

const InvoiceBody = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const InvoiceBodyFields = styled.ul`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    padding: 0;
    margin: 0;
`

const HeaderField = styled.li`
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    background: #dedede;
    justify-content: center;
    border: 1px solid black;
    width: ${(props) => props.width ? props.width : '100px' };
    font-size: 0.75rem;
    font-weight: 700;
`

const InvoiceBodyDataContent = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    min-height: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* border: 1px solid black; */
    margin-bottom: 10px;
`

const InvoiceBodyRowData = styled.ul`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    min-height: 30px;
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
`

const DataField = styled.li`
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.width ? props.width : '100px' };
    border: 1px solid black;
    ${(props) => props.wordWrap ? 'word-wrap: anywhere;' : ''}
    ${(props) => props.border ? `border: ${props.border};` : ''}
`

const InvoiceFooter = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    align-items: center;
    justify-content: center;
`

const VoucherLogo = styled.img`
    height: 80px;
    width: auto;
`

const VoucherHeader = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;

`

const Divider = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 1px;
    background: black;
    margin: 20px 0 5px 0;
`

const Space = styled.div`
    width: 100%;
    height: 14px;
`

const Form = styled.div`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    box-sizing: border-box;
    padding: 10px 20px;
`

const ButtonsOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    button {
        margin: 0 10px;
    }

`

/* ----- Principal function ----- */
function ModalViewInvoice({ rowData, openModal, closeModal, title, user, saleData, createInvoice }) {

    const [ estimateData, setEstimateData] = React.useState({
        estimateDate: getDate(new Date()),
        dueDate: getDate(new Date()),
        customer: '',
        seller: '',
        mailSeller: '',
        observation: ''
    })

    const [ saved, setSaved ] = React.useState(false);

    const [ customer, setCustomer ] = React.useState(null);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setEstimateData({
            ...estimateData,
            [name]: value
        })
    }

    const getTotal = () => {
        let total = 0;
        rowData.forEach(element => {
            total = total + Math.round((element.amount * element.salePrice)*100)/100;
        });
        return total;
    }

    //PRUEBA
    const userInvoiceData = {
        transmitterCuit: '20333569893',
        transmitterBusinessName: 'JULIO CÉSAR BISET',
        transmitterCommercialAddress: 'Bereterbide 762',
        transmitterIvaCondition: 8,
        pointSale: 2,
        caeNumber: '',
        caeExpirationDate: '',
        transmitterActivityStartDate: '2017-11-29',
        transmitterGrossIncome: '30-71586015-1',
        createdUser: user.idUser
    }

    const invoiceData = {
        invoiceTypeCode: 35,
        invoiceDate: getDate(new Date()),
        invoicedPeriodFrom: getDate(new Date()),
        invoicedPeriodTo: getDate(new Date()),
        paymentDueDate: getDate(new Date()),
        receiverCuit: 0,
        receiverBusinessName: 'Doc. (Otro)',
        receiverCommercialAddress: 'Not commercial address',
        receiverIvaCondition: 38,
        saleCondition: saleData.paymentMethod
    }

    const invoiceContent = {
        afipAliquotType: '',
        afipAliquotAmount: 0.00,
        invoiceDetails: [],
        subtotal: getTotal(),
        amountOtherTaxes: 0.00,
        total: getTotal()
    }

    const handleOnFinish = async (e) => {
        e.preventDefault();
        let invoicesDet = invoiceContent.invoiceDetails
        rowData.forEach(element => {
            invoicesDet.push({
                code: element.code,
                concept: element.name,
                quantity: element.amount,
                unitMeasurement: element.quantityUnit.idValueList,
                unitPrice: element.salePrice,
                bonusPercentage: 0,
                bonusAmount: 0,
                subtotal: Math.round((element.amount * element.salePrice)*100)/100
            })
        });
        const voucher = {
            ...userInvoiceData,
            ...invoiceData,
            ...invoiceContent,
            invoiceDetails: invoicesDet,
            metaVoucher: estimateData
        }
        await createInvoice(voucher);
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!customer){
            axios.get(`/api/users/role/${config.idCustomerRole}`,configHeader)
            .then(customersData => {
                const customerList = customersData.data.users;
                const selectedCustomer = customerList.filter(c => parseInt(c.idUser) === parseInt(saleData.customer));
                setCustomer(selectedCustomer[0]);
                setEstimateData({
                    ...estimateData,
                    customer: `${selectedCustomer[0].firstname} ${selectedCustomer[0].lastname}`
                })

            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [])
    
    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                        <ButtonsOptions>
                            <button disabled={saved} onClick={handleOnFinish}>Guardar</button>
                            <button onClick={handlePrint}>Imprimir</button>
                        </ButtonsOptions>
                    </ModalTitle>
                    {
                        true ?
                            <ModalBody >
                                <Form>
                                    <TextField id="estimateDate" value={estimateData.estimateDate} name="estimateDate" placeholder="Fecha" type="date" fontSize="14px" onChange={handleChange} />    
                                    <TextField id="dueDate" value={estimateData.dueDate} name="dueDate" placeholder="Fecha vencimiento" type="date" fontSize="14px" onChange={handleChange} />    
                                    <TextField id="customer" value={estimateData.customer} name="customer" placeholder="Nombre cliente" type="text" fontSize="14px" onChange={handleChange} />    
                                    <TextField id="seller" value={estimateData.seller} name="seller" placeholder="Nombre Vendedor" type="text" fontSize="14px" onChange={handleChange} />    
                                    <TextField id="mailSeller" value={estimateData.mailSeller} name="mailSeller" placeholder="Correo vendedor" type="text" fontSize="14px" onChange={handleChange} />    
                                    <TextField id="observation" value={estimateData.observation} name="observation" placeholder="Observación" type="textarea" fontSize="14px" onChange={handleChange} />    
                                </Form>
                                <Voucher ref={componentRef}>
                                <VoucherContainer>
                                        
                                        <InvoiceHeader>
                                            <TransmitterData>
                                                <VoucherLogo src={Logo} alt="logo" />
                                            </TransmitterData>
                                            <InvoiceGrlData>
                                                <span><strong>{showDate(estimateData.estimateDate)}</strong></span>
                                            </InvoiceGrlData>
                                        </InvoiceHeader>
                                        <ReceiverData>
                                            <span><strong>Atención</strong></span>
                                            <span>Sr(a). {estimateData.customer}</span>
                                        </ReceiverData>
                                        <VoucherHeader>{title}</VoucherHeader>
                                        <ReceiverData>
                                            <span>Estimado/a cliente:</span>
                                            <span>Le escribimos con el fin de dar respuesta a la solicitud de cotización de los siguientes productos:</span>
                                        </ReceiverData>
                                        <InvoiceBody>
                                            <InvoiceBodyFields>
                                                <HeaderField width='10%'>CANT.</HeaderField>
                                                <HeaderField width='60%'>PRODUCTO/SERVICIO</HeaderField>
                                                <HeaderField width='15%'>PRECIO UNIT.</HeaderField>
                                                <HeaderField width='15%'>PRECIO</HeaderField>
                                            </InvoiceBodyFields>
                                            <InvoiceBodyDataContent>
                                                {
                                                    (rowData.length > 0) ? 
                                                        rowData.map((element, index) => 
                                                            <InvoiceBodyRowData key={`InvoiceDetail${index}`}>
                                                                <DataField width='10%'> {element.amount} </DataField>
                                                                <DataField width='60%' wordWrap={true}> {element.name} </DataField>
                                                                <DataField width='15%'> ${element.salePrice} </DataField>
                                                                <DataField width='15%'> ${Math.round((element.amount * element.salePrice)*100)/100} </DataField>
                                                            </InvoiceBodyRowData>
                                                        )
                                                    :
                                                        <span> NO HAY ITEMS PARA MOSTRAR</span>
                                                }
                                                <InvoiceBodyRowData>
                                                    <DataField width='10%' ></DataField>
                                                    <DataField width='60%' wordWrap={true} > </DataField>
                                                    <DataField width='15%'> TOTAL </DataField>
                                                    <DataField width='15%'> ${getTotal()} </DataField>
                                                </InvoiceBodyRowData>
                                                
                                            </InvoiceBodyDataContent>
                                            <ReceiverData>
                                                <span><strong>Importante: </strong>Los precios estarán vigentes hasta el día <strong>{showDate(estimateData.dueDate)}</strong>.</span>
                                                <span>A continuación, detalles de los productos:</span>
                                            </ReceiverData>
                                            <InvoiceBodyFields>
                                                <HeaderField width='40%'>PRODUCTO/SERVICIO</HeaderField>
                                                <HeaderField width='60%'>DESCRIPCIÓN</HeaderField>
                                            </InvoiceBodyFields>
                                            <InvoiceBodyDataContent>
                                                {
                                                    (rowData.length > 0) ? 
                                                        rowData.map((element, index) => 
                                                            <InvoiceBodyRowData key={`InvoiceDetail${index}`}>
                                                                <DataField width='40%'> {element.name} </DataField>
                                                                <DataField width='60%' wordWrap={true}> {element.description} </DataField>
                                                            </InvoiceBodyRowData>
                                                        )
                                                    :
                                                        <span> NO HAY ITEMS PARA MOSTRAR</span>
                                                }
                                                
                                            </InvoiceBodyDataContent>
                                            <ReceiverData>
                                                <span>Estamos a su disposición para cualquier duda.</span>
                                                <span>Lo esperamos, será un placer atenderlo.</span>
                                            </ReceiverData>
                                            <ReceiverData>
                                                <span><strong>Observación:</strong></span>
                                                {estimateData.observation}
                                            </ReceiverData>
                                        </InvoiceBody>
                                        <InvoiceFooter>
                                            <span>Atentamente</span>
                                            <Divider width="200px" />
                                            <span>{estimateData.seller}</span>
                                            <span>Administración y ventas</span>
                                            <span>{estimateData.mailSeller}</span>
                                            <Space />
                                            <span><a href="https://santalucia.jintand.com" target="_blank">santalucia.jintand.com</a>{` - `}</span>
                                            <span><strong>Domicilio comercial:</strong> {userInvoiceData.transmitterCommercialAddress} </span>
                                        </InvoiceFooter>
                                    </VoucherContainer>
                                </Voucher>
                            </ModalBody>
                        :
                            <h2>CARGANDO...</h2>
                    }
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

function mapState(state) {
    const { invoicesFields } = state.invoice;
    return { invoicesFields};
}

const actionCreators = {
    getFields: invoicesActions.getFields,
    createInvoice: invoicesActions.createInvoice,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ModalViewInvoice);