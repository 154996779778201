import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import { Provider } from 'react-redux'
import { store } from './app/store'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider, LOCALES } from "./providers/i18n";
import { ThemeProvider, THEMES } from "./providers/theme";
/* import * as serviceWorker from './serviceWorker'; */



ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={THEMES.default}>
            <I18nProvider
            locale={LOCALES.SPANISH}
            >
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </I18nProvider>
        </ThemeProvider>
    </Provider>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/* serviceWorker.unregister(); */
