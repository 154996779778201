import { authHeader } from "../app/auth-header";
import React from "react";
import axios from "axios";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ConstantLinearProgress from "./components/constantLinearProgress";
import { config } from "../app/config";

const Root = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(235, 235, 235);
`;

const ToggleButton = styled.button`
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: flex;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  align-items: center;
  justify-content: center;
  outline: none;
  z-index: 10;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const Loading = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
  svg {
    color: #9fac69;
  }
`;

const Logo = styled.img`
  width: 90%;
  height: auto;
  @media (min-width: 600px) {
    width: 50%;
  }
`;

const Barcode = styled.img`
  width: auto;
  height: 100px;
`;

const Title = styled.h2`
  color: #9fac69;
  font-weight: 500;
  font-size: 2rem;
  margin: 20px 0 20px 0;
  text-align: center;
  width: 80%;
  @media (min-width: 600px) {
    font-size: 3rem;
  }
`;

const Code = styled.p`
  color: #9fac69;
  font-weight: 500;
  font-size: 2rem;
  margin: 0;
`;

const Price = styled.p`
  color: rgb(141, 78, 62);
  font-weight: 600;
  font-size: 3rem;
`;

export default function Inquiry() {
  const timeout = config.inquiryTimeout ?? 10000;
  const [product, setProduct] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [timer, setTimer] = React.useState();
  const [fullScreen, setFullScreen] = React.useState(
    (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
      ? false
      : true,
  );

  const toggleFullScreen = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
        setFullScreen(true);
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
        setFullScreen(true);
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
        setFullScreen(true);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
        setFullScreen(false);
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        setFullScreen(false);
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
        setFullScreen(false);
      }
    }
  };

  let configHeader = {
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  const getProduct = () => {
    axios
      .get(`/api/products?search=${code}`, configHeader)
      .then((res) => {
        const newProduct = res.data?.rows[0];
        setProduct(newProduct ?? {});
      })
      .catch((err) => {
        setLoading(false);
        alert("Hubo un error al cargar los productos.");
      });
    setCode("");
  };

  const handleEnter = () => {
    clearTimeout(timer);
    setLoading(true);
    getProduct();
  };

  React.useEffect(() => {
    if (product !== null) {
      setLoading(false);
      if (Object.keys(product).length !== 0) {
        const newTimer = setTimeout(() => {
          setProduct(null);
        }, timeout);
        setTimer(newTimer);
      } else {
        const newTimer = setTimeout(() => {
          setProduct(null);
        }, timeout / 2);
        setTimer(newTimer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  React.useEffect(() => {
    const root = document.body;
    const keyboardListener = (e) => {
      const key = e.key;
      if (key !== "Enter") {
        setCode((prevState) => {
          const newValue = prevState + key;
          return newValue;
        });
      } else {
        handleEnter();
      }
    };
    root.addEventListener("keypress", keyboardListener);
    return () => root.removeEventListener("keypress", keyboardListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return loading ? (
    <Loading>
      <CircularProgress />
    </Loading>
  ) : (
    <Root id="inquiry">
      <ToggleButton onClick={toggleFullScreen}>
        {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </ToggleButton>
      {!product ? (
        <>
          <Logo src="santalucia_logo_large.png" />
          <Title>Escanee el producto</Title>
          <Barcode src="barcode_transparent.gif" />
        </>
      ) : Object.keys(product).length === 0 ? (
        <>
          <Title>Producto no encontrado</Title>
          <ConstantLinearProgress
            color="#9fac69"
            duration={timeout / 1.1 / 2}
          />
        </>
      ) : (
        <>
          <Title>
            {product?.alternativeName ?? product.name ?? "Producto sin nombre"}
          </Title>
          <Code>
            Código: {product?.barcode ?? product.code ?? "Código no encontrado"}
          </Code>
          <Price>$ {product?.salePrice ?? "Precio no encontrado"}</Price>
          <ConstantLinearProgress color="#9fac69" duration={timeout / 1.1} />
        </>
      )}
    </Root>
  );
}
