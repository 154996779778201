import React from 'react';
import styled from 'styled-components';
import RemoveIcon from '../../icons/remove.svg';
import ModalAddSupplier from './ModalAddSupplier';

const Root = styled.div`
    position: relative;
    /* box-shadow: 0 0 5px rgba(0,0,0,0.3); */
    width: 100%;
    height: 250px;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px;
`

const Table = styled.table`
    width: 100%;
`

const Header = styled.thead`
    position: relative;
    width: 100%;
    height: 50px;
    background: rgba(0,0,0,0.1);
    tr {
        width: 100%;
        font-size: 0.85rem;
    }
`

const Body = styled.tbody`
    position: relative;
    width: 100%;
    height: auto;
    tr {
        font-size: 0.75rem;
    }
    td{
        height: 20px;
    }
    img {
        width: 20px;
        height: 20px;
    }
`

const Footer = styled.tfoot`
    position: relative;
    width: 100%;
    height: 50px;
    td {
        text-align: center;
    }
`

const Button = styled.button`
    margin: 10px 0;
`

const SupplierBox = (props) => {

    const [open, setOpen] = React.useState(false);

    const handleAddSupplier = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    };

    const handleConfirmAddSupplier = async (data) => {
        await props.handleConfirmAddSupplier(data);
        handleClose();
    }

    return (
        <Root>
            <Table>
                <Header>
                    <tr>
                        <th width="15%">ID</th>
                        <th width="75%">PROVEEDOR</th>
                        <th width="10%"></th>
                    </tr>
                </Header>
                <Body>
                    {
                        props.suppliers && props.suppliers.length > 0 ? 
                        props.suppliers.map((supplier, index) => 
                            <tr key={`supplier${index}`}>
                                <td>{supplier.idSupplier}</td>
                                <td>{supplier.name}</td>
                                <td><img src={RemoveIcon} alt='Icon' data-idsupplier={supplier.idSupplier} data-name={supplier.name} onClick={props.removeSupplier} /></td>
                            </tr>
                        )
                        :
                        <tr>
                            <td style={{textAlign: "center"}} colSpan="3">Sin proveedores</td>
                        </tr>
                    }
                </Body>
                <Footer align="center">
                    <tr>
                        <td colSpan="3">
                            <Button onClick={handleAddSupplier}>AGREGAR</Button>
                        </td>
                    </tr>
                </Footer>
            </Table>
            {
                open && <ModalAddSupplier title='AGREGAR PROVEEDOR' textButton='AGREGAR' handleAction={handleConfirmAddSupplier} supList={props.suppliers} openModal={open} closeModal={handleClose} alertMessage={props.alertMessage} />
            }
            
        </Root>
    );
}

export default SupplierBox;