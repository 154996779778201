/* General imports */
import React from 'react'
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import Input from '../../form/Input';
import LocationOn from './LocationOn.svg';
import PhoneInTalk from './PhoneInTalk.svg';
import Email from './Email.svg';
import Zoom from 'react-reveal/Zoom';


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled Components */
const Root = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '80vh'};
    min-height:${(props) => props.minHeight ? props.minHeight : '600px'};
    background: ${(props) => props.background ? props.background : '#A30D0D'};
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    padding: ${(props) => props.padding ? props.padding : '0 15%'};
    box-sizing: border-box;
`

const Header = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '20%'};
    h3 {
        color: ${(props) => props.color ? props.color : 'white'};
        text-shadow: black 0.1em 0.1em 0.2em;
        text-align:center;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '48px'};
        margin: ${(props) => props.margin ? props.margin : '30px 0'};
    }
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: row;
    height: ${(props) => props.height ? props.height : '87.5%'};
`

const FormContainer = styled.div`
    width: ${(props) => props.width ? props.width : '65%'};
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-around;
    
`

const TextContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
`

const FormCompleteContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2.5%;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
`

const TitleFormComplete = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #3A3A3A;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    text-align: left;
    margin-top: 10%;
`

const Form = styled.form`
    width: 100%;
    height: ${(props) => props.height ? props.height : '60%'};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
`

const Message = styled.div`
        width:100%;
        color: ${(props) => props.color ? props.color : '#FFFFFF'};
        text-align:left;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '16px'};
        padding-left:16px;
        transition: .5s;
`

const FormButton = styled.div`
    width: 160px;
    height: 32px;
    border-radius: 24px;
    margin: 24px;
    background: white;
    color: black;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    user-select: none;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    &:hover {
        background: #CDCDCD;
        color: white;
    }
`

const DataContact = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: ${(props) => props.width ? props.width : '35%'};
    height: ${(props) => props.height ? props.height : '100%'};
    box-sizing: border-box;
`

const DataContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width ? props.width : '90%'};
    height: ${(props) => props.height ? props.height : '85%'};
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: space-around;
    background: ${(props) => props.background ? props.background : 'linear-gradient(90deg, rgba(135,18,55,1) 0%, rgba(245,44,62,1) 100%)'};
    font-family: 'Josefin Sans', sans-serif;
    font-size: ${(props) => props.fontSize ? props.fontSize : '18px'};
    color: ${(props) => props.color ? props.color : 'white'};
    padding: 20px 0;
`
const DataTitle = styled.ul`
    display: flex;
    padding-inline-start: ${(props) => props.paddingStart ? props.paddingStart : '30px'};
    list-style: none;
    font-family: 'Josefin Sans', sans-serif;
    font-size: ${(props) => props.fontSize ? props.fontSize : '20px'};
    font-weight: 700;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    svg {
        margin-right: 16px;
    }
`

const DataSubtitle = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`

const DataValue = styled.li`
    display: flex;
    align-items:center;
    list-style: none;
    box-sizing: border-box;
    padding-left: 30px;
    margin-bottom: 0.8vmin;
    margin-top: 0.8vmin;
    font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};
    width: 100%;
`

const Icons = styled.img`
    width: auto;
    height: ${(props) => props.height ? props.height : '100%'};
    display: flex;
    position:relative;
    align-items: center;
    justify-content: flex-start;
    padding-right:12px;
`

/* Principal function*/
function ContactUs({idsection, match}) {

    const [contactUsData, setContactUsData] = React.useState(null)

    const [dataForm, setDataForm] = React.useState({
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        contactAddress: '',
        contactSubject: '',
        contactMessage: ''
    })

    const [submitted, setSubmitted] = React.useState(false);

    const handleChange = (name, value) => {
        setDataForm({
            ...dataForm,
            [name]: value
        })
    }

    const handleForm = (e) => {

        e.preventDefault()
        if (dataForm.contactName && dataForm.contactEmail && dataForm.contactPhone && dataForm.contactAddress && dataForm.contactSubject && dataForm.contactMessage) {
            axios.post( '/api/email' , dataForm)
                .then(response => {
                    setSubmitted(true);
                })
                .catch(error => {
                    alert('Hubo un error con el envío...')
                })  
        } else {
            setSubmitted(false);
        }
    }

    React.useEffect(() => {
        if(!contactUsData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setContactUsData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [contactUsData, idsection])


    return (
        !contactUsData ? <Spinner spinnerType='ThreeDots' />
        :
        <React.Fragment>
            <Desktop>
                <Root background={contactUsData.firstColor}>
                    <Header color={contactUsData.colorText1}>
                        <Zoom>
                                <h3>{contactUsData.title}</h3>
                        </Zoom>
                    </Header>
                    <Container>
                        {
                            (!submitted) &&
                            <FormContainer>
                                <Message color={contactUsData.colorText1}>
                                    Ante cualquier duda o consulta, puede utilizar el formulario de contacto que se encuentra a continuación.
                                </Message>
                                <Form>
                                    <TextContainer>
                                        <Input name='contactName' data={dataForm} width='40%' color={contactUsData.colorText1} border={true} fontSize='14px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Nombre y Apellido' required={true} inputMargin='14px' error={dataForm[`contactName_error`]} />
                                        <Input name='contactEmail' data={dataForm} width='40%' color={contactUsData.colorText1} border={true} fontSize='14px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Email' required={true} inputMargin='14px' error={dataForm[`contactEmail_error`]} />
                                        <Input name='contactPhone' data={dataForm} width='40%' color={contactUsData.colorText1} border={true} fontSize='14px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Telefóno' required={true} inputMargin='14px' error={dataForm[`contactPhone_error`]} />
                                        <Input name='contactAddress' data={dataForm} width='40%' color={contactUsData.colorText1} border={true} fontSize='14px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Dirección' required={true} inputMargin='14px' error={dataForm[`contactAddress_error`]} />
                                    </TextContainer>
                                    <Input name='contactSubject' data={dataForm} width='83%' color={contactUsData.colorText1} border={true} fontSize='14px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Asunto' required={true} inputMargin='14px' error={dataForm[`contactSubject_error`]} />
                                    <Input name='contactMessage' data={dataForm} width='83%' color={contactUsData.colorText1} height='120px' border={true} fontSize='14px' onHandleChange={handleChange} type='textarea' effectColor='white' placeholder='Escribe tu mensaje aquí...' required={true} inputMargin='14px' error={dataForm[`contactMessage_error`]} />
                                </Form>
                                <FormButton onClick={handleForm}>
                                    ENVIAR
                            </FormButton>
                            </FormContainer>
                        }
                        {
                            submitted &&
                            <FormCompleteContainer>
                                <TitleFormComplete>
                                    Muchas gracias por <br />contactarte con nosotros.
                                </TitleFormComplete>
                                <Message>
                                    A la brevedad nos pondremos en contacto <br /> para resolver tus dudas.
                                </Message>
                            </FormCompleteContainer>
                        }

                        <DataContact>
                            <DataContainer background={contactUsData.secondColor} color={contactUsData.colorText2}>
                                <Zoom cascade>
                                    <DataTitle> 
                                        <DataSubtitle><Icons src={LocationOn} /> {contactUsData.addresses.split(';').length > 1 ? 'DIRECCIONES' : 'DIRECCIÓN'}</DataSubtitle>
                                        {
                                            contactUsData.addresses.split(';').map((address, index) => (
                                                <DataValue key={index}>{address}</DataValue>
                                            ))
                                        }
                                    </DataTitle>
                                    <DataTitle> 
                                        <DataSubtitle><Icons src={PhoneInTalk} /> {contactUsData.phones.split(';').length > 1 ? 'TELÉFONOS' : 'TELÉFONO'}</DataSubtitle>
                                        {
                                            contactUsData.phones.split(';').map((phone, index) => (
                                                <DataValue key={index}>{phone}</DataValue>
                                            ))
                                        }
                                    </DataTitle>
                                    <DataTitle> 
                                        <DataSubtitle><Icons src={Email} /> {contactUsData.emails.split(';').length > 1 ? 'CORREOS ELECTRÓNICOS' : 'CORREO ELECTRÓNICO'}</DataSubtitle>
                                        {
                                            contactUsData.emails.split(';').map((email, index) => (
                                                <DataValue key={index}>{email}</DataValue>
                                            ))
                                        }
                                    </DataTitle>
                                </Zoom>
                            </DataContainer>
                        </DataContact>
                    </Container>
                </Root>

            </Desktop>

            <Tablet>
                <Root background={contactUsData.firstColor}>
                    <Header color={contactUsData.colorText1}>
                        <h3>{contactUsData.title}</h3>
                    </Header>
                    <Container>
                        {
                            (!submitted) &&
                            <FormContainer width='60%' >
                                <Message color={contactUsData.colorText1}>
                                    Ante cualquier duda o consulta, puede utilizar el formulario de contacto que se encuentra a continuación.
                                </Message>
                                <Form>
                                    <TextContainer>
                                        <Input name='contactName' data={dataForm} width='42%' color={contactUsData.colorText1} border={true} fontSize='12px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Nombre y Apellido' required={true} inputMargin='12px' error={dataForm[`contactName_error`]} />
                                        <Input name='contactEmail' data={dataForm} width='42%' color={contactUsData.colorText1} border={true} fontSize='12px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Email' required={true} inputMargin='12px' error={dataForm[`contactEmail_error`]} />
                                        <Input name='contactPhone' data={dataForm} width='42%' color={contactUsData.colorText1} border={true} fontSize='12px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Telefóno' required={true} inputMargin='12px' error={dataForm[`contactPhone_error`]} />
                                        <Input name='contactAddress' data={dataForm} width='42%' color={contactUsData.colorText1} border={true} fontSize='12px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Dirección' required={true} inputMargin='12px' error={dataForm[`contactAddress_error`]} />
                                    </TextContainer>
                                    <Input name='contactSubject' data={dataForm} width='92%' color={contactUsData.colorText1} border={true} fontSize='12px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Asunto' required={true} inputMargin='12px' error={dataForm[`contactSubject_error`]} />
                                    <Input name='contactMessage' data={dataForm} width='92%' color={contactUsData.colorText1} height='120px' border={true} fontSize='12px' onHandleChange={handleChange} type='textarea' effectColor='white' placeholder='Escribe tu mensaje aquí...' required={true} inputMargin='12px' error={dataForm[`contactMessage_error`]} />
                                </Form>
                                <FormButton onClick={handleForm}>
                                    ENVIAR
                            </FormButton>
                            </FormContainer>
                        }
                        {
                            submitted &&
                            <FormCompleteContainer>
                                <TitleFormComplete>
                                    Muchas gracias por <br />contactarte con nosotros.
                                </TitleFormComplete>
                                <Message>
                                    A la brevedad nos pondremos en contacto <br /> para resolver tus dudas.
                                </Message>
                            </FormCompleteContainer>
                        }

                        <DataContact width='40%' height='80%'>
                            <DataContainer background={contactUsData.secondColor} color={contactUsData.colorText2}>
                                <DataTitle fontSize='16px' paddingStart='15px' > 
                                <DataSubtitle><Icons height='80%' src={LocationOn} /> {contactUsData.addresses.split(';').length > 1 ? 'DIRECCIONES' : 'DIRECCIÓN'} </DataSubtitle>
                                    {
                                        contactUsData.addresses.split(';').map((address, index) => (
                                            <DataValue fontSize='10px' key={index}>{address}</DataValue>
                                        ))
                                    }
                                </DataTitle>
                                <DataTitle fontSize='16px' paddingStart='15px'> 
                                <DataSubtitle><Icons height='80%' src={PhoneInTalk} /> {contactUsData.phones.split(';').length > 1 ? 'TELÉFONOS' : 'TELÉFONO'} </DataSubtitle>
                                    {
                                        contactUsData.phones.split(';').map((phone, index) => (
                                            <DataValue fontSize='10px' key={index}>{phone}</DataValue>
                                        ))
                                    }
                                </DataTitle>
                                <DataTitle fontSize='16px' paddingStart='15px' > 
                                <DataSubtitle><Icons height='80%' src={Email} /> {contactUsData.emails.split(';').length > 1 ? 'CORREOS ELECTRÓNICOS' : 'CORREO ELECTRÓNICO'} </DataSubtitle>
                                    {
                                        contactUsData.emails.split(';').map((email, index) => (
                                            <DataValue fontSize='10px' key={index}>{email}</DataValue>
                                        ))
                                    }
                                </DataTitle>
                            </DataContainer>
                        </DataContact>
                    </Container>
                </Root>
            </Tablet>
            <Mobile>
                <Root background={contactUsData.firstColor} height="150vh" minHeight='900px' padding='0 10%'>
                    <Header color={contactUsData.colorText1} margin='20px 0' fontSize="14px">
                        <h3>{contactUsData.title}</h3>
                    </Header>
                    <Container height='80%'>
                        {
                            (!submitted) &&
                            <FormContainer width='100%' >
                                <Message color={contactUsData.colorText1} fontSize="5px">
                                    Ante cualquier duda o consulta, puede utilizar el formulario de contacto que se encuentra a continuación.
                                </Message>
                                <Form height="80%">
                                    <TextContainer>
                                        <Input name='contactName' data={dataForm} width='100%' color={contactUsData.colorText1} border={true} fontSize='12px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Nombre y Apellido' required={true} inputMargin='12px' error={dataForm[`contactName_error`]} />
                                        <Input name='contactEmail' data={dataForm} width='100%' color={contactUsData.colorText1} border={true} fontSize='12px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Email' required={true} inputMargin='12px' error={dataForm[`contactEmail_error`]} />
                                        <Input name='contactPhone' data={dataForm} width='100%' color={contactUsData.colorText1} border={true} fontSize='12px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Telefóno' required={true} inputMargin='12px' error={dataForm[`contactPhone_error`]} />
                                        <Input name='contactAddress' data={dataForm} width='100%' color={contactUsData.colorText1} border={true} fontSize='12px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Dirección' required={true} inputMargin='12px' error={dataForm[`contactAddress_error`]} />
                                    </TextContainer>
                                    <Input name='contactSubject' data={dataForm} width='92%' color={contactUsData.colorText1} border={true} fontSize='12px' onHandleChange={handleChange} type='text' effectColor='white' placeholder='Asunto' required={true} inputMargin='12px' error={dataForm[`contactSubject_error`]} />
                                    <Input name='contactMessage' data={dataForm} width='92%' color={contactUsData.colorText1} height='120px' border={true} fontSize='12px' onHandleChange={handleChange} type='textarea' effectColor='white' placeholder='Escribe tu mensaje aquí...' required={true} inputMargin='12px' error={dataForm[`contactMessage_error`]} />
                                </Form>
                                <FormButton onClick={handleForm}>
                                    ENVIAR
                            </FormButton>
                            </FormContainer>
                        }
                        {
                            submitted &&
                            <FormCompleteContainer>
                                <TitleFormComplete>
                                    Muchas gracias por <br />contactarte con nosotros.
                            </TitleFormComplete>
                                <Message>
                                    A la brevedad nos pondremos en contacto <br /> para resolver tus dudas.
                                </Message>
                            </FormCompleteContainer>
                        }
                    </Container>
                    <DataContact width='100%'>
                        <DataContainer background={contactUsData.secondColor} width="100%" height='90%' color={contactUsData.colorText2}>
                            <DataTitle fontSize='16px' paddingStart='15px' > 
                            <DataSubtitle><Icons height='80%' src={LocationOn} /> {contactUsData.addresses.split(';').length > 1 ? 'DIRECCIONES' : 'DIRECCIÓN'} </DataSubtitle>
                                {
                                    contactUsData.addresses.split(';').map((address, index) => (
                                        <DataValue fontSize='10px' key={index}>{address}</DataValue>
                                    ))
                                }
                            </DataTitle>
                            <DataTitle fontSize='16px' paddingStart='15px'> 
                            <DataSubtitle><Icons height='80%' src={PhoneInTalk} /> {contactUsData.phones.split(';').length > 1 ? 'TELÉFONOS' : 'TELÉFONO'} </DataSubtitle>
                                {
                                    contactUsData.phones.split(';').map((phone, index) => (
                                        <DataValue fontSize='10px' key={index}>{phone}</DataValue>
                                    ))
                                }
                            </DataTitle>
                            <DataTitle fontSize='16px' paddingStart='15px' > 
                            <DataSubtitle><Icons height='80%' src={Email} /> {contactUsData.emails.split(';').length > 1 ? 'CORREOS ELECTRÓNICOS' : 'CORREO ELECTRÓNICO'} </DataSubtitle>
                                {
                                    contactUsData.emails.split(';').map((email, index) => (
                                        <DataValue fontSize='10px' key={index}>{email}</DataValue>
                                    ))
                                }
                            </DataTitle>
                        </DataContainer>
                    </DataContact>
                </Root>
            </Mobile>
        </React.Fragment >
    )
}

export default ContactUs;