import React from "react";
import { useIntl } from 'react-intl';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers';
import { MobileDateRangePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { ThemeContext } from "../../../providers/theme";

const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StyledTextField = styled(TextField)`

`

const StyledDateRangePicker = styled(MobileDateRangePicker)`
  width: 100%;
`

const StyledButton = styled.button`
  grid-column: 1/3;
  align-self: center;
  color: ${(props) => props.color ? props.color : "blue"};
  background: ${(props) => props.background ? props.background : "blue"};
  border: none;
  outline: none;
  border-radius: 8px;
  margin: 5px 10px 5px 0;
  cursor: pointer;
  width: fit-content;
  padding: 5px 10px;
  justify-self: flex-end;
  font-size: 1rem;
`

const DateFilter = ({filter, values, onChange}) => {

const theme = React.useContext(ThemeContext);

const intl = useIntl();
const [value, setValue] = React.useState([null, null]);

const clear = () => {
handleDateChange([null, null])
if (filter.type === "date") {
onChange(filter.name, [])
}
else{
onChange(filter.start, [])
onChange(filter.end, [])
}
}

const handleDateChange = (newValue) => {
setValue(newValue);
if (filter.type == "date") {
(newValue[0] != null || newValue[1] != null) &&
onChange(filter.name, newValue)
}
else{
if (value[0] != null && value[1] != null) {
onChange(filter.start, [value[0]])
onChange(filter.end, [value[1]])
}
}
}

React.useEffect(() => {
values.length === 0 && value && setValue([null, null])
}, [values])

return (
<LocalizationProvider dateAdapter={MomentUtils} localeText={{ start: 'Desde', end: 'Hasta' }}>
  <StyledDateRangePicker
  label="Rango Fechas"
  toolbarTitle="Seleccionar rango fechas"
  startText="Desde"
  endText="Hasta"
  value={value}
  onChange={handleDateChange}
  renderInput={(startProps, endProps) => (
                    <StyledDatePicker>
                      <StyledTextField  size="small" {...startProps} label="Desde" helperText="" variant="standard"/>
                      <StyledTextField  size="small" {...endProps} label="Hasta" helperText="" variant="standard"/>
                      <StyledButton  color={theme.palette.base} background={theme.palette.principal}   onClick={clear}>Limpiar</StyledButton>
                    </StyledDatePicker>
                )}
                  />
                    </LocalizationProvider>
    );
}

export default DateFilter;

