/* General imports */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { devices, config } from '../app/config';
import { sidebarCartActions } from '../SidebarCart/SidebarCartActions';
import { alertActions } from '../alert/AlertActions'
import queryString from 'query-string'
import axios from 'axios';

/* Components imports */
import SuccessImage from '../images/compraexitosa.jpg';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: 100%;
    height: auto;
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: ${(props) => props.padding ? props.padding : "100px 200px"};
    flex-direction: column;
`

const Message = styled.img`
    position: relative;
    display: flex;
    width: 400px;
    height: 400px;
    align-items: center;
    justify-content: center;
`

const BackToPage = styled.a`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 20px 0;
    padding: 10px 20px;
    box-sizing: border-box;
    color: black;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    strong {
        margin-left: 5px;
    }
`

/* ----- Principal function ----- */
function PaymentSuccess({clearCart, cartitems, alertMessage, location}) {

    const {idOrder} = queryString.parse(location.search);

    const updateOrder = () => {
        axios.post( '/api/mp/success' , {idOrder: idOrder})
        .then(response => {
            axios.put( `/api/orders/processed/${idOrder}`)
            .then( orderResponse => {
                axios.post( '/api/email/order' , {idOrder: idOrder});
                clearCart();
            })
            .catch(error => {
                alertMessage(error.toString())
            }); 
        })
        .catch(error => {
            alertMessage(error.toString())
        }); 
    }

    React.useEffect(() => {
        updateOrder();
    }, [ ]) 

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <Message src={SuccessImage} alt="Compra exitosa" />
                    <BackToPage href={config.url}>Volver a <strong>Dietética Santa Lucía.</strong></BackToPage>
                </Root>
            </Desktop>
            <Tablet>
                <Root padding="100px">
                    <Message src={SuccessImage} alt="Compra exitosa" />
                    <BackToPage href={config.url}>Volver a <strong>Dietética Santa Lucía</strong></BackToPage>
                </Root>
            </Tablet>
            <Mobile>
                <Root flexDirection="column" padding="50px 20px">
                    <Message src={SuccessImage} alt="Compra exitosa" />
                    <BackToPage href={config.url}>Volver a <strong>Dietética Santa Lucía</strong></BackToPage>
                </Root>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, open, cartitems} = state.sidebarcart;
    return { error, isFetching, open, cartitems};
}

const actionCreators = {
    clearCart: sidebarCartActions.clearCart,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(PaymentSuccess);