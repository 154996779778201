/* General imports */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { invoicesActions } from './InvoicesActions'
import { alertActions } from '../alert/AlertActions'
import { authHeader } from '../app/auth-header'
import axios from 'axios'

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import InvoiceActionsStep from './invoiceActionsStep'
import VoucherGeneralData from './voucherGeneralData'
import VoucherDetail from './VoucherDetail'
import VoucherConfirm from './VoucherConfirm'


/* useStyles and functions to Stepper Material-UI */
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 1200px;
    min-height: 600px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 20px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
`

const StepContentContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    button {
        &:disabled {
            opacity: 0.5;
        }
        width: 150px;
        color: #fff !important;
        background-color: #192d3e;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        border-radius: 4px;
        border: 0;
        outline: none;
        &:hover {
            background-color: #122230;
        }
    }
`

/* ----- Principal function ----- */
function ModalCreateUpdateInvoice({ user, idClient, saleCondition, saleDate, invoiceDetails, voucherType, createInvoice, title, rowData, afipStatus, voucherPrintRef, openModal, closeModal, getFields, invoicesFields, handleAction, alertMessage }) {

    // PRUEBA
    const userInvoiceData = {
        transmitterCuit: '20333569893',
        transmitterBusinessName: 'JULIO CÉSAR BISET',
        transmitterCommercialAddress: 'Bereterbide 762',
        transmitterIvaCondition: 8,
        pointSale: 2,
        caeNumber: '',
        caeExpirationDate: '',
        transmitterActivityStartDate: '2017-11-29',
        transmitterGrossIncome: '30-71586015-1',
        createdUser: user.idUser
    }

    

    /* Stepper constants */
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const [idFormData, setIdFormData] = React.useState(null);

    const [generalData, setGeneralData] = React.useState(null);

    const [detailData, setDetailData] = React.useState(null);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleOnFinishStepData = (data) => {
        setGeneralData(data);
        handleNext();
    }

    const handleOnFinishStepDetail = (data) => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        axios.post('/api/afip/nextvoucher', {...data, ...generalData}, configHeader)
        .then(async result => {
            const resultData = result.data;
            const voucher = {
                ...userInvoiceData,
                ...data,
                ...generalData,
                voucherNumber: resultData.voucherNumber,
                caeNumber: resultData.CAE,
                caeExpirationDate: resultData.CAEFchVto
            }
            await createInvoice(voucher);
            setDetailData({...data,
                voucherNumber: resultData.voucherNumber,
                caeNumber: resultData.CAE,
                caeExpirationDate: resultData.CAEFchVto});
            handleNext();
        })
        .catch(error => {
            console.log(error);
            alert("Hubo un problema al generar el comprobante en AFIP.")
        })
        
    }

    const handleOnFinishStepTicketDetail = async (data) => {
        setDetailData(data);
        const voucher = {
            ...userInvoiceData,
            ...generalData,
            ...data
        }
        await createInvoice(voucher);
        handleNext();
    }

    const handleOnFinishStepConfirm = () => {
        handleAction({...userInvoiceData, ...generalData, ...detailData});
    }

    const getSteps = () => {
        return ['SELECCIONAR ACCIÓN','DATOS GENERALES', 'DETALLES COMPROBANTE', 'IMPRIMIR'];
    }

    
    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!invoicesFields){
            getFields()
        }
        if(idClient){
            axios.get(`/api/users/${idClient}`,configHeader)
            .then(data => {
                const userData = data.data.user;
                if(userData.roles[0].userRole.invoiceData){
                    const clientData = userData.roles[0].userRole.invoiceData
                    setGeneralData({
                        ...generalData,
                        invoiceDate: saleDate,
                        invoicedPeriodFrom: saleDate,
                        invoicedPeriodTo: saleDate,
                        paymentDueDate: saleDate,
                        receiverCuit: clientData.cuit,
                        receiverBusinessName: clientData.businessname,
                        receiverCommercialAddress: clientData.commercialAddress,
                        receiverIvaCondition: clientData.ivaCondition,
                        saleCondition: saleCondition ? saleCondition : null
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
            if(invoiceDetails){
                const itemsDetail = [];
                let total = 0;
                invoiceDetails.forEach(element => {
                    itemsDetail.push({
                        code: element.code || null,
                        concept: element.name,
                        quantity: element.amount,
                        unitMeasurement: element.quantityUnit.idValueList,
                        unitPrice: element.salePrice,
                        bonusPercentage: 0,
                        bonusAmount: 0,
                        subtotal: (element.amount * element.salePrice)
                    });
                    total = total + (element.amount * element.salePrice);
                });
                setDetailData({
                    afipAliquotType: '',
                    afipAliquotAmount: 0.00,
                    invoiceDetails: itemsDetail,
                    subtotal: total,
                    amountOtherTaxes: 0.00,
                    total: total,
                    deletedInvoiceDetails:[],
                })
            }
        }
    }, [ invoicesFields, getFields, idClient ])


    const handleStepAction = (idForm) => {
        setIdFormData(idForm)
        setActiveStep(parseInt(activeStep + 1));
    }
    
    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
        case 0:
            return <InvoiceActionsStep afipStatus={afipStatus} voucherType={voucherType} rowData={rowData} handleAction={handleStepAction} />;
        case 1:
            return idFormData && <VoucherGeneralData idForm={idFormData} rowData={ rowData ? rowData : generalData} onBack={handleBack} handleOnFinishStep={handleOnFinishStepData} />;
        case 2:
            return idFormData && <VoucherDetail idForm={idFormData} rowData={rowData ? rowData : detailData} onBack={handleBack} handleOnFinishStep={handleOnFinishStepDetail} handleOnFinishStepTicket={handleOnFinishStepTicketDetail} />;
        case 3:
            return idFormData && generalData && detailData && <VoucherConfirm idForm={idFormData} rowData={{...userInvoiceData, ...detailData, ...generalData}} voucherPrintRef={voucherPrintRef} onBack={handleBack} handleOnFinishStep={handleOnFinishStepConfirm} />;
        default:
            return 'Unknown stepIndex ' + stepIndex;
        }
    }

    const steps = getSteps();

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ModalBody>
                        <div className={classes.root}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                                ))}
                            </Stepper>
                            <div>
                                <StepContentContainer >
                                    {getStepContent(activeStep)}
                                </StepContentContainer>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

function mapState(state) {
    const { error, isFetching, invoicesFields } = state.invoice;
    const { user } = state.login;
    return { user, error, isFetching, invoicesFields};
}

const actionCreators = {
    getFields: invoicesActions.getFields,
    createInvoice: invoicesActions.createInvoice,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ModalCreateUpdateInvoice);