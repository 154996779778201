export const nameAccess = {
    ROOT: 'root',
    DASHBOARD: 'dashboard',
    PRODUCT: 'product',
    CATEGORY: 'category',
    STOCK: 'stock',
    INCOME: 'income',
    SALE: 'sale',
    PURCHASE: 'purchase',
    ACCOUNT: 'account',
    CUSTOMER: 'customer',
    SUPPLIER: 'supplier',
    REPORTS: 'reports',
    SETTINGS: 'settings',
    MANAGEMENT: 'management',
    INVOICES: 'invoices',
    PROFESSIONALS: 'professionals',
    PATIENTS: 'patients',
    WEBEDITOR: 'webeditor',
    ACCOUNTS: 'accounts',
    STOCKSYSTEM: 'stocksystem',
    SALESREPORT: 'salereport',
    PURCHASESREPORT: 'purchasereport',
    TRADEMARKS: 'trademarks',
    APPLICATION: 'application',
    CONFIG: 'configuration',
    ADMINISTRATION: 'administration',
    SALECHART: 'salechart',
    SHORTCUTREPOSITOR:"shortcutRepositor"
};