
/* General imports */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';

/* Components imports */

/* Styled components */

const Inputbox = styled.div`
    position: relative;
    display: flex;
    width: ${(props) => props.width ? props.width : '100%'};
    height: ${(props) => props.height ? props.height : props.type === 'textarea' ? props.fontSize ? `calc(${props.fontSize} * 0.65 + ${props.fontSize} * 2 + 12px)` : "60px" : props.fontSize ? `calc(${props.fontSize} * 0.55 + ${props.fontSize} + 12px)` : '40px'};
    align-items: flex-end;
    justify-content: left;
    box-sizing: border-box;
    color: ${(props) => props.color ? props.color : 'black'};
    background: ${(props) => props.background ? props.background : 'transparent'};
    font-size: ${(props) => props.fontSize ? props.fontSize : '18px'};
    margin: ${(props) => props.margin ? `${props.margin}` : '7px 0'};
    border-bottom: ${(props) => props.color ? `1px solid ${props.color}` : '1px solid black'};
    ${(props) => props.border ? props.border : ''};
    ${(props) => props.error ? 'border-color: red;' : ''};
    outline: none;
    ${(props) => props.fontFamily ? `font-family: ${props.fontFamily};` : ''}
    label {
        position: absolute;
        display: flex;
        top: ${(props) => props.fontSize ? `calc(${props.fontSize} * 0.8 + 2px)` : '16px' };
        left: ${(props) => props.icon ? props.fontSize ? `calc(${props.fontSize} + 12px)` : '30px' : '5px'};
        transition: .5s;
        opacity: .5;
        pointer-events: none;
        font-size: ${(props) => props.fontSize ? props.fontSize : '18px' };
        outline: none;
    }

    input {
        position: relative;
        display: flex;
        width: ${(props) => props.icon ? props.fontSize ? `calc(100% - ${props.fontSize} - 12px)` : `calc(100% - 30px)` : '100%'};
        height: ${(props) => props.fontSize ? `calc(${props.fontSize} + 10px)`: '28px'};
        align-items: center;
        justify-content: left;
        box-sizing: border-box;
        border: none;
        outline: none;
        padding: ${(props) => props.icon ? props.fontSize? `5px 5px 5px calc(${props.fontSize} + 10px)` : '5px 5px 5px 28px' : '5px'};
        background: ${(props) => props.background ? props.background : 'transparent'};
        color: ${(props) => props.color ? props.color : 'black'};
        ${(props) => props.fontFamily ? `font-family: ${props.fontFamily};` : ''}
    }

    select {
        position: relative;
        display: flex;
        width: ${(props) => props.icon ? props.fontSize ? `calc(100% - ${props.fontSize} - 12px)` : `calc(100% - 30px)` : '100%'};
        height: ${(props) => props.fontSize ? `calc(${props.fontSize} + 10px)`: '28px'};
        align-items: center;
        justify-content: left;
        box-sizing: border-box;
        border: none;
        outline: none;
        padding: ${(props) => props.icon ? '5px 5px 5px 0' : '0 5px'};
        box-sizing: border-box;
        background: ${(props) => props.background ? props.background : 'transparent'};
        color: ${(props) => props.color ? props.color : 'black'};
        ${(props) => props.fontFamily ? `font-family: ${props.fontFamily};` : ''}
    }

    textarea {
        position: relative;
        display: flex;
        width: ${(props) => props.icon ? props.fontSize ? `calc(100% - ${props.fontSize} - 12px)` : `calc(100% - 30px)` : '100%'};
        height: ${(props) => props.height ? `calc(${props.height} - 12px)` : props.fontSize ? `calc(${props.fontSize} * 2 + 10px)`: '46px'};
        align-items: flex-start;
        justify-content: left;
        border: none;
        outline: none;
        resize: none;
        padding: ${(props) => props.icon ? '5px 5px 5px 0' : '5px'};
        box-sizing: border-box;
        background: ${(props) => props.background ? props.background : 'transparent'};
        color: ${(props) => props.color ? props.color : 'black'};
        ${(props) => props.fontFamily ? `font-family: ${props.fontFamily};` : ''}
    }

    input:focus + label {
        top: 2px;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*0.55)` : '10px'};
        ${(props) => props.colorEffect ? `color: ${props.colorEffect};` : ''}
    }

    input:not([value=""]) + label {
        top: 2px;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize} * 0.55)` : '10px'};
        ${(props) => props.colorEffect ? `color: ${props.colorEffect};` : ''}
    }

    select:valid + label {
        top: 2px;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize} * 0.55)` : '10px'};
        ${(props) => props.colorEffect ? `color: ${props.colorEffect};` : ''}
    }

    select:disabled + label {
        top: 2px;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize} * 0.55)` : '10px'};
        ${(props) => props.colorEffect ? `color: ${props.colorEffect};` : ''}
    }

    textarea:valid + label {
        top: 2px;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize} * 0.55)` : '10px'};
        ${(props) => props.colorEffect ? `color: ${props.colorEffect};` : ''}
    }

    input[type=date] + label {
        top: 2px;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize} * 0.55)` : '10px'};
        ${(props) => props.colorEffect ? `color: ${props.colorEffect};` : ''}
    }

    input[type=color] + label {
        top: 2px;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize} * 0.55)` : '10px'};
        ${(props) => props.colorEffect ? `color: ${props.colorEffect};` : ''}
    }

    select:focus + label {
        top: 2px;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize} * 0.55)` : '10px'};
        ${(props) => props.colorEffect ? `color: ${props.colorEffect};` : ''}
    }

    textarea:focus + label {
        top: 2px;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize} * 0.55)` : '10px'};
        ${(props) => props.colorEffect ? `color: ${props.colorEffect};` : ''}
    }

    img {
        position: absolute;
        display: flex;
        width: ${(props) => props.fontSize ? `calc(${props.fontSize} + 2px)` : '20px'};
        height: ${(props) => props.fontSize ? `calc(${props.fontSize} + 2px)` : '20px'};
        bottom: 5px;
        left: 5px;
    }
`

/* ----- Principal function ----- */
function TextField({name, required, autoFocus, options, fontFamily, type, rows, value, placeholder, width, fontSize, color, background, margin, error, border, icon, onChange, height, colorEffect, min, max, step, readOnly, disabled}) {

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        onChange(name, value)
    }

    // Render the corresponding input type
    const renderInput = (type) => {
        switch (type) {
            case 'select':
                return (
                    <select id={name} value={value} required={required} name={name} onChange={handleChange} disabled={disabled} readOnly={readOnly}>
                        <option value="-1" hidden>Seleccione</option>
                        {
                            options && options.map((option, index) => 
                                <option key={`${name}_option${index}`} value={option.value}>{option.key ? `${option.key}(${option.name})` : option.name}</option>
                            )
                        }
                    </select>
                );
            case 'text':
                return (
                    <input id={name} required={required} autoFocus={autoFocus} name={name} value={value} type={type}  onChange={handleChange} disabled={disabled} />
                );
            case 'date':
                return (
                    <input id={name} required={required} autoFocus={autoFocus} name={name} value={value} type={type}  onChange={handleChange} disabled={disabled} />
                );
            case 'password':
                return (
                    <input id={name} required={required} autoFocus={autoFocus} name={name} value={value} type={type}  onChange={handleChange} disabled={disabled} />
                );
            case 'number':
                    return (
                        <input id={name} required={required} autoFocus={autoFocus} name={name} value={value} type={type} min={min} max={max} step={step}  onChange={handleChange} readOnly={readOnly} disabled={disabled} />
                    );
            case 'textarea':
                return (
                    <textarea id={name} value={value} style={{width: '100%', verticalAlign: 'top'}} required={required} autoFocus={autoFocus} name={name} type={type} rows={rows}  onChange={handleChange} disabled={disabled} />
                );
            case 'color':
                return (
                    <input id={name} required={required} autoFocus={autoFocus} name={name} value={value} type={type}  onChange={handleChange} disabled={disabled} />
                );
            default:
                return <h6>No se reconoce el tipo de input</h6>;
        }
    }

    return(
        <Inputbox width={width} fontSize={fontSize} fontFamily={fontFamily} color={color} background={background} margin={margin} error={error} border={border} icon={icon} type={type} height={height} colorEffect={colorEffect}>
            { renderInput(type) }
            <label>{required ? `${placeholder} *` :  placeholder }</label>
            {
                icon && <img src={icon} alt={`icon_${name}`} />
            }
        </Inputbox>
    );
}

TextField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    required: PropTypes.bool,
    autoFocus: PropTypes.bool,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string, 
    fontSize: PropTypes.string,
    color: PropTypes.string,
    background: PropTypes.string,
    margin: PropTypes.string,
    error: PropTypes.bool,
    border: PropTypes.string,
    icon: PropTypes.string,
    colorEffect: PropTypes.string,
    fontFamily: PropTypes.string,
    options: PropTypes.array, //only applies to select
    rows: PropTypes.number, //only applies to textarea
    onChange: PropTypes.func.isRequired
};

TextField.defaultProps = {
    name: 'TextField',
    type: 'text',
};

export default TextField;