import React, { forwardRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

const Root = styled.div`
    display: flex;
    position: relative;
    width: 95%;
    height: 70px;
    align-items: center;
    justify-content: flex-start;
    label {
        font-size: 20px;
        margin: 5px;
    }
    input {
        font-size: 20px;
        margin: 5px;
        padding: 2px 5px;
        box-sizing: border-box;
    }
    span {
        font-size: 10px;
        opacity: 0.85;
    }
`

const Code = styled.div`
    display: flex;
    position: relative;
`

const ProductList = styled.ul`
    display: ${(props) => props.show ? "flex" : "none"};
    position: absolute;
    flex-direction: column;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 5px;
    width: 200%;
    height: auto;
    max-height: 500px;
    overflow: auto;
    background: white;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
    z-index: 999;
`

const ItemProductList = styled.li`
    display: flex;
    position: relative;
    width: 100%;
    text-decoration: none;
    padding: 20px;
    margin: 0;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.2);
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    span {
        font-size: 12px;
    }
    &:hover {
        box-shadow: 1px 1px 15px rgba(0,0,0,0.8); 
    }
`

const ProductInput = React.forwardRef(({getSubtotal, setSubtotal, items, setItems, saledata, alertMessage}, ref) => {

    const [helpCode, setHelpCode] = React.useState(false);
    const [amount, setAmount] = React.useState(1);
    const [code, setCode] = React.useState('');
    const [showProductList, setShowProductList] = React.useState(false);
    const [productList, setProductList] = React.useState([])
    const codeRef = React.useRef();

    const onChangeCode = (e) => {
        let search = e.target.value;
        setCode(search);
    }

    const onKeyUpCode = (e) => {
        const keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13') {
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            let search = code;
            if(search.length > 2){
                setHelpCode(false);
                let directProduct = false;
                if(search.length > 12 ){
                    let localAmount = 0;
                    if(parseInt(search.substring(0, 2)) === 20){
                        search = parseInt(search.substring(2, 7));
                        directProduct = true;
                    } else if(parseInt(search.substring(0, 2)) === 21){
                        localAmount = parseFloat(search.substring(7, 9) + '.' + search.substring(9, 12));
                        setAmount(localAmount);
                        search = parseInt(search.substring(2, 7));
                        directProduct = true;
                    }
                    if(directProduct){
                        axios.get(`/api/products/code/${search}`,configHeader)
                        .then(productsData => {
                            if(productsData){
                                const product = productsData.data.product;
                                if(localAmount !== 0){
                                    product['amount'] = localAmount;
                                } else {
                                    product['amount'] = amount;
                                }
                                insertProduct(product);
                            }
                        })
                        .catch(err => {
                            alertMessage('Hubo un error...');
                            console.log(err);
                        })
                    }
                }
                if(!directProduct){
                    axios.get(`/api/products?search=${search}`,configHeader)
                    .then(productsData => {
                        const products = productsData.data.rows;
                        if(products.length === 1){
                            const product = products[0];
                            product['amount'] = amount;
                            insertProduct(product);
                        } else {
                            setProductList(products);
                            setShowProductList(true);
                        }
                    })
                    .catch(err => {
                        alertMessage('Hubo un error...');
                        console.log(err);
                    })
                }
            } else {
                setHelpCode(true);
                setShowProductList(false);
            }
        }
        if(keycode == '27') {
            cancelCode();
        }
    }

    const insertProduct = (prod) => {
        let newItems = items;
        let repeatedItem = items.findIndex((element) => parseInt(element.idProduct) === parseInt(prod.idProduct));
        if(repeatedItem !== -1){
            newItems[repeatedItem].amount = (parseFloat(newItems[repeatedItem].amount) + parseFloat(prod.amount)).toFixed(3);
            newItems[repeatedItem].newStock = newItems[repeatedItem].newStock - parseFloat(prod.amount);
        } else {
            const newItem = {
                idProduct: prod.idProduct,
                code: prod.code,
                barcode: prod.barcode,
                name: prod.name,
                description: prod.description,
                img: prod.img,
                costPrice: prod.costPrice,
                salePrice: prod.salePrice,
                originalPrice: prod.salePrice,
                category: prod.id_Category,
                currencyUnit: prod.idCurrency_Unit,
                quantityUnit: prod.idQuantity_Unit,
                suppliers: prod.suppliers,
                amount: prod.amount,
                newStock: prod.stock.filter(sto => parseInt(sto.idStorage) === parseInt(saledata.storage))[0].quantity - parseFloat(prod.amount),
                stock: prod.stock
            }
            newItems.push(newItem);
        }
        setSubtotal(getSubtotal(newItems));
        setItems(newItems);
        cancelCode();
    }

    const addProduct = (e) => {
        const {index} = e.target.dataset;
        if(index){
            const product = productList[index];
            product['amount'] = amount;
            insertProduct(product);
        } else {
            alertMessage("No se pudo agregar el producto. Intente nuevamente");
        }
    }

    const cancelCode = () => {
        setCode('');
        setHelpCode(false);
        setShowProductList(false);
        setAmount(1);
        setProductList([]);
    }

    const focusCodeInput = (event) => {
        alert("YA IMPRIMIÓ")
    }

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    React.useImperativeHandle(ref, () => ({
        clean() {
            cancelCode();
        }
    }));

    React.useEffect(() => {
        window.addEventListener('afterprint', focusCodeInput);
    
        // cleanup this component
        /* return () => {
          window.removeEventListener('afterprint', focusCodeInput);
        }; */
      }, []);

    /* React.useEffect(() => {
        if(productList.length === 1){
            insertProduct(0);
        }
    }, [productList]) */

    return (
        <Root>
            <label>Cant:</label>
            <input id="amount" type="number" style={{width: "100px", textAlign: "right"}} value={amount} onChange={(e) => setAmount(e.target.value)} ></input>
            <label>Código:</label>
            <Code >
                <input id="code" type="text" autoFocus placeholder="Ingrese el código" value={code} onChange={onChangeCode} onKeyUp={onKeyUpCode}  ></input>
                <ProductList show={showProductList} >
                    {
                        productList.map((product, index) => 
                            <ItemProductList key={`itemproductlist_${index}`} data-index={index} onClick={addProduct}>
                                <span><strong>{product.code ? product.code : product.barcode}</strong></span><span> {`${product.name} (Stock: ${product.stock.filter(sto => parseInt(sto.idStorage) === parseInt(saledata.storage))[0].quantity})`}</span><span> <strong>${product.salePrice}</strong></span>
                            </ItemProductList>
                        )
                    }
                    {
                        productList && productList.length === 0 && <h4>Sin resultado</h4>
                    }
                </ProductList>
            </Code>
            
            {
                helpCode && <span>Debe ingresar al menos 3 caracteres...</span>
            }
        </Root>
    );
})

export default ProductInput;