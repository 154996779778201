/* General imports */
import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { authHeader } from '../app/auth-header';
import axios from 'axios';

const Root = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
`

const Content = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 50px);
    box-sizing: border-box;
    padding: 10px;
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
`

const Footer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
`

const StyledTextField = styled(TextField)`
    width: 250px;
    margin: 10px 20px;
`

const StyledFormControl = styled(FormControl)`
  width: 250px;
`

const CreditNoteAData = ({rowData, onBack, handleFinish}) => {
    const today = new Date()
    const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate()
    const month = today.getMonth() < 9 ? '0' + (today.getMonth()+1) : today.getMonth()+1

    const todayFormated = today.getFullYear()+'-'+month+'-'+day;

    const [ ivaConditions, setIvaConditions ] = React.useState(null);

    const [ paymentMethods, setPaymentMethods ] = React.useState(null);

    const [ conceptos, setConceptos ] = React.useState(null);

    const [ docTipos, setDocTipos ] = React.useState(null);

    const [ vouchers, setVouchers ] = React.useState(null);

    const [ dataStep, setDateStep ] = React.useState(rowData ? 
        {
            invoiceTypeCode: 31,
            afipCbteTipo: 3, // Nota de crédito A
            afipConcepto: rowData.afipConcepto,
            afipDocTipo: rowData.afipDocTipo,
            associateInvoice: null,
            afipCbtesAsoc: [],
            invoiceDate: todayFormated,
            invoicedPeriodFrom: todayFormated,
            invoicedPeriodTo: todayFormated,
            paymentDueDate: todayFormated,
            receiverCuit: rowData.receiverCuit,
            receiverBusinessName: rowData.receiverBusinessName,
            receiverCommercialAddress: rowData.receiverCommercialAddress,
            receiverIvaCondition: rowData.receiverIvaCondition,
            saleCondition: rowData.saleCondition
        }
        : {
        invoiceTypeCode: 31,
        afipCbteTipo: 3, // Nota de crédito A
        afipConcepto: '',
        afipDocTipo: '',
        associateInvoice: null,
        afipCbtesAsoc: [],
        invoiceDate: todayFormated,
        invoicedPeriodFrom: todayFormated,
        invoicedPeriodTo: todayFormated,
        paymentDueDate: todayFormated,
        receiverCuit: '',
        receiverBusinessName: '',
        receiverCommercialAddress: '',
        receiverIvaCondition: '',
        saleCondition: ''
    })

    const onChange = (e) => {
        const {name, value} = e.target;
        if(name === 'associateInvoice'){
            const assocInv = vouchers.filter(element => parseInt(element.value) === parseInt(value));
            if(assocInv.length > 0){
                setDateStep({
                    ...dataStep,
                    associateInvoice: value,
                    afipCbtesAsoc: [{
                        Tipo: 1,
                        PtoVta: parseInt(assocInv[0].data.pointSale),
                        Nro: parseInt(assocInv[0].data.voucherNumber)
                    }],
                    afipConcepto: parseInt(assocInv[0].data.afipConcepto),
                    afipDocTipo: parseInt(assocInv[0].data.afipDocTipo),
                    receiverCuit: assocInv[0].data.receiverCuit,
                    receiverBusinessName: assocInv[0].data.receiverBusinessName,
                    receiverCommercialAddress: assocInv[0].data.receiverCommercialAddress,
                    receiverIvaCondition: assocInv[0].data.receiverIvaCondition,
                    saleCondition: assocInv[0].data.saleCondition
                })
            }
        } else {
            setDateStep({
                ...dataStep,
                [name]: value
            })
        }
    }

    const onFinishStep = () => {
        if(dataStep.associateInvoice){
            handleFinish(dataStep);
        } else {
            alert("Debe seleccionar una factura A asociada")
        }
        
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!ivaConditions){
            axios.get(`/api/valuelist/group/ivaConditions`,configHeader)
            .then(ivaConditionsData => {
                const ivaConditionsList = [];
                ivaConditionsData.data.valueslist.forEach(option => {
                    ivaConditionsList.push({
                        name: option.value,
                        key: option.key,
                        value: option.idValueList
                    })
                });
                setIvaConditions(ivaConditionsList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ ivaConditions ])

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!paymentMethods){
            axios.get(`/api/valuelist/group/paymentConditions`,configHeader)
            .then(saleConditionsData => {
                const saleConditionsList = [];
                saleConditionsData.data.valueslist.forEach(option => {
                    saleConditionsList.push({
                        name: option.value,
                        key: option.key,
                        value: option.idValueList
                    })
                });
                setPaymentMethods(saleConditionsList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ paymentMethods ])

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!conceptos){
            axios.get(`/api/afip/concepttypes`,configHeader)
            .then(conceptosData => {
                const conceptosList = [];
                conceptosData.data.forEach(option => {
                    conceptosList.push({
                        name: option.Desc,
                        value: option.Id
                    })
                });
                setConceptos(conceptosList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ conceptos ])

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!docTipos){
            axios.get(`/api/afip/documenttypes`,configHeader)
            .then(docTiposData => {
                const docTiposList = [];
                docTiposData.data.forEach(option => {
                    docTiposList.push({
                        name: option.Desc,
                        value: option.Id
                    })
                });
                setDocTipos(docTiposList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ docTipos ])

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!vouchers){
            axios.get(`/api/invoices`,configHeader)
            .then(vouchersData => {
                const vouchersList = [];
                const filteredVouchersData = vouchersData.data.invoices.filter(element => parseInt(element.invoiceTypeCode) === 29);
                filteredVouchersData.forEach(option => {
                    vouchersList.push({
                        name: `${option.receiverBusinessName} $${option.total}`,
                        value: option.idInvoice,
                        data: option
                    })
                });
                setVouchers(vouchersList);
                if(rowData && rowData.idInvoice){
                    const assocInv = vouchersList.filter(element => parseInt(element.value) === parseInt(rowData.idInvoice));
                    if(assocInv.length > 0){
                        setDateStep({
                            ...dataStep,
                            associateInvoice: rowData.idInvoice,
                            afipCbtesAsoc: [{
                                Tipo: 1,
                                PtoVta: parseInt(assocInv[0].data.pointSale),
                                Nro: parseInt(assocInv[0].data.voucherNumber)
                            }],
                            afipConcepto: parseInt(assocInv[0].data.afipConcepto),
                            afipDocTipo: parseInt(assocInv[0].data.afipDocTipo),
                            receiverCuit: assocInv[0].data.receiverCuit,
                            receiverBusinessName: assocInv[0].data.receiverBusinessName,
                            receiverCommercialAddress: assocInv[0].data.receiverCommercialAddress,
                            receiverIvaCondition: assocInv[0].data.receiverIvaCondition,
                            saleCondition: assocInv[0].data.saleCondition
                        })
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ vouchers ])

    return (
        <Root>
            <Content>
                <StyledFormControl required>
                    <InputLabel id="concepto-label"> Concepto </InputLabel>
                    <Select
                        native
                        labelId="concepto-label"
                        
                        onChange={onChange}
                        required
                        inputProps={{
                        id:"afipConcepto",
                        name:"afipConcepto",
                        value:dataStep.afipConcepto
                        }}
                    >
                        <option value="" />
                        {
                            conceptos && conceptos.map((concept, index) =>
                                <option key={`concept_option_${index}`} value={concept.value}> {concept.name} </option>
                            )
                        }
                    </Select>
                </StyledFormControl>
                <StyledTextField
                    id="invoiceDate"
                    name="invoiceDate"
                    label="Fecha de emision"
                    type="date"
                    value={dataStep.invoiceDate}
                    onChange={onChange}
                    required
                />
                <StyledTextField
                    id="invoicedPeriodFrom"
                    name="invoicedPeriodFrom"
                    label="Fecha desde"
                    type="date"
                    value={dataStep.invoicedPeriodFrom}
                    onChange={onChange}
                    disabled={parseInt(dataStep.afipConcepto) === 1}
                />
                <StyledTextField
                    id="invoicedPeriodTo"
                    name="invoicedPeriodTo"
                    label="Fecha hasta"
                    type="date"
                    value={dataStep.invoicedPeriodTo}
                    onChange={onChange}
                    disabled={parseInt(dataStep.afipConcepto) === 1}
                />
                <StyledTextField
                    id="paymentDueDate"
                    name="paymentDueDate"
                    label="Vto. de pago"
                    type="date"
                    value={dataStep.paymentDueDate}
                    onChange={onChange}
                    required
                />
                <StyledFormControl required>
                    <InputLabel id="docTipo-label"> Tipo de documento </InputLabel>
                    <Select
                        native
                        labelId="docTipo-label"
                        
                        onChange={onChange}
                        required
                        inputProps={{
                        id:"afipDocTipo",
                        name:"afipDocTipo",
                        value:dataStep.afipDocTipo
                        }}
                    >
                        <option value="" />
                        {
                            docTipos && docTipos.map((doc, index) =>
                                <option key={`docTipo_option_${index}`} value={doc.value}> {doc.name} </option>
                            )
                        }
                    </Select>
                </StyledFormControl>
                <StyledTextField
                    id="receiverCuit"
                    name="receiverCuit"
                    label="Nro. de documento"
                    type="number"
                    value={dataStep.receiverCuit}
                    onChange={onChange}
                    required
                    helperText="Poner 0 si el tipo de documento es (Otro)"
                />
                <StyledTextField
                    id="receiverBusinessName"
                    name="receiverBusinessName"
                    label="Razón social"
                    type="text"
                    value={dataStep.receiverBusinessName}
                    onChange={onChange}
                    required
                />
                <StyledTextField
                    id="receiverCommercialAddress"
                    name="receiverCommercialAddress"
                    label="Domicilio comercial"
                    type="text"
                    value={dataStep.receiverCommercialAddress}
                    onChange={onChange}
                    required
                />
                <StyledFormControl required>
                    <InputLabel id="receiverIvaCondition-label"> Cond. de IVA </InputLabel>
                    <Select
                        native
                        labelId="receiverIvaCondition-label"
                        
                        onChange={onChange}
                        required
                        inputProps={{
                        id:"receiverIvaCondition",
                        name:"receiverIvaCondition",
                        value:dataStep.receiverIvaCondition
                        }}
                    >
                        <option value="" />
                        {
                            ivaConditions && ivaConditions.map((ic, index) =>
                                <option key={`iva_option_${index}`} value={ic.value}> {`${ic.key} (${ic.name})`} </option>
                            )
                        }
                    </Select>
                </StyledFormControl>
                <StyledFormControl required>
                    <InputLabel id="saleCondition-label"> Cond. de venta </InputLabel>
                    <Select
                        native
                        labelId="saleCondition-label"
                        
                        onChange={onChange}
                        required
                        inputProps={{
                        id:"saleCondition",
                        name:"saleCondition",
                        value:dataStep.saleCondition
                        }}
                    >
                        <option value="" />
                        {
                            paymentMethods && paymentMethods.map((pm, index) =>
                                <option key={`iva_option_${index}`} value={pm.value}> {`${pm.key} (${pm.name})`} </option>
                            )
                        }
                    </Select>
                </StyledFormControl>
                <StyledFormControl required>
                    <InputLabel id="associateInvoice-label"> Factura asociada </InputLabel>
                    <Select
                        native
                        labelId="associateInvoice-label"
                        
                        onChange={onChange}
                        required
                        inputProps={{
                        id:"associateInvoice",
                        name:"associateInvoice",
                        value:dataStep.associateInvoice
                        }}
                    >
                        <option value="" />
                        {
                            vouchers && vouchers.map((ai, index) =>
                                <option key={`associateInvoice_option_${index}`} value={ai.value}> {ai.name} </option>
                            )
                        }
                    </Select>
                </StyledFormControl>
            </Content>
            <Footer>
                {
                    onBack && <Button onClick={onBack}>ATRÁS</Button>
                }
                <Button onClick={onFinishStep}>SIGUIENTE</Button>
            </Footer>
        </Root>
    );
}

export default CreditNoteAData;