/* General imports */
import React from 'react'
import styled from 'styled-components'
import InputChange from './InputChange';
import { connect } from 'react-redux'
import { alertActions } from '../alert/AlertActions'

const Table = styled.table`
    width: 100%;
    border-spacing: 0;
    thead {
        background: #02004c;
        color: #FFF;
    }
    th, td {
        border: 1px solid grey;
        height: 30px;
        text-align: center;
        
    }
    tfoot {
        background: aliceblue;
    }
`



/* ----- Principal function ----- */
function ItemsTable({items, changePrice, deleteItem}) {

    const onDelete = (e) => {
        deleteItem(e.target.dataset.index);
    }

    React.useEffect(() => {

    }, [items])

    return(
        <Table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Código</th>
                    <th>Descripción</th>
                    <th>Cantidad</th>
                    <th>Unitario($)</th>
                    <th>Importe($)</th>
                </tr>
            </thead>
            <tbody>
                {
                   items && items.map((item, index) => 
                    <tr key={`item_${index}`}>
                        <td>{index}</td>
                        <td>{item.code ? item.code : item.barcode}</td>
                        <td>{item.name}</td>
                        <td>{item.amount}</td>
                        <td><InputChange changePrice={changePrice} index={index} inputvalue={item.costPrice} /></td>
                        <td>{Math.round((item.amount * item.costPrice)*100)/100}</td>
                        <td data-index={index} style={{cursor: "pointer", color: "red"}} onClick={onDelete}>X</td>
                    </tr>
                    )
                }
                
            </tbody>
            <tfoot>
            </tfoot>
        </Table>
    );
}

function mapState(state) {
    const { items} = state.pointpurchase;
    const { user } = state.login;
    return { user, items};
}

const actionCreators = {
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ItemsTable);