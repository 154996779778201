import { LOCALES } from '../locales';

export default {
	[LOCALES.ENGLISH]: {
		'hello': 'Hi, {name}!',
		'page': 'Page',
		'of': 'of',
		'show': 'Show',
		'records': 'records',
		'total': 'Total',
		'actions': 'Actions',
		'search': 'Search',
		'selected': 'Selected',
	}
}
