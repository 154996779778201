/* General imports */
import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { authHeader } from '../../app/auth-header';

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '../../form/TextField';

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 400px;
    min-height: 300px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;
`

const Button = styled.button`

`

/* ----- Principal function ----- */
function ModalFilter({ title, openModal, closeModal, datafilter, handleChangeFilter }) {
    

    const handleChange = (name, value) => {
        handleChangeFilter(name, value);
    }

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    {
                        true &&
                        <ModalBody>
                            <TextField id="startDate" value={datafilter.startDate} required={true} name="startDate" placeholder="Fecha Inicio" type="date" fontSize="20px" width='80%' onChange={handleChange} />
                            <TextField id="endDate" value={datafilter.endDate} required={true} name="endDate" placeholder="Fecha Fin" type="date" fontSize="20px" width='80%' margin='20px 0' onChange={handleChange} />    
                            <Button onClick={closeModal} >Aplicar</Button>
                        </ModalBody>
                    }
                    
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalFilter;