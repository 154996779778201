/* import FileDownloadIcon from '@material-ui/icons/GetApp'; */
import React from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../providers/theme';

const Root = styled.button`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    background: none;
    border: none;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    &:hover {
        box-shadow: 0 0 5px rgba(0,0,0,0.8);
        svg {
            transform: scale(1.1);
        }
    }
`

const NewButton = ({onClick}) => {

    const theme = React.useContext(ThemeContext);

    return (
        <Root onClick={onClick}>
            {theme.icons.add}
        </Root>
    );
}

export default NewButton;