/* General imports */
import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { authHeader } from '../app/auth-header';
import axios from 'axios';

const Root = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
`

const Content = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 50px);
    box-sizing: border-box;
    padding: 10px;
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
`

const Footer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
`

const StyledTextField = styled(TextField)`
    width: 250px;
    margin: 10px 20px;
`

const StyledFormControl = styled(FormControl)`
  width: 250px;
`

const ReceiptXData = ({rowData, onBack, handleFinish}) => {
    const today = new Date()
    const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate()
    const month = today.getMonth() < 9 ? '0' + (today.getMonth()+1) : today.getMonth()+1

    const todayFormated = today.getFullYear()+'-'+month+'-'+day;

    const [ ivaConditions, setIvaConditions ] = React.useState(null);

    const [ paymentMethods, setPaymentMethods ] = React.useState(null);

    const [ accounts, setAccounts ] = React.useState(null);

    const [ dataStep, setDateStep ] = React.useState(rowData ? 
        {
            invoiceTypeCode: 37,
            afipCbteTipo: null, // 1 Factura A, 2 Factura B, 11 Factura C, 4 Recibos A, 9 Recibos B, 15 Recibos C
            afipConcepto: null,
            afipDocTipo: rowData.afipDocTipo,
            invoiceDate: todayFormated,
            invoicedPeriodFrom: todayFormated,
            invoicedPeriodTo: todayFormated,
            paymentDueDate: todayFormated,
            receiverCuit: rowData.receiverCuit,
            receiverBusinessName: rowData.receiverBusinessName,
            receiverCommercialAddress: rowData.receiverCommercialAddress,
            receiverIvaCondition: rowData.receiverIvaCondition,
            saleCondition: '',
            idAccount: ''
        }
        : 
        {
            invoiceTypeCode: 37,
            afipCbteTipo: null, // 1 Factura A, 2 Factura B, 11 Factura C, 4 Recibos A, 9 Recibos B, 15 Recibos C
            afipConcepto: null,
            afipDocTipo: '',
            invoiceDate: todayFormated,
            invoicedPeriodFrom: todayFormated,
            invoicedPeriodTo: todayFormated,
            paymentDueDate: todayFormated,
            receiverCuit: '',
            receiverBusinessName: '',
            receiverCommercialAddress: '',
            receiverIvaCondition: '',
            saleCondition: '',
            idAccount: ''
        })

    const onChange = (e) => {
        const {name, value} = e.target;
        setDateStep({
            ...dataStep,
            [name]: value
        })
    }

    const onFinishStep = () => {
        handleFinish(dataStep);
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!ivaConditions){
            axios.get(`/api/valuelist/group/ivaConditions`,configHeader)
            .then(ivaConditionsData => {
                const ivaConditionsList = [];
                ivaConditionsData.data.valueslist.forEach(option => {
                    ivaConditionsList.push({
                        name: option.value,
                        key: option.key,
                        value: option.idValueList
                    })
                });
                setIvaConditions(ivaConditionsList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ ivaConditions ])

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!paymentMethods){
            axios.get(`/api/valuelist/group/paymentConditions`,configHeader)
            .then(saleConditionsData => {
                const saleConditionsList = [];
                saleConditionsData.data.valueslist.forEach(option => {
                    saleConditionsList.push({
                        name: option.value,
                        key: option.key,
                        value: option.idValueList
                    })
                });
                setPaymentMethods(saleConditionsList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ paymentMethods ])

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!accounts){
            if(rowData && rowData.idUser){
                axios.get(`/api/accounts/user/${rowData.idUser}`,configHeader)
                .then(accountsData => {
                    const accountList = [];
                    accountsData.data.rows.forEach(option => {
                        accountList.push({
                            name: option.value,
                            key: option.key,
                            value: option.idValueList
                        })
                    });
                    setAccounts(accountList);
                })
                .catch(err => {
                    console.log(err);
                })
            } else {
                axios.get(`/api/accounts`,configHeader)
                .then(accountsData => {
                    const accountList = [];
                    accountsData.data.rows.forEach(option => {
                        accountList.push({
                            name: `${option.id_user.firstname} ${option.id_user.lastname} (${option.balance})`,
                            key: option.idUser,
                            value: option.idAccount
                        })
                    });
                    setAccounts(accountList);
                })
                .catch(err => {
                    console.log(err);
                })
            }
            
        }
    }, [ accounts ])

    return (
        <Root>
            <Content>
                <StyledTextField
                    id="invoiceDate"
                    name="invoiceDate"
                    label="Fecha de emision"
                    type="date"
                    value={dataStep.invoiceDate}
                    onChange={onChange}
                    required
                />
                <StyledTextField
                    id="invoicedPeriodFrom"
                    name="invoicedPeriodFrom"
                    label="Fecha desde"
                    type="date"
                    value={dataStep.invoicedPeriodFrom}
                    onChange={onChange}
                    disabled={parseInt(dataStep.afipConcepto) === 1}
                />
                <StyledTextField
                    id="invoicedPeriodTo"
                    name="invoicedPeriodTo"
                    label="Fecha hasta"
                    type="date"
                    value={dataStep.invoicedPeriodTo}
                    onChange={onChange}
                    disabled={parseInt(dataStep.afipConcepto) === 1}
                />
                <StyledTextField
                    id="paymentDueDate"
                    name="paymentDueDate"
                    label="Vto. de pago"
                    type="date"
                    value={dataStep.paymentDueDate}
                    onChange={onChange}
                    required
                />
                <StyledTextField
                    id="receiverCuit"
                    name="receiverCuit"
                    label="Nro. de documento"
                    type="number"
                    value={dataStep.receiverCuit}
                    onChange={onChange}
                    required
                />
                <StyledTextField
                    id="receiverBusinessName"
                    name="receiverBusinessName"
                    label="Razón social"
                    type="text"
                    value={dataStep.receiverBusinessName}
                    onChange={onChange}
                    required
                />
                <StyledTextField
                    id="receiverCommercialAddress"
                    name="receiverCommercialAddress"
                    label="Domicilio comercial"
                    type="text"
                    value={dataStep.receiverCommercialAddress}
                    onChange={onChange}
                    required
                />
                <StyledFormControl required>
                    <InputLabel id="receiverIvaCondition-label"> Cond. de IVA </InputLabel>
                    <Select
                        native
                        labelId="receiverIvaCondition-label"
                        
                        onChange={onChange}
                        required
                        inputProps={{
                        id:"receiverIvaCondition",
                        name:"receiverIvaCondition",
                        value:dataStep.receiverIvaCondition
                        }}
                    >
                        <option value="" />
                        {
                            ivaConditions && ivaConditions.map((ic, index) =>
                                <option key={`iva_option_${index}`} value={ic.value}> {`${ic.key} (${ic.name})`} </option>
                            )
                        }
                    </Select>
                </StyledFormControl>
                <StyledFormControl required>
                    <InputLabel id="saleCondition-label"> Cond. de venta </InputLabel>
                    <Select
                        native
                        labelId="saleCondition-label"
                        
                        onChange={onChange}
                        required
                        inputProps={{
                        id:"saleCondition",
                        name:"saleCondition",
                        value:dataStep.saleCondition
                        }}
                    >
                        <option value="" />
                        {
                            paymentMethods && paymentMethods.map((pm, index) =>
                                <option key={`iva_option_${index}`} value={pm.value}> {`${pm.key} (${pm.name})`} </option>
                            )
                        }
                    </Select>
                </StyledFormControl>
                <StyledFormControl required>
                    <InputLabel id="idAccount-label"> Cuenta corriente </InputLabel>
                    <Select
                        native
                        labelId="idAccount-label"
                        
                        onChange={onChange}
                        required
                        inputProps={{
                        id:"idAccount",
                        name:"idAccount",
                        value:dataStep.idAccount
                        }}
                    >
                        <option value="" />
                        {
                            accounts && accounts.map((ac, index) =>
                                <option key={`account_option_${index}`} value={ac.value}> {ac.name} </option>
                            )
                        }
                    </Select>
                </StyledFormControl>
            </Content>
            <Footer>
                {
                    onBack && <Button onClick={onBack}>ATRÁS</Button>
                }
                <Button onClick={onFinishStep}>SIGUIENTE</Button>
            </Footer>
        </Root>
    );
}

export default ReceiptXData;