/* General imports */
import React from 'react';
import styled from 'styled-components';
import { productActions } from './ProductActions';
import { alertActions } from '../../alert/AlertActions';
import { connect } from 'react-redux';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';
import BasicEditor from '../../textEditors/basicEditor';

/* Components imports */
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import TagSelect from '../../form/TagSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import ImagesInput from '../../form/ImagesInput';
import SupplierBox from './SupplierBox';
import Autocomplete from '@material-ui/lab/Autocomplete';


/* Styled components */
const Root = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 250px 650px 250px;
    grid-template-rows: 50px 250px 200px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    gap: 10px 20px;
`

const GridBox = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'column'};
    grid-column: ${(props) => props.gridColumn ? props.gridColumn : 'inherit'};
    grid-row: ${(props) => props.gridRow ? props.gridRow : 'inherit'};
    align-items: ${(props) => props.alignItems ? props.alignItems : 'center'};
    justify-content: ${(props) => props.justifyContent ? props.justifyContent : 'center'};
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
    /* border: 1px solid grey; */
`

const StyledAddShoppingCartIcon = styled(AddShoppingCartIcon)`
    color: blue;
`

const StyledTextField = styled(TextField)`
    width: ${(props) => props.width ? props.width : 'inherit'};
    font-size: 0.5rem;
`

const IconAction = styled.div`
    position: absolute;
    display: flex;
    width: 25px;
    height: 25px;
    top: 5px;
    right: ${(props) => props.right ? props.right : '10px'};
    align-items: center;
    justify-content: center;
    
    svg {
        font-size: 25px;
        cursor: pointer;
        z-index: 999;
    }
`

/* ----- Principal function ----- */
function NewProduct({user, rowData, error, getProducts, createProduct, updateProduct, currencyUnits, quantityUnits, trademarks, getTrademarks, getCurrencyUnits, getQuantityUnits, alertMessage, history }) {

    const getRowDataTags = (tags) => {
        let responseTags = []
        tags.forEach(tg => {
            responseTags.push({
                label: tg.name,
                value: tg.idTag
            })
        });
        return responseTags
    } 

    const [ images, setImages ] = React.useState(rowData ? rowData.img ? rowData.img.images : [] : []);

    const [editorLoaded, setEditorLoaded] = React.useState(false);

    const [ dataForm, setDataForm ] = React.useState(rowData ? 
        {
            code: rowData.code,
            barcode: rowData.barcode,
            name: rowData.name,
            alternativeName: rowData.alternativeName,
            idQuantityUnit: rowData.idQuantityUnit,
            idCurrencyUnit: rowData.idCurrencyUnit,
            description: rowData.description,
            salePrice: rowData.salePrice,
            costPrice: rowData.costPrice,
            idCategory: rowData.idCategory,
            features: rowData.features,
            suppliers: rowData.suppliers,
            promo: rowData.promo,
            discount: rowData.discount,
            ecommerce: rowData.ecommerce,
            idTrademark: rowData.idTrademark,
            initialStock: 0,
            tags: getRowDataTags(rowData.tags)
        }
        : 
        { 
            code: '',
            barcode: '',
            name: '',
            alternativeName: '',
            idQuantityUnit: '',
            idCurrencyUnit: '',
            description: '',
            salePrice: 1,
            costPrice: 1,
            idCategory: '',
            features: [],
            suppliers: [],
            promo: '',
            discount: 0,
            ecommerce: true,
            idTrademark: '',
            initialStock: 0,
            tags: []
        }
    )

    const [ categories, setCategories ] = React.useState(null);

    const [ trademark, setTrademark ] = React.useState(rowData && trademarks ? trademarks.find(t => t.value === rowData.idTrademark) : null);

    const [ category, setCategory ] = React.useState(null);

    const [inputTrademark, setInputTrademark ] = React.useState('');

    const [inputCategory, setInputCategory ] = React.useState('');

    const handleChangeTag = (newValue, actionMeta) => {
        setDataForm({
            ...dataForm,
            tags: newValue
        });
    };

    const handleCheckEcommerce = (event) => {
        setDataForm({
            ...dataForm,
            ecommerce: event.target.checked
        });
      };

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setDataForm({
            ...dataForm,
            [name]: value
        });
    }

    const handleChangeTrademark = (value) => {
        if(value){
            setDataForm({
                ...dataForm,
                idTrademark: value.value
            });
            setTrademark(value);
        } else {
            setDataForm({
                ...dataForm,
                idTrademark: null
            });
            setTrademark(value);
        }
    }

    const handleChangeCategory = (value) => {
        if(value){
            setDataForm({
                ...dataForm,
                idCategory: value.value
            });
            setCategory(value);
        } else {
            setDataForm({
                ...dataForm,
                idCategory: null
            });
            setCategory(value);
        }
    }

    const handleConfirmAddSupplier = async(data) => {
        let newData = dataForm.suppliers;
        newData.push(data)
        setDataForm({
            ...dataForm,
            suppliers: newData
        })
    }

    const removeSupplier = (e) => {
        const { idsupplier, name } = e.target.dataset;
        // eslint-disable-next-line no-restricted-globals
        if(confirm('¿Desea sacar de la lista al proveedor '+name+'?')){
            const newData = dataForm.suppliers.filter(sup => parseInt(sup.idSupplier) !== parseInt(idsupplier));
            setDataForm({
                ...dataForm,
                suppliers: newData
            })
        }
    }

    const handleEditor = (e, dataeditor) => {
        setDataForm({
            ...dataForm,
            description: dataeditor
        })
    }

    const handleEditorPromo = (e, dataeditor) => {
        setDataForm({
            ...dataForm,
            promo: dataeditor
        })
    }

    const requiredFields = ['name', 'idQuantityUnit', 'idCurrencyUnit', 'salePrice', 'costPrice', 'idCategory', 'suppliers']

    const checkForm = async () => {
        let flag = true
        let message = 'Faltan completar campos necesarios'
        requiredFields.forEach(element => {
            if(dataForm[element] === null || dataForm[element] === '' || dataForm[element] === undefined || dataForm[element].length === 0){
                flag = false;
            }
        });
        return { flag, message }
    }

    const saveProduct = async () => {
        const {flag, message} = await checkForm();
        if(flag){
            let savePromise = new Promise((resolve, reject) => {
                if(rowData){
                    updateProduct(rowData.idProduct, {
                        ...dataForm,
                        img: {
                            images: images
                        },
                        updatedUser: user.idUser
                    });
                } else {
                    createProduct({
                        ...dataForm,
                        img: {
                            images: images
                        },
                        createdUser: user.idUser
                    });
                }
                resolve("Producto guardado");    
            });
            savePromise.then(()=> {
                getProducts();
                history.push('/admin/stocksystem/product');    
            });
        } else {
            alertMessage(message);
        } 
    } 

    const cancelProduct = () => {
        history.push('/admin/stocksystem/product')
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!categories){
            axios.get('/api/categoryListOptions',configHeader)
            .then(categoriesData => {
                setCategories(categoriesData.data.categoriesList);
                if(rowData){
                    setCategory(categoriesData.data.categoriesList.find(c => c.value === rowData.idCategory))
                }
            })
            .catch(err => {
                alertMessage('Hubo un error al cargar las categorías')
            })
        }

        if(!currencyUnits){
            getCurrencyUnits()
        }
        if(!quantityUnits){
            getQuantityUnits()
        }
        if(!trademarks){
            getTrademarks()
        }
        
    }, [])

    React.useEffect(() => {
        setEditorLoaded(true);
      }, []);

    return(
        <Root>
            <IconAction right='40px'><SaveIcon onClick={saveProduct} /></IconAction>
            <IconAction ><CancelIcon onClick={cancelProduct} /></IconAction>
            <GridBox gridColumn="1/2" gridRow="1/4" justifyContent="flex-start">
                <TextField label="CÓDIGO" id="code" name="code" value={dataForm.code} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" />
                <TextField label="CÓDIGO DE BARRAS" id="barcode" name="barcode" value={dataForm.barcode} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" />
                <TextField label="NOMBRE DEL PRODUCTO" id="name" name="name" value={dataForm.name} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" required />
                <TextField label="NOMBRE ALTERNATIVO" id="alternativeName" name="alternativeName" value={dataForm.alternativeName} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense"/>
                <TextField label="UNIDAD DE MEDIDA" select id="idQuantityUnit" name="idQuantityUnit" value={dataForm.idQuantityUnit} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" required>
                    {
                        quantityUnits &&
                        quantityUnits.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.key ? `${option.key}(${option.name})` : option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <TextField label="MONEDA" select id="idCurrencyUnit" name="idCurrencyUnit" value={dataForm.idCurrencyUnit} onChange={handleChange} size="small" variant="outlined" fullWidth margin="dense" required>
                    {
                        currencyUnits &&
                        currencyUnits.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.key ? `${option.key}(${option.name})` : option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
                {
                    categories &&
                    <Autocomplete
                        value={category}
                        onChange={(event, newValue) => {
                            handleChangeCategory(newValue);
                        }}
                        inputValue={inputCategory}
                        onInputChange={(event, newInputValue) => {
                            setInputCategory(newInputValue);
                        }}
                        fullWidth
                        size="small"
                        id="idCategory"
                        options={categories}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="CATEGORÍA" variant="outlined" required margin="dense" />}
                    />
                }
                {
                    trademarks &&
                    <Autocomplete
                        value={trademark}
                        onChange={(event, newValue) => {
                            handleChangeTrademark(newValue);
                        }}
                        inputValue={inputTrademark}
                        onInputChange={(event, newInputValue) => {
                            setInputTrademark(newInputValue);
                        }}
                        fullWidth
                        size="small"
                        id="idTrademark"
                        options={trademarks}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="MARCA" variant="outlined" required margin="dense" />}
                    />
                }
                <TagSelect
                    isMulti
                    cacheOptions
                    handleChange={handleChangeTag}
                    value={dataForm.tags}
                    url="/api/tagslistoptions"
                    limit={20}
                    margin="8px 0 4px 0"
                    placeholder="ETIQUETAS..."
                />
                <FormControlLabel 
                    control={
                        <Checkbox 
                            checked={dataForm.ecommerce}
                            onChange={handleCheckEcommerce}
                            inputProps={{ 'aria-label': 'controlled' }} 
                            icon={<AddShoppingCartOutlinedIcon />} 
                            checkedIcon={<StyledAddShoppingCartIcon />} 
                        />} 
                    label="Ecommerce" 
                />
            </GridBox>
            <GridBox gridColumn="2/3" gridRow="1/2" flexDirection="row" justifyContent="space-between">
                <StyledTextField disabled={!currencyUnits && !quantityUnits && !trademarks && !categories} label="PRECIO COSTO" id="costPrice" name="costPrice" value={dataForm.costPrice} onChange={handleChange} size="small" variant="outlined" width={rowData ? '22.5%' : '18%'} margin="dense" />
                <StyledTextField disabled={!currencyUnits && !quantityUnits && !trademarks && !categories} label="PRECIO VENTA" id="salePrice" name="salePrice" value={dataForm.salePrice} onChange={handleChange} size="small" variant="outlined" width={rowData ? '22.5%' : '18%'} margin="dense" />
                <StyledTextField label="DESCUENTO" id="discount" name="discount" value={dataForm.discount} onChange={handleChange} size="small" variant="outlined" width={rowData ? '22.5%' : '18%'} margin="dense" />
                {
                    !rowData && <StyledTextField label="STOCK INICIAL" id="initialStock" name="initialStock" value={dataForm.initialStock} onChange={handleChange} size="small" variant="outlined" width="18%" margin="dense" />
                }
            </GridBox>
            <GridBox gridColumn="3/4" gridRow="1/4" justifyContent="space-between" padding="10px 0">
                <ImagesInput images={images} handleImages={setImages} name='images' />
                <SupplierBox suppliers={dataForm.suppliers} removeSupplier={removeSupplier} handleConfirmAddSupplier={handleConfirmAddSupplier} alertMessage={alertMessage} />
            </GridBox>
            <GridBox gridColumn="2/3" gridRow="2/3">
                <BasicEditor
                    name="text"
                    value={dataForm.description}
                    onChange={handleEditor}
                    editorLoaded={editorLoaded}
                    maxContentHeight="205px"
                    minContentHeight="205px"
                    placeholder="Ingrese aquí la descripción del producto..."
                />
            </GridBox>
            <GridBox gridColumn="2/3" gridRow="3/4">
                <BasicEditor
                    name="text"
                    value={dataForm.promo}
                    onChange={handleEditorPromo}
                    editorLoaded={editorLoaded}
                    maxContentHeight="155px"
                    minContentHeight="155px"
                    placeholder="Ingrese aquí la promoción del producto..."
                />
            </GridBox>
        </Root>
    );
}

function mapState(state) {
    const { currencyUnits, quantityUnits, trademarks } = state.product;
    const { user } = state.login;
    return { user, currencyUnits, quantityUnits, trademarks };
}

const actionCreators = {
    getProducts: productActions.getProducts,
    createProduct: productActions.createProduct,
    updateProduct: productActions.updateProduct,
    getCurrencyUnits: productActions.getCurrencyUnits,
    getQuantityUnits: productActions.getQuantityUnits,
    getTrademarks: productActions.getTrademarks,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(NewProduct);