/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { config } from '../../app/config'
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner';
import { TextField } from '@material-ui/core';
import NoImage from '../../images/no-image.png';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const HeightHeader = '70px'

/* Styled components */
const Root = styled.form`
    position: relative;
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - ${HeightHeader});
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: ${HeightHeader};
    padding: 0 25px;
    box-sizing: border-box;
    background: #234;
`

const HeaderTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
`

const HeaderButtons = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
`

const ButtonHeader = styled.button`
    margin: 0 5px;
`

const Box = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
    padding: 50px;
`
const CancelImg = styled.div`
    position: absolute;
    display: none;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    background-color: #ff000080;
    align-items: center;
    justify-content: center;
    opacity: 0;
    user-select: none;
    cursor: pointer;
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 60%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${(props) => props.active ?
    `&:hover {
        ${CancelImg} {
            display: flex;
            opacity: 1;
        }
        img {
            opacity: 0.4;
        }
    }`
    :
    ''
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

/* ----- Principal function ----- */
function NoveltySettings({match, history, user}) {

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const { idPage, idSection } = match.params;

    const [data, setData] = React.useState(null)

    const [ image, setImage ] = React.useState('')

    const onBack = () => {
        history.push(config.webeditorpath+'/page/'+idPage)
    }

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        e.preventDefault()
        const { name } = e.currentTarget.dataset;
        if(image !== '' ){
            if(!data[name]){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, configHeader)
                .then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setData({
                        ...data,
                        [name]: url_img
                    })
                })
                .catch(error => {
                    alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, configHeader)
                    .then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setData({
                            ...data,
                            [name]: url_img
                        })   
                    })
                    .catch(error => {
                        alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alert("Debe selecionar una imagen...")
        }
    }


    const onSave = () => {
        const saveData = {
            data: data,
            updatedUser: user.idUser
        }
        axios.put("/api/updatedatasection/"+idSection, saveData, configHeader)
        .then(res => {
            history.push(config.webeditorpath+'/page/'+idPage)
        })
        .catch(error => {
            alert('Hubo un error...')
        })
    }

    const cancelImage = (e) => {
        e.preventDefault()
        const { name } = e.currentTarget.dataset;
        setData({
            ...data,
            [name]:''
        })
    }

    React.useEffect(() => {
        if(!data){
            axios.get('/api/sections/' + idSection,configHeader)
            .then(sectionData => {
                if(parseInt(Object.keys(sectionData.data.section.data).length) > 0){
                    setData(sectionData.data.section.data);
                } else {
                    setData({
                        title: '',
                        text:'',
                        button1: '',
                        link1: '',
                        button2: '',
                        link2: '',
                        img: ''
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idSection])

    return(
        <React.Fragment>
            <Header>
                {
                    <HeaderTitle>CONFIGURACIÓN NOVELTY</HeaderTitle>
                }
                <HeaderButtons><ButtonHeader onClick={onBack}>VOLVER</ButtonHeader> <ButtonHeader onClick={onSave}>GUARDAR</ButtonHeader> </HeaderButtons>
            </Header>
            {
                !data ? <Spinner spinnerType='ThreeDots' />
                :
                <Root>
                    <Box>
                            <ImageContainer active={data.img ? true : false}>
                                <img src={data.img ? data.img : NoImage} alt="Imagen subida" />
                                {
                                    data.img && <CancelImg data-name='img' onClick={cancelImage}> X </CancelImg>
                                }
                            </ImageContainer>
                            <UploadImage>
                                <input style={{fontSize: '0.75rem', width: '100%'}} id="img" name="img" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img' onClick={handleFileUpload} />
                            </UploadImage>
                        </Box>
                    <Box>
                        <TextField fullWidth margin="normal" type='text' id="title" name='title' value={data.title} label="Título" variant="outlined" onChange={handleChange} required />
                        <TextField fullWidth margin="normal" type='text' id="text" name='text' value={data.text} label="Texto" variant="outlined" onChange={handleChange} />
                        <TextField fullWidth margin="normal" type='text' id="button1" name='button1' value={data.button1} label="Botón 1" variant="outlined" onChange={handleChange} />
                        <TextField fullWidth margin="normal" type='text' id="link1" name='link1' value={data.link1} label="Enlace 1" variant="outlined" onChange={handleChange} />
                        <TextField fullWidth margin="normal" type='text' id="button2" name='button2' value={data.button2} label="Botón 2" variant="outlined" onChange={handleChange} />
                        <TextField fullWidth margin="normal" type='text' id="link2" name='link2' value={data.link2} label="Enlace 2" variant="outlined" onChange={handleChange} />
                    </Box>
                </Root>
            }
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

export default connect(mapState)(NoveltySettings);