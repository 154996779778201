/* General imports */
import React from 'react'
import { useRef } from 'react'
import styled from 'styled-components'

/* Styled components */
const InputPrice = styled.input`
    text-align: center;
    border: none;
`

const InputChange = ({inputvalue, index, changeAmount}) => {

    const inputRef = useRef();

    const [value, setValue] = React.useState(null);

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const onKeyUpCode = (e) => {
        const keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13') {
            changeAmount(e.target.dataset.index, e.target.value);
            inputRef.current.blur();
        }
        if(keycode == '27') {
            setValue(inputvalue);
        }
    }

    const onBlur = (e) => {
        e.preventDefault();
        /* setValue(props.value); */
    }

    React.useEffect(() => {
        if(value !== inputvalue){
            setValue(inputvalue);
        }
    }, [ inputvalue ])

    return (
        value !== null && <InputPrice ref={inputRef} data-index={index} onBlur={onBlur} onChange={onChange} onKeyUp={onKeyUpCode} type="number" value={value} />
    );
}

export default InputChange;