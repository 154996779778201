import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import KeyIcon from '@material-ui/icons/VpnKey';
import AddIcon from '@material-ui/icons/Add';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import GridViewIcon from '@material-ui/icons/Apps';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MenuIcon from '@material-ui/icons/Menu';
import ApiIcon from '@material-ui/icons/AcUnit';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PointOfSaleIcon from '@material-ui/icons/MonetizationOn';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HexagonIcon from '@material-ui/icons/AllInclusive';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import FilterListIcon from '@material-ui/icons/FilterList';

export const THEMES = {
    default: {
        palette: {
            principal: "#192d3e",
            base: "#FFFFFF",
            accent: "#195f91",
            details: "#707070"
        },
        icons: {
            view: <VisibilityIcon />,
            add: <AddIcon />,
            edit: <EditIcon />,
            delete: <DeleteForeverIcon />,
            password: <KeyIcon />,
            notifications: <NotificationsIcon />,
            notificationsActive: <NotificationsActiveIcon />,
            person: <PersonIcon />,
            settings: <SettingsIcon />,
            arrowBack: <ArrowBackIcon />,
            logout: <LogoutIcon />,
            gridView: <GridViewIcon />,
            accountCircle: <AccountCircleOutlinedIcon />,
            calendar: <CalendarTodayIcon />,
            menu: <MenuIcon />,
            api: <ApiIcon />,
            wallet: <AccountBalanceWalletIcon />,
            sale: <PointOfSaleIcon />,
            check: <CheckIcon />,
            remove: <RemoveIcon />,
            tree: <AccountTreeIcon />,
            exit: <ExitToAppIcon />,
            hexagon: <HexagonIcon />,
            info: <InfoIcon />,
            alert: <ErrorIcon />,
            close: <CloseIcon />,
            facebook: <FacebookIcon />,
            twitter: <TwitterIcon />,
            youtube: <YouTubeIcon />,
            instagram: <InstagramIcon />,
            addlist: <PlaylistAddIcon />,
            filter: <FilterListIcon />
        }
    }
}

