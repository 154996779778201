import { LinearProgress } from "@material-ui/core";
import styled from "styled-components";
import React from "react";

const Root = styled.div`
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 0;
  & .MuiLinearProgress-root {
    height: 100%;
    background-color: transparent;
  }
  & .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => props.color ?? ""};
  }
`;

export default function ConstantLinearProgress({ duration, color }) {
  const [progress, setProgress] = React.useState(0);

  const timer = () => {
    let counter = 0;
    let interval = setInterval(() => {
      setProgress(counter);
      if (counter === 100) {
        clearInterval(interval);
        return;
      }
      counter++;
    }, duration / 100);
  };

  React.useEffect(() => {
    if (progress === 0) {
      timer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <Root color={color}>
      <LinearProgress variant="determinate" value={progress} />
    </Root>
  );
}
