/* General imports */
import React from 'react'
import { Route } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { productActions } from './ProductActions'
import { alertActions } from '../../alert/AlertActions'
import { useReactToPrint } from 'react-to-print';



/* Components imports */
/* import Table from '../../tables/AsyncTable' */
import Table from "../../tables/moleTable/table";
import NewProduct from './NewProduct'
import UpdatePrices from './UpdatePrices'
import UpdatePromos from './UpdatePromos'
import ProfessionalsIcon from '../../icons/professionalsWhite.png'
import { ThemeContext } from '../../providers/theme'
import axios from 'axios'
import { FilterGroup, Filters, SearchInput } from '../../tables/moleTable'
import SwitchFilter from "../../tables/moleTable/filters/SwitchFilter";
import CheckFilter from "../../tables/moleTable/filters/CheckFilter";
import DateFilter from "../../tables/moleTable/filters/DateFilter";
import translate from '../../providers/i18n/translate';
import Tags from './Tags';
import Spinner from '../../spinners/LoaderSpinner';
import { printTagsActions } from './printTagsState/printTagsActions'
import Barcodes from './Barcodes'

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
    justify-content: space-between;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const ProductUpdates = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 98%;
    height: calc(100% - 100px);
    padding: 15px 0 0 15px;
    box-sizing: border-box;
    top: -64px;
    margin: 0 auto;
    background: ${props => props.theme.palette.base};
    border-radius: 20px 20px 0 0;
`

const UpdatePricesButton = styled.button`
    display: flex;
    position: relative;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px 10px;
    margin: 0 10px;
`

const ContentTags = styled.div`
    display: block;
    margin: 0;
    padding: 0;
`

const ContentBarcodes = styled.div`
    display: block;
    margin: 0;
    padding: 0;
`


/* ----- Principal function ----- */
function Product({ user, products, getProducts, setTags, tags, addTag, addAllTags, removeTag, removeAllTags, deleteProduct, selectedItems, isFetching, alertMessage, history, test, setTest }) {

    const dispatch = useDispatch();

    const [dataset, setDataset] = React.useState();

    const [renderTags, setRenderTags] = React.useState(false);

    const [renderBarcodes, setRenderBarcodes] = React.useState(false);

    const [tagsKey, setTagsKey] = React.useState();

    const [barcodesKey, setBarcodesKey] = React.useState();

    const [pageSize, setPageSize] = React.useState();

    const columnsTable = [
        { title: 'ID', field: 'idProduct', order: true, width: "5%" },
        { title: 'Código', field: 'code', order: true, width: "7%" },
        { title: 'Barcode', field: 'barcode', order: true, width: "8%" },
        { title: 'Nombre', field: 'name', width: "17%", order: true },
        { title: 'Categoría', field: 'id_Category.name', width: "12%" },
        { title: 'Marca', field: 'trademark.name', width: "20%" },
        {
            title: 'P. Costo', field: 'costPrice', width: "7%", order: true,
            render: rowData => <span>${rowData.costPrice}</span>
        },
        {
            title: 'P. Venta', field: 'salePrice', width: "7%", order: true,
            render: rowData => <span>${rowData.salePrice}</span>
        },
        { title: 'Modificado', field: 'updatedDate', width: "%15", order: true, type: 'date' },
        { title: translate("actions"), actions: true, width: "auto", hidden: false }
    ]

    const filters = [
        {
            name: "supplier",
            label: "Proveedor",
            type: "check",
            src: `/api/supplierslist`
        },
        {
            name: "category",
            label: "Categoría",
            type: "check",
            src: `/api/categorylist`
        },
        {
            name: "trademark",
            label: "Marca",
            type: "check",
            src: `/api/trademarklist`
        },
        {
            name: "updatedDate",
            label: "Fecha Actualización",
            type: "date",
            src: `/api/trademarklist`
        },
    ]

    const defaultSelectedFilters = [
        {
            name: "supplier",
            values: [],
            condition: "equal",
            type: "object",
            search: ''

        },
        {
            name: "category",
            values: [],
            condition: "equal",
            type: "object",
            search: ''

        },
        {
            name: "trademark",
            values: [],
            condition: "equal",
            type: "object",
            search: ''

        },
        {
            name: "updatedDate",
            values: [],
            condition: "equal",
            type: "object"

        },
    ]

    const [selectedFilters, setSelectedFilters] = React.useState(defaultSelectedFilters);

    const handleFilterSearch = (name, value) => {
        const oldFilters = selectedFilters;
        const newFilters = selectedFilters.filter(f => f.name !== name);
        const filterChange = oldFilters.find(f => f.name === name);
        filterChange["search"] = value;
        newFilters.push(filterChange);
        setSelectedFilters(newFilters);
    }

    const theme = React.useContext(ThemeContext);

    const tableRef = React.useRef();

    const [search, setSearch] = React.useState('');

    const [editData, setEditData] = React.useState(null);

    const onSearchChange = (value) => {
        setSearch(value);
    }

    const handleSearch = () => {
        setDataset();
        tableRef.current.clean();
    }

    const handleNew = () => {
        history.push('/admin/stocksystem/product/new')
    };

    // Ref for barcode print component
    const componentRefBarcodes = React.useRef();

    // Function for printing barcodes
    const printBarcodes = useReactToPrint({
        content: () => componentRefBarcodes.current,
    });

    // Function for requesting confirmation befre printing
    const handlePrintBarcodes = (selectedItems) => {
        const itemsToPrint = selectedItems.size === 0 ? dataset : dataset.filter(i => selectedItems.has(i.idProduct));
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Se van a imprimir ${itemsToPrint.length} códigos de barra`)){
            setRenderBarcodes(true);
        }
    };

    const componentRefTags = React.useRef();

    const printTags = useReactToPrint({
        content: () => componentRefTags.current,
    });

    const handlePrintTags = (selectedItems) => {
        const itemsToPrint = selectedItems.size === 0 ? dataset : dataset.filter(i => selectedItems.has(i.idProduct));
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Se van a imprimir ${itemsToPrint.length} etiquetas`)){
            setRenderTags(true);
        }
    };


    const handleEdit = (rowData) => {
        setEditData(rowData)
        history.push('/admin/stocksystem/product/update/' + rowData.idProduct)
    }

    const handleDelete = (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar el producto " + rowData.idProduct + "?")) {
            deleteProduct(rowData.idProduct);
        }
    }

    const handleSelect = (checked, item) => {
        if (checked) {
            addTag(item);
        }
        else {
            removeTag(item);
        }
    }

    const handleSelectAll = (checked) => {
        if (checked) {
            addAllTags(dataset);
        }
        else {
            removeAllTags(dataset);
        }
    }

    const handleUpdatePrices = (e) => {
        e.preventDefault();
        history.push('/admin/stocksystem/product/updateprices')
    }

    const handleUpdatePromos = (e) => {
        e.preventDefault();
        history.push('/admin/stocksystem/product/updatepromos')
    }

    const RouteComponent = ({ component: Component, rowData, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                return <Component rowData={rowData} {...props} />
            }}
        />
    )

    const onApplyFilter = () => {
        setDataset();
        tableRef.current.clean();
    }

    const onClearFilter = () => {
        setSelectedFilters(defaultSelectedFilters);
    }

    const actionsTable = {
        view: {
            icon: theme.icons.view,
            tooltip: 'Ver',
            onClick: handleNew,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: true
        },
        edit: {
            icon: theme.icons.edit,
            tooltip: 'Editar',
            onClick: handleEdit,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        },
        delete: {
            icon: theme.icons.delete,
            tooltip: 'Eliminar',
            onClick: handleDelete,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        },
        select: {
            selectedItems: selectedItems,
            dataset: dataset,
            handleChange: handleSelect,
            handleChangeAll: handleSelectAll,
        },
    }

    const handleChangeSwitch = (name, value) => {
        const oldFilters = selectedFilters;
        const newFilters = selectedFilters.filter(f => f.name !== name);
        const filterChange = oldFilters.find(f => f.name === name);
        filterChange["values"] = [value];
        newFilters.push(filterChange);
        setSelectedFilters(newFilters);
    }

    const handleCheckFilter = (name, value) => {
        const olds = selectedFilters;
        const news = selectedFilters.filter(f => f.name !== name);
        const filterChange = olds.find(f => f.name === name);
        if (filterChange.values.includes(parseInt(value))) {
            const newArray = filterChange.values.filter(val => val !== parseInt(value));
            filterChange["values"] = newArray;
            news.push(filterChange);
            setSelectedFilters(state => [...news]);
        } else {
            const uptArray = filterChange.values;
            uptArray.push(parseInt(value));
            filterChange["values"] = uptArray;
            news.push(filterChange);
            setSelectedFilters(state => [...news]);
        }
    }

    const handleDateChange = (name, value) => {
        const oldFilters = selectedFilters;
        const newFilters = selectedFilters.filter(f => f.name !== name);
        const filterChange = oldFilters.find(f => f.name === name);
        filterChange["values"] = value;
        newFilters.push(filterChange);
        setSelectedFilters(newFilters);
    }

    React.useEffect(() => {
        if (!dataset) {
            let filterList = selectedFilters.filter(fil => fil.values.length > 0);
            if (filterList.length === 0) {
                filterList = "";
            }
            else {
                filterList = JSON.stringify(filterList);
            }
            axios.get(`/api/products?search=${search}&filters=${filterList}`)
                .then(result => {
                    setDataset(result.data.rows);
                })
                .catch(err => {
                    console.log(err);
                })
        }
        else{
            setTags(dataset);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataset]);

    React.useEffect(() => {
        const newKey = Math.random();
        setTagsKey(newKey);
        if (renderTags){
            setPageSize('size: 21cm 29.7cm; margin: 2cm 2cm 4cm 2cm');
            printTags();
            setRenderTags(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderTags])

    React.useEffect(() => {
        const newKey = Math.random();
        setBarcodesKey(newKey);
        if (renderBarcodes){
            setPageSize('size: 38mm 20mm; margin: 0cm 0cm 0cm 0cm')
            printBarcodes();
            setRenderBarcodes(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderBarcodes])

    const customStyle = {"--pageSize": "38mm 20mm"};

    React.useEffect(() => {
        const pageSizeRule = `@page{${pageSize}}`;
        document.head.appendChild(document.createElement("style")).innerHTML = pageSizeRule;
    }, [pageSize])

    return (
        dataset ?
            <Root style={customStyle}>
                <Header>
                    <Title>
                        <img src={ProfessionalsIcon} alt='Icon' />
                        <h6>Productos</h6>
                    </Title>
                    <ProductUpdates>
                        <Route path={'/admin/stocksystem/product'} exact={true} >
                            <UpdatePricesButton onClick={handleUpdatePromos}>Actualizar promos</UpdatePricesButton>
                        </Route>
                        <Route path={'/admin/stocksystem/product'} exact={true} >
                            <UpdatePricesButton onClick={handleUpdatePrices}>Actualizar precios</UpdatePricesButton>
                        </Route>
                    </ProductUpdates>
                </Header>
                <Content>
                    <Route path={'/admin/stocksystem/product'} exact={true} >
                        <Table
                            disableTotalTitle
                            title="Listado de productos"
                            ref={tableRef}
                            columns={columnsTable}
                            actionsTable={actionsTable}
                            viewTable="list"
                            options={{ 
                                newButton: true, 
                                onNew: handleNew, 
                                printTags: true, 
                                printBarcodes: true, 
                                onPrintTags: handlePrintTags,
                                onPrintBarcodes: handlePrintBarcodes,
                            }}
                            data={
                                (query) => new Promise((resolve, reject) => {
                                    let filterList = selectedFilters.filter(fil => fil.values.length > 0);
                                    if (filterList.length === 0) {
                                        filterList = "";
                                    }
                                    else {
                                        filterList = JSON.stringify(filterList);
                                    }
                                    axios.get(`/api/products?page=${(query.page - 1)}&limit=${query.pageSize}&search=${search}&orderBy=${JSON.stringify(query.order)}&filters=${filterList}`)
                                        .then(result => {
                                            resolve(result.data);
                                        })
                                        .catch(err => {
                                            console.log(err);
                                        })
                                })
                            }
                        >
                            <SearchInput search={search} onChange={onSearchChange} onSearch={handleSearch} />
                            <Filters clear={onClearFilter} action={onApplyFilter}>
                                {
                                    filters && filters.map((fil, index) =>
                                        <FilterGroup name={fil.name && fil.name} onChange={handleFilterSearch} key={`filter_group_${index}`} title={fil.type === "switch" ? null : fil.label} search={fil.name && selectedFilters.find(ft => ft.name === fil.name).search && selectedFilters.find(ft => ft.name === fil.name).search} >
                                            {
                                                fil.type === "switch" ?
                                                    <SwitchFilter filter={fil} value={selectedFilters.find(ft => ft.name === fil.name).values[0]} onChange={handleChangeSwitch} />
                                                    :
                                                    fil.type === "date" ?
                                                        <DateFilter filter={fil} values={selectedFilters.find(ft => ft.name === fil.name).values} onChange={handleDateChange} />
                                                        :
                                                        <CheckFilter filter={fil} search={selectedFilters.find(ft => ft.name === fil.name).search && selectedFilters.find(ft => ft.name === fil.name).search} values={selectedFilters.find(ft => ft.name === fil.name).values} handleChange={handleCheckFilter} />
                                            }
                                        </FilterGroup>
                                    )

                                }
                            </Filters>
                        </Table>
                    </Route>
                    <Route path={'/admin/stocksystem/product/new'} component={NewProduct} />
                    <Route path={'/admin/stocksystem/product/updateprices'} component={UpdatePrices} />
                    <Route path={'/admin/stocksystem/product/updatepromos'} component={UpdatePromos} />
                    <RouteComponent path={'/admin/stocksystem/product/update'} rowData={editData} component={NewProduct} />
                </Content>
                { renderBarcodes ?
                    <div style={{ display: 'none' }}>
                        <ContentBarcodes renderTags={renderTags} renderBarcodes={renderBarcodes} key={barcodesKey} ref={componentRefBarcodes}>
                            <Barcodes
                                tags={selectedItems.size === 0 ? dataset : tags}
                            />
                        </ContentBarcodes>
                    </div>
                    :
                    null
                }
                { renderTags ?
                    <div style={{ display: 'none' }}>
                        <ContentTags renderTags={renderTags} pageSize={pageSize} key={tagsKey} ref={componentRefTags}>
                            <Tags
                                tags={selectedItems.size === 0 ? dataset : tags}
                            />
                        </ContentTags>
                    </div>
                    :
                    null
                }
            </Root>
            :
            <Spinner spinnerType='TailSpin' timeout='0' />
    );
}

function mapState(state) {
    const { error, isFetching, products } = state.product;
    const { user } = state.login;
    const { selectedItems, tags } = state.printTags;
    return { user, error, isFetching, products, selectedItems, tags };
}

const actionCreators = {
    getProducts: productActions.getProducts,
    deleteProduct: productActions.deleteProduct,
    alertMessage: alertActions.error,
    addTag: printTagsActions.addTag,
    addAllTags: printTagsActions.addAllTags,
    removeTag: printTagsActions.removeTag,
    removeAllTags: printTagsActions.removeAllTags,
    setTags: printTagsActions.setTags,
}

export default connect(mapState, actionCreators)(Product);
