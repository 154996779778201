/* General imports */
import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { productActions } from './ProductActions'
import { alertActions } from '../../alert/AlertActions'

/* Components imports */
/* import Table from '../../tables/AsyncTable' */
import Table from "../../tables/moleTable/table";
import ProfessionalsIcon from '../../icons/professionalsWhite.png'
import { ThemeContext } from '../../providers/theme'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { FilterGroup, Filters, SearchInput } from '../../tables/moleTable'
import SwitchFilter from "../../tables/moleTable/filters/SwitchFilter";
import CheckFilter from "../../tables/moleTable/filters/CheckFilter";
import DateFilter from "../../tables/moleTable/filters/DateFilter";
import translate from '../../providers/i18n/translate';
import UpdateSupplierPrices from './updateSupplierPrices'

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
    justify-content: space-between;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 98%;
    height: calc(100% - 100px);
    padding: 15px 0 0 15px;
    box-sizing: border-box;
    top: -64px;
    margin: 0 auto;
    background: ${props => props.theme.palette.base};
    border-radius: 20px 20px 0 0;
`

const BulkUpdateButton = styled.button`
    display: flex;
    position: relative;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px 10px;
    margin: 0 10px;
`

/* ----- Principal function ----- */
function ProductxSupplier({ user, products, getProducts, deleteProduct, isFetching, alertMessage, history }) {

    const columnsTable = [
        { title: 'ID', field: 'idProduct', order: true, width: "5%" },
        { title: 'Código', field: 'code', order: true, width: "7%", render: rowData => <span>{rowData.barcode && rowData.barcode !== "" ? rowData.barcode : rowData.code}</span> },
        { title: 'Nombre', field: 'name', width: "17%", order: true},
        { title: 'Categoría', field: 'category_name', width: "20%" },
        { title: 'Proveedor', field: 'supplier_name', width: "20%" },
        { title: 'Precio proveedor', field: 'price', width: "20%", order: true,
            render: rowData => <span>${rowData.price}</span>
        },
        { title: 'Modificado', field: 'updatedDate', width: "%15", order: true, type: 'date' },
        { title: translate("actions"), actions: true, width: "auto", hidden: true }
    ]

    const filters = [
        {
            name: "supplier",
            label: "Proveedor",
            type: "check",
            src: `/api/supplierslist`
        },
        {
            name: "category",
            label: "Categoría",
            type: "check",
            src: `/api/categorylist`
        }
    ]

    const defaultSelectedFilters = [
        {
            name: "supplier",
            values: [],
            condition: "equal",
            type: "object",
            search: ''

        },
        {
            name: "category",
            values: [],
            condition: "equal",
            type: "object",
            search: ''

        }
    ]

    const [ selectedFilters, setSelectedFilters ] = React.useState(defaultSelectedFilters);

    const handleFilterSearch = (name, value) => {
        const oldFilters = selectedFilters;
        const newFilters = selectedFilters.filter(f => f.name !== name);
        const filterChange = oldFilters.find(f => f.name === name);
        filterChange["search"] = value;
        newFilters.push(filterChange);
        setSelectedFilters(newFilters);
    }

    const theme = React.useContext(ThemeContext);

    const tableRef = React.useRef();


    const [ search, setSearch ] = React.useState('');

    const onSearchChange = (value) => {
        setSearch(value);
    }

    const handleSearch = () => {
        tableRef.current.clean();
    }

    const handleNew = () => {
        history.push('/admin/stocksystem/product/new')
    };


    const handleDelete = (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar el producto "+rowData.idProduct+"?")) {
            deleteProduct(rowData.idProduct);
        }
    }

    const RouteComponent = ({ component: Component, rowData, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                return <Component rowData={rowData} {...props} />
            }}
        />
    )

    const onApplyFilter = () => {
        tableRef.current.clean();
    }

    const onClearFilter = () => {
        setSelectedFilters(defaultSelectedFilters);
    }

    const actionsTable={
        view: {
            icon: theme.icons.view,
            tooltip: 'Ver',
            onClick: handleNew,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: true
        },
        delete: {
            icon: theme.icons.delete,
            tooltip: 'Eliminar',
            onClick: handleDelete,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        }
    }

    const handleChangeSwitch = (name, value) => {
        const oldFilters = selectedFilters;
        const newFilters = selectedFilters.filter(f => f.name !== name);
        const filterChange = oldFilters.find(f => f.name === name);
        filterChange["values"] = [value];
        newFilters.push(filterChange);
        setSelectedFilters(newFilters);
    }

    const handleCheckFilter = (name, value) => {
        const olds = selectedFilters;
        const news = selectedFilters.filter(f => f.name !== name);
        const filterChange = olds.find(f => f.name === name);
        if(filterChange.values.includes(parseInt(value))){
            const newArray = filterChange.values.filter(val => val !== parseInt(value));
            filterChange["values"] = newArray;
            news.push(filterChange);
            setSelectedFilters(state => [...news]);
        } else {
            const uptArray = filterChange.values;
            uptArray.push(parseInt(value));
            filterChange["values"] = uptArray;
            news.push(filterChange);
            setSelectedFilters(state => [...news]);
        }
    }

    const handleDateChange = (name, value) => {
        const oldFilters = selectedFilters;
        const newFilters = selectedFilters.filter(f => f.name !== name);
        const filterChange = oldFilters.find(f => f.name === name);
        filterChange["values"] = value;
        newFilters.push(filterChange);
        setSelectedFilters(newFilters);
    }

    const handleBulkUpdate = () => {
        history.push("/admin/stocksystem/productxsupplier/update");
    }

    return(
        <Root>
            <Header>
                <Title>
                    <img src={ProfessionalsIcon} alt='Icon' />
                    <h6>Productos por Proveedor</h6>
                </Title>
                <Route path={'/admin/stocksystem/productxsupplier'} exact={true} >
                    <BulkUpdateButton onClick={handleBulkUpdate}>ACTUALIZAR PRECIOS</BulkUpdateButton>
                </Route>
            </Header>
            <Content>
                <Route path={'/admin/stocksystem/productxsupplier'} exact={true} >
                    <Table
                    disableTotalTitle
                        title="Listado de productos"
                        ref={tableRef}
                        columns={columnsTable}
                        actionsTable={actionsTable}
                        viewTable="list"
                        /* options={{newButton: true, onNew: handleNew, printTags: true, onPrintTags: handlePrintTags}} */
                        data={
                            (query) => new Promise((resolve, reject) => {
                                let filterList = selectedFilters.filter(fil => fil.values.length > 0);
                                if (filterList.length === 0){
                                    filterList = "";
                                }
                                else {
                                    filterList = JSON.stringify(filterList);
                                }
                                axios.get(`/api/productsxsupplier?page=${(query.page - 1)}&limit=${query.pageSize}&search=${search}&orderBy=${JSON.stringify(query.order)}&filters=${filterList}`)
                                .then(result => {
                                    resolve({
                                        rows: result.data
                                    });
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                            })
                        }
                    >
                        <SearchInput search={search} onChange={onSearchChange} onSearch={handleSearch} />
                        <Filters clear={onClearFilter} action={onApplyFilter}>
                        {
                            filters && filters.map((fil, index) =>
                                <FilterGroup name={fil.name && fil.name} onChange={handleFilterSearch} key={`filter_group_${index}`} title={fil.type === "switch" ? null : fil.label} search={fil.name && selectedFilters.find(ft => ft.name === fil.name).search && selectedFilters.find(ft => ft.name === fil.name).search} >
                                    {
                                        fil.type === "switch" ?
                                        <SwitchFilter filter={fil} value={selectedFilters.find(ft => ft.name === fil.name).values[0]} onChange={handleChangeSwitch} />
                                        :
                                        fil.type === "date" ?
                                        <DateFilter filter={fil} values={selectedFilters.find(ft => ft.name === fil.name).values} onChange={handleDateChange} />
                                        :
                                        <CheckFilter filter={fil} search={selectedFilters.find(ft => ft.name === fil.name).search && selectedFilters.find(ft => ft.name === fil.name).search }  values={selectedFilters.find(ft => ft.name === fil.name).values} handleChange={handleCheckFilter} />
                                    }
                                </FilterGroup>
                            )

                        }
                        </Filters>
                    </Table>
                </Route>
                <Route path={'/admin/stocksystem/productxsupplier/update'} exact={true} >
                    <UpdateSupplierPrices history={history}/>
                </Route>
            </Content>
        </Root>
    );
}

function mapState(state) {
    const { error, isFetching, products } = state.product;
    const { user } = state.login;
    return { user, error, isFetching, products};
}

const actionCreators = {
    getProducts: productActions.getProducts,
    deleteProduct: productActions.deleteProduct,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ProductxSupplier);
