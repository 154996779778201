/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../../app/config'
import axios from 'axios';
import { Link } from 'react-router-dom';

/* Components imports */
import ProductCard from '../ProductList/ProductCard'
import TextField from '../../form/TextField'
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: flex-start;
`

const NavSpace = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(0deg, rgba(255,255,255,0.36458333333333337) 0%, rgba(78,78,78,1) 100%);
`

const ContentBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
    box-sizing: border-box;
    width: ${(props) => props.width ? props.width : '85%'};
    height: ${(props) => props.height ? props.height : '100%'};
    align-items: ${(props) => props.alignItems ? props.alignItems : 'flex-start'};
    justify-content: ${(props) => props.justifyContent ? props.justifyContent : 'center'};
`
const BoxLeft = styled.ul`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width ? props.width : '20%'};
    height: 100%;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    color: black;
    h2 {
        color: black;
        font-size: 20px;
        font-weight: 600;
    }
    a {
        text-decoration: none;
    }
`

const BoxLeftMobile = styled.ul`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width ? props.width : '20%'};
    height: ${(props) => props.height ? props.height : '100%'};
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
    align-items: ${(props) => props.alignItems ? props.alignItems : 'flex-start' };
    justify-content: flex-start;
    color: black;
    overflow-y: hidden;
    transition: height 400ms ease-in-out;
    transition: align-items 500ms ease-in-out;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0px 20px;
        background: transparent;
        border: none;
        outline: none;
    }
    h2 {
        display: flex;
        color: black;
        font-size: 20px;
        font-weight: 600;
    }
    a {
        text-decoration: none;
    }
`

const CategoryItem = styled.li`
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    ul {
        padding: 0;
    }
    h3 {
        color: black;
        font-size: ${(props) => props.selected ? "18px" : "16px"};
        font-weight: ${(props) => props.selected ? 600 : 400};
    }
    h4 {
        color: black;
        font-size: ${(props) => props.selected ? "18px" : "16px"};
        font-weight: ${(props) => props.selected ? 600 : 400};
        margin: 5px 0;
    }
`

const BoxRight = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: ${(props) => props.width ? props.width : '80%'};
    height: 100%;
    align-items: flex-start;
    justify-content: space-around;
    border-left: 1px solid rgba(0,0,0,0.5);
`

const ContentProductCard = styled.div`
    display: flex;
    position: relative;
    width: ${(props) => props.width ? props.width : '30%'};
    height: 500px;
    align-items: center;
    justify-content: center;
    ${(props) => props.margin ? `margin: ${props.margin};` : '' }
`

const Hr = styled.div`
    display: flex;
    position: relative;
    width: 90%;
    height: 1px;
    background: rgba(0,0,0,0.1);
    margin: 20px 0;
`

const BoxTitle = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-between;
`

/* ----- Principal function ----- */
function CategoryProducts({idsection, match}) {

    const { idcategory } = match.params;

    const [ showFilter, SetShowFilter ] = React.useState(false)

    const [productList, setProductList] = React.useState(null)  
    const [categories, setCategories] = React.useState(null)
    const [orderBy, setOrderBy] = React.useState('default')
    const [search, setSearch] = React.useState('');

    const orderOptions = [
        { name: "Por defecto", value: 'default' }, 
        { name: "Menor precio", value: 'salePriceAsc' }, 
        { name: "Mayor precio", value: 'salePriceDesc' },
        { name: "Nombre ascendente", value: 'nameAsc' }, 
        { name: "Nombre descendente", value: 'nameDesc' }
    ]

    const getProductList = () => {
        axios.get(`/api/products?idCategory=${idcategory}&orderBy=${orderBy}&search=${search}&ecommerce=1`)
            .then(response => {
                setProductList(response.data.rows)
            })
            .catch(err => {
                alert('Hubo un error...');
            })
    }

    const handleChange = (name, value) => {
        if(name === "order"){
            setOrderBy(value);
            getProductList()
        } else if(name === "search"){
            setSearch(value);
            if(search.length > 2){
                getProductList()
            }
        }
        
    }

    const onFilter = () => {
        SetShowFilter(!showFilter)
    }

    React.useEffect(() => {
        if(!productList){
            getProductList(orderBy)
        }
        if(!categories){
            axios.get(`/api/category`)
            .then(categoriesData => {
                setCategories(categoriesData.data.categories);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [])

    return(
        <Root>
            <NavSpace />
            <Desktop>
                <ContentBox>
                    <BoxLeft>
                        <TextField id="search" value={search} width="90%" name="search" placeholder="Búsqueda" type="text" fontSize="14px" onChange={handleChange} />
                        <h2>CATEGORIAS:</h2>
                        {
                            categories && categories.map((cat, index) => 
                            cat.parent_idCategory === 0 &&
                            <CategoryItem selected={parseInt(cat.idCategory) === parseInt(idcategory)}>
                                {
                                    parseInt(cat.idCategory) !== 0 && <Link to={`/tienda/categoria/${cat.idCategory}`}><h3>{cat.name}</h3></Link>
                                }
                                <ul>
                                    {
                                        categories.map((subcat, index) => 
                                            (subcat.parent_idCategory === cat.idCategory) && 
                                            <CategoryItem selected={parseInt(subcat.idCategory) === parseInt(idcategory)}>
                                                {
                                                    parseInt(cat.idCategory) !== 0 && <Link to={`/tienda/categoria/${subcat.idCategory}`}><h4>{subcat.name}</h4></Link>
                                                }
                                            </CategoryItem>
                                        )
                                    }
                                </ul>
                            </CategoryItem>
                            
                            )
                        }
                        <Hr />
                        <TextField id="order" value={orderBy} width="80%" name="order" placeholder="Ordenar por" options={orderOptions} type="select" fontSize="18px" onChange={handleChange} />
                    </BoxLeft>
                    <BoxRight>
                        {
                           productList && productList.map((product, index) => 
                           <ContentProductCard key={`product_${product.name}`}>
                                <ProductCard productInfo={product} />
                           </ContentProductCard>
                           )
                       }
                    </BoxRight>
                </ContentBox>
            </Desktop>
            <Tablet>
                <ContentBox>
                    <BoxLeft>
                        <TextField id="search" value={search} width="90%" name="search" placeholder="Búsqueda" type="text" fontSize="14px" onChange={handleChange} />
                        <h2>CATEGORIAS:</h2>
                        {
                            categories && categories.map((cat, index) => 
                            cat.parent_idCategory === 0 &&
                            <CategoryItem selected={parseInt(cat.idCategory) === parseInt(idcategory)}>
                                {
                                    parseInt(cat.idCategory) !== 0 && <Link to={`/tienda/categoria/${cat.idCategory}`}><h3>{cat.name}</h3></Link>
                                }
                                <ul>
                                    {
                                        categories.map((subcat, index) => 
                                            (subcat.parent_idCategory === cat.idCategory) && 
                                            <CategoryItem selected={parseInt(subcat.idCategory) === parseInt(idcategory)}>
                                                {
                                                    parseInt(cat.idCategory) !== 0 && <Link to={`/tienda/categoria/${subcat.idCategory}`}><h4>{subcat.name}</h4></Link>
                                                }
                                            </CategoryItem>
                                        )
                                    }
                                </ul>
                            </CategoryItem>
                            
                            )
                        }
                        <Hr />
                        <TextField id="order" value={orderBy} width="80%" name="order" placeholder="Ordenar por" options={orderOptions} type="select" fontSize="18px" onChange={handleChange} />
                    </BoxLeft>
                    <BoxRight>
                        {
                           productList && productList.map((product, index) => 
                           <ContentProductCard key={`product_${product.name}`}>
                                <ProductCard productInfo={product} />
                           </ContentProductCard>
                           )
                       }
                    </BoxRight>
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox flexDirection="column" width="100%" height="auto" alignItems="center" justifyContent="flex-start">
                    <TextField id="search" value={search} width="90%" name="search" placeholder="Búsqueda" type="text" fontSize="14px" onChange={handleChange} />
                    <BoxLeftMobile alignItems={showFilter ? "flex-start" : "flex-end"} width="100%" height={showFilter ? "100%" : "80px"} padding="10px 20px">
                        {
                            showFilter ? 
                                <BoxTitle>
                                    <h2>CATEGORIAS:</h2>
                                    <button onClick={onFilter}>
                                        <CloseIcon />
                                    </button>
                                </BoxTitle>
                                
                            :
                                <button onClick={onFilter}>
                                    <h2>Filtros <FilterListIcon/></h2>
                                </button>
                                
                        }
                        
                        {
                            categories && categories.map((cat, index) => 
                            cat.parent_idCategory === 0 &&
                            <CategoryItem selected={parseInt(cat.idCategory) === parseInt(idcategory)}>
                                {
                                    parseInt(cat.idCategory) !== 0 && <Link to={`/tienda/categoria/${cat.idCategory}`}><h3>{cat.name}</h3></Link>
                                }
                                <ul>
                                    {
                                        categories.map((subcat, index) => 
                                            (subcat.parent_idCategory === cat.idCategory) && 
                                            <CategoryItem selected={parseInt(subcat.idCategory) === parseInt(idcategory)}>
                                                {
                                                    parseInt(cat.idCategory) !== 0 && <Link to={`/tienda/categoria/${subcat.idCategory}`}><h4>{subcat.name}</h4></Link>
                                                }
                                            </CategoryItem>
                                        )
                                    }
                                </ul>
                            </CategoryItem>
                            
                            )
                        }
                        <Hr />
                        <TextField id="order" value={orderBy} width="80%" name="order" placeholder="Ordenar por" options={orderOptions} type="select" fontSize="18px" onChange={handleChange} />
                    </BoxLeftMobile>
                    <BoxRight width="100%">
                        {
                           productList && productList.map((product, index) => 
                           <ContentProductCard key={`product_${product.name}`} width="100%" margin="20px 0">
                                <ProductCard productInfo={product} />
                           </ContentProductCard>
                           )
                       }
                    </BoxRight>
                </ContentBox>
            </Mobile>
        </Root>
    );
}

export default CategoryProducts;