import { updateSuppliersConstants } from './UpdateSuppliersConstants';

let items = JSON.parse(localStorage.getItem('updatesuppliersitems'));
let updatesuppliersdata = JSON.parse(localStorage.getItem('updatesuppliersdata'));
const initialState = items ? 
        { 
            error: false,
            isFetching: false,
            items: items ? items : [],
            updatesuppliersdata: updatesuppliersdata ? 
            updatesuppliersdata
                :
                { 
                    idSupplier: null
                }
        }
    : 
        { 
            error: false,
            isFetching: true,
            items: items ? items : [],
            updatesuppliersdata: updatesuppliersdata ? 
            updatesuppliersdata
                :
                { 
                    idSupplier: null
                }
        };

export function updateSuppliersReducer(state = initialState, action) {
    switch (action.type) {
        case updateSuppliersConstants.FETCHING:
            return { 
                ...state,
                isFetching: true
            };
        case updateSuppliersConstants.ERROR:
            return { 
                ...state,
                error: true
            };
        case updateSuppliersConstants.SET:
            return { 
                    ...state,
                    isFetching: false,
                    items: action.items
                };
        case updateSuppliersConstants.SETDATA:
            return { 
                    ...state,
                    isFetching: false,
                    updatesuppliersdata: {...action.data}
                };
        default:
            return state
    }
}