/* General imports */
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';


const Root = styled(Link)`
    all:unset;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px 120px 30px;
    width: 100%;
    height: 100%;
    cursor:pointer;
`

const Box = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow: ${(props) => props.boxShadow ? props.boxShadow : 'unset'};
  grid-row: ${(props) => props.gridRow ? props.gridRow : 'unset' };
  box-sizing: border-box;
  grid-column: ${(props) => props.gridColumn ? props.gridColumn : 'unset' };
  padding: ${(props) => props.padding ? props.padding : 'none'};
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background ? props.background : 'transparent' };
  color: ${(props) => props.color ? props.color : '#000' };
  flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
  gap: 20px;
`

const Icon = styled.img`
    filter: invert(100%);
    width:25px;
`


const ShortcutBox = ({title, icon, link, borderRadius}) => {
    return (
        <Root borderRadius={borderRadius} to={link}>
            <Box gridColumn="1/3" background="linear-gradient(to left,#122230 0%,#192d3e 100%)" color="#FFF">
                <Icon src={icon} />
                <strong>{title}</strong>
            </Box>
        </Root>
    );
}

export default ShortcutBox;