/* General imports */
import React, {useRef} from 'react'
import styled from 'styled-components'
import { argDate } from '../utils/getDate';

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 164px;
    padding: 50px 0;
`

const Comment = styled.span`
    font-size: 0.55rem;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const InfoDate = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: ${(props) => props.fontSize ? props.fontSize : '0.55rem'};
    border-top: 1px dashed black;
    border-bottom: 1px dashed black;
    box-sizing: border-box;
    margin: 15px 0;
    padding: 5px 0;
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const ContentProduct = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const Title = styled.h1`
    text-align: center;
    font-size: 1rem;
    margin: 5px 0 5px 0;
`

const Subtitle = styled.h2`
    font-size: 1.5rem;
    margin: 0 0 5px 0;
`

const Address = styled.span`
    font-size: 0.65rem;
    margin: 0 0 5px 0;
`

const Product = styled.span`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 0.75em;
`

const Price = styled.span`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 0.75em;
`

const Footer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-top: 1px dashed black;
    box-sizing: border-box;
    margin: 20px 0;
    /* padding: 10px 0; */
`

const FooterItem = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: ${(props) => props.fontSize ? props.fontSize : '1rem'};
    margin: 2px 0;
`

const Total = styled(FooterItem)`
    border-top: 1px dashed black;
    border-bottom: 1px dashed black ;
    padding: 2px 0;
`

const Ticket = ({products, subtotal, discount, total, ticketDate, ref}) => {

    const today = ticketDate ? new Date(ticketDate) : new Date();
   /*  const tdate = ;
    const thour = ; */

    return (
        <Root ref={ref}>
            <Header>
                <Comment>**Documento no válido como factura**</Comment>
                <Title>Dietética</Title>
                <Subtitle>Santa Lucía</Subtitle>
                <Address>Hornos 2634 - Tel. 2284422990</Address>
            </Header>
            <InfoDate>
                <span>{`Fecha ${ticketDate ? today.getDate() - 1 : today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`}</span>
                <span>{`Hora ${today.getHours()}:${today.getMinutes()}`}</span>
            </InfoDate>
            <Content>
            {
                products && products.map((product, index) =>
                <ContentProduct>
                    <Product key={`product_${index}`}>
                        <span>{`${product.name.length > 20 ? `${product.name.substring(0,20)}...` : product.name}`}</span>
                    </Product>
                    <Price>
                        <span>{`${product.amount} x $${product.salePrice.toFixed(2)}`}</span>
                        <span>{`$${(Math.round((product.amount * product.salePrice)*100)/100).toFixed(2)}`}</span>
                    </Price>
                </ContentProduct>
                )
            }
            <Footer>
                <FooterItem fontSize="0.85rem">
                    <span>Subtotal</span>
                    <span>{`$${subtotal.toFixed(2)}`}</span>
                </FooterItem>
                {
                    subtotal - total > 0 &&
                    <FooterItem fontSize="0.75rem">
                        <span>Descuento</span>
                        <span>{`- $${(subtotal - total).toFixed(2)}`}</span>
                    </FooterItem>
                }
                <Total>
                    <span><strong>TOTAL</strong></span>
                    <span><strong>{`$${total.toFixed(2)}`}</strong></span>
                </Total>
            </Footer>

            </Content>
        </Root>
    );
}

export default Ticket;
