/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import { Route } from 'react-router-dom'

/* Components imports */
import Logo from '../images/DIETETICA_SANTA_LUCIA_LOGO.jpg'
import NavAnimate from '../navs/NavAnimate'
import { components } from '../app/componentList'
import axios from 'axios'


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const NavSpace = styled.div`
    width: 100%;
    height: 100px;
`

/* ----- Principal function ----- */
function BasicWebPage({idpage, listpages, history}) {

    const [ sectionsPage, setSectionsPage ] = React.useState(null)

    React.useEffect(() => {
        async function fechData() {
            axios.get('/api/sectionspage/page/'+idpage)
            .then(sectionsData => {
                const sections = sectionsData.data.sectionsPage
                setSectionsPage(sections)
            })
            .catch(err => {
                alert('Hubo un error...');
            })    
        }
        if(!sectionsPage){
            fechData()
        }
    }, [ sectionsPage, idpage ]) 

    const RouteComponent = ({ component: Component, idsection, datasection, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                return <Component idsection={idsection} datasection={datasection} {...props} />
            }}
        />
    )

    return(
        <React.Fragment>
            <Desktop>
                <NavAnimate data={{logo: Logo, items: listpages}} history={history} />
                <NavSpace />
                {
                    sectionsPage &&
                    sectionsPage.map( (item, index) =>
                        <RouteComponent key={`${item.idSectionPage}_${index}`} path={`${item.page.link}`} idsection={item.idSection} datasection={item.section.data} component={components.find(comp => comp.name === item.section.section_type.reference).component} />    
                    )
                }
            </Desktop>
            <Tablet>
                <NavAnimate data={{logo: Logo, items: listpages}} history={history} />
                <NavSpace />
                {
                    sectionsPage &&
                    sectionsPage.map( (item, index) =>
                        <RouteComponent key={`${item.idSectionPage}_${index}`} path={`${item.page.link}`} idsection={item.idSection} datasection={item.section.data} component={components.find(comp => comp.name === item.section.section_type.reference).component} />    
                    )
                }
            </Tablet>
            <Mobile>
                <NavAnimate data={{logo: Logo, items: listpages}} history={history} />
                <NavSpace />
                {
                        sectionsPage &&
                        sectionsPage.map( (item, index) =>
                            <RouteComponent key={`${item.idSectionPage}_${index}`} path={`${item.page.link}`} idsection={item.idSection} datasection={item.section.data} component={components.find(comp => comp.name === item.section.section_type.reference).component} />    
                        )
                }
            </Mobile>
        </React.Fragment>
    );
}

export default BasicWebPage;