import React from "react";
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const StyledButtonDown = styled(ArrowDropDownIcon)`
    vertical-align: text-bottom;
    cursor: pointer;
    color: ${props => props.active ? props.theme.palette.accent : "inherit"};
`

const StyledButtonUp = styled(ArrowDropUpIcon)`
    vertical-align: text-bottom;
    cursor: pointer;
    color: ${props => props.active ? props.theme.palette.accent : "inherit"};
`

const OrderButton = ({name, active, action}) => {

    const [ order, setOrder ] = React.useState('ASC');

    const changeOrder = () => {
        const newOrder = order === 'ASC' ? 'DESC' : 'ASC';
        setOrder(newOrder);
        action(name, newOrder);
    }

    return (
        order === 'ASC' ?
        <StyledButtonUp active={active} onClick={changeOrder} />
        :
        <StyledButtonDown active={active} onClick={changeOrder} />
    );
}

export default OrderButton;