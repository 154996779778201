import FileDownloadIcon from '@material-ui/icons/GetApp';
import styled from 'styled-components';

const Root = styled.button`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    background: none;
    border: none;
    cursor: pointer;
    user-select: none;
    &:hover {
        transform: scale(1.1);
    }
`

const ExportButton = ({onClick}) => {
    return (
        <Root onClick={onClick}>
            <FileDownloadIcon />
        </Root>
    );
}

export default ExportButton;