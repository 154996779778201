/* General imports */
import React from 'react';
import styled from 'styled-components';

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { theme } from '../../app/config';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';
import TextField from '../../form/TextField'
import TextFieldUI from '@material-ui/core/TextField';

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 400px;
    min-height: 450px;
    height: auto;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    box-sizing: border-box;
    flex-direction: column;
    padding: 0 20px;
`

const Inputbox = styled.div`
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 20px;
    margin: 10px 0;
    box-sizing: border-box;
    height: 48px;
    border-bottom: 1px solid black;
    ${(props) => props.error ? 'border-color: red;' : ''};
    label {
        position: absolute;
        top: ${(props) => props.effect ? '-10px' : '18px' };
        left: 0;
        color: #ccc;
        transition: .5s;
        pointer-events: none;
        font-size: ${(props) => props.effect ? '16px' : '20px'};
        height: 16px;
    }

    input {
        border: none;
        outline: none;
        background: none;
        color: black;
        font-size: 18px;
        width: calc(100% - 35px);
        padding-bottom: 12px;
        margin-top: 16px;
        box-sizing: border-box;
        height: 32px;
    }

    input:focus + label {
        top: -10px;
        color: black;
        font-size: 16px;
    }
`

const StyledButton = styled.button`
    width: calc(${(props) => props.columWidth ? props.columWidth : '100%'} * 0.75);
    height: 44px;
    background-color: ${theme.default.primaryButtonBg};
    color: ${theme.default.primaryButtonText};
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin-top: 25px;
    margin-bottom: 25px;
    outline: none;
`

/* ----- Principal function ----- */
function ModalExchangeStock({ title, textButton, openModal, closeModal, rowData, user, handleUpdate }) {

    const [data, setData ] = React.useState({
        concept: 'Movimiento normal',
        idStockSource: rowData.idStorage,
        idStockDestination: rowData.idStorage,
        quantity: 0
    })
    const [storages, setStorages] = React.useState(null);
    const [addAmount, setAddAmount] = React.useState(rowData.quantity || '');

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    }

    const handleChangeAdd = (e) => {
        setAddAmount(e.target.value);
    }

    const handleKeyUpAdd = (e) => {
        const keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13') {
            const {value} = e.target;
            if(value !== ''){
                if(value.length > 12 ){
                    let newValue = '';
                    if(parseInt(value.substring(0, 2)) === 20){
                        newValue = parseInt(value.substring(7, 12));
                    } else if(parseInt(value.substring(0, 2)) === 21){
                        newValue = parseFloat(value.substring(7, 9) + '.' + value.substring(9, 12));
                    }
                    setData({
                        ...data,
                        quantity: (parseFloat(data.quantity) + parseFloat(newValue)).toFixed(2)
                    });
                    setAddAmount('');
                } else {
                    setData({
                        ...data,
                        quantity: (parseFloat(data.quantity) + parseFloat(value)).toFixed(2)
                    });
                    setAddAmount('');
                }
            }   
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        await handleUpdate(rowData.idProduct, {...data, createdUser: user.idUser});
        closeModal();
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!storages){
            axios.get(`/api/storages`,configHeader)
            .then(storagesData => {
                const storagesList = [];
                storagesData.data.storages.forEach(element => {
                    storagesList.push({
                        name: element.name,
                        value: element.idStorage
                    })
                });
                setStorages(storagesList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ storages ])

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ModalBody onSubmit={submit}>
                        <TextField id="concept" value={data.concept} name="concept" placeholder="Concepto" type="text" fontSize="14px" onChange={handleChange} />
                        <TextField id="idStockSource" value={data.idStockSource} required={true} name="idStockSource" placeholder="Origen" options={storages} type="select" fontSize="14px" onChange={handleChange} />
                        <TextField id="idStockDestination" value={data.idStockDestination} required={true} name="idStockDestination" placeholder="Destino" options={storages} type="select" fontSize="14px" onChange={handleChange} />
                        <TextFieldUI id="addAmount" onFocus={e => e.target.select()} value={addAmount} fullWidth size="medium" margin="normal" name="addAmount" placeholder="Agregar cant." type="number" onKeyUp={handleKeyUpAdd} onChange={handleChangeAdd} />
                        <TextField id="quantity" value={data.quantity} name="quantity" placeholder="TOTAL" type="number" fontSize="14px" readOnly={true} />
                        <StyledButton onClick={submit} > {textButton} </StyledButton>
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalExchangeStock;