/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices, config } from '../../app/config'

/* Components imports */
import NoImage from '../../images/no-image.png'
import FeedbackIcon from '@material-ui/icons/Feedback';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    min-height: 80vh;
    align-items: flex-start;
    justify-content: center;
`

const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    background: #FFF;
`

const ContentBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : "row"};
    box-sizing: border-box;
    width: 100%;
    height: 450px;
    align-items: ${(props) => props.alignItems ? props.alignItems : "flex-start"};;
    justify-content: ${(props) => props.justifyContent ? props.justifyContent : "space-between"};
    @media (max-width: 450px) {
        height: auto;
    }
`

const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: ${(props) => props.width ? props.width : "67.5%"};
    height: 100%;
    box-sizing: border-box;
    padding: 25px;
    background-color: rgba(255,255,255,1);
    margin: ${(props) => props.mobile ? "20px 0" : "0"};
    img {
        align-self: center;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    @media (max-width: 450px) {
        width: 100%;
    }
`

const SideBox = styled(Box)`
    box-sizing: border-box;
    padding: 25px;
    width: ${(props) => props.width ? props.width : "30%"};
    height: auto;
    margin: ${(props) => props.mobile ? "20px 0" : "0"};
    h2 {
        font-size: 20px;
        font-weight: 400;
    }
    h3 {
        margin: 0;
        font-size: 2rem;
        font-weight: 400;
        color: #D1030F;
        align-self: center;
    }
    label {
        font-size: 12px;
        font-weight: 300;
        margin: 10px 0;
    }
    input {
        padding: 5px;
        background: transparent;
    }
    @media (max-width: 450px) {
        width: 100%;
    }
`

const Announcement = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    svg {
        color: lightcoral;
        font-size: 2.5rem;
        margin: 0 10px;
    }
`

const Block = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    opacity: 0.7;
    h4 {
        margin: 5px 0;
        font-size: 1rem;
    }
    p {
        margin: 0;
        font-size: 1rem;
    }
`

const Hr = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 1px;
    line-height: 1px;
    background-color: black;
    opacity: 0.3;
    align-self: center;
    margin: 10px 0;
`

const Vr = styled.div`
    position: relative;
    display: flex;
    width: 1px;
    height: 95%;
    background-color: black;
    opacity: 0.3;
    align-self: center;
`

const AddToCart = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 50px;
    border: 1px solid rgba(0,0,0,0.5);
    background: #d1030f;
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    margin: 15px 0;
    cursor: pointer;
`

const AskStock = styled.a`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 50px;
    border: 1px solid rgba(0,0,0,0.5);
    background: #d1030f;
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    margin: 15px 0;
    cursor: pointer;
    font-weight: 300;
    text-decoration: none;
    &:hover {
        font-weight: 500;
    }
`

const Stock = styled.span`
    color: #000;
    opacity: 0.8;
    margin: 0 10px;
    font-size: 0.8rem;
`

const Description = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    p {
        color: #4a4a4a;
        margin: 0;
    }
`

/* ----- Principal function ----- */
function ProductDescription({productData, cartitems, addToCart, switchSidebarCart}) {

    const [amount, setAmount] = React.useState(1);
    const [inCart, setInCart] = React.useState(false)

    const onAdd = () => {
        const newItem = {
            idProduct: productData.idProduct,
            code: productData.code,
            barcode: productData.barcode,
            name: productData.name,
            description: productData.description,
            img: productData.img,
            costPrice: productData.costPrice,
            salePrice: productData.salePrice,
            category: productData.id_Category,
            currencyUnit: productData.idCurrency_Unit,
            quantityUnit: productData.idQuantity_Unit,
            suppliers: productData. suppliers,
            amount: amount
        }
        addToCart(newItem);
    }

    const getUnit = (name, value) => {
        switch (name) {
            case 'm2':
                return <span style={{margin: 0}}>{`${value} metros cuadrados`}</span>
                break;
            case 'number':
                return <span>{`${value} unidades`}</span>
                break;
        
            default:
                return <span>{value}</span>
                break;
        }
    }

    React.useEffect(() => {
        setInCart(cartitems.findIndex((element) => element.idProduct === parseInt(productData.idProduct)) !== -1)
    }, [cartitems, setInCart])

    

    return(
        <Root>
            <Desktop>
                <Content>
                    <ContentBox>
                        <Box>
                            <img src={productData.img && productData.img.images ? productData.img.images[0] : NoImage} />
                        </Box>
                        <Vr />
                        <SideBox>
                            <h3>{`$${productData.salePrice} ${productData.idCurrency_Unit.value}`}</h3>
                            {
                                !inCart && <label>Cantidad:</label>
                            }
                            <div>
                                {
                                    !inCart && <input disabled={parseInt(productData.stock[0].quantity) < 1}  id="amount" type="number" style={{width: "50px", textAlign: "center"}} max={productData.stock[0].quantity} value={amount} onChange={(e) => setAmount(e.target.value)} ></input>
                                }
                                {
                                    parseInt(productData.stock[0].quantity) < 1 ?
                                    <Stock>Sin stock</Stock>
                                    :
                                    <Stock> Stock: {productData.stock[0].quantity} </Stock>
                                }
                                
                            </div>
                            {
                                parseInt(productData.stock[0].quantity) < 1 ?
                                <AskStock href={`https://api.whatsapp.com/send?phone=${config.whatsapp}&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto ${config.url}/product/${productData.idProduct}`} target="_blank">Consultar stock</AskStock>
                                :
                                inCart ?
                                    <AddToCart onClick={switchSidebarCart} >Ver carrito</AddToCart>
                                    :
                                    <AddToCart onClick={onAdd} >Agregar al carrito</AddToCart>
                                    
                            }
                            {
                                productData.promo &&
                                <React.Fragment>
                                    <Hr />
                                    <Announcement>
                                        <FeedbackIcon />
                                        <Block>
                                            <h4>PROMO:</h4>
                                            <p>{productData.promo}</p>
                                        </Block>
                                    </Announcement>
                                    <Hr />
                                </React.Fragment>
                            }
                        </SideBox>
                    </ContentBox>
                    <Hr />
                    <Description>
                        <p>{productData.description}</p>
                    </Description>
                </Content>
            </Desktop>
            <Tablet>
                <Content>
                    <ContentBox>
                        <Box>
                            <img src={productData.img && productData.img.images ? productData.img.images[0] : NoImage} />
                        </Box>
                        <Vr />
                        <SideBox>
                            <h3>{`$${productData.salePrice} ${productData.idCurrency_Unit.value}`}</h3>
                            {
                                !inCart && <label>Cantidad:</label>
                            }
                            <div>
                                {
                                    !inCart && <input disabled={parseInt(productData.stock[0].quantity) < 1}  id="amount" type="number" style={{width: "50px", textAlign: "center"}} max={productData.stock[0].quantity} value={amount} onChange={(e) => setAmount(e.target.value)} ></input>
                                }
                                {
                                    parseInt(productData.stock[0].quantity) < 1 ?
                                    <Stock>Sin stock</Stock>
                                    :
                                    <Stock> Stock: {productData.stock[0].quantity} </Stock>
                                }
                                
                            </div>
                            {
                                parseInt(productData.stock[0].quantity) < 1 ?
                                <AskStock href={`https://api.whatsapp.com/send?phone=${config.whatsapp}&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto ${config.url}/product/${productData.idProduct}`} target="_blank">Consultar stock</AskStock>
                                :
                                inCart ?
                                    <AddToCart onClick={switchSidebarCart} >Ver carrito</AddToCart>
                                    :
                                    <AddToCart onClick={onAdd} >Agregar al carrito</AddToCart>
                                    
                            }
                            {
                                productData.promo &&
                                <React.Fragment>
                                    <Hr />
                                    <Announcement>
                                        <FeedbackIcon />
                                        <Block>
                                            <h4>PROMO:</h4>
                                            <p>{productData.promo}</p>
                                        </Block>
                                    </Announcement>
                                    <Hr />
                                </React.Fragment>
                            }
                        </SideBox>
                    </ContentBox>
                    <Hr />
                    <Description>
                        <p>{productData.description}</p>
                    </Description>
                </Content>
            </Tablet>
            <Mobile>
                <Content>
                    <ContentBox flexDirection="column" alignItems="center" justifyContent="flex-start">
                        <Box>
                            <img src={productData.img && productData.img.images ? productData.img.images[0] : NoImage} />
                        </Box>
                        <SideBox>
                            <h3>{`$${productData.salePrice} ${productData.idCurrency_Unit.value}`}</h3>
                            {
                                !inCart && <label>Cantidad:</label>
                            }
                            <div>
                                {
                                    !inCart && <input disabled={parseInt(productData.stock[0].quantity) < 1}  id="amount" type="number" style={{width: "50px", textAlign: "center"}} max={productData.stock[0].quantity} value={amount} onChange={(e) => setAmount(e.target.value)} ></input>
                                }
                                {
                                    parseInt(productData.stock[0].quantity) < 1 ?
                                    <Stock>Sin stock</Stock>
                                    :
                                    <Stock> Stock: {productData.stock[0].quantity} </Stock>
                                }
                                
                            </div>
                            {
                                parseInt(productData.stock[0].quantity) < 1 ?
                                <AskStock href={`https://api.whatsapp.com/send?phone=${config.whatsapp}&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto ${config.url}/product/${productData.idProduct}`} target="_blank">Consultar stock</AskStock>
                                :
                                inCart ?
                                    <AddToCart onClick={switchSidebarCart} >Ver carrito</AddToCart>
                                    :
                                    <AddToCart onClick={onAdd} >Agregar al carrito</AddToCart>
                                    
                            }
                            {
                                productData.promo &&
                                <React.Fragment>
                                    <Hr />
                                    <Announcement>
                                        <FeedbackIcon />
                                        <Block>
                                            <h4>PROMO:</h4>
                                            <p>{productData.promo}</p>
                                        </Block>
                                    </Announcement>
                                    <Hr />
                                </React.Fragment>
                            }
                        </SideBox>
                    </ContentBox>
                    <Hr />
                    <Description>
                        <p>{productData.description}</p>
                    </Description>
                </Content>
            </Mobile>
        </Root>
    );
}

export default ProductDescription;