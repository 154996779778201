import React from "react";
import styled from 'styled-components';
import translate from "../../providers/i18n/translate";

const Root = styled.tr`
    width: 100%;
    vertical-align: middle;
    height: fit-content;
    &:hover {
        background-color: rgba(0,0,0,0.1);
    }
`

const Row = ({children}) => {
    return (
        <Root>
            {children}
        </Root>
    );
}

export default Row;