/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices, config } from '../../app/config'
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner';
import Input from '../../form/Input';
import NoImage from '../../images/no-image.png'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

const HeightHeader = '70px'

/* Styled components */
const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

`

const Box = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: 20px;
    align-items: ${(props) => props.alignY ? props.alignY : 'flex-start'};
    justify-content: ${(props) => props.alignX ? props.alignX : 'center'};
    flex-direction: column;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: ${HeightHeader};
    padding: 0 25px;
    box-sizing: border-box;
    background: #234;
`

const HeaderTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
`

const HeaderButtons = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
`

const ButtonHeader = styled.button`
    margin: 0 5px;
`

const CancelImg = styled.div`
    position: absolute;
    display: none;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    background-color: #ff000080;
    align-items: center;
    justify-content: center;
    opacity: 0;
    user-select: none;
    cursor: pointer;
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 400px;
    height: 400px;
    margin-top: 20px;
    margin-bottom: 10px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${(props) => props.active ?
    `&:hover {
        ${CancelImg} {
            display: flex;
            opacity: 1;
        }
        img {
            opacity: 0.4;
        }
    }`
    :
    ''
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 90%;
    box-sizing: border-box;
`


/* ----- Principal function ----- */
function FooterSectionSettings({match, history, user}) {

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const { idPage, idSection } = match.params;

    const [ image, setImage ] = React.useState('')

    const [data, setData] = React.useState(null);

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        e.preventDefault()
        const { name } = e.currentTarget.dataset;
        if(image !== '' ){
            if(!data[name]){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, configHeader)
                .then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setData({
                        ...data,
                        [name]: url_img
                    })
                })
                .catch(error => {
                    alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, configHeader)
                    .then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setData({
                            ...data,
                            [name]: url_img
                        })   
                    })
                    .catch(error => {
                        alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alert("Debe selecionar una imagen...")
        }
    }

    const cancelImage = (e) => {
        e.preventDefault()
        const { name } = e.currentTarget.dataset;
        setData({
            ...data,
            [name]:''
        })
    }

    const onBack = () => {
        history.push(config.webeditorpath+'/page/'+idPage)
    }

    const onSave = () => {
        const saveData = {
            data: data,
            updatedUser: user.idUser
        }
        axios.put("/api/updatedatasection/"+idSection, saveData, configHeader)
        .then(res => {
            history.push(config.webeditorpath+'/page/'+idPage)
        })
        .catch(error => {
            alert('Hubo un error...')
        })
    }

    const handleChange = (name, value) => {
        setData({ 
            ...data,
            [name]: value 
        });
    }

    React.useEffect(() => {
        if(!data){
            axios.get('/api/sections/' + idSection,configHeader)
            .then(sectionData => {
                if(parseInt(Object.keys(sectionData.data.section.data).length) > 0){
                    setData(sectionData.data.section.data);
                } else {
                    setData({
                        img: '',
                        title: '',
                        addresses: '',
                        phones: '',
                        emails: '',
                        googleMap: '',
                        instagram: '',
                        facebook: '',
                        firstColor: '',
                        colorText1: '',
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idSection])

    return(
        <React.Fragment>
            <Header>
                {
                    <HeaderTitle>CONFIGURACIÓN FOOTER SECTION</HeaderTitle>
                }
                <HeaderButtons><ButtonHeader onClick={onBack}>VOLVER</ButtonHeader> <ButtonHeader onClick={onSave}>GUARDAR</ButtonHeader> </HeaderButtons>
            </Header>
            <Desktop>
                    {
                        !data ? <Spinner spinnerType='ThreeDots' />
                        :
                        <ContentBox>
                            <Box width='50%' alignX='center' alignY='center'>
                                <ImageContainer active={data.img ? true : false}>
                                    <img src={data.img ? data.img : NoImage} alt="Imagen subida" />
                                    {
                                        data.img && <CancelImg data-name='img' onClick={cancelImage}> X </CancelImg>
                                    }
                                </ImageContainer>
                                <UploadImage>
                                    <input style={{fontSize: '0.75rem', width: '100%'}} id="img" name="img" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img' onClick={handleFileUpload} />
                                </UploadImage>
                            </Box>
                            <Box width='50%'>
                                <Input name='title' data={data} width='500px' border={true} fontSize='20px' onHandleChange={handleChange} type='text' effectColor='blue' placeholder='TÍTULO' required={true} inputMargin='20px' error={data[`title_error`]} />
                                <Input name='addresses' data={data} width='500px' border={true} fontSize='20px' onHandleChange={handleChange} type='text' effectColor='blue' placeholder='DIRECCIONES (separa por ;)' required={true} inputMargin='20px' error={data[`addresses_error`]} />
                                <Input name='phones' data={data} width='500px' border={true} fontSize='20px' onHandleChange={handleChange} type='text' effectColor='blue' placeholder='TELÉFONOS (separa por ;)' required={true} inputMargin='20px' error={data[`phones_error`]} />
                                <Input name='emails' data={data} width='500px' border={true} fontSize='20px' onHandleChange={handleChange} type='text' effectColor='blue' placeholder='CORREOS ELECTRÓNICOS (separa por ;)' required={true} inputMargin='20px' error={data[`emails_error`]} />
                                <Input name='googleMap' data={data} width='500px' border={true} fontSize='20px' onHandleChange={handleChange} type='text' effectColor='blue' placeholder='GOOGLE MAP' required={true} inputMargin='20px' error={data[`googleMap_error`]} />
                                <Input name='instagram' data={data} width='500px' border={true} fontSize='20px' onHandleChange={handleChange} type='text' effectColor='blue' placeholder='INSTAGRAM' required={true} inputMargin='20px' error={data[`instagram_error`]} />
                                <Input name='facebook' data={data} width='500px' border={true} fontSize='20px' onHandleChange={handleChange} type='text' effectColor='blue' placeholder='FACEBOOK' required={true} inputMargin='20px' error={data[`facebook_error`]} />
                                <Input name='firstColor' data={data} width='200px' onHandleChange={handleChange} type='color' required={true} placeholder='COLOR FONDO' />
                                <Input name='colorText1' data={data} width='200px' onHandleChange={handleChange} type='color' required={true} placeholder='COLOR TEXTO' />
                            </Box>
                        </ContentBox>
                    }
            </Desktop>
            <Tablet>
                <ContentBox>
                    TABLET FOOTER SECTION SETTINGS
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    MOBILE FOOTER SECTION SETTINGS
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

export default connect(mapState)(FooterSectionSettings);