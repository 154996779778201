/* General imports */
import React from 'react';
import styled from 'styled-components';
import { alertActions } from '../alert/AlertActions';
import { connect } from 'react-redux';
import axios from 'axios';
import  { authHeader } from '../app/auth-header';

/* Components imports */
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ModalInvoiceData from './ModalInvoiceData';


/* Styled components */
const Root = styled.form`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    background-color: white;
`

const BoxContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`

const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;
`

const Footer = styled.div`
    position: relative;
    display: flex;
    grid-column: 1/3;
    align-items: center;
    justify-content: center;
`


const IconAction = styled.button`
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    top: 5px;
    right: ${(props) => props.right ? props.right : '10px'};
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: transparent;
    svg {
        font-size: 35px;
        cursor: pointer;
        z-index: 999;
    }
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

const StyledButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

/* ----- Principal function ----- */
function NewCustomer({user, rowData, alertMessage, history }) {
 
    const [ dataForm, setDataForm ] = React.useState(rowData ? 
        {
            ...rowData, 
            invoiceData: rowData.roles[0].userRole.invoiceData
        } : 
        { 
            firstname: '',
            lastname: '',
            documentType: '',
            documentNumber: '',
            birthday: '',
            tel: '',
            email: '',
            username: '',
            password: '',
            repassword: '',
            invoiceData: null
        })

    const [documentTypes, setDocumentTypes] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const handleAdd = (e) => {
        e.preventDefault();
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }    

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
      }

    const cancel = (e) => {
        e.preventDefault();
        history.push('/admin/customers')
    }

    const save = (e) => {
        e.preventDefault();
        if(rowData){
            axios.put( `/api/customers/${rowData.idUser}/${rowData.roles[0].idRole}` , {
                ...dataForm,
                updatedUser: user.idUser
            }, configHeader)
            .then(response => {
                alertMessage("Cliente actualizado");
                history.push('/admin/customers');
            })
            .catch(error => {
                alertMessage("Hubo un error al actualizar el cliente.");
                console.log(error);
            })
        } else {
            if(dataForm.password === dataForm.repassword){
                axios.post( '/api/customers' , {
                    ...dataForm,
                    createdUser: user.idUser
                }, configHeader)
                .then(response => {
                    alertMessage("Cliente creado");
                    history.push('/admin/customers');
                })
                .catch(error => {
                    alertMessage("Hubo un error al crear el cliente.");
                    console.log(error);
                })
            } else {
                alertMessage("Verifique las contraseñas.")
            } 
        }
    } 

    const handleInvoiceData = (dataInvoice) => {
        setDataForm({
            ...dataForm,
            invoiceData: dataInvoice
        })
    }

    React.useEffect(() => {
        if(!documentTypes){
            axios.get('/api/valuelist/group/documentType',configHeader)
            .then(documentTypesData => {
                const documentTypes_temp = [];
                documentTypesData.data.valueslist.forEach(option => {
                    documentTypes_temp.push({
                    name: option.value,
                    key: option.key,
                    value: option.idValueList
                    })
                });
                setDocumentTypes(documentTypes_temp);
            })
            .catch(err => {
                alert("Hubo un error al cargar los tipos de documentos.")
            })
        }
    }, [])

    return(
        <React.Fragment>
        <Root onSubmit={save}>
            <IconAction type="submit" right='60px'><SaveIcon /></IconAction>
            <IconAction ><CancelIcon onClick={cancel} /></IconAction>

            <BoxContainer>
                <Box>
                    <TextField fullWidth margin="normal" id="firstname" name="firstname" label="Nombres" type='text' value={dataForm.firstname} onChange={onChange} required />
                    <TextField fullWidth margin="normal" id="lastname" name="lastname" label="Apellidos" type='text' value={dataForm.lastname} onChange={onChange} required />
                    <StyledFormControl required>
                        <InputLabel id="documentType-label"> Tipo documento </InputLabel>
                        <Select
                            native
                            labelId="documentType-label"
                            onChange={onChange}
                            required
                            inputProps={{
                                id:"documentType",
                                name:"documentType",
                                value:dataForm.documentType
                            }}
                        >
                            <option value="" />
                            {
                                documentTypes &&
                                documentTypes.map((dt, index) => 
                                    <option key={`documentType_value_option_${index}`} value={dt.value}> {`${dt.key} (${dt.name})`} </option>
                                )
                            }
                        </Select>
                    </StyledFormControl>
                    <TextField fullWidth margin="normal" id="documentNumber" name="documentNumber" label="Nro. documento" type='text' value={dataForm.documentNumber} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="birthday" name="birthday" label="Fecha de nacimiento" type='date' value={dataForm.birthday} onChange={onChange} />
                </Box>
                <Box>
                    <TextField fullWidth margin="normal" id="tel" name="tel" label="Tel/Cel" type='text' value={dataForm.tel} onChange={onChange} />
                    <TextField fullWidth margin="normal" id="email" name="email" label="Correo electrónico" type='text' value={dataForm.email} onChange={onChange} required />
                    <TextField fullWidth margin="normal" id="username" name="username" label="Nombre de usuario" type='text' value={dataForm.username} onChange={onChange} required />
                    {
                        !rowData && <TextField fullWidth margin="normal" id="password" name="password" label="Contraseña" type="password" value={dataForm.password} autoComplete="current-password" onChange={onChange} required />
                    }
                    {
                        !rowData && <TextField fullWidth margin="normal" id="repassword" name="repassword" label="Repetir la contraseña" type="password" value={dataForm.repassword} onChange={onChange} required />
                    }
                </Box>
                <Footer>
                    <StyledButton onClick={handleAdd}>DATOS DE FACTURACIÓN</StyledButton>
                </Footer>
                
            </BoxContainer>            
        </Root>
        {
            open && <ModalInvoiceData title='DATOS DE FACTURACIÓN' textButton='APLICAR' rowData={dataForm} user={user} handleAction={handleInvoiceData} openModal={open} closeModal={handleClose} alertMessage={alertMessage} />
        }
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

const actionCreators = {
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(NewCustomer);