export const printTagsConstants = {
    SET: 'PRINT_TAGS_SET',
    ADD: 'PRINT_TAGS_ADD',
    ADD_ALL: 'PRINT_TAGS_ADD_ALL',
    REMOVE: 'PRINT_TAGS_REMOVE',
    REMOVE_ALL: 'PRINT_TAGS_REMOVE_ALL',
    SUCCESS: 'PRINT_TAGS_SUCCESS',
    ERROR: 'PRINT_TAGS_ERROR',
    FETCHING: 'PRINT_TAGS_FETCHING'
};
