/* General imports */
import React from 'react'
import styled, {keyframes} from 'styled-components'
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';
import Tada from 'react-reveal/Tada';

/* Components imports */
import ProductCard from './ProductCard'

/* Styled components */
const moveSlideshow = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

const Root = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0 83px;
    margin: 25px 0;
    @media (max-width: 600px) {
        padding: 0 20px;
    }
`

const Title = styled.h2`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    text-align: left;
    font-weight: 300;
    font-size: 2.5rem;
    color: #001554;
    margin: auto 0;
    @media (max-width: 600px) {
        font-size: 1.8rem;
    }
`

const ContentList = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 400px;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
`

const Band = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: 100%;
    animation: ${moveSlideshow} ${props => props.amount * 3}s linear infinite;
`

/* ----- Principal function ----- */
function ProductList({idsection}) {  

    const [productsListData, setProductsListData] = React.useState(null);
    const [ productsData, setProductsData ] = React.useState(null);

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!productsListData){
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setProductsListData(sectionData.data.section.data);
                let url = parseInt(sectionData.data.section.data.productFilter) === -1 ?
                     sectionData.data.section.data.tagsFilter === "" ?
                        '/api/products' 
                        :
                        `/api/products?tags=${sectionData.data.section.data.tagsFilter}`   
                     : 
                     sectionData.data.section.data.tagsFilter === "" ?
                        `/api/products?idCategory=${sectionData.data.section.data.productFilter}`
                        :
                        `/api/products?idCategory=${sectionData.data.section.data.productFilter}&tags=${sectionData.data.section.data.tagsFilter}`;
                if(parseInt(sectionData.data.section.data.productFilter) === -1){
                    if(sectionData.data.section.data.tagsFilter === ""){
                        if(parseInt(sectionData.data.section.data.trademark) !== -1){
                            url = url + `?idTrademark=${sectionData.data.section.data.trademark}`
                            if(parseInt(sectionData.data.section.data.supplier) !== -1){
                                url = url + `&idSupplier=${sectionData.data.section.data.supplier}`
                            }
                        } else {
                            if(parseInt(sectionData.data.section.data.supplier) !== -1){
                                url = url + `?idSupplier=${sectionData.data.section.data.supplier}`
                            }
                        }
                        
                    } else {
                        if(parseInt(sectionData.data.section.data.trademark) !== -1){
                            url = url + `&idTrademark=${sectionData.data.section.data.trademark}`
                        }
                        if(parseInt(sectionData.data.section.data.supplier) !== -1){
                            url = url + `&idSupplier=${sectionData.data.section.data.supplier}`
                        }
                    }
                }                        
                
                if(!productsData){
                    console.log(url)
                    axios.get(url,configHeader)
                    .then(productData => {
                        setProductsData(productData.data.rows);
                    })
                    .catch(err => {
                        alert('Hubo un error...');
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [productsData, productsListData])

    return(
        productsListData && productsData &&
        <Root>
            <Tada delay={300}>
                <Title>{productsListData.title}</Title>
            </Tada>
           <ContentList>
                <Band amount={productsData.length}>
                    {
                        productsData.map((product, index) =>
                            <ProductCard key={`product_${index}`} productInfo={product} />
                        )
                    }
                </Band>
           </ContentList>
        </Root>
    );
}

export default ProductList;