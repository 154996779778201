export const config = {
    companyName: "Dietética Santa Lucía",
    url: "http://localhost:3020",
    urlBaseApi: "http://localhost:3021/api",
    port_backend: 3021,
    port_frontend: 3020,
    copyright: "Mole Development",
    webeditorpath: "/admin/webeditor",
    cryptoKey: "cryptomole2020",
    whatsapp: +5492494514627,
    facebook: "",
    instagram: "",
    idCustomerRole: 5,
    inquiryTimeout: process.env.REACT_APP_INQUIRY_TIMEOUT,
    enabledAfip: process.env.REACT_APP_ENABLED_AFIP === "true"
};

export const theme = {
    default: {
        primary: "#21282d",
        second: "#871237",
        third: "#F52C3E",
        fourth: "#242A38",
        sidebarBackground: "#21282d",
        sidebarHeaderBackground: "#21282d",
        sidebarBottonMenuBackground: "white",
        darkBackground: "#040404DE",
        redButton: "#FA4248",
        whiteText: "#FFFFFF",
        greyText: "#444",
        redText: "#FC3535",
        primaryButtonBg: "#FA4248",
        primaryButtonText: "#FFFFFF",
        secundaryButtonBg: "#FA4248",
        secundaryButtonText: "#FFFFFF",
    },
};

export const layoutConfig = {
    header: "4rem",
};

export const devices = {
    mobileMaxWidth: 767,
    tabletMinWidth: 768,
    tabletMaxWidth: 991,
    desktopMinWidth: 992,
    defaultMinWidth: 768,
};

export const receiverInvoiceData = {
    receiverCuit: 20333569893,
    receiverBusinessName: "Mole Media",
    receiverCommercialAddress: "Bereterbide 762",
    receiverIvaCondition: 7,
    saleCondition: 10,
};

export const AFIPDocumentTypes = [
    {
        Id: 80,
        Desc: "CUIT",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 86,
        Desc: "CUIL",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 87,
        Desc: "CDI",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 89,
        Desc: "LE",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 90,
        Desc: "LC",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 91,
        Desc: "CI Extranjera",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 92,
        Desc: "en trámite",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 93,
        Desc: "Acta Nacimiento",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 95,
        Desc: "CI Bs. As. RNP",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 96,
        Desc: "DNI",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 94,
        Desc: "Pasaporte",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 0,
        Desc: "CI Policía Federal",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 1,
        Desc: "CI Buenos Aires",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 2,
        Desc: "CI Catamarca",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 3,
        Desc: "CI Córdoba",
        FchDesde: "20080725",
        FchHasta: "NULL",
    },
    {
        Id: 4,
        Desc: "CI Corrientes",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 5,
        Desc: "CI Entre Ríos",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 6,
        Desc: "CI Jujuy",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 7,
        Desc: "CI Mendoza",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 8,
        Desc: "CI La Rioja",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 9,
        Desc: "CI Salta",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 10,
        Desc: "CI San Juan",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 11,
        Desc: "CI San Luis",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 12,
        Desc: "CI Santa Fe",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 13,
        Desc: "CI Santiago del Estero",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 14,
        Desc: "CI Tucumán",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 16,
        Desc: "CI Chaco",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 17,
        Desc: "CI Chubut",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 18,
        Desc: "CI Formosa",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 19,
        Desc: "CI Misiones",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 20,
        Desc: "CI Neuquén",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 21,
        Desc: "CI La Pampa",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 22,
        Desc: "CI Río Negro",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 23,
        Desc: "CI Santa Cruz",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 24,
        Desc: "CI Tierra del Fuego",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
    {
        Id: 99,
        Desc: "Doc. (Otro)",
        FchDesde: "20080728",
        FchHasta: "NULL",
    },
];
