import React from "react";
import axios from "axios";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useIntl } from 'react-intl';
import styled from "styled-components";

const StyledCheckbox = styled(Checkbox)`
    padding: 1px 9px;
`

const CheckFilter = ({filter, values, search, translate, handleChange}) => {

    const intl = useIntl();

    const onChange = (e) => {
        const { value } = e.target;
        handleChange(filter.name, value);
    }

    const [ filterList, setFilterList ] = React.useState();

    const [currentFilterList, setCurrentFilterList] = React.useState();

    const [filterSearch, setFilterSearch] = React.useState(null);


    React.useEffect(() => {
        if(!currentFilterList && filterList){
            setCurrentFilterList(filterList);
        }
        if(!filterList){
            if(filter.src){
                axios.get(filter.src)
                .then(result => {
                    setFilterList(result.data);
                })
                .catch(err => {
                    console.log(err);
                })
            } else {
                setFilterList(filter.options); // Set options manually
            }

        }
    }, [filterList]);

    React.useEffect(() => {
        if(filterSearch === null){
            search != null && setFilterSearch(search);
        }
        else{
            if(filterList){
                const newFilters = filterList.filter(f => f.value && f.value.toLowerCase().includes(filterSearch.toLowerCase()) );
                setCurrentFilterList(newFilters);
            }
        }
    }, [filterSearch]);


    search != null && filterSearch != null && filterSearch != search && setFilterSearch(search);



    return (
        <React.Fragment>
            {
                currentFilterList && values && currentFilterList.map((fil, index) =>
                <FormControlLabel
                    key={`checkbox_option_${index}`}
                    label={translate ? intl.formatMessage({ id: fil.value }) : fil.value}
                    control={<StyledCheckbox size="filled" value={fil.id} checked={values.includes(fil.id)} onChange={onChange} />}
                />
                )
            }
        </React.Fragment>
    );
}

export default CheckFilter;
