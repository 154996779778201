/* General imports */
import React from 'react'
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import Zoom from 'react-reveal/Zoom';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled Components */
const Root = styled.div`
    width:100%;
    height:auto;
    display: flex;
    position: relative;
`

const Row = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '100vh'};
    display:flex;
    align-items: center;
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
`;

const Column = styled.div`
    width: ${(props) => props.width ? props.width : '50%'};
    height:100%;
    font-family: 'Josefin Sans', sans-serif;
    box-sizing: border-box;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding: ${(props) => props.padding ? props.padding : 0};
    background: ${(props) => props.background ? props.background : 'transparent'};
    ${(props) => props.backgroundImage ? `background-image: url(${props.backgroundImage})` : ''};
    ${(props) => props.parallax ? 'background-attachment: fixed' : ''};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    font-size: ${(props) => props.fontSize ? props.fontSize : '1.75rem'};
    text-align:center;
    line-height: ${(props) => props.fontSize ? `calc(${props.fontSize}*1.42)` : '2.5rem'};
    h3 {
        color: #FFFFFF;
        text-shadow: black 0.1em 0.1em 0.2em;
        text-align:center;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '3rem'};
    }
`

/* Principal function*/
/*
Params
title
switchColumns
text
img
*/
function TwoColums({idsection}) {

    const [twoColumnsData, setTwoColumnsData] = React.useState(null)

    React.useEffect(() => {
        if(!twoColumnsData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setTwoColumnsData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [twoColumnsData, idsection])

    return (
        <Root>
            <Desktop>
                {
                    !twoColumnsData ? <Spinner spinnerType='ThreeDots' />
                    :
                    twoColumnsData.switchColumns === 1 ?
                        <Row>
                            <Column padding='0 3rem' background='linear-gradient(90deg, rgba(135,18,55,1) 0%, rgba(245,44,62,1) 100%)'>
                                <Zoom>
                                    <h3> {twoColumnsData.title} </h3> {twoColumnsData.text}
                                </Zoom>
                            </Column>
                            <Column backgroundImage={twoColumnsData.img} parallax={twoColumnsData.parallax}></Column>
                        </Row>
                        :
                        <Row>
                            <Column backgroundImage={twoColumnsData.img} parallax={twoColumnsData.parallax}></Column>
                            <Column padding='0 3rem' background='#A30D0D'>
                                <Zoom>
                                    <h3> {twoColumnsData.title} </h3> {twoColumnsData.text}
                                </Zoom>
                            </Column>
                        </Row>
                }
            </Desktop>
            <Tablet>
                {
                    !twoColumnsData ? <Spinner spinnerType='ThreeDots' />
                    :
                    twoColumnsData.switchColumns === 1 ?
                        <Row>
                            <Column padding='0 3rem' background='#A30D0D' fontSize='0.75rem'>
                                <h3> {twoColumnsData.title} </h3> {twoColumnsData.text}
                            </Column>
                            <Column backgroundImage={twoColumnsData.img} parallax={twoColumnsData.parallax}></Column>
                        </Row>
                        :
                        <Row>
                            <Column backgroundImage={twoColumnsData.img} parallax={twoColumnsData.parallax}></Column>
                            <Column padding='0 3rem' background='#A30D0D' fontSize='0.75rem'>
                                <h3> {twoColumnsData.title} </h3> {twoColumnsData.text}
                            </Column>
                        </Row>
                }
            </Tablet>
            <Mobile>
                {
                    !twoColumnsData ? <Spinner spinnerType='ThreeDots' />
                    :
                    <Row height='125vh' flexDirection='column'>
                        <Column width='100%' padding='0 3rem' background='#A30D0D' fontSize='0.75rem'>
                            <h3> {twoColumnsData.title} </h3> {twoColumnsData.text}
                        </Column>
                        <Column width='100%' backgroundImage={twoColumnsData.img} parallax={twoColumnsData.parallax}></Column>
                    </Row>
                }
            </Mobile>
        </Root>
    )
}

export default TwoColums;