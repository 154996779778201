import { printTagsConstants } from "./printTagsConstants";

const initialState = {
  tags: [],
  selectedItems: new Set(),
};

export function printTagsReducer(state = initialState, action) {
  switch (action.type) {
    case printTagsConstants.SET:
      return {
        ...state,
        tags: action.items
      }
    case printTagsConstants.ADD:
      return {
        ...state,
        selectedItems: action.newSelectedItems,
        tags: action.newTags
      }
    case printTagsConstants.ADD_ALL:
      return {
        ...state,
        selectedItems: action.newSelectedItems,
        tags: action.newTags
      }
    case printTagsConstants.REMOVE:
      return {
        ...state,
        selectedItems: action.newSelectedItems,
        tags: action.newTags
      }
    case printTagsConstants.REMOVE_ALL:
      return {
        ...state,
        selectedItems: action.newSelectedItems,
        tags: action.newTags
      }
    default:
      return state
  }
}
