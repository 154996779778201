/* General imports */
import React from "react";
import styled from "styled-components";

import InputChange from "./InputChange";

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  thead {
    background: aliceblue;
  }
  th,
  td {
    border: 1px solid grey;
    height: 30px;
    text-align: center;
  }
  tr {
    height: 30px;
  }
  tfoot {
    background: aliceblue;
  }
`;

/* ----- Principal function ----- */
function ItemsTable({ items, changeAmount, deleteItem, idSupplier }) {
  const onDelete = (e) => {
    deleteItem(e.target.dataset.index);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th style={{width: "5%"}}>#</th>
          <th style={{width: "15%"}}>Código</th>
          <th style={{width: "35%"}}>Nombre</th>
          <th style={{width: "15%"}}>Categoría</th>
          <th style={{width: "15%"}}>Precio actual proveedor</th>
          <th style={{width: "15%"}}>Precio Nuevo</th>
        </tr>
      </thead>
      <tbody>
        {items &&
          items.map((item, index) => {
            const prov_price =
              idSupplier &&
              item.suppliers.find((s) => s.idSupplier == idSupplier)
                ?.productSupplierData.price
                ? item.suppliers.find((s) => s.idSupplier == idSupplier)
                    ?.productSupplierData.price
                : 0;
            return (
              <tr key={`item_${index}`}>
                <td>{index}</td>
                <td>{item.barcode && item.barcode !== "" ? item.barcode : item.code}</td>
                <td>{item.name}</td>
                <td>{item.category.name}</td>
                <td>{prov_price}</td>
                <td>
                  <InputChange
                    changeAmount={changeAmount}
                    index={index}
                    inputvalue={item.newPrice}
                  />
                </td>
                <td
                  data-index={index}
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={onDelete}
                >
                  X
                </td>
              </tr>
            );
          })}
      </tbody>
      <tfoot></tfoot>
    </Table>
  );
}
export default ItemsTable;
