/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices, config } from '../../app/config'
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import NoImage from '../../images/no-image.png'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Spinner from '../../spinners/LoaderSpinner'
import Input from '../../form/Input'


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}
const HeightHeader = '70px'

/* Styled components */

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: ${HeightHeader};
    padding: 0 25px;
    box-sizing: border-box;
    background: #234;
`

const HeaderTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
`

const HeaderButtons = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
`

const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - ${HeightHeader});
    align-items: center;
    justify-content: center;
`

const Box = styled.div`
    position: relative;
    display: flex;
    width: 50%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
    padding: 20px;
`

const ButtonHeader = styled.button`
    margin: 0 5px;
`

const CancelImg = styled.div`
    position: absolute;
    display: none;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    background-color: #ff000080;
    align-items: center;
    justify-content: center;
    opacity: 0;
    user-select: none;
    cursor: pointer;
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 60%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${(props) => props.active ?
    `&:hover {
        ${CancelImg} {
            display: flex;
            opacity: 1;
        }
        img {
            opacity: 0.4;
        }
    }`
    :
    ''
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

const CheckOneButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: ${(props) => props.width ? props.width : '100%'};
    margin: ${(props) => props.margin ? props.margin : 0};
    label {
        margin-left: 5px;
    }
`

/* ----- Principal function ----- */
function TwoColumsSettings({match, history, user}) {

    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    const { idPage, idSection } = match.params

    const [ image, setImage ] = React.useState('')

    const [data, setData] = React.useState(null)

    const onBack = () => {
        history.push(config.webeditorpath+'/page/'+idPage)
    }

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    }

    const handleCheckSwitchColumns = (e) => {
        const { name, checked } = e.target;
        setData({
            ...data,
            [name]: checked ? 1 : 0
        })
    }

    const handleCheckParallax = (e) => {
        const { name, checked } = e.target;
        setData({
            ...data,
            [name]: checked ? 1 : 0
        })
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        e.preventDefault()
        const { name } = e.currentTarget.dataset;
        if(image !== '' ){
            if(!data[name]){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, configHeader)
                .then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setData({
                        ...data,
                        [name]: url_img
                    })
                })
                .catch(error => {
                    alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, configHeader)
                    .then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setData({
                            ...data,
                            [name]: url_img
                        })   
                    })
                    .catch(error => {
                        alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alert("Debe selecionar una imagen...")
        }
    }

    const onSave = () => {
         const saveData = {
            data: data,
            updatedUser: user.idUser
        }
        
        axios.put("/api/updatedatasection/"+idSection, saveData, configHeader)
        .then(res => {
            history.push(config.webeditorpath+'/page/'+idPage)
        })
        .catch(error => {
            alert('Hubo un error...')
        })
    }

    const cancelImage = (e) => {
        e.preventDefault()
        const { name } = e.currentTarget.dataset;
        setData({
            ...data,
            [name]:''
        })
    }

    React.useEffect(() => {
        if(!data){
            axios.get('/api/sections/' + idSection,configHeader)
            .then(sectionData => {
                if(parseInt(Object.keys(sectionData.data.section.data).length) > 0){
                    setData(sectionData.data.section.data);
                } else {
                    setData({
                        switchColumns: 0,
                        title: '',
                        text: '',
                        img: '',
                        parallax: 0
                    })
                }
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [data, idSection])

    return(
        <React.Fragment>
            <Header>
                {
                    <HeaderTitle>CONFIGURACIÓN TWO COLUMNS</HeaderTitle>
                }
                <HeaderButtons><ButtonHeader onClick={onBack}>VOLVER</ButtonHeader> <ButtonHeader onClick={onSave}>GUARDAR</ButtonHeader> </HeaderButtons>
            </Header>
            <Desktop>
                {
                    !data ? <Spinner spinnerType='ThreeDots' />
                    :
                    <ContentBox>
                        <Box>
                            <ImageContainer active={data.img ? true : false}>
                                <img src={data.img ? data.img : NoImage} alt="Imagen subida" />
                                {
                                    data.img && <CancelImg data-name='img' onClick={cancelImage}> X </CancelImg>
                                }
                            </ImageContainer>
                            <UploadImage>
                                <input style={{fontSize: '0.75rem', width: '100%'}} id="img" name="img" type="file" onChange={onFileChange} /><CloudUploadIcon data-name='img' onClick={handleFileUpload} />
                            </UploadImage>
                        </Box>
                        <Box>
                            <Input name='title' data={data} width='90%' border={true} fontSize='20px' onHandleChange={handleChange} type='text' effectColor='blue' placeholder='TÍTULO' required={true} inputMargin='18px' error={data[`title_error`]} />
                            <Input name='text' data={data} border={true} width='90%' height='250px' onHandleChange={handleChange} type='textarea' rows={8} columns={50} effectColor='blue' placeholder='TEXTO' required={true} inputMargin='18px' error={data[`text_error`]} />
                            <CheckOneButton width='90%' margin='20px 0'>
                                <input id='switchColumns' name='switchColumns' checked={data.switchColumns === 0 ? false : true} type='checkbox' onChange={handleCheckSwitchColumns} />
                                <label>Intercambiar columnas</label>
                            </CheckOneButton>
                            <CheckOneButton width='90%' margin='20px 0'>
                                <input id='parallax' name='parallax' checked={data.parallax === 0 ? false : true} type='checkbox' onChange={handleCheckParallax} />
                                <label>Efecto Parallax</label>
                            </CheckOneButton>
                        </Box>
                    </ContentBox>
                }
                
            </Desktop>
            <Tablet>
                <ContentBox>
                    TABLET TWOCOLUMNS
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    MOBILE TWOCOLUMNS
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { user } = state.login;
    return { user };
}

export default connect(mapState)(TwoColumsSettings);