/* General imports */
import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
`

const Content = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    @media print{
      display: table;
    }
`

const ContentProduct = styled.div`
    display: flex;
    float: left;
    box-sizing: border-box;
    position: relative;
    height: 4.74cm;
    width: 50%;
    height: 4.74cm;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid black;
    padding: 20px 10px 10px 10px;
      page-break-after: always;
      page-break-inside: avoid;
    background-color: ${props => props.brak ? 'red' : ''};
   `


const Product = styled.span`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: ${props => props.small ? '1.5em' : '1.75em'};
    font-weight: 700;
`
const Brand = styled.span`
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  font-size: 1em;
  font-weight: 900;
`

const Price = styled.span`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 2em;
    font-weight: 700;
`
const Logo = styled.img`
  width: 45%;
  height: auto;
`


const Tags = ({ ref, tags }) => {

    const checkIfEmpty = (str) => {
        if (str?.trim()) {
            return (true)
        }
        return (false)
    }

    return (
        <Root   ref={ref}>
            <Content>
                {
                    tags?.map((product, index) =>
                        <ContentProduct key={`product_${index}`} index={index}>
                            <Brand>"{product.trademark ? product.trademark.name.toLowerCase() : 'sin marca'}"</Brand>
                            <Product small={checkIfEmpty(product.alternativeName) ? product.alternativeName.length > 35 && true : product.name.length > 35 && true}>
                                {checkIfEmpty(product.alternativeName) ? product.alternativeName : product.name}
                            </Product>
                            <Price>
                                <Logo src="/DIETETICA_SANTA_LUCIA_LOGO_SMALL.jpg" />
                                ${`${product.salePrice}`}.-
                            </Price>
                        </ContentProduct>)
                }
            </Content>
        </Root>
    );
}

export default Tags;

