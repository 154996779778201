import BasicWebpage from '../webpage/BasicWebPage'
import Home from '../home/Home'
import Login from '../login/Login'
import Register from '../register/Register'
import Dashboard from '../dashboard/Dashboard'
import ShortcutScreen from '../shortcutScreen/ShortcutScrenn'
import Invoices from '../invoices/Invoices'
import Professionals from '../professionals/Professionals'
import Patients from '../patients/Patients'
import WebEditor from '../webeditor/WebEditor'
import PageEditor from '../pageEditor/PageEditor'
import GeneralConfig from '../webeditor/GeneralConfig'
import PageConfig from '../webeditor/PageConfig'
import Basic from '../sectionsTypes/Basic/Basic'
import BasicSettings from '../sectionsTypes/Basic/BasicSettings'
import Carousel from '../sectionsTypes/Carousel/Carousel'
import CarouselSettings from '../sectionsTypes/Carousel/CarouselSettings'
import ButtonsAccess from '../sectionsTypes/ButtonsAccess/ButtonsAccess'
import ButtonsAccessSettings from '../sectionsTypes/ButtonsAccess/ButtonsAccessSettings'
import TwoColumns from '../sectionsTypes/TwoColumns/TwoColumns'
import TwoColumnsSettings from '../sectionsTypes/TwoColumns/TwoColumnsSettings'
import ThreeServices from '../sectionsTypes/ThreeServices/ThreeServices'
import ThreeServicesSettings from '../sectionsTypes/ThreeServices/ThreeServicesSettings'
import Objetives from '../sectionsTypes/Objetives/Objetives'
import ObjetivesSettings from '../sectionsTypes/Objetives/ObjetivesSettings'
import ProductList from '../sectionsTypes/ProductList/ProducList'
import ProductListSettings from '../sectionsTypes/ProductList/ProductListSettings'
import ProductTypeSection from '../sectionsTypes/Product/Product'
import ProductTypeSectionSettings from '../sectionsTypes/Product/ProductSettings'
import ContactUs from '../sectionsTypes/ContactUs/ContactUs'
import ContactUsSettings from '../sectionsTypes/ContactUs/ContactUsSettings'
import MapSection from '../sectionsTypes/MapSection/MapSection'
import MapSectionSettings from '../sectionsTypes/MapSection/MapSectionSettings'
import FooterSection from '../sectionsTypes/FooterSection/FooterSection'
import FooterSectionSettings from '../sectionsTypes/FooterSection/FooterSectionSettings'
import Accounts from '../accounts/Accounts'
import Category from '../stockSystem/category/Category'
import Product from '../stockSystem/product/Product'
import ProductxSupplier from '../stockSystem/productxsupplier/ProductxSupplier'
import Stock from '../stockSystem/stock/Stock'
import PointSale from '../pointSale/PointSale'
import PointPurchase from '../pointPurchase/PointPurchase'
import CategoriesList from '../sectionsTypes/CategoriesList/CategoriesList'
import CategoriesListSettings from '../sectionsTypes/CategoriesList/CategoriesListSettings'
import CategoryProducts from '../sectionsTypes/CategoryProducts/CategoryProducts'
import CategoryProductsSettings from '../sectionsTypes/CategoryProducts/CategoryProductsSettings'
import CheckOut from '../checkout/CheckOut'
import PaymentSuccess from '../checkout/PaymentSuccess'
import SalesReport from '../reportSystem/sales/Sales'
import PurchasesReport from '../reportSystem/purchases/Purchases'
import Suppliers from '../Suppliers/Suppliers'
import Customers from '../Customers/Customers'
import Trademarks from '../stockSystem/trademark/Trademarks'
import SuppliersSection from '../sectionsTypes/Suppliers/Suppliers'
import SuppliersSettings from '../sectionsTypes/Suppliers/SuppliersSettings'
import Novelty from '../sectionsTypes/Novelty/Novelty'
import NoveltySettings from '../sectionsTypes/Novelty/NoveltySettings'
import ThreeVerticalDataIcon from '../sectionsTypes/ThreeVerticalDataIcon/ThreeVerticalDataIcon'
import ThreeVerticalDataIconSettings from '../sectionsTypes/ThreeVerticalDataIcon/ThreeVerticalDataIconSettings'
import ThreeDataIcon from '../sectionsTypes/ThreeDataIcon/ThreeDataIcon'
import ThreeDataIconSettings from '../sectionsTypes/ThreeDataIcon/ThreeDataIconSettings'
import Inquiry from '../inquiry/Inquiry';

/* components is the list of components available to render in the application */

export const components = [
    {
        name: 'basicwebpage',
        component: BasicWebpage
    },
    {
        name: 'webeditor',
        component: WebEditor
    },
    {
        name: 'pageeditor',
        component: PageEditor
    },
    {
        name: 'basic',
        component: Basic
    },
    {
        name: 'basicsettings',
        component: BasicSettings
    },
    {
        name: 'threedataicon',
        component: ThreeDataIcon
    },
    {
        name: 'threedataiconsettings',
        component: ThreeDataIconSettings
    },
    {
        name: 'threeverticaldataicon',
        component: ThreeVerticalDataIcon
    },
    {
        name: 'threeverticaldataiconsettings',
        component: ThreeVerticalDataIconSettings
    },
    {
        name: 'novelty',
        component: Novelty
    },
    {
        name: 'noveltysettings',
        component: NoveltySettings
    },
    {
        name: 'supplierssection',
        component: SuppliersSection
    },
    {
        name: 'supplierssettings',
        component: SuppliersSettings
    },
    {
        name: 'carousel',
        component: Carousel
    },
    {
        name: 'carouselsettings',
        component: CarouselSettings
    },
    {
        name: 'buttonsaccess',
        component: ButtonsAccess
    },
    {
        name: 'buttonsaccesssettings',
        component: ButtonsAccessSettings
    },
    {
        name: 'twocolumns',
        component: TwoColumns
    },
    {
        name: 'twocolumnssettings',
        component: TwoColumnsSettings
    },
    {
        name: 'threeservices',
        component: ThreeServices
    },
    {
        name: 'threeservicessettings',
        component: ThreeServicesSettings
    },
    {
        name: 'objetives',
        component: Objetives
    },
    {
        name: 'objetivessettings',
        component: ObjetivesSettings
    },
    {
        name: 'productlist',
        component: ProductList
    },
    {
        name: 'productlistsettings',
        component: ProductListSettings
    },
    {
        name: 'producttypesection',
        component: ProductTypeSection
    },
    {
        name: 'producttypesectionsettings',
        component: ProductTypeSectionSettings
    },
    {
        name: 'contactus',
        component: ContactUs
    },
    {
        name: 'contactussettings',
        component: ContactUsSettings
    },
    {
        name: 'mapsection',
        component: MapSection
    },
    {
        name: 'mapsectionsettings',
        component: MapSectionSettings
    },
    {
        name: 'categorieslist',
        component: CategoriesList
    },
    {
        name: 'categorieslistsettings',
        component: CategoriesListSettings
    },
    {
        name: 'categoryproducts',
        component: CategoryProducts
    },
    {
        name: 'categoryproductssettings',
        component: CategoryProductsSettings
    },
    {
        name: 'footersection',
        component: FooterSection
    },
    {
        name: 'footersectionsettings',
        component: FooterSectionSettings
    },
    {
        name: 'generalconfigpage',
        component: GeneralConfig
    },
    {
        name: 'configpages',
        component: PageConfig
    },
    {
        name: 'home',
        component: Home
    },
    {
        name: 'login',
        component: Login
    },
    {
        name: 'register',
        component: Register
    },
    {
        name: 'dashboard',
        component: Dashboard
    },
    {
        name: 'inquiry',
        component: Inquiry
    },
    {
        name: 'shortcutscreen',
        component: ShortcutScreen
    },
    {
        name: 'pointsale',
        component: PointSale
    },
    {
        name: 'pointpurchase',
        component: PointPurchase
    },
    {
        name: 'invoices',
        component: Invoices
    },
    {
        name: 'professionals',
        component: Professionals
    },
    {
        name: 'patients',
        component: Patients
    },
    {
        name: 'accounts',
        component: Accounts
    },
    {
        name: 'categorypage',
        component: Category
    },
    {
        name: 'productpage',
        component: Product
    },
    {
        name: 'productxsupplierpage',
        component: ProductxSupplier
    },
    {
        name: 'stockpage',
        component: Stock
    },
    {
        name: 'checkout',
        component: CheckOut
    },
    {
        name: 'payment_success',
        component: PaymentSuccess
    },
    {
        name: 'salesreport',
        component: SalesReport
    },
    {
        name: 'purchasesreport',
        component: PurchasesReport
    },
    {
        name: 'suppliers',
        component: Suppliers
    },
    {
        name: 'customers',
        component: Customers
    },
    {
        name: 'trademarks',
        component: Trademarks
    }
]
