/* General imports */
import React from 'react'
import styled from 'styled-components'
import { theme } from '../app/config'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import axios from 'axios'
import  { authHeader } from '../app/auth-header';

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 400px;
    min-height: ${(props) => props.mobile ? '300px' : '400px'};
    align-items: ${(props) => props.mobile ? 'flex-start' : 'center'};
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: linear-gradient(to left,#122230 0%,#192d3e 100%);
`

const ModalBody = styled.form`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    img {
        user-select: none;
        width: ${(props) => props.mobile ? '80px' : '200px'};
        height: ${(props) => props.mobile ? '50px' : '150px'};;
    }
`
const StyledButton = styled.button`
    width: 75%;
    height: 44px;
    background-color: ${theme.default.primaryButtonBg};
    color: ${theme.default.primaryButtonText};
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    border-radius: 80px;
    border-color: transparent;
    border-style: solid;
    margin-top: 25px;
    margin-bottom: 25px;
`

const StyledFormControl = styled(FormControl)`
  width: 90%;
`

/* ----- Principal function ----- */
function ModalAddFromSale({ title, textButton, openModal, closeModal, handleAction }) {

    const [ state, setState ] = React.useState('');
    const [ sales, setSales ] = React.useState(null);
    
    const handleChange = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setState(value);
    }

    const submit = (e) => {
        e.preventDefault()
        handleAction(state)
    }

    React.useEffect(() => {
        if(!sales){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get(`/api/sales`,configHeader)
            .then(result => {
                setSales(result.data.rows);
            })
            .catch(err => {
                console.log(err)
            }) 
            
        }
    }, [ sales])

    return(
        <React.Fragment>
            <Desktop>
                <ModalStyled
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={closeModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        <ModalContent>
                            <ModalTitle>
                                {title}
                            </ModalTitle>
                            <ModalBody onSubmit={submit}>
                                {
                                    sales &&
                                    <StyledFormControl required>
                                        <InputLabel id="sale-label"> Venta </InputLabel>
                                        <Select
                                            native
                                            labelId="sale-label"
                                            onChange={handleChange}
                                            required
                                            inputProps={{
                                                id:"selectedSale",
                                                name:"selectedSale",
                                                value:state
                                            }}
                                        >
                                        <option value="" />
                                        {
                                            sales && sales.map((sale, index) =>
                                                <option key={`sale_option_${index}`} value={sale.idSale}> {`ID: ${sale.idSale} - Fecha: ${sale.saleDate.substring(0,10)} - Monto: $${sale.totalAmount}`} </option>
                                            )
                                        }
                                        </Select>
                                    </StyledFormControl>
                                }
                                {
                                    sales && <StyledButton>{ textButton }</StyledButton>
                                }
                            </ModalBody>
                        </ModalContent>
                    </Fade>
                </ModalStyled>
            </Desktop>
            <Tablet>
                <ModalStyled
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={closeModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        <ModalContent>
                            <ModalTitle>
                                {title}
                            </ModalTitle>
                            <ModalBody onSubmit={submit}>
                                <StyledButton>{ textButton }</StyledButton>
                            </ModalBody>
                        </ModalContent>
                    </Fade>
                </ModalStyled>
            </Tablet>
            <Mobile>
                <ModalStyled
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={closeModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        <ModalContent mobile={true} >
                            <ModalTitle>
                                {title}
                            </ModalTitle>
                            <ModalBody onSubmit={submit} mobile={true} >
                                <StyledButton>{ textButton }</StyledButton>
                            </ModalBody>
                        </ModalContent>
                    </Fade>
                </ModalStyled>
            </Mobile>
        </React.Fragment>
    );
}

export default ModalAddFromSale;