/* General imports */
import React from 'react'
import styled from 'styled-components'
import axios from 'axios'

/* Components imports */
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormStep from '../../form/FormStep'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NoImage from '../../images/no-image.png'

/* Styled components */
const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
    z-index: 99999;
    outline: none;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 900px;
    min-height: 550px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    outline: none;
`

const ModalTitle = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 70px;
    color: white;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    background: #234;
`

const ModalBody = styled.div`
    position: absolute;
    display: flex;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    align-items: center;
    justify-content: center;
`

const LeftContent = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const RightContent = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    width: 300px;
    height: 300px;
    margin-bottom: 25px;
    img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    box-sizing: border-box;
`

/* ----- Principal function ----- */
function ModalCreateSlide({ title, textButton, rowData, openModal, closeModal, handleAction, alertMessage }) {

    const [ dataForm, setDataForm ] = React.useState(rowData ? rowData : { 
        name: '',
        title: '',
        text: '',
        textButton: '',
        link: '',
        order: '',
        videolink: ''
    })
    const [ image, setImage ] = React.useState('')
    
    const fields = [
        {
            name: 'name',
            type: 'text',
            placeholder: 'Nombre Slide',
            required: true,
            autoFocus: true
        },
        {
            name: 'slideType',
            type: 'select',
            placeholder: 'Tipo de slide',
            options: [
                {
                    name: 'Fondo video',
                    value: 'videocontent'
                },
                {
                    name: 'Imagen fondo',
                    value: 'imagecontent'
                },
                {
                    name: 'Dos columnas 1',
                    value: 'twocolumns1'
                },
                {
                    name: 'Dos columnas 2',
                    value: 'twocolumns2'
                }
            ],
            required: true
        },
        {
            name: 'title',
            type: 'text',
            placeholder: 'Título Slide',
            required: false
        },
        {
            name: 'text',
            type: 'text',
            placeholder: 'Texto Slide',
            required: false
        },
        {
            name: 'textButton',
            type: 'text',
            placeholder: 'Texto Enlace',
            required: false
        },
        {
            name: 'link',
            type: 'text',
            placeholder: 'Enlace Slide',
            required: false
        },
        {
            name: 'order',
            type: 'number',
            placeholder: 'Orden',
            required: false
        },
        {
            name: 'videolink',
            type: 'text',
            placeholder: 'URL video (solo en tipo Fondo video)',
            required: false
        },
    ]

    const checkForm = () => {
        let flag = true
        let message = 'Faltan completar campos'
        fields.forEach(element => {
            if(element.required){
                if(dataForm[element.name] === null || dataForm[element.name] === '' || dataForm[element.name] === undefined){
                    flag = false
                    dataForm[`${element.name}_error`] = true
                } else {
                    dataForm[`${element.name}_error`] = false
                }
            }
        });
        if(dataForm['img'] === null || dataForm['img'] === '' || dataForm['img'] === undefined){
            flag = false
            dataForm['img_error'] = true
        }
        return { flag, message }
    }

    const handleChange = (name, value) => {
        setDataForm({ 
            ...dataForm,
            [name]: value 
        });
    }

    

    const submit = () => {
        const { flag, message } = checkForm()
        if(flag){
            handleAction(dataForm)
        } else {
            alertMessage(message)
        }
        
        
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        if(image !== '' ){
            if(!dataForm.img){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, {
                }).then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setDataForm({
                        ...dataForm,
                        img: url_img,
                        img_error:false
                    })
                })
                .catch(error => {
                    alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, {
                    }).then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setDataForm({
                            ...dataForm,
                            img: url_img,
                            img_error:false
                        })    
                    })
                    .catch(error => {
                        alert('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alert("Debe selecionar una imagen...")
        }
    }
    
    

    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <ModalContent>
                    <ModalTitle>
                        {title}
                        <button onClick={submit} >{textButton}</button>
                    </ModalTitle>
                    <ModalBody>
                        <LeftContent>
                            {
                                !fields ? <h2>CARGANDO...</h2>
                                :
                                <FormStep fields={fields} width='400px' height='450px' data={dataForm} onHandleChange={handleChange} columWidth='250px' alignV='flex-start'  />
                            }
                        </LeftContent>
                        <RightContent>
                            <ImageContainer>
                                <img src={dataForm.img ? dataForm.img : NoImage} alt="Imagen subida" />
                            </ImageContainer>
                            <UploadImage>
                                <input style={{fontSize: '0.75rem', width: '300px', color: dataForm['img_error'] ? 'red' : 'black'}} id="image" name="image" type="file" onChange={onFileChange} error={dataForm['img_error']} required /><CloudUploadIcon onClick={handleFileUpload} />
                            </UploadImage>
                        </RightContent>
                    </ModalBody>
                </ModalContent>
            </Fade>
        </ModalStyled>
    );
}

export default ModalCreateSlide;