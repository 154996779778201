/* General imports */
import React from 'react';
import styled from 'styled-components';
import { alertActions } from '../../../alert/AlertActions';
import { connect } from 'react-redux';
import axios from 'axios';
import  { authHeader } from '../../../app/auth-header';

/* Components imports */
import CancelIcon from '@material-ui/icons/Cancel';
import { stockActions } from '../StockActions';
import ProductInput from './ProductInput';
import ItemsTable from './ItemsTable';
import TextField from '../../../form/TextField';


/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    background-color: white;
`

const BoxContainer = styled.div`
    display: grid;
    position: relative;
    width: 100%;
    height: 100%;
    grid-template-columns: 70% 30%;
    grid-template-rows: 1fr;
    box-sizing: border-box;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
`

const Box = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    grid-template-columns: 1fr;
    grid-template-rows: 15% 80%;
`

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 95%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 10px;
`

const Actions = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    box-sizing: border-box;
`

const IconAction = styled.div`
    position: absolute;
    display: flex;
    width: 25px;
    height: 25px;
    top: 5px;
    right: ${(props) => props.right ? props.right : '10px'};
    align-items: center;
    justify-content: center;
    
    svg {
        font-size: 25px;
        cursor: pointer;
        z-index: 999;
    }
`

const StyledButton = styled.button`
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    font-size: 1.5rem;
    outline: none;
    cursor: pointer;
    user-select: none;
    transition: all 300ms ease-in-out;
    &:hover {
        box-shadow: 0 0 5px rgba(0,0,0,0.8);
        font-weight: 700;
    }
`

/* ----- Principal function ----- */
// PROPS: user, alertMessage, items, setItems, bulkExchangeStock, history
function ExchangeStock(props) {

    const [storages, setStorages] = React.useState(null);

    const productInputRef = React.useRef();

    const reset = () => {
        props.setItems([]);
    }

    const cancelCode = () => {
        /* productInputRef.currentTarget.clean(); */
        reset();
        props.setExchangeData({
            idStockSource: -1,
            idStockDestination: -1
        });
    }

    const cancel = () => {
        cancelCode();
        props.history.push('/admin/stocksystem/stock');
    }

    const changeAmount = (index, value) => {
        let newItems = props.items;
        newItems[index].amount = parseFloat(value);
        props.setItems([...newItems]);
    }

    const deleteItem = (indexDelete) => {
        let newItems = props.items.filter(function(value, index, arr){ 
            return parseInt(index) !== parseInt(indexDelete);
        });
        props.setItems([...newItems]);
    }

    const handleChange = (name, value) => {
        reset();
        props.setExchangeData({
            ...props.exchangedata,
            [name]: value
        })
    }

    const submit = (e) => {
        e.preventDefault();
        if(props.exchangedata.idStockSource === null || props.exchangedata.idStockDestination === null){
            props.alertMessage("Debe completar campos obligatorios");
        } else if(!props.items.length > 0){
            props.alertMessage("Debe haber al menos un item cargado");
        } else {
            // eslint-disable-next-line no-restricted-globals
            if (confirm("¿Está seguro/a de aplicar esta actualización?")) {
                try {
                    props.bulkExchangeStock(props.user.idUser);  
                    cancelCode();
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(!storages){
            axios.get(`/api/storages`,configHeader)
            .then(storagesData => {
                const storagesList = [];
                storagesData.data.storages.forEach(element => {
                    storagesList.push({
                        name: element.name,
                        value: element.idStorage
                    })
                });
                setStorages(storagesList);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [ storages ])

    return(
        <Root>
            <IconAction ><CancelIcon onClick={cancel} /></IconAction>
            <BoxContainer>
                {
                    props.exchangedata.idStockSource && props.exchangedata.idStockSource != -1 && props.exchangedata.idStockDestination != -1 && storages ?
                    <Box>
                        <ProductInput 
                            ref={productInputRef} 
                            setItems={props.setItems} 
                            alertMessage={props.alertMessage}
                            items={props.items} 
                            storage={props.exchangedata.idStockSource}
                        />
                        
                        <Container>
                            <ItemsTable 
                                items={props.items} 
                                changeAmount={changeAmount} 
                                deleteItem={deleteItem} 
                                storage={props.exchangedata.idStockSource}
                            />
                        </Container>
                    </Box>
                    :
                    <h2>SELECCIONE UN DEPÓSITO ORIGEN</h2>
                }
                
                <Actions>
                    {
                        storages &&
                        <TextField id="idStockSource" value={props.exchangedata.idStockSource} required={true} name="idStockSource" placeholder="Depósito ORIGEN" options={storages} type="select" fontSize="20px" onChange={handleChange} />    
                    }
                    {
                        storages &&
                        <TextField id="idStockDestination" value={props.exchangedata.idStockDestination} required={true} name="idStockDestination" placeholder="Depósito DESTINO" options={storages.filter(s => parseInt(s.value) !== parseInt(props.exchangedata.idStockSource))} type="select" fontSize="20px" onChange={handleChange} />    
                    }
                    <TextField id="concept" value={props.exchangedata.concept} name="concept" placeholder="Concepto" type="textarea" fontSize="20px" onChange={handleChange} />    
                    <StyledButton onClick={submit}>APLICAR</StyledButton>
                    <StyledButton onClick={cancelCode}>CANCELAR</StyledButton>
                </Actions>
            </BoxContainer>
        </Root>
    );
}

function mapState(state) {
    const { items, exchangedata } = state.stock;
    const { user } = state.login;
    return { user, items, exchangedata };
}

const actionCreators = {
    setItems: stockActions.setItems,
    setExchangeData: stockActions.setExchangeData,
    bulkExchangeStock: stockActions.bulkExchangeStock,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ExchangeStock);