/* General imports */
import React from 'react'
import styled from 'styled-components'
import { Route, Switch } from 'react-router-dom'
import axios from 'axios'

/* Redux imports */
import { connect } from 'react-redux'
import { alertActions } from '../alert/AlertActions'

/* Components imports */
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import { nameAccess } from './nameAccessConstants'
import { components } from './componentList'
import PrivateRoute from './PrivateRoute'
import Spinner from '../spinners/LoaderSpinner'
import SidebarCart from '../SidebarCart/SidebarCart'
import CheckOut from '../checkout/CheckOut'

/* History import */
/* import { history } from '../app/history' */ //TODO: Analizar history de BorwserRoute para unir con Google apps

/* TODO: Reemplazar routeList consultando la base de datos */
const routesList = [
  {
    name: 'DashBoard',
    pageType: 'dashboard',
    path: '/admin',
    privateRoute: true,
    name_access: nameAccess.APPLICATION
  },
  {
    name: 'inquiry',
    pageType: 'inquiry',
    path: '/inquiry',
    privateRoute: true,
    name_access: 'consult'
  },
  {
    name: 'Login',
    pageType: 'login',
    path: '/login',
    privateRoute: false
  },
  {
    name: 'Register',
    pageType: 'register',
    path: '/registration',
    privateRoute: false
  }
]

const useStyles = makeStyles(theme => ({
    close: {
      padding: theme.spacing(0.5),
    },
    bot: {
      bottom: 0,
      right : 0,
      position: 'fixed',
      width: '350px',
  }
  }));

/* Styled components */
const RootApp = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  margin: 0;
  padding: 0;
`

/* ----- Principal function ----- */
function App(props) {
  const classes = useStyles();

  const [webPages, setWebPages] = React.useState(null);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.clearAlerts();
  };

  React.useEffect(() => {
    if(!webPages){
        axios.get('/api/pages')
        .then(webPagesDataResponse => {
            const webPagesData = webPagesDataResponse.data.pages;
            setWebPages(webPagesData);
        })
        .catch(err => {
            alert('Hubo un error...');
        })
    } 
    
}, [ webPages, setWebPages ]) 

  const RouteComponent = ({ component: Component, idpage, listpages, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            return <Component idpage={idpage} listpages={listpages} {...props} />
        }}
    />
)
  
  return (
      <RootApp>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={props.open}
          autoHideDuration={6000}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{props.message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <SidebarCart />
        <Switch>
          { // Internal system basic pages (Dashboard, Login, Register)
            routesList.map( (r, index) =>
            r.privateRoute ?
              <PrivateRoute key={`${r.name}+${index}`} path={r.path} exact={r.exact} name_access={r.name_access} component={components.find(comp => comp.name === r.pageType).component} />    
            :
              <Route key={`${r.name}+${index}`} path={r.path} exact={r.exact} component={components.find(comp => comp.name === r.pageType).component} />    
            )
          }
          {
            webPages && <RouteComponent listpages={webPages} path="/checkout/:failure?" exact={true} component={components.find(comp => comp.name === "checkout").component} /> 
          }
          {
            webPages && <RouteComponent listpages={webPages} path="/payment_success" exact={true} component={components.find(comp => comp.name === "payment_success").component} /> 
          }
          { // External system pages
            !webPages ? <Spinner spinnerType='ThreeDots' /> :
            webPages.map((webPage, index) => 
              <RouteComponent key={`webpage${index}`} idpage={webPage.idPage} listpages={webPages} path={webPage.link} exact={webPage.exact} component={components.find(comp => comp.name === webPage.style.pagetype).component} />    
            )
          }   
        </Switch>
      </RootApp>
  );
}

function mapState(state) {
  const { message, open } = state.alert;
  return { message, open };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  alertMessage: alertActions.error
};

export default connect(mapState, actionCreators)(App);
