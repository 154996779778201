/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const ContentBox = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`

/* ----- Principal function ----- */
function GeneralConfig(props) {

    return(
        <React.Fragment>
            <Desktop>
                <ContentBox>
                    DESKTOP GENERALCONFIG
                </ContentBox>
            </Desktop>
            <Tablet>
                <ContentBox>
                    TABLET GENERALCONFIG
                </ContentBox>
            </Tablet>
            <Mobile>
                <ContentBox>
                    MOBILE GENERALCONFIG
                </ContentBox>
            </Mobile>
        </React.Fragment>
    );
}

export default GeneralConfig;

