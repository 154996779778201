/* General imports */
import React from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { alertActions } from '../alert/AlertActions'
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import { pointPurchaseActions } from './PointPurchaseActions';
import ItemsTable from './ItemsTable';

/* Components imports */
import MetaPurchase from './MetaPurchase';
import ProductInput from './ProductInput';

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    max-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
`

const Header = styled.div`
    display: flex;
    position: relative;
    width: 95%;
    height: 70px;
    align-items: center;
    justify-content: flex-start;
    label {
        font-size: 20px;
        margin: 5px;
    }
    input {
        font-size: 20px;
        margin: 5px;
        padding: 2px 5px;
        box-sizing: border-box;
    }
    span {
        font-size: 10px;
        opacity: 0.85;
    }
`

const SaleRow = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
`

const SaleData = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 70%;
    height: auto;
    min-height: 650px;
    align-items: center;
    justify-content: flex-start;
`

const ResumeData = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 30%;
    height: auto;
    min-height: 650px;
    align-items: center;
    justify-content: flex-start;
`

const ResumeDisplay = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #02004c;
    color: #ddd;
    width: 95%;
    height: 300px;
    box-sizing: border-box;
    padding: 20px 0;
    font-size: 24px;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
`

const ItemList = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 95%;
    height: 400px;
    max-height: 400px;
    overflow: auto;
    box-shadow: 1px 1px 15px rgba(2,0,76,0.5);
    box-sizing: border-box;
    padding: 10px;
`

const DisplayRow = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    ${(props) => props.fontSize ? `font-size: ${props.fontSize};` : ''}
    ${(props) => props.color ? `color: ${props.color};` : ''}
`

const DisplayItem = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: ${(props) => props.alignH ? props.alignH : "center"};
    padding: 0 20px;
    box-sizing: border-box;
    input {
        width: 100px;
        text-align: end;
        background: black;
        color: white;
        font-size: 16px;
    }
`

const ActionsDisplay = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
`

const ButtonAction = styled.button`
    display: flex;
    position: relative;
    padding: 15px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 5px;
    background: #2e6da4;
    color: white;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    border: none;
    outline: none;
    cursor: pointer;
`

const Divider = styled.div`
    width: 80%;
    height: 1px;
    background: ${(props) => props.color ? props.color : "white"};
    opacity: 0.5;
`


/* ----- Principal function ----- */
function PointPurchase(props) {
    const dispatch = useDispatch();
    const getSubtotal = (elements) => {
        let subtotal = 0;
        elements.forEach(element => {
            const priceElement = Math.round((element.amount * element.costPrice)*100)/100;
            subtotal = subtotal + priceElement;
        });
        return subtotal;
    }
    const [subtotal, setSubtotal] = React.useState(props.items.length > 0 ? getSubtotal(props.items) : 0);    
    const [payment, setPayment] = React.useState(parseFloat(0.00));
    const [discount, setDiscount] = React.useState(parseFloat(0.00));
    const [purchaseData, setPurchaseData] = React.useState({
        supplier: -1,
        purchaseDate: new Date().toISOString().slice(0, 10),
        purchaseNumber:"",
        storage: 1,
        paymentMethod: 10
    });

    

    const productInputRef = React.useRef();

    const handleChangePurchaseData = (name, value) => {
        setPurchaseData({
            ...purchaseData,
            [name]: value
        })
        const newItems = [];
        props.items.forEach(item => {
            const newItem = {
                idProduct: item.idProduct,
                code: item.code,
                barcode: item.barcode,
                name: item.name,
                description: item.description,
                img: item.img,
                costPrice: item.costPrice,
                salePrice: item.originalPrice,
                originalPrice: item.originalPrice,
                category: item.id_Category,
                currencyUnit: item.idCurrency_Unit,
                quantityUnit: item.idQuantity_Unit,
                suppliers: item.suppliers,
                amount: item.amount,
                newStock: name === "storage" ? item.stock.filter(sto => parseInt(sto.idStorage) === parseInt(value))[0].quantity + parseFloat(item.amount) : item.newStock
            }
            newItems.push(newItem);
        });
        setSubtotal(getSubtotal(newItems));
        props.setItems(newItems);
    }    

    const cancelCode = () => {
        // TO DO
    }

    const resetPurchaseData = () => {
        setPurchaseData({
            supplier: -1,
            purchaseDate: new Date().toISOString().slice(0, 10),
            purchaseNumber:"",
            storage: 1,
            paymentMethod: 10
        })
    }

    const deleteItem = (indexDelete) => {
        let newItems = props.items.filter(function(value, index, arr){ 
            return parseInt(index) !== parseInt(indexDelete);
        });
        setSubtotal(getSubtotal(newItems));
        props.setItems(newItems);
    }

    const checkForm = async (purchaseData) => {
        let flag = true
        let message = 'Faltan completar campos necesarios'
        if (purchaseData.supplier !== -1  && purchaseData.storage !== -1 &&purchaseData.paymentMethod !== -1)
            return (false, "");
    
        return { flag, message }
    }

    const collectOrder = async () => {
        const {flag, message} = await checkForm(purchaseData);
        
        if(!flag){
            await props.collectPurchase({
                ...purchaseData,
                createdUser: props.user.idUser,
                subtotal: subtotal,
                discount: discount,
                total: subtotal - (subtotal*discount/100),
                idStatus: 6, // Finished
            });
            cancel();
        }else {
            dispatch(alertActions.error(message));
        } 
    }
    
    const cancel = () => {
        setPayment(0);
        setSubtotal(0);
        props.setItems([]);
        cancelCode();
        resetPurchaseData()
    }

    const changePrice = (index, value) => {
        let newItems = props.items;
        newItems[index].costPrice = value;
        setSubtotal(getSubtotal(newItems));
        props.setItems(newItems);
        // eslint-disable-next-line no-restricted-globals
        if(confirm(`¿Desea actualizar también el precio del producto ${newItems[index].name}?`)){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.put(`/api/products/update/costPrice/${newItems[index].idProduct}`, {costPrice: value},configHeader)
            .then(result => {
                props.alertMessage("Producto actualizado")
            })
            .catch(err => {
                console.log(err);
            })
        }
    }

    return(
        <Root>
            <ProductInput ref={productInputRef} items={props.items} setItems={props.setItems} saledata={purchaseData} setSubtotal={setSubtotal} getSubtotal={getSubtotal} alertMessage={props.alertMessage} />
            <SaleRow>
                <SaleData>
                    <ItemList>
                        <ItemsTable changePrice={changePrice} deleteItem={deleteItem} />
                    </ItemList>
                    <MetaPurchase metaData={purchaseData} handleChange={handleChangePurchaseData} />
                </SaleData>
                <ResumeData>
                    <ResumeDisplay>
                        <DisplayRow><DisplayItem alignH="flex-start">Subtotal</DisplayItem><DisplayItem alignH="flex-end">${subtotal}</DisplayItem></DisplayRow>
                        <DisplayRow fontSize="20px"><DisplayItem alignH="flex-start">Descuento</DisplayItem><DisplayItem alignH="flex-end">%<input type="number" value={discount} onChange={(e) => {setDiscount(e.target.value)}} min="0" /></DisplayItem></DisplayRow>
                        <DisplayRow color="#fff" ><DisplayItem alignH="flex-start">TOTAL</DisplayItem><DisplayItem alignH="flex-end">${subtotal - (subtotal*discount/100)}</DisplayItem></DisplayRow>
                        <Divider />
                        <DisplayRow fontSize="20px"><DisplayItem alignH="flex-start">Pago</DisplayItem><DisplayItem alignH="flex-end">$<input type="number" value={payment} onChange={(e) => {setPayment(e.target.value)}} min="0" /></DisplayItem></DisplayRow>
                        <Divider />
                        <DisplayRow fontSize="20px" color="#9a9a9a" ><DisplayItem alignH="flex-start">Vuelto</DisplayItem><DisplayItem alignH="flex-end">${(Math.round((payment - (subtotal - (subtotal*discount/100)))*100)/100)}</DisplayItem></DisplayRow>
                    </ResumeDisplay>
                    <ActionsDisplay>
                        <ButtonAction onClick={collectOrder}>Ingresar</ButtonAction>
                        <ButtonAction onClick={cancel}>Cancelar</ButtonAction>
                    </ActionsDisplay>
                </ResumeData>
            </SaleRow>
        </Root>
    );
}

function mapState(state) {
    const { error, isFetching, items} = state.pointpurchase;
    const { user } = state.login;
    return { user, error, isFetching, items};
}

const actionCreators = {
    setItems: pointPurchaseActions.setItems,
    collectPurchase: pointPurchaseActions.collectPurchase,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(PointPurchase);
