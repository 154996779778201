import { productConstants } from './ProductConstants';
import { alertActions } from '../../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';

export const productActions = {
    createProduct,
    updateProduct,
    getProducts,
    deleteProduct,
    getCurrencyUnits,
    getQuantityUnits,
    getTrademarks
};

function createProduct(product) {
    return dispatch => {
        axios.post( '/api/products' , {
            name: product.name,
            alternativeName: product.alternativeName,
            description: product.description,
            features: product.features,
            costPrice: product.costPrice,
            salePrice: product.salePrice,
            code: product.code,
            barcode: product.barcode,
            img: product.img,
            createdUser: product.createdUser,
            deleted: product.deleted,
            idCategory: product.idCategory,
            idCurrencyUnit: product.idCurrencyUnit,
            idQuantityUnit: product.idQuantityUnit,
            suppliers:[...product.suppliers],
            promo: product.promo,
            ecommerce: product.ecommerce,
            idTrademark: product.idTrademark,
            initialStock: product.initialStock,
            tags: product.tags
        })
        .then(response => {
            dispatch(alertActions.success('Producto creado'));
        })
        .catch(error => {
            dispatch(alertActions.error(error.toString()));
            console.log(error.msg)

        })
    };
}

function updateProduct(idProduct, product) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        axios
        .put( '/api/products/' + idProduct, {
            name: product.name,
            alternativeName: product.alternativeName,
            description: product.description,
            features: product.features,
            costPrice: product.costPrice,
            salePrice: product.salePrice,
            code: product.code,
            barcode: product.barcode,
            img: product.img,
            updatedUser: product.createdUser,
            deleted: product.deleted,
            idCategory: product.idCategory,
            idCurrencyUnit: product.idCurrencyUnit,
            idQuantityUnit: product.idQuantityUnit,
            suppliers: [...product.suppliers],
            promo: product.promo,
            ecommerce: product.ecommerce,
            idTrademark: product.idTrademark,
            tags: product.tags
            }, config)
        .then(response => {
            dispatch(alertActions.success('Producto actualizado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function getProducts() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: productConstants.FETCHING });
        axios.get('/api/products',config)
        .then(productsData => {
            const products = productsData.data.products;
            dispatch({ type: productConstants.SUCCESS, products });
        })
        .catch(err => {
            dispatch({ type: productConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getCurrencyUnits() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: productConstants.FETCHING_FORMFIELDS });
        axios.get('/api/valuelist/group/'+productConstants.CURRENCY_GROUP,config)
        .then(currencyData => {
            const currencyUnits = [];
            currencyData.data.valueslist.forEach(option => {
                currencyUnits.push({
                  name: option.value,
                  key: option.key,
                  value: option.idValueList
                })
            });
            dispatch({ type: productConstants.SUCCESS_CURRENCYUNITS, currencyUnits });
        })
        .catch(err => {
            dispatch({ type: productConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
    }
}

function getTrademarks() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: productConstants.FETCHING_FORMFIELDS });
        axios.get('/api/trademarks',config)
        .then(trademarksData => {
            const trademarks = [];
            trademarksData.data.rows.forEach(option => {
                trademarks.push({
                  name: option.name,
                  value: option.idTrademark
                })
            });
            dispatch({ type: productConstants.SUCCESS_TRADEMARKS, trademarks });
        })
        .catch(err => {
            dispatch({ type: productConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
    }
}

function getQuantityUnits() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    return dispatch => {
        dispatch({ type: productConstants.FETCHING_FORMFIELDS });
        axios.get('/api/valuelist/group/'+productConstants.QUANTITY_GROUP,config)
        .then(quantityData => {
            const quantityUnits = [];
            quantityData.data.valueslist.forEach(option => {
                quantityUnits.push({
                  name: option.value,
                  key: option.key,
                  value: option.idValueList
                })
            });
            dispatch({ type: productConstants.SUCCESS_QUANTITYUNITS, quantityUnits });
        })
        .catch(err => {
            dispatch({ type: productConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
    }
}

function deleteProduct(idProduct) {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    return dispatch => {
        axios
        .delete( '/api/products/' + idProduct, config)
        .then(response => {
            dispatch(alertActions.success('Producto eliminado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}
