/* General imports */
import React, {useRef} from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { alertActions } from '../alert/AlertActions'
import axios from 'axios';
import  { authHeader } from '../app/auth-header';
import { pointSaleActions } from './PointSaleActions';
import ItemsTable from './ItemsTable';
import { useReactToPrint } from 'react-to-print';
import {config } from '../app/config';

/* Components imports */
import MetaSale from './MetaSale';
import ModalEstimate from '../invoices/ModalEstimate';
import ModalCreateUpdateInvoice from '../invoices/ModalCreateUpdateInvoice';
import Ticket from './Ticket';
import ProductInput from './ProductInput';

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    max-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
`

const Header = styled.div`
    display: flex;
    position: relative;
    width: 95%;
    height: 70px;
    align-items: center;
    justify-content: flex-start;
    label {
        font-size: 20px;
        margin: 5px;
    }
    input {
        font-size: 20px;
        margin: 5px;
        padding: 2px 5px;
        box-sizing: border-box;
    }
    span {
        font-size: 10px;
        opacity: 0.85;
    }
`

const SaleRow = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
`

const SaleData = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 70%;
    height: auto;
    min-height: 650px;
    align-items: center;
    justify-content: flex-start;
`

const ResumeData = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 30%;
    height: auto;
    min-height: 650px;
    align-items: center;
    justify-content: flex-start;
`

const ResumeDisplay = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #000000;
    color: #ddd;
    width: 95%;
    height: 300px;
    box-sizing: border-box;
    padding: 20px 0;
    font-size: 24px;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
`

const ItemList = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 95%;
    height: 400px;
    max-height: 400px;
    overflow: auto;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
    box-sizing: border-box;
    padding: 10px;
`

const DisplayRow = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    ${(props) => props.fontSize ? `font-size: ${props.fontSize};` : ''}
    ${(props) => props.color ? `color: ${props.color};` : ''}
`

const DisplayItem = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: ${(props) => props.alignH ? props.alignH : "center"};
    padding: 0 20px;
    box-sizing: border-box;
    input {
        width: 100px;
        text-align: end;
        background: black;
        color: white;
        font-size: 16px;
    }
`

const ActionsDisplay = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
`

const ButtonAction = styled.button`
    display: flex;
    position: relative;
    padding: 15px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 5px;
    background: #2e6da4;
    color: white;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    border: none;
    outline: none;
    cursor: pointer;
`

const Code = styled.div`
    display: flex;
    position: relative;
`

const ProductList = styled.ul`
    display: ${(props) => props.show ? "flex" : "none"};
    position: absolute;
    flex-direction: column;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 5px;
    width: 200%;
    height: auto;
    max-height: 500px;
    overflow: auto;
    background: white;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
    z-index: 999;
`

const ItemProductList = styled.li`
    display: flex;
    position: relative;
    width: 100%;
    text-decoration: none;
    padding: 20px;
    margin: 0;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.2);
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    span {
        font-size: 12px;
    }
    &:hover {
        box-shadow: 1px 1px 15px rgba(0,0,0,0.8); 
    }
`

const Divider = styled.div`
    width: 80%;
    height: 1px;
    background: ${(props) => props.color ? props.color : "white"};
    opacity: 0.5;
`

const ContentTicket = styled.div`
    margin: 0;
    padding: 0;
    /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
    @media all {
        .page-break {
            display: none;
        }
    }

    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
            font-family:arial, sans-serif;
        }
    }

    @media print {
        .page-break {
            /* margin-top: 1rem; */
            display: block;
            page-break-before: auto;
        }
    }

    @page {
        size: auto;
        margin: 0.5mm;
    }
`

/* ----- Principal function ----- */
function PointSale(props) {

    const CC_PERC = 1.1;
    
    const [payment, setPayment] = React.useState(parseFloat(0.00));
    const [discount, setDiscount] = React.useState(parseFloat(0.00));
    const getSubtotal = (elements) => {
        let subtotal = 0;
        elements.forEach(element => {
            const priceElement = Math.round((element.amount * element.salePrice)*100)/100;
            subtotal = subtotal + priceElement;
        });
        
        return subtotal;
    }
    const productInputRef = React.useRef();

    const [subtotal, setSubtotal] = React.useState(props.items.length > 0 ? getSubtotal(props.items) : 0);
    const [estimate, setEstimate] = React.useState(false);
    const [createInvoice, setCreateInvoice] = React.useState(false);
    const [ afipStatus, setAfipStatus ] = React.useState({
        status: false,
        msg: 'Offline'
    });

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const componentRefTicket = useRef();
    const handlePrintTicket = useReactToPrint({
        content: () => componentRefTicket.current,
    });

    /* const handlePrintTicket = () => {
        alert("hola")
    } */

    const handleChangeSaleData = (name, value) => {
        props.setSaleData({
            ...props.saledata,
            [name]: value
        })
        const newItems = [];
        props.items.forEach(item => {
            const newItem = {
                idProduct: item.idProduct,
                code: item.code,
                barcode: item.barcode,
                name: item.name,
                description: item.description,
                img: item.img,
                costPrice: item.costPrice,
                salePrice: item.originalPrice,
                originalPrice: item.originalPrice,
                category: item.id_Category,
                currencyUnit: item.idCurrency_Unit,
                quantityUnit: item.idQuantity_Unit,
                suppliers: item.suppliers,
                amount: item.amount,
                newStock: name === "storage" ? item.stock.filter(sto => parseInt(sto.idStorage) === parseInt(value))[0].quantity - parseFloat(item.amount) : item.newStock
            }
            newItems.push(newItem);
        });
        setSubtotal(getSubtotal(newItems));
        props.setItems(newItems);
    }

    const cancelCode = () => {
        productInputRef.current.clean();
    }

    const deleteItem = (indexDelete) => {
        let newItems = props.items.filter(function(value, index, arr){ 
            return parseInt(index) !== parseInt(indexDelete);
        });
        setSubtotal(getSubtotal(newItems));
        props.setItems(newItems);
    }


    const collectOrder = async () => {
        await props.collectSale({
            ...props.saledata,
            idUserSeller: props.user.idUser,
            subtotal: subtotal,
            discount: discount,
            total: subtotal - (subtotal*discount/100),
            idStatus: 3, // Finished
        });
        if(props.items.length >0 && subtotal > 0){
            handlePrintTicket();
        }
        
        setPayment(0);
        setSubtotal(0);
        props.setItems([]);
        props.setSaleData({customer: 0,
            saleDate: new Date().toISOString().slice(0, 10),
            storage: 2,
            paymentMethod: 10,
            observation: ''})
        cancelCode();

    }
    
    const cancel = () => {
        setPayment(0);
        setSubtotal(0);
        props.setItems([]);
        cancelCode();
    }

    const changePrice = (index, value) => {
        let newItems = props.items;
        newItems[index].salePrice = parseFloat(value);
        setSubtotal(getSubtotal(newItems));
        props.setItems(newItems);
        // eslint-disable-next-line no-restricted-globals
        if(confirm(`¿Desea actualizar también el precio del producto ${newItems[index].name}?`)){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.put(`/api/products/update/salePrice/${newItems[index].idProduct}`, {salePrice: value},configHeader)
            .then(result => {
                props.alertMessage("Producto actualizado")
            })
            .catch(err => {
                console.log(err);
            })
        }
    }

    const onEstimate = () => {
        setEstimate(true);
    }
    const closeEstimate = () => {
        setEstimate(false);
    }

    /* const onCreateInvoice = async () => {
        await props.collectSale({
            ...props.saledata,
            idUserSeller: props.user.idUser,
            subtotal: subtotal,
            discount: discount,
            total: subtotal - (subtotal*discount/100),
            idStatus: 3, // Finished
        });
        setCreateInvoice(true);
    } */
    const closeCreateInvoice = () => {
        setCreateInvoice(false);
        setPayment(0);
        setSubtotal(0);
        props.setItems([]);
        props.setSaleData({customer: 0,
            saleDate: new Date().toISOString().slice(0, 10),
            storage: 2,
            paymentMethod: 10,
            observation: ''});
        cancelCode();
    }

    const handleConfirmNew = () => {
        try {
            handlePrint();
            closeCreateInvoice();
        } catch (error) {
            alert("Hubo un error")
        }
        
        
    }

    React.useEffect(() => {
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        if(config.enabledAfip && !afipStatus){
            axios.get(`/api/afip/status`,configHeader)
            .then(statusData => {
                const status = statusData.data;
                if(status.AppServer === 'OK'){
                    setAfipStatus({
                        status: true,
                        msg: 'Online'
                    });
                } else {
                    setAfipStatus({
                        status: false,
                        msg: 'Offline'
                    });
                }
                
            })
            .catch(err => {
                console.log(err);
                setAfipStatus({
                    status: false,
                    msg: 'Offline'
                });
            })
        }
    }, [])

    React.useEffect(() => {
        props.setSaleData({
            ...props.saledata,
            saleDate: new Date().toISOString().slice(0, 10),
        })
    }, [])

    return(
        <Root>
            <ProductInput ref={productInputRef} saledata={props.saledata} setItems={props.setItems} setSubtotal={setSubtotal} alertMessage={props.alertMessage} getSubtotal={getSubtotal} items={props.items} />
            <SaleRow>
                <SaleData>
                    <ItemList>
                        <ItemsTable items={props.items} paymentMethod={props.saledata.paymentMethod} changePrice={changePrice} deleteItem={deleteItem} />
                    </ItemList>
                    <MetaSale metaData={props.saledata} handleChange={handleChangeSaleData} />
                </SaleData>
                <ResumeData>
                    <ResumeDisplay>
                        <DisplayRow><DisplayItem alignH="flex-start">Subtotal</DisplayItem><DisplayItem alignH="flex-end">${subtotal.toFixed(2)}</DisplayItem></DisplayRow>
                        <DisplayRow fontSize="20px"><DisplayItem alignH="flex-start">Descuento</DisplayItem><DisplayItem alignH="flex-end">%<input type="number" value={discount} onChange={(e) => {setDiscount(e.target.value)}} min="0" /></DisplayItem></DisplayRow>
                        <DisplayRow color="#fff" ><DisplayItem alignH="flex-start">TOTAL</DisplayItem><DisplayItem alignH="flex-end">${subtotal - (subtotal*discount/100).toFixed(2)}</DisplayItem></DisplayRow>
                        <Divider />
                        <DisplayRow fontSize="20px"><DisplayItem alignH="flex-start">Pago</DisplayItem><DisplayItem alignH="flex-end">$<input type="number" value={payment} onChange={(e) => {setPayment(e.target.value)}} min="0" /></DisplayItem></DisplayRow>
                        <Divider />
                        <DisplayRow fontSize="20px" color="#9a9a9a" ><DisplayItem alignH="flex-start">Vuelto</DisplayItem><DisplayItem alignH="flex-end">${(Math.round((payment - (subtotal - (subtotal*discount/100).toFixed(2)))*100)/100).toFixed(2)}</DisplayItem></DisplayRow>
                    </ResumeDisplay>
                    <ActionsDisplay>
                        <ButtonAction onClick={collectOrder}>Cobrar</ButtonAction>
                        <ButtonAction onClick={cancel}>Cancelar</ButtonAction>
                    </ActionsDisplay>
                    <ActionsDisplay>
                        {/* <ButtonAction onClick={onCreateInvoice}>Cobrar y facturar</ButtonAction> */}
                        <ButtonAction onClick={onEstimate}>Presupuestar</ButtonAction>
                    </ActionsDisplay>
                        <div style={{display: 'none'}}>
                            <ContentTicket ref={componentRefTicket}>
                                <Ticket  products={props.items} subtotal={subtotal} discount={discount} total={subtotal - (subtotal*discount/100)}/>
                            </ContentTicket>
                        </div>
                        {/* <button onClick={handlePrintTicket}>Imprimir ticket</button> */}
                        {
                            createInvoice && afipStatus &&
                            <ModalCreateUpdateInvoice idClient={props.saledata.customer} saleCondition={props.saledata.paymentMethod} saleDate={props.saledata.saleDate} invoiceDetails={props.items} afipStatus={afipStatus.status} voucherPrintRef={componentRef} title='NUEVO COMPROBANTE' textButton='CREAR' handleAction={handleConfirmNew} openModal={createInvoice} closeModal={closeCreateInvoice} />
                        }
                        {
                            estimate &&
                            <ModalEstimate user={props.user} title="COTIZACIÓN" openModal={onEstimate} closeModal={closeEstimate} saleData={props.saledata} rowData={props.items} />
                        }
                </ResumeData>
                
            </SaleRow>
            
        </Root>
    );
}

function mapState(state) {
    const { error, isFetching, items, saledata} = state.pointsale;
    const { user } = state.login;
    return { user, error, isFetching, items, saledata};
}

const actionCreators = {
    setSaleData: pointSaleActions.setSaleData,
    setItems: pointSaleActions.setItems,
    collectSale: pointSaleActions.collectSale,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(PointSale);