/* General imports */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'

/* Components imports */
/* import Button3d from '../buttons/Button3d' */
/* import Button2d from '../buttons/Button2d'
import { menuItems } from '../dashboard/menuItems' */
import StaticGridLayout from '../gridLayout/StaticGridLayout'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    max-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
`

/* const ContentPanel = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: none;
    opacity: 0;
    transition-delay: 0.5s;
    transition: all 1s ease;
` */
/* 
const Panel = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    width: 100%;
    height: 50px;
    opacity: 0;
    transition: all 0.5s ease;
    &:hover {
        height: 200px;
        opacity: 1;
    }
    &:hover ${ContentPanel} {
        display: flex;
        opacity: 1;
    }
`
 */


const RootMobile = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 25px;
`

const GridContainer = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`

/* ----- Principal function ----- */
function ShortcutScreen(props) {
    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <GridContainer>
                        <StaticGridLayout user={props.user}  />
                    </GridContainer>
                </Root>
            </Desktop>
            <Tablet>SHORTCUT SCREEN TABLET</Tablet>
            <Mobile>
                <RootMobile>
                    {/* {
                        menuItems.map((item, index) =>
                            (index !== 0) &&
                            <Button2d 
                                size='100px'
                                textSize='14px'
                                content={item.name} 
                                textColor='red'
                                shadowColor='black'
                                icon={item.icon} 
                                bgColor='white'
                                link={item.link}
                                key={index}
                            />          
                        )
                    } */}
                </RootMobile>
                    
            </Mobile>
        </React.Fragment>
    );
}

export default ShortcutScreen;