/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import { accountsActions } from './AccountsActions'
import { alertActions } from '../alert/AlertActions'

/* Components imports */
import Table from '../tables/AsyncTable'
import ModalCreateUpdateAccount from './ModalCreateUpdateAccount'
import ModalViewAccount from './ModalViewAccount'
import AccountsIcon from '../icons/accountsWhite.svg'


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: 100%;
    padding: ${(props) => props.padding ? props.padding : 0};
    box-sizing: border-box;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background: linear-gradient(to left, #122230 0%, #192d3e 100%);
    align-items: flex-start;
    padding: 35px 35px 70px 35px;
    box-sizing: border-box;
`

const Title = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
    }
    h6 {
        position: relative;
        display: flex;
        margin: 0 20px;
        color: white;
        font-size: 1.5rem;
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 100px);
    padding: 0 35px;
    box-sizing: border-box;
    top: -64px;
`

/* ----- Principal function ----- */
function Accounts({ user, accounts, getAccounts, createAccount, updateAccount, deleteAccount, getFields, accountsFields }) {

    const columsTable = [
        { title: 'ID', field: 'idAccount', width: 50 },
        { title: 'Código', field: 'idAccount_Type.code', width: 100 },
        { title: 'Cod. Auxiliar', field: 'aux', width: 50 },
        { title: 'Tipo', field: 'idAccount_Type.name', width: 300 },
        { title: 'Nombre Titular', field: 'id_user.firstname', width: 300 },
        { title: 'Apellido Titular', field: 'id_user.lastname', width: 300 },
        { title: 'Saldo', field: 'balance', type: 'currency', width: 200 },
        { title: 'Observaciones', field: 'observation' }
    ]

    const tableRef = React.useRef()

    const [open, setOpen] = React.useState(false);

    const [ view, setView ] = React.useState(false)

    const [ viewData, setViewData ] = React.useState(null);

    const [ edit, setEdit ] = React.useState(false)

    const [ editData, setEditData ] = React.useState(null);

    const refreshData = () => tableRef.current.onQueryChange()

    const handleView = (rowData) => {
        setViewData(rowData)
        setView(true);
    };

    const handleCloseView = () => {
        setView(false)
        refreshData()
    };

    const handleNew = () => {
        setOpen(true);
    };

    const handleConfirmNew = async(data) => {
        const newData = {
            ...data,
            createdUser: user.idUser
        }
        await createAccount(newData)
        handleClose()
    }
    
    const handleClose = () => {
        setOpen(false)
        refreshData()
    };

    const handleEdit = (rowData) => {
        setEditData(rowData)
        setEdit(true)
    }

    const handleConfirmEdit = async (data) => {
        await updateAccount(data)
        handleCloseEdit()
    }

    const handleCloseEdit = () => {
        setEdit(false)
        setEditData(null)
        refreshData()
    }

    const handleDelete = async (rowData) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm('¿Desea eliminar esta cuenta?')){
            const deleteData = {
                ...rowData,
                delete: 1,
                updatedUser: user.idUser
            }
            await deleteAccount(deleteData)
        }
    }

    return(
        <React.Fragment>
            <Desktop>
                <Root>
                    <Header>
                        <Title>
                            <img src={AccountsIcon} alt='Icon' />
                            <h6>Cuentas</h6>
                        </Title>
                    </Header>
                    <Content>
                        {
                            <Table 
                                urlapi="/api/accounts"
                                tableRef={tableRef} 
                                autoExport={true}  
                                title="LISTADO DE CUENTAS" 
                                columnsTable={columsTable} 
                                handleView={handleView} 
                                handleNew={handleNew} 
                                handleEdit={handleEdit} 
                                handleDelete={handleDelete} 
                            />   
                        }
                        {
                            view && <ModalViewAccount title='VER CUENTA' openModal={view} rowData={viewData} closeModal={handleCloseView} />
                        }
                        {
                            open && <ModalCreateUpdateAccount title='NUEVA CUENTA' textButton='CREAR' getFields={getFields} fields={accountsFields} handleAction={handleConfirmNew} openModal={open} closeModal={handleClose} />
                        }
                        {
                            editData && <ModalCreateUpdateAccount title='EDITAR CUENTA' textButton='EDITAR' getFields={getFields} fields={accountsFields} handleAction={handleConfirmEdit} rowData={editData} openModal={edit} closeModal={handleCloseEdit} />
                        }
                    </Content>
                </Root>
            </Desktop>
            <Tablet>ACCOUNTS TABLET</Tablet>
            <Mobile>ACCOUNTS MOBILE</Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { error, isFetching, accounts, accountsFields } = state.account;
    const { user } = state.login;
    return { user, error, isFetching, accounts, accountsFields};
}

const actionCreators = {
    getAccounts: accountsActions.getAccounts,
    getFields: accountsActions.getFields,
    createAccount: accountsActions.createAccount,
    updateAccount: accountsActions.updateAccount,
    deleteAccount: accountsActions.deleteAccount,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Accounts);