import React, { PureComponent } from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';

import styled from 'styled-components';

const StyledTooltip = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px;
  background: rgba(0,0,0,0.75);
  box-shadow: 1px 1px 2px rgba(0,0,0,0.25);
  border-radius: 5px;
`

const LabelTooltip = styled.span`
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #FFF;
  text-shadow: 1px 1px 5px rgba(255,255,255, 0.25);
`

const DataTooltip = styled.span`
  align-self: center;
  margin: 10px 0 0 0;
  color: #FFF;
  text-shadow: 1px 1px 2px rgba(255,255,255, 0.5);
`

const data = [
  {
    name: 'Page A', total: 4000
  },
  {
    name: 'Page B', total: 3000
  },
  {
    name: 'Page C', total: 2000
  },
  {
    name: 'Page D', total: 2780
  },
  {
    name: 'Page E', total: 1890
  },
  {
    name: 'Page F', total: 2390
  },
  {
    name: 'Page G', total: 3490
  },
];

const CustomTooltip = ({ payload, label, active }) => {
  if(payload.length > 0){
    return (
      <StyledTooltip>
        <LabelTooltip><strong>{label}</strong></LabelTooltip>
        <DataTooltip>{payload[0].value.toLocaleString('es-ar', {style: 'currency',currency: 'ARS',minimumFractionDigits: 2})}</DataTooltip>
      </StyledTooltip>
    );
  }
  return null;
};

export default class Example extends PureComponent {

  render() {

    


    return (
      <ResponsiveContainer>
        <AreaChart
          data={this.props.data ? this.props.data : data}
          margin={{
            top: 10, right: 50, left: 50, bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />}/>
          <Area type="monotone" dataKey="total" stroke="#002546" fill="rgb(2, 94, 177)" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
