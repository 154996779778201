/* General imports */
import React from 'react'
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';
import axios from 'axios'
import  { authHeader } from '../../app/auth-header';

/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import Zoom from 'react-reveal/Zoom';


/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}


/* Styled Components */
const Root = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '100vh'};
    /* background: ${(props) => props.background ? props.background : 'white'}; */
    background: white;
    display: flex;
    position:relative;
    flex-direction:column;
`

const Header = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '15%'};
    display: flex;
    align-items: ${(props) => props.alignY ? props.alignY : 'flex-end'};
    justify-content: center;
    h3 {
        display: flex;
        margin: 0;
        color: #21282d;
        /* text-shadow: black 0.1em 0.1em 0.2em; */
        text-align:center;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '3rem'};
    }
`

const Container = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '85%'};
    display:flex;
    box-sizing:border-box;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) => props.padding ? props.padding : '20px'};
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
`;

const FrontFace = styled.div`
    width:100%;
    height:100%;
    position:absolute;
    top: 0;
    left: 0;
    transition:0.5s;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index: 1;
    transform-origin:bottom;
`;

const BackFace = styled.div`
    width:100%;
    height:100%;
    position:absolute;
    top: 0;
    left: 0;
    transition:0.5s;
    display:flex;
    justify-content:center;
    align-items:center;
    transform-origin:top;
    transform: translateY(100%) rotateX(90deg);

`;

const Card = styled.div`
    width: ${(props) => props.width ? props.width : '30%'};
    height:70%;
    font-family: 'Josefin Sans', sans-serif;
    font-size: ${(props) => props.fontSize ? props.fontSize : '1.25rem'};
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    padding: ${(props) => props.padding ? props.padding : 0};
    text-align:center;
    cursor: pointer;
    &:hover ${FrontFace}{
        transform: translateY(-100%) rotateX(90deg);
    }
    &:hover ${BackFace}{
        transform: translateY(0%) rotateX(0deg);
    }
`;

const Content = styled.div`
    width:100%;
    height:100%;
    background: ${(props) => props.background ? props.background : 'transparent'};
    z-index:10;
    display:flex;
    h4 {
        width:100%;
        color: #FFFFFF;
        text-shadow: black 0.1em 0.1em 0.2em;
        align-self: ${(props) => props.alignSelf ? props.alignSelf : 'flex-end'};
        text-align:center;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '2rem'};
    }
    p {
        width:100%;
        color: ${(props) => props.textColor ? props.textColor : 'black'};
        align-self:center;
        text-align:center;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '1.8rem'};
    }
`;

const Image = styled.img`
    width:100%;
    height:100%;
    position:absolute;
    top: 0;
    left: 0;
    object-fit: cover;
`;


function ThreeServices({idsection}) {

    const [threeServicesData, setThreeServicesData] = React.useState(null)

    React.useEffect(() => {
        if(!threeServicesData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setThreeServicesData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [threeServicesData, idsection])

    return (
        <React.Fragment>
            <Desktop>
                {
                    !threeServicesData ? <Spinner spinnerType='ThreeDots' />
                    :
                    <Root height='100vh' background='#21282d'>
                        <Header>
                            <Zoom>
                                <h3> {threeServicesData.title} </h3>
                            </Zoom>
                        </Header>
                        <Container>
                            <Card>
                                <FrontFace>
                                    <Image src={threeServicesData.img1} alt={threeServicesData.name1} />
                                    <Content>
                                        <h4> {threeServicesData.name1} </h4>
                                    </Content>
                                </FrontFace>
                                <BackFace>
                                    <Content background='#A30D0D' textColor='white'>
                                        <p> {threeServicesData.text1} </p>
                                    </Content>
                                </BackFace>
                            </Card>
                            <Card>
                                <FrontFace>
                                    <Image src={threeServicesData.img2} alt={threeServicesData.name2} />
                                    <Content>
                                        <h4> {threeServicesData.name2} </h4>
                                    </Content>
                                </FrontFace>
                                <BackFace>
                                    <Content background='#A30D0D' textColor='white'>
                                        <p> {threeServicesData.text2} </p>
                                    </Content>
                                </BackFace>
                            </Card>
                            <Card>
                                <FrontFace>
                                    <Image src={threeServicesData.img3} alt={threeServicesData.name3} />
                                    <Content>
                                        <h4> {threeServicesData.name3} </h4>
                                    </Content>
                                </FrontFace>
                                <BackFace>
                                    <Content background='#A30D0D' textColor='white'>
                                        <p> {threeServicesData.text3} </p>
                                    </Content>
                                </BackFace>
                            </Card>
                        </Container>
                    </Root>   
                }
            </Desktop>
            <Tablet>
                {
                    !threeServicesData ? <Spinner spinnerType='ThreeDots' />
                    :
                    <Root height='100vh' background='#4E4E4E'>
                        <Header height='10%' fontSize='0.75rem'>
                            <h3>Nuestros Servicios</h3>
                        </Header>
                        <Container height='90%' >
                            <Card>
                                <FrontFace>
                                    <Image src={threeServicesData.img1} alt={threeServicesData.name1} />
                                    <Content>
                                        <h4> {threeServicesData.name1} </h4>
                                    </Content>
                                </FrontFace>
                                <BackFace>
                                    <Content background='white'>
                                        <p> {threeServicesData.text1} </p>
                                    </Content>
                                </BackFace>
                            </Card>
                            <Card>
                                <FrontFace>
                                    <Image src={threeServicesData.img2} alt={threeServicesData.name2} />
                                    <Content>
                                        <h4> {threeServicesData.name2} </h4>
                                    </Content>
                                </FrontFace>
                                <BackFace>
                                    <Content background='white'>
                                        <p> {threeServicesData.text2} </p>
                                    </Content>
                                </BackFace>
                            </Card>
                            <Card>
                                <FrontFace>
                                    <Image src={threeServicesData.img3} alt={threeServicesData.name3} />
                                    <Content>
                                        <h4> {threeServicesData.name3} </h4>
                                    </Content>
                                </FrontFace>
                                <BackFace>
                                    <Content background='white'>
                                        <p> {threeServicesData.text3} </p>
                                    </Content>
                                </BackFace>
                            </Card>
                        </Container>
                    </Root>
                }
            </Tablet>
            <Mobile>
                {
                    !threeServicesData ? <Spinner spinnerType='ThreeDots' />
                    :
                    <Root height='200vh' background='#4E4E4E'>
                        <Header height='10%' fontSize='0.75rem' alignY='center'>
                            <h3>Nuestros Servicios</h3>
                        </Header>
                        <Container height='90%' flexDirection='column' padding='0'>
                            <Card width='100%'>
                                <FrontFace>
                                    <Image src={threeServicesData.img1} alt={threeServicesData.name1} />
                                    <Content alignSelf='center'>
                                        <h4> {threeServicesData.name1}</h4>
                                    </Content>
                                </FrontFace>
                                <BackFace>
                                    <Content background='white' fontSize='0.75rem'>
                                        <p> {threeServicesData.text1} </p>
                                    </Content>
                                </BackFace>
                            </Card>
                            <Card width='100%'>
                                <FrontFace>
                                    <Image src={threeServicesData.img2} alt={threeServicesData.name2} />
                                    <Content alignSelf='center'>
                                        <h4> {threeServicesData.name2} </h4>
                                    </Content>
                                </FrontFace>
                                <BackFace>
                                    <Content background='white' fontSize='0.75rem'>
                                        <p> {threeServicesData.text2} </p>
                                    </Content>
                                </BackFace>
                            </Card>
                            <Card width='100%'>
                                <FrontFace>
                                    <Image src={threeServicesData.img3} alt={threeServicesData.name3} />
                                    <Content alignSelf='center'>
                                        <h4> {threeServicesData.name3} </h4>
                                    </Content>
                                </FrontFace>
                                <BackFace>
                                    <Content background='white' fontSize='0.75rem'>
                                        <p> {threeServicesData.text3} </p>
                                    </Content>
                                </BackFace>
                            </Card>
                        </Container>
                    </Root>
                }
            </Mobile>
        </React.Fragment>
    )
}

export default ThreeServices;