import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckFilter = ({filter, name, value, onChange}) => {
    const handleChange = (e) => {
        onChange(filter, name);
    }

    return (
        <FormControlLabel
            label={name}
            control={<Checkbox size="small" checked={value} onChange={handleChange} />}
            
        />
    );
}

const SwitchFilter = ({filter, value, onChange}) => {

    const handleChange = (e) => {
        onChange(filter, e.target.checked);
    }

    return (
        <FormControlLabel 
            label={filter}
            control={<Switch size="small" checked={value} onChange={handleChange} />}  
        />
    )
}

const Filter = ({type, filter, name, value, onChange}) => {

    switch (type) {
        case "check":
                return <CheckFilter filter={filter} name={name} value={value} onChange={onChange} />
        case "switch":
                return <SwitchFilter filter={filter} value={value} onChange={onChange} />
        default:
            break;
    }
    
}

export default Filter;