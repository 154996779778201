/* General imports */
import React from 'react'
import { connect } from 'react-redux'
import { Route, Link } from 'react-router-dom'
/* import { dashboardActions } from './DashboardActions' */
import { loginActions } from '../login/LoginActions'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { config, theme, devices, layoutConfig } from '../app/config'
import { authHeader } from '../app/auth-header'
import axios from 'axios'

/* Components imports */
import Sidebar from '../sidebar/SidebarMole'
import { components } from '../app/componentList'
import { menuItems, internalPages } from './menuItems'
import ImageProfile from '../icons/userCircle.svg'
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import PrivateRoute from '../app/PrivateRoute'
import ModalUpdateUser from '../register/ModalUpdateUser'

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Root = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: initial;
    display: flex;
    justify-content: left;
    flex-direction: column;
`

const Header = styled.div`
    width: 100%;
    height: ${layoutConfig.header};
    box-sizing: border-box;
    display: flex;
    font-size: ${(props) => props.fontSize ? props.fontSize : '40px'};
    color: ${theme.default.sidebarBackground};
    align-items: center;
    padding-left: ${(props) => props.mobile ? 0 : props.open ? '280px' : '95px'};
    transition: all 200ms ease;
    background-color: #f5f5f5;
    justify-content: space-between;
`

const Content = styled.div`
    width: 100%;
    height: calc(100vh - ${layoutConfig.header});
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: ${(props) => props.mobile ? 0 : props.open ? '250px' : '65px'};
    transition: all 200ms ease;
    flex-direction: column;
    background-color: white;
    ${(props) => props.minWidth ? `min-width: ${props.minWidth};` : ''}
    ${(props) => props.minHeight ? `min-height: ${props.minHeight};` : ''}
`

const AccessIcons = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-left: 25px;
`

const Options = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    padding-right: 25px;
`

const Profile = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding: 0 15px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.open ? 'transparent' : 'rgba(0, 0, 0, 0.2)'};
    }
    img {
        width: 40px;
        height: 40px;
        margin: 0 0 0 15px;
    }
`

const ProfileMenu = styled.ul`
    position: absolute;
    top: 90%;
    margin: 0;
    display: ${(props) => props.open ? 'flex' : 'none'};
    opacity: ${(props) => props.open ? 1 : 0};
    flex-direction: column;
    transition: all .5s ease;
    width: 95%;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    outline: none;
    z-index: 100;
    padding: 10px 0;
    right: 2.5%;
    border-radius: 10px;
    background-color: #f5f5f5;
    a{
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100%;
        line-height: 40px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        box-sizing: border-box;
        padding: 0 12px;
        transition: all 200ms ease;
        span {
            font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
            font-weight: 400;
            line-height: 1.5;
        }
    }
    a:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
`

const DataProfile = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    font-size: 14px;
    text-align: right;
    user-select: none;
    span {
        opacity: .5;
    }
`

const ToggleButton = styled.button`
    position: relative;
    display: flex;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 15px;
    border: none;
    align-items: center;
    justify-content: center;
    outline: none;
    &:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
`

const UpdateProfile = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    line-height: 40px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    padding: 0 12px;
    transition: all 200ms ease;
    border: none;
    &:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
`

/* ----- Principal function ----- */
function Dashboard(props) {

    const [open, setOpen] = React.useState(false);

    const [updateProfile, setUpdateProfile] = React.useState(false);

    const [userProfile, setUserProfile] = React.useState(null);

    const [profileMenu, setProfileMenu] = React.useState(false);

    const [fullScreen, setFullScreen] = React.useState(((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) ? false : true)

    const handleMenu = () => {
        setOpen(!open);
    };

    const handleProfileMenu = () => {
        setProfileMenu(!profileMenu);
    };

    const toggleFullScreen = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||    
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {  
                document.documentElement.requestFullScreen(); 
                setFullScreen(true);
            } else if (document.documentElement.mozRequestFullScreen) {  
                document.documentElement.mozRequestFullScreen();  
                setFullScreen(true);
            } else if (document.documentElement.webkitRequestFullScreen) {  
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);  
                setFullScreen(true);
            }  
        } else {  
            if (document.cancelFullScreen) {  
                document.cancelFullScreen();  
                setFullScreen(false);
            } else if (document.mozCancelFullScreen) {  
                document.mozCancelFullScreen();  
                setFullScreen(false);
            } else if (document.webkitCancelFullScreen) {  
                document.webkitCancelFullScreen();  
                setFullScreen(false);
            }  
        }  
    }

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        let configHeader = {
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        }
        axios.get(`/api/users/${props.user.idUser}`,configHeader)
        .then(userData => {
            setUserProfile(userData.data.user);
            setUpdateProfile(true);
        })
        .catch(err => {
            console.log(err);
        })
        
    }

    const closeUpdateProfile = () => {
        setUpdateProfile(false);
    }

    return(
        <React.Fragment>
            <Sidebar open={open} handleAction={handleMenu} handleClose={handleMenu} title={config.companyName} menuItems={menuItems} logout={props.logout} user={props.user} />
            <Root>
                {/* ----------DESKTOP---------- */}
                <Desktop>
                    <Header open={open}>
                        <AccessIcons>

                        </AccessIcons>
                        <Options>
                            <ToggleButton onClick={toggleFullScreen}>
                                {
                                    fullScreen ?
                                        <FullscreenExitIcon />
                                    :
                                        <FullscreenIcon />
                                }
                            </ToggleButton>
                            <Profile onClick={handleProfileMenu} open={profileMenu}>
                                <DataProfile>
                                    <strong>{props.user.username}</strong>
                                    <span>{props.user.role.name}</span>
                                </DataProfile>
                                <img src={ImageProfile} alt='Profile' />
                                <ProfileMenu open={profileMenu}>
                                    <UpdateProfile onClick={handleUpdateProfile}>
                                        Perfil
                                    </UpdateProfile>
                                    <Link to='/login' onClick={props.logout}>
                                        <span>Cerrar Sesión</span>
                                    </Link>
                                </ProfileMenu>
                            </Profile>
                        </Options>
                    </Header>
                    <Content open={open} minWidth='1280px' minHeight='600px'>
                        {
                            menuItems.map( (r) => 
                                r.menus.map((m, index) => (
                                    <PrivateRoute key={`${m.id}+${index}`} path={m.link} exact={m.exact} name_access={m.name_access} user={props.user} component={components.find(comp => comp.name === m.id).component} />    
                                ))
                            )
                        }
                        {
                            internalPages.map( (r, index) => 
                                <PrivateRoute key={`${r.name}+${index}`} path={r.path} exact={r.exact} name_access={r.name_access} user={props.user} component={components.find(comp => comp.name === r.pageType).component} />  
                            )
                        }
                    </Content>
                    {
                        userProfile && updateProfile && <ModalUpdateUser openModal={updateProfile} data={userProfile} closeModal={closeUpdateProfile} />
                    }
                </Desktop>
                {/* ----------TABLET---------- */}
                <Tablet>
                    <Header fontSize='17px' paddingLeft='100px'>
                        <AccessIcons>

                        </AccessIcons>
                        <Options>
                            <ToggleButton onClick={toggleFullScreen}>
                                {
                                    fullScreen ?
                                        <FullscreenExitIcon />
                                    :
                                        <FullscreenIcon />
                                }
                            </ToggleButton>
                            <Profile onClick={handleProfileMenu} open={profileMenu}>
                                <DataProfile>
                                    <strong>{props.user.username}</strong>
                                    <span>{props.user.role.name}</span>
                                </DataProfile>
                                <img src={ImageProfile} alt='Profile' />
                                <ProfileMenu open={profileMenu}>
                                    <Link to='/login' onClick={props.logout}>
                                        <span>Cerrar Sesión</span>
                                    </Link>
                                </ProfileMenu>
                            </Profile>
                        </Options>
                    </Header>
                    <Content>
                        {
                            menuItems.map( (r) => 
                                r.menus.map((m, index) => (
                                    <Route key={`${m.id}+${index}`} path={m.link} exact={m.exact} component={components.find(comp => comp.name === m.id).component} />    
                                ))
                            )
                        }
                    </Content>
                </Tablet>
                {/* ----------MOBILE---------- */}
                <Mobile>
                    <Header fontSize='14px' mobile={true} >

                    </Header>
                    <Content mobile={true} >
                        {
                            menuItems.map( (r) => 
                                r.menus.map((m, index) => (
                                    <Route key={`${m.id}+${index}`} path={m.link} exact={m.exact} component={components.find(comp => comp.name === m.id).component} />    
                                ))
                            )
                        }
                    </Content>
                </Mobile>
            </Root>
        </React.Fragment>
    );
}

function mapState(state) {
    const { loggingIn, user } = state.login;
    return { loggingIn, user };
}

const actionCreators = {
    login: loginActions.login,
    logout: loginActions.logout
};

export default connect(mapState, actionCreators)(Dashboard);