import { LOCALES } from '../locales';

export default {
	[LOCALES.SPANISH]: {
		'hello': 'Hola, {name}!',
		'page': 'Página',
		'of': 'de',
		'show': 'Mostrar',
		'records': 'registros',
		'total': 'Total',
		'actions': 'Acciones',
		'search': 'Buscar',
		'selected': 'Seleccionados'
	}
}
