/* General imports */
import React from "react";
import styled from "styled-components";
import { alertActions } from "../../../alert/AlertActions";
import { connect } from "react-redux";
import axios from "axios";
import { authHeader } from "../../../app/auth-header";

/* Components imports */
import CancelIcon from "@material-ui/icons/Cancel";
import ProductInput from "./ProductInput";
import ItemsTable from "./ItemsTable";
import TextField from "../../../form/TextField";
import { updateSuppliersActions } from "./UpdateSuppliersActions";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 20px;
  flex-direction: column;
  background-color: white;
`;

const BoxContainer = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  grid-template-columns: 80% 20%;
  grid-template-rows: 1fr;
  box-sizing: border-box;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 15% 80%;
  gap: 20px;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 95%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 10px;
  grid-column: 1/3;
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  box-sizing: border-box;
`;

const IconAction = styled.div`
  position: absolute;
  display: flex;
  width: 25px;
  height: 25px;
  top: 5px;
  right: ${(props) => (props.right ? props.right : "10px")};
  align-items: center;
  justify-content: center;

  svg {
    font-size: 25px;
    cursor: pointer;
    z-index: 999;
  }
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  font-size: 1.5rem;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: all 300ms ease-in-out;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    font-weight: 700;
  }
`;

const Note = styled.span`
    margin: 20px auto;
    font-size: 2rem;
    font-weight: 600;
`

/* ----- Principal function ----- */
// PROPS: user, alertMessage, items, setItems, bulkExchangeStock, history
function UpdateSupplierPrices(props) {
  const [suppliers, setSuppliers] = React.useState(null);

  const productInputRef = React.useRef();

  const reset = () => {
    props.setItems([]);
  };

  const cancelCode = () => {
    reset();
    props.setUpdateSuppliersData({
      idSupplier: -1,
    });
  };

  const cancel = () => {
    cancelCode();
    props.history.push("/admin/stocksystem/productxsupplier");
  };

  const changeAmount = (index, value) => {
    let newItems = [...props.items];
    newItems[index].newPrice = parseFloat(value);
    props.setItems([...newItems]);
  };

  const deleteItem = (indexDelete) => {
    let newItems = props.items.filter(function (value, index, arr) {
      return parseInt(index) !== parseInt(indexDelete);
    });
    props.setItems([...newItems]);
  };

  const handleChange = (name, value) => {
    props.setUpdateSuppliersData({
      ...props.updatesuppliersdata,
      [name]: value,
    });
  };

  const submit = (e) => {
    e.preventDefault();
    if (
      props.updatesuppliersdata.idSupplier === null ||
      props.updatesuppliersdata.idSupplier === -1
    ) {
      props.alertMessage("Debe completar campos obligatorios");
    } else if (!props.items.length > 0) {
      props.alertMessage("Debe haber al menos un item cargado");
    } else {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("¿Está seguro/a de aplicar esta actualización?")) {
        try {
          props.bulkUpdateProductsSupplier(props.user.idUser);
          cancelCode();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  React.useEffect(() => {
    let configHeader = {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    if (!suppliers) {
      axios
        .get(`/api/supplierslist`, configHeader)
        .then((suppliersData) => {
          const suppliersList = [];
          suppliersData.data.forEach((element) => {
            suppliersList.push({
              name: element.value,
              value: element.id,
            });
          });
          setSuppliers(suppliersList);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [suppliers]);

  return (
    <Root>
      <IconAction>
        <CancelIcon onClick={cancel} />
      </IconAction>
      <BoxContainer>
        <Box>
          {suppliers && (
            <TextField
              id="idSupplier"
              value={props.updatesuppliersdata.idSupplier}
              required={true}
              name="idSupplier"
              placeholder="Proveedor"
              options={suppliers}
              type="select"
              fontSize="20px"
              onChange={handleChange}
              disabled={props.items.length === 0 ? false : true}
            />
          )}
          <ProductInput
            ref={productInputRef}
            setItems={props.setItems}
            alertMessage={props.alertMessage}
            items={props.items}
            idSupplier={props.updatesuppliersdata.idSupplier}
          />

          <Container>
            {props.updatesuppliersdata.idSupplier === null ||
            props.updatesuppliersdata.idSupplier === -1 ? (
              <Note>Seleccione un proveedor</Note>
            ) : (
              <ItemsTable
                items={props.items}
                changeAmount={changeAmount}
                deleteItem={deleteItem}
                idSupplier={props.updatesuppliersdata.idSupplier}
              />
            )}
          </Container>
        </Box>

        <Actions>
          
          <StyledButton onClick={submit}>APLICAR</StyledButton>
          <StyledButton onClick={cancelCode}>CANCELAR</StyledButton>
        </Actions>
      </BoxContainer>
    </Root>
  );
}

function mapState(state) {
  const { items, updatesuppliersdata } = state.updatesuppliers;
  const { user } = state.login;
  return { user, items, updatesuppliersdata };
}

const actionCreators = {
  setItems: updateSuppliersActions.setItems,
  setUpdateSuppliersData: updateSuppliersActions.setUpdateSuppliersData,
  bulkUpdateProductsSupplier: updateSuppliersActions.bulkUpdateProductsSupplier,
  alertMessage: alertActions.error,
};

export default connect(mapState, actionCreators)(UpdateSupplierPrices);
