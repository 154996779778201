export const stockConstants = {
    FETCHING_FORMFIELDS: 'STOCK_FETCHING_FORMFIELDS',
    SUCCESS_FORMFIELDS: 'STOCK_SUCCESS_FORMFIELDS',
    SUCCESS_UPDATEFORMFIELDS: 'STOCK_SUCCESS_UPDATEFORMFIELDS',
    ERROR_FORMFIELDS: 'STOCK_ERROR_FORMFIELDS',
    SUCCESS: 'STOCK_SUCCESS',
    ERROR: 'STOCK_ERROR',
    FETCHING: 'STOCK_FETCHING',
    CURRENCY_GROUP: 'unitsCurrency',
    SUCCESS_CURRENCYUNITS: 'CURRENCY_UNITS_SUCCESS',
    SUCCESS_QUANTITYUNITS: 'QUANTITY_UNITS_SUCCESS',
    QUANTITY_GROUP: 'unitsMeasurement',
    SUPPLIER_IDROLE: 4,
    SET: 'STOCK_SET',
    SETDATA: 'STOCK_SETDATA'
};
