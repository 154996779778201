import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginActions } from '../login/LoginActions';
import { AuthNameAccess } from '../utils/AuthNameAccess' 

// This component allows rendering the components if there is user login, but redirects it to the login page

function PrivateRoute({ component: Component, ...rest }) {
    return (    
        <Route {...rest} render={props => (
            auth(rest)
                ? <Component {...props} {...rest} /> 
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    )
}

function auth(rest){
    if(rest.loggedIn) {
        /* First, the expiration of the session is corroborated */
        if(JSON.parse(atob(rest.user.token.split('.')[1])).exp < Math.floor(Date.now() / 1000)){
            rest.logout(false)
            return false
        } else {
            /* Then the user's access permissions are confirmed */
            return AuthNameAccess(rest.user, rest.name_access)
        }
    } else {
        return false
    }
}

function mapState(state) {
    const { loggedIn, user } = state.login;
    return { loggedIn, user };
}

const actionCreators = {
    logout: loginActions.logout
};

export default connect(mapState, actionCreators)(PrivateRoute)