import { updateSuppliersConstants } from './UpdateSuppliersConstants';
import { alertActions } from '../../../alert/AlertActions';
import axios from 'axios';
import  { authHeader } from '../../../app/auth-header';

export const updateSuppliersActions = {
    setItems,
    setUpdateSuppliersData,
    bulkUpdateProductsSupplier
};


function setItems(items) {
    return dispatch => {
        localStorage.setItem('updatesuppliersitems', JSON.stringify(items));
        dispatch({ type: updateSuppliersConstants.SET, items });
    };
}

function setUpdateSuppliersData(data) {
    return dispatch => {
        localStorage.setItem('updatesuppliersdata', JSON.stringify(data));
        dispatch({ type: updateSuppliersConstants.SETDATA, data });
    };
}

function bulkUpdateProductsSupplier(userId) {
    let configHeader = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }
    const items = JSON.parse(localStorage.getItem('updatesuppliersitems'));
    const updatesuppliersdata = JSON.parse(localStorage.getItem('updatesuppliersdata'));
    return dispatch => {
        axios.post( '/api/bulkupdateproductssupplier', {
            ...updatesuppliersdata,
            items: items,
            createdUser: userId
            }, configHeader)
        .then(response => {
            dispatch(alertActions.success('Precios del proveedor actualizados'));
        })
        .catch(error => {
            console.log(error)
            dispatch(alertActions.error('Hubo un error al actualizar los precios...'));
        })
    };
}
