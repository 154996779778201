/* General imports */
import React from 'react'
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { devices } from '../../app/config';
import axios from 'axios';
import  { authHeader } from '../../app/auth-header';


/* Components imports */
import Spinner from '../../spinners/LoaderSpinner'
import LocationOn from './LocationOn.svg';
import instagramIcon from './instagramIcon.svg';
import facebookIcon from './facebookIcon.svg';
import Zoom from 'react-reveal/Zoom';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled Components */
const Root = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '30vh'};
    min-height:${(props) => props.minHeight ? props.minHeight : '200px'};
    position: relative;
    display: ${(props) => props.display ? props.display : 'flex'};
    background: ${(props) => props.background ? props.background : 'linear-gradient(90deg, rgba(135,18,55,1) 0%, rgba(245,44,62,1) 100%)'};
`

const Row = styled.div`
    width:100%;
    height: ${(props) => props.height ? props.height : '100%'};
    display:flex;
    align-items: center;
    justify-content:center;
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
`;

const Column = styled.div`
    width: ${(props) => props.width ? props.width : '50%'};
    height:100%;
    font-family: 'Josefin Sans', sans-serif;
    box-sizing: border-box;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'column'};
    padding: ${(props) => props.padding ? props.padding : 0};
    background: ${(props) => props.background ? props.background : 'transparent'};
    color: white;
    font-size: ${(props) => props.fontSize ? props.fontSize : '1.25rem'};
    text-align:center;
    h3 {
        color: #FFFFFF;
        text-shadow: black 0.1em 0.1em 0.2em;
        text-align:center;
        font-size: ${(props) => props.fontSize ? `calc(${props.fontSize}*2.4)` : '3rem'};
    }
`

const Image = styled.img`
    position: relative;
    display: flex;
    width: auto;
    height: 70%;
`;

const DataContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 90%;
    box-sizing: border-box;
    justify-content:center;
    background: ${(props) => props.background ? props.background : 'transparent'};
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1vw;
    color: white;
    padding-top: ${(props) => props.paddingTop ? props.paddingTop : '10px'};
    padding-bottom: ${(props) => props.paddingBottom ? props.paddingBottom : '20px'};
`;

const DataTitle = styled.ul`
    display: flex;
    list-style: none;
    font-family: 'Josefin Sans', sans-serif;
    font-size: ${(props) => props.fontSize ? props.fontSize : '20px'};
    font-weight: 700;
    width: 100%;
    flex-direction: column;
    align-self:center;
    padding: 0 2px;
    margin:15px;
`

const DataSubtitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
`

const DataValue = styled.li`
    display: flex;
    align-items:center;
    list-style: none;
    align-self:center;
    font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};
    width: 100%;
    justify-content: center;
    margin: 2.5px 0; 
`

const Icons = styled.img`
    width: auto;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-right:24px;
`

/* Principal function*/
function FooterSection({idsection}) {

    const [sectionData, setSectionData] = React.useState(null)

    React.useEffect(() => {
        if(!sectionData){
            let configHeader = {
                headers: { ...authHeader(), 'Content-Type': 'application/json' }
            }
            axios.get('/api/sections/' + idsection,configHeader)
            .then(sectionData => {
                setSectionData(sectionData.data.section.data);
            })
            .catch(err => {
                alert('Hubo un error...');
            })
        }
    }, [sectionData, idsection])

    return (
        !sectionData ? <Spinner spinnerType='ThreeDots' />
        :
        <React.Fragment>
            <Desktop>
                <Root >
                    <Row>
                        <Column><Image src={sectionData.img} /> </Column>
                        <Column>
                            <DataContainer >
                                <Zoom bottom cascade>
                                    <DataTitle> 
                                        <DataSubtitle>{sectionData.title}</DataSubtitle>
                                        {
                                            sectionData.emails.split(';').map((email, index) => (
                                                <DataValue key={index}>{email}</DataValue>
                                            ))
                                        }
                                        {
                                            sectionData.phones.split(';').map((phone, index) => (
                                                <DataValue key={index}>{phone}</DataValue>
                                            ))
                                        }
                                        {
                                            sectionData.addresses.split(';').map((address, index) => (
                                                <DataValue key={index}>{address}</DataValue>
                                            ))
                                        }
                                    </DataTitle>
                                </Zoom>
                            </DataContainer>
                        </Column>
                        <Column flexDirection='row' >
                            <Zoom bottom cascade>
                            {
                                sectionData.googleMap !== '' && <a target="_blank" rel="noopener noreferrer" href={sectionData.googleMap} ><Icons src={LocationOn} alt='Icon Facebook' /></a>
                            }
                            {
                                sectionData.instagram !== '' && <a target="_blank" rel="noopener noreferrer" href={sectionData.instagram} ><Icons src={instagramIcon} alt='Icon Facebook' /></a>
                            }
                            {
                                sectionData.facebook !== '' && <a target="_blank" rel="noopener noreferrer" href={sectionData.facebook} ><Icons src={facebookIcon} alt='Icon Facebook' /></a>
                            }
                            </Zoom>
                        </Column>
                    </Row>
                </Root>
            </Desktop>
            <Tablet>
                <Root minHeight='150px'>
                    <Row>
                        <Column> <Image src={sectionData.img} />  </Column>
                        <Column>
                            <DataContainer>
                                <DataTitle fontSize='14px'>
                                    <DataSubtitle>{sectionData.title}</DataSubtitle>    
                                    {
                                        sectionData.emails.split(';').map((email, index) => (
                                            <DataValue fontSize='10px' key={index}>{email}</DataValue>
                                        ))
                                    }
                                    {
                                        sectionData.phones.split(';').map((phone, index) => (
                                            <DataValue fontSize='10px' key={index}>{phone}</DataValue>
                                        ))
                                    }
                                    {
                                        sectionData.addresses.split(';').map((address, index) => (
                                            <DataValue fontSize='10px' key={index}>{address}</DataValue>
                                        ))
                                    }
                                </DataTitle>
                            </DataContainer>
                        </Column>
                        <Column flexDirection='row' >
                            {
                                sectionData.googleMap !== '' && <a target="_blank" rel="noopener noreferrer" href={sectionData.googleMap} ><Icons src={LocationOn} alt='Icon Facebook' /></a>
                            }
                            {
                                sectionData.instagram !== '' && <a target="_blank" rel="noopener noreferrer" href={sectionData.instagram} ><Icons src={instagramIcon} alt='Icon Facebook' /></a>
                            }
                            {
                                sectionData.facebook !== '' && <a target="_blank" rel="noopener noreferrer" href={sectionData.facebook} ><Icons src={facebookIcon} alt='Icon Facebook' /></a>
                            }
                        </Column>
                    </Row>
                </Root>
            </Tablet>
            <Mobile>
                <Root minHeight='150px' display='block'>
                    <Row height='70%' >
                        <Column width='40%'> <Image src={sectionData.img} />  </Column>
                        <Column width='60%'>
                            <DataContainer paddingTop='0' paddingBottom='0' >
                                {
                                    sectionData.emails.split(';').map((email, index) => (
                                        <DataValue fontSize='10px' key={index}>{email}</DataValue>
                                    ))
                                }
                                {
                                    sectionData.phones.split(';').map((phone, index) => (
                                        <DataValue fontSize='10px' key={index}>{phone}</DataValue>
                                    ))
                                }
                                {
                                    sectionData.addresses.split(';').map((address, index) => (
                                        <DataValue fontSize='10px' key={index}>{address}</DataValue>
                                    ))
                                }
                            </DataContainer>
                        </Column>
                    </Row>
                    <Row height='30%' width='100%'>
                        {
                            sectionData.googleMap !== '' && <a target="_blank" rel="noopener noreferrer" href={sectionData.googleMap} ><Icons src={LocationOn} alt='Icon Facebook' /></a>
                        }
                        {
                            sectionData.instagram !== '' && <a target="_blank" rel="noopener noreferrer" href={sectionData.instagram} ><Icons src={instagramIcon} alt='Icon Facebook' /></a>
                        }
                        {
                            sectionData.facebook !== '' && <a target="_blank" rel="noopener noreferrer" href={sectionData.facebook} ><Icons src={facebookIcon} alt='Icon Facebook' /></a>
                        }
                    </Row>
                </Root>
            </Mobile>
        </React.Fragment >
    )
}

export default FooterSection;