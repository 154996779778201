/* General imports */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { devices } from '../app/config'
import { NavLink } from 'react-router-dom'
import { loginActions } from '../login/LoginActions'
import { sidebarCartActions } from '../SidebarCart/SidebarCartActions'

/* Components imports */
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ImageProfile from '../icons/userCircle.svg'
import ModalLogin from '../login/ModalLogin'
import ModalRegister from '../register/ModalRegister'
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

/* Responsive configuration */
const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: devices.desktopMinWidth })
    return isDesktop ? children : null
}
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: devices.tabletMinWidth, maxWidth: devices.tabletMaxWidth })
    return isTablet ? children : null
}
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: devices.mobileMaxWidth })
    return isMobile ? children : null
}

/* Styled components */
const Nav = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${(props) => props.height ? props.height : '100px'};
    padding: 0px 50px 0 50px;
    box-sizing: border-box;
    transition: all 1s ease;
    background: ${(props) => props.animate ? 'rgba(185,225,137,0.8)' : 'linear-gradient(90deg,rgba(185,225,137,1) 0%,rgba(146,154,21,1) 100%)'};
    z-index: 100;
    img {
        float: left;
        height: ${(props) => props.animate ? '90px' : 'auto'};
        width: ${(props) => props.animate ? 'auto' : '200px'};
        transition: all 1s ease;
    }
`

const NavUl = styled.ul`
    float: right;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    span {
        text-shadow: ${(props) => props.animate ? '1px 1px 2px white' : '1px 1px 2px black'};
    }
`

const NavUlMobile = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: rgba(0,0,0,0.8);
    top: 100%;
    right: 0;
    margin: 0;
    padding: 0;
    height: calc(100vh - 100px);
    width: ${(props) => props.show ? '80%' : 0};
    border-radius: 10px 0 0 10px;
    overflow-x: hidden;
    transition: all 300ms ease-in-out;
    span {
        text-shadow: ${(props) => props.animate ? '1px 1px 2px white' : '1px 1px 2px black'};
    }
`

const NavLi = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    transition: all .5s ease-in-out;
    color: white;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 0 0 10px 10px;
    background: ${(props) => props.active ? 'linear-gradient(90deg,rgba(185,225,137,1) 0%,rgba(146,154,21,1) 100%)' : 'transparent'};
    cursor: pointer;
    line-height: 50px;
    height: 100%;
    &:hover {
        background: linear-gradient(90deg,rgba(185,225,137,1) 0%,rgba(146,154,21,1) 100%);
        color: white;
    }
`

const ItemLink = styled(NavLink)`
    line-height: 70px;
    color: ${(props) => props.animate ? 'black' : 'white'};
    padding: 5px 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: ${(props) => props.animate ? '1px 1px 2px white' : '1px 1px 2px black'};
    transition: .5s;
`

const CartIcon = styled(ShoppingCartOutlinedIcon)`
    display: flex;
    position: relative;
    cursor: pointer;
    filter: brightness(0.5);
`

const Profile = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding: 0 15px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.open ? 'transparent' : 'rgba(0, 0, 0, 0.2)'};
    }
    img {
        width: 40px;
        height: 40px;
        margin: 0 0 0 15px;
    }
`

const ProfileMenu = styled.ul`
    position: absolute;
    top: 90%;
    margin: 0;
    display: ${(props) => props.open ? 'flex' : 'none'};
    opacity: ${(props) => props.open ? 1 : 0};
    transition: all .5s ease;
    width: 100%;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    outline: none;
    z-index: 100;
    padding: 10px;
    box-sizing: border-box;
    right: 2.5%;
    border-radius: 0 0 10px 10px;
    background: linear-gradient(90deg,rgba(185,225,137,1) 0%,rgba(146,154,21,1) 100%);
    a{
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100%;
        line-height: 40px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        box-sizing: border-box;
        padding: 0 12px;
        transition: all 200ms ease;
        span {
            font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
            font-weight: 400;
            line-height: 1.5;
        }
    }
    a:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
`

const ItemProfile = styled.li`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    span {
        font-family: Muli,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 400;
        line-height: 1.5;
    }
    &:hover {
        background: linear-gradient(90deg,rgba(185,225,137,1) 0%,rgba(146,154,21,1) 100%);
    }
`

const DataProfile = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    font-size: 14px;
    text-align: right;
    user-select: none;
    text-shadow: 0 0 5px rgba(0,0,0,0.8);
    span {
        opacity: .5;
    }
`

const MenuButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    display: flex;
    background: transparent;
    box-sizing: border-box;
    padding: 5px 10px;
    color: white;
    outline: none;
`

/* ----- Principal function ----- */
function NavAnimate({data, switchSidebarCart, cartitems, loggingIn, loggedIn, user, modalLogin, logout, getFields, history }) {

    const [navAnimate, setNavAnimate] = React.useState(false)
    const [profileMenu, setProfileMenu] = React.useState(false);
    const [ openLogin, setOpenLogin ] = React.useState(false);
    const [ openRegister, setOpenRegister ] = React.useState(false);
    const [ openMenu, setOpenMenu ] = React.useState(false);

    const navRef = React.useRef(null)

    const handleProfileMenu = () => {
        setProfileMenu(!profileMenu);
    };

    const openModalLogin = () => {
        setOpenLogin(true);
    }

    const closeModalLogin = () => {
        setOpenLogin(false);
    }

    const openModalRegister = () => {
        setOpenRegister(true);
    }

    const closeModalRegister = () => {
        setOpenRegister(false);
    }

    const onMenu = () => {
        setOpenMenu(!openMenu)
    }

    React.useLayoutEffect(() => {
    
        const onScroll = () => {
            const scrollPos = window.scrollY;
            if (scrollPos > 5) {
                setNavAnimate(true)
            } else {
                setNavAnimate(false)
            }
        };
    
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return(
        <React.Fragment>
            <Desktop>
                <Nav height={data.height} animate={navAnimate} ref={navRef}>
                    <img src={data.logo} alt='Logo' />
                    <NavUl>
                        {
                            data.items.map((item, index) =>
                                item.showNav && <NavLi key={`MenuItem${index}`}><ItemLink exact to={item.link} >{item.title}</ItemLink></NavLi>
                            )
                        }
                        {/* <NavLi><CartIcon onClick={switchSidebarCart} /><span>{cartitems.length > 0 ? `(${cartitems.length})` : ''}</span></NavLi> */}
                        {
                            loggedIn ?
                            <NavLi active={profileMenu}>
                                <Profile onClick={handleProfileMenu} open={profileMenu}>
                                    <DataProfile>
                                        <strong>{user.username}</strong>
                                    </DataProfile>
                                    <img src={ImageProfile} alt='Profile' />
                                    <ProfileMenu open={profileMenu}>
                                        <ItemProfile onClick={logout}><span>Cerrar Sesión</span></ItemProfile>
                                    </ProfileMenu>
                                </Profile>
                            </NavLi>
                            :
                            <NavLi onClick={openModalLogin}><span>Acceder</span></NavLi>
                        }
                    </NavUl>
                    {
                        openLogin && <ModalLogin registerAccess={openModalRegister} openModal={openModalLogin} closeModal={closeModalLogin} history={history} />
                    }
                    {
                        openRegister && <ModalRegister loginAccess={openModalLogin} openModal={openModalRegister} closeModal={closeModalRegister} />
                    }
                </Nav>
            </Desktop>
            <Tablet>
            <Nav height={data.height} animate={navAnimate} ref={navRef}>
                    <img src={data.logo} alt='Logo' />
                    <NavUl>
                        {
                            data.items.map((item, index) =>
                                item.showNav && <NavLi key={`MenuItem${index}`}><ItemLink exact to={item.link} >{item.title}</ItemLink></NavLi>
                            )
                        }
                        {/* <NavLi><CartIcon onClick={switchSidebarCart} /><span>{cartitems.length > 0 ? `(${cartitems.length})` : ''}</span></NavLi> */}
                        {
                            loggedIn ?
                            <NavLi active={profileMenu}>
                                <Profile onClick={handleProfileMenu} open={profileMenu}>
                                    <DataProfile>
                                        <strong>{user.username}</strong>
                                    </DataProfile>
                                    <img src={ImageProfile} alt='Profile' />
                                    <ProfileMenu open={profileMenu}>
                                        <ItemProfile onClick={logout}><span>Cerrar Sesión</span></ItemProfile>
                                    </ProfileMenu>
                                </Profile>
                            </NavLi>
                            :
                            <NavLi onClick={openModalLogin}><span>Acceder</span></NavLi>
                        }
                    </NavUl>
                    {
                        openLogin && <ModalLogin registerAccess={openModalRegister} openModal={openModalLogin} closeModal={closeModalLogin} />
                    }
                    {
                        openRegister && <ModalRegister loginAccess={openModalLogin} openModal={openModalRegister} closeModal={closeModalRegister} />
                    }
                </Nav>
            </Tablet>
            <Mobile>
                <Nav height={data.height} animate={navAnimate} ref={navRef}>
                    <img src={data.logo} alt='Logo' />
                    <MenuButton onClick={onMenu}>
                        {
                            openMenu ?
                                <CloseIcon />
                            :
                                <MenuIcon />
                        }
                    </MenuButton>
                    <NavUlMobile show={openMenu}>
                        {
                            data.items.map((item, index) =>
                                item.showNav && <NavLi key={`MenuItem${index}`}><ItemLink exact to={item.link} >{item.title}</ItemLink></NavLi>
                            )
                        }
                        {/* <NavLi><CartIcon onClick={switchSidebarCart} /><span>{cartitems.length > 0 ? `(${cartitems.length})` : ''}</span></NavLi> */}
                        {
                            loggedIn ?
                            <NavLi active={profileMenu}>
                                <Profile onClick={handleProfileMenu} open={profileMenu}>
                                    <DataProfile>
                                        <strong>{user.username}</strong>
                                    </DataProfile>
                                    <img src={ImageProfile} alt='Profile' />
                                    <ProfileMenu open={profileMenu}>
                                        <ItemProfile onClick={logout}><span>Cerrar Sesión</span></ItemProfile>
                                    </ProfileMenu>
                                </Profile>
                            </NavLi>
                            :
                            <NavLi onClick={openModalLogin}><span>Acceder</span></NavLi>
                        }
                    </NavUlMobile>
                    {
                        openLogin && <ModalLogin registerAccess={openModalRegister} openModal={openModalLogin} closeModal={closeModalLogin} />
                    }
                    {
                        openRegister && <ModalRegister loginAccess={openModalLogin} openModal={openModalRegister} closeModal={closeModalRegister} />
                    }
                </Nav>
            </Mobile>
        </React.Fragment>
    );
}

function mapState(state) {
    const { cartitems, open} = state.sidebarcart;
    const { loggingIn, user, loggedIn } = state.login;
    return { cartitems, open, loggingIn, user, loggedIn};
}

const actionCreators = {
    switchSidebarCart: sidebarCartActions.switchSidebarCart,
    modalLogin: loginActions.modalLogin,
    logout: loginActions.logout,
    getFields: loginActions.getFields
}

export default connect(mapState, actionCreators)(NavAnimate);